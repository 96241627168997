import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { setFingerprintIdsHandler } from '@lib/api/auth';
import { setFpjsData } from '@lib/browser-storage';
import { useEffect } from 'react';

export const FingerprintBlock = () => {
	const { isLoading, error, data } = useVisitorData(
		{ extendedResult: true },
		{ immediate: true }
	);

	useEffect(() => {
		if (data && !isLoading && !error) {
			setFpjsData('requestId', data.requestId);
			setFpjsData('visitorId', data.visitorId);

			setFingerprintIdsHandler({ visitorId: data.visitorId, requestId: data.requestId });
		}
	}, [data, isLoading, error]);

	return null;
};

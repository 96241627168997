import isNull from 'lodash/isNull';
import { getCurrentUserData, getCurrentUserLocation } from '@lib/api/user';
import { getGameCategories, getGameCount } from '@lib/api/game-provider';
import { getAuthToken, setAuthToken } from '@lib/browser-storage';
import { PlatfromQueryE, UrlQueries } from '@types';
import { getStringQueryFromUrl } from '@utils';
import { setPlatform } from '@store/reducers/common-ui/dispatchers';

export const loadMetaData = async (setIsLoadedData: () => void) => {
	const accessTokenExternal =
		getStringQueryFromUrl(UrlQueries?.accessToken) ||
		getStringQueryFromUrl(UrlQueries.token);
	const platform = getStringQueryFromUrl(UrlQueries?.platform) as PlatfromQueryE;
	const isTelegram = platform === PlatfromQueryE.telegram;

	const authedPromises = async () => {
		if (accessTokenExternal) {
			setAuthToken(accessTokenExternal);

			window.history.pushState(
				{},
				document.title,
				window?.location?.origin + window?.location?.pathname
			);
		}

		if (!isNull(getAuthToken()) || accessTokenExternal) {
			await getCurrentUserData();
		}
	};

	platform && setPlatform(platform);

	await Promise.all([
		await getCurrentUserLocation(isTelegram, !isNull(getAuthToken())),
		authedPromises(),
		getGameCategories(
			{
				limit: 100,
				page: 1
			},
			false
		),
		getGameCount()
	]).then(() => setIsLoadedData());
};

interface ICommonUiConfig {
	mediaSmallWidth: number;
	mediaTabletWidth: number;
	modal: {
		timeForBettingModalShowing: number;
	};
}

export const commonUiConfig: ICommonUiConfig = {
	mediaSmallWidth: 767, // px
	mediaTabletWidth: 1080, // px
	modal: {
		timeForBettingModalShowing: 2000 // ms
	}
};

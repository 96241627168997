import React from 'react';

import { ExtraOffers, GamesMenu } from '@components/common/sections';
import { Footer, ScrollTopArrow } from '@components/common/page';

const Home = () => {
	return (
		<>
			<ExtraOffers />
			<GamesMenu />
			<Footer />
			<ScrollTopArrow />
		</>
	);
};

export default Home;

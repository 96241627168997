import React, { useState } from 'react';
import { Button, Ripple } from '@components/common/elements';
import { useLocales, useUrlLang } from '@lib/hooks';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { AnalyticsEventType, ButtonType } from '@types';
import { CloseSvg, VideoInstructionIcon } from '@components/svg';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Div100vh from 'react-div-100vh';
import { pathsConfig } from '@config/paths';
import { Trans } from 'react-i18next';
import { setQrPaymentInfo } from '@store/reducers/payment/dispathcers';
import { paymentSelectors } from '@store/reducers/payment/selectors';
import s from './style.module.scss';
import { VideoBlock } from '@components/routes/payment/parts/resolver/parts';
import { sendAnalyticsHandler } from '@utils';
import { getImageByURL } from '@utils/getImageByURL';

export const PaymentQRModal = () => {
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { qrPaymentInfo, selectedPaymentMethodId, paymentMethodsInvoice } = useSelector(
		paymentSelectors.paymentInfo
	);

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Payment'
	});

	const [videoOpen, setVideoOpen] = useState(false);

	const openVideoHandler = () => {
		setVideoOpen(!videoOpen);
	};

	const selectedMethod = paymentMethodsInvoice?.find(
		(method) => method?.id === selectedPaymentMethodId
	);

	const continuePlay = () => {
		navigate(langUrlPrefix + pathsConfig.home.link);
		setModalType(null);
		setQrPaymentInfo({ step: 1, qr: '', id: 0 });
	};

	const exitHandler = () => {
		setModalType(null);
		setQrPaymentInfo({ step: 1, qr: '', id: 0 });
	};

	const Container = isMobile
		? Div100vh
		: (
				props: JSX.IntrinsicAttributes &
					React.ClassAttributes<HTMLDivElement> &
					React.HTMLAttributes<HTMLDivElement>
			) => <div {...props} />;

	return (
		<Container
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) exitHandler();
			}}
			className={s.wrapper}
		>
			<div className={s.inner}>
				<div className={s.body}>
					<div
						onClick={exitHandler}
						className={s.headerClose}
					>
						<CloseSvg />
					</div>
					<div className={s.titleBlock}>
						<div className={s.title}>{localizeText('invoice')}</div>
					</div>

					{selectedMethod?.settings?.videoUrl && (
						<div
							onClick={() => {
								openVideoHandler && openVideoHandler();
								sendAnalyticsHandler(AnalyticsEventType?.payment_click_instruction);
							}}
							className={s.videoBlock}
						>
							<div className={s.videoBlock_btn}>
								<VideoInstructionIcon />
								<span className={s.videoBlock_text}>{localizeText('how_refill')}</span>
								<Ripple />
							</div>
							<div className={s.videoBlock_tip}>{localizeText('video_instruction')}</div>
						</div>
					)}

					{qrPaymentInfo?.step === 1 ? (
						<>
							{qrPaymentInfo?.qr && (
								<div className={s.qr}>
									<img
										alt='QR'
										src={`data:image/png;base64,${qrPaymentInfo?.qr}`}
									/>
								</div>
							)}

							<div className={s.qrDescr}>
								<Trans
									ns='components/routes/translation'
									i18nKey={'Payment.qr_copy_info'}
								/>
							</div>
						</>
					) : (
						<>
							<img
								src={getImageByURL('../../../../../../assets/img/payments/money.webp')}
								className={s.imageMoney}
							/>

							<div className={s.acceptedTitle}>{localizeText('accepted')}</div>

							<div className={s.stepItem}>
								<div>1</div>
								<div>{localizeText('we_accept_your_deposite')}</div>
							</div>

							<div className={s.bntsBlock}>
								<Button
									handle={continuePlay}
									rippleAnimation
									className={s.payApp}
									type={ButtonType.primary}
									text={localizeText('continue_play')}
								/>
							</div>
						</>
					)}
				</div>
			</div>

			{selectedMethod?.settings?.videoUrl && videoOpen && (
				<VideoBlock
					openVideoHandler={openVideoHandler}
					videoUrl={selectedMethod?.settings?.videoUrl}
				/>
			)}
		</Container>
	);
};

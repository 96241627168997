import React from 'react';
import { useLocales } from '@lib/hooks';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import cn from 'classnames';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';
import { getImageByURL } from '@utils/getImageByURL';

type PropsTypes = {
	isFreeSpin: boolean;
	isBonusBalances: boolean;
};

export const MenuBonusBlock = (props: PropsTypes) => {
	const { isAuthed } = useSelector(authSelectors.authInfo);

	const { isFreeSpin, isBonusBalances } = props;

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'AllBonus'
	});

	const onBtnClick = (type: string) => {
		const block = document?.querySelector(`#${type}_block`);

		if (block) {
			block.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}

		sendAnalyticsHandler(AnalyticsEventType?.all_bonuses_click_navigation, {
			navigation_bonuses_name: type
		});
	};

	return (
		<div className={cn(s.content, { [s.authed]: isAuthed })}>
			<div className={s.btnList}>
				{isBonusBalances ? (
					<button
						onClick={() => onBtnClick('bonus_balances')}
						className={s.btn}
					>
						{localizeText('bonus_balances_title')}{' '}
						<img
							className={s.icon}
							alt='Bonus icon'
							src={getImageByURL(
								'../../../../../../assets/img/common/bonus_balances_icon.webp'
							)}
						/>
					</button>
				) : null}
				{isFreeSpin ? (
					<button
						onClick={() => onBtnClick('freespin')}
						className={s.btn}
					>
						{localizeText('to_freespin')}{' '}
						<img
							className={s.icon}
							alt='Bonus icon'
							src={getImageByURL('../../../../../../assets/img/common/free_spins.webp')}
						/>
					</button>
				) : null}
				<button
					onClick={() => onBtnClick('invoice')}
					className={s.btn}
				>
					{localizeText('to_deposite')}{' '}
					<img
						className={s.icon}
						alt='Bonus icon'
						src={getImageByURL('../../../../../../assets/img/bonus/dep_bonus.webp')}
					/>
				</button>
				<button
					onClick={() => onBtnClick('cashback')}
					className={s.btn}
				>
					{localizeText('cashback')}
					<img
						className={s.icon}
						alt='Bonus icon'
						src={getImageByURL('../../../../../../assets/img/bonus/cashback_bonus.webp')}
					/>
				</button>
				<button
					onClick={() => onBtnClick('other')}
					className={s.btn}
				>
					{localizeText('other_bonuses_title')}
					<img
						className={s.icon}
						alt='Bonus icon'
						src={getImageByURL('../../../../../../assets/img/bonus/other_bonus.webp')}
					/>
				</button>
			</div>
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { useLocales, useUrlLang } from '@lib/hooks';
import { setAuthType, setIsAuthPending } from '@store/reducers/auth/dispathcers';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import {
	AnalyticsEventType,
	AuthType,
	ButtonType,
	ModalType,
	SessionStorageParams,
	SocialLoginMethodTypesE,
	UrlQueries
} from '@types';
import {
	AcceptCheckbox,
	Button,
	CurrencySelect,
	FormError,
	Input,
	Preloader,
	SocialLoginBtns
} from '@components/common/elements';
import { CloseSvg, CommentSvg, GreenCheckIconSvg } from '@components/svg';
import { useFormik } from 'formik';
import { getStringQueryFromUrl, openCarrotChat, sendAnalyticsHandler } from '@utils';
import { signUp } from '@lib/api/auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { authSelectors } from '@store/reducers/auth/selectors';
import { EMAIL_ENDS, isTapX, isTopXGo, SUPPORTED_LANGS } from '@config/global';
import { pathsConfig } from '@config/paths';
import { HowGetBonus } from './parts';
import { userSelectors } from '@store/reducers/user/selectors';
import { useGetButtonsLang } from '@lib/hooks/useGetAuthButtonsLang';
import isNull from 'lodash/isNull';
import Div100vh from 'react-div-100vh';
import s from './style.module.scss';
import cn from 'classnames';
import * as Yup from 'yup';
import 'yup-phone-lite';

type RegisterValues = {
	email?: string;
	password?: string;
	phone?: string;
	currency?: string;
	timezone?: string;
	rememberMe?: boolean;
};
export const AuthModal = () => {
	const { i18n } = useTranslation();
	const { langUrlPrefix } = useUrlLang();
	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const tgIdQuery = getStringQueryFromUrl(UrlQueries.tgId);
	const refQuery = getStringQueryFromUrl(UrlQueries.ref);
	const partnerIdQuery = getStringQueryFromUrl(UrlQueries.partnerId);
	const sessionIdQuery = getStringQueryFromUrl(UrlQueries.session_id);

	const { mobileHeight, isMobile, modalType, isApp } = useSelector(
		commonUISelectors.commonUIInfo
	);

	const { isAuthPending } = useSelector(authSelectors.authInfo);
	const { locationData } = useSelector(userSelectors.userInfo);

	const navigate = useNavigate();
	const location = useLocation();

	const closeAuthModal = () => {
		sendAnalyticsHandler(AnalyticsEventType?.close_back, { close_back: 'signup' });

		const returnGame = sessionStorage.getItem(SessionStorageParams?.returnGame);

		if (returnGame) {
			navigate(langUrlPrefix + returnGame);
			sessionStorage.removeItem(SessionStorageParams?.returnGame);
		}

		setModalType(null);
		navigate(location?.pathname + location?.search);
		setAuthType(null);
	};

	const [selectedCurrency, setSelectedCurrency] = useState('');

	const [bonusModal, setBonusModal] = useState(false);

	const bonusModalHandler = () => setBonusModal(!bonusModal);

	useEffect(() => {
		setAuthType(AuthType.signUp);
	}, []);

	useEffect(() => {
		if (modalType === ModalType?.auth) {
			sendAnalyticsHandler(AnalyticsEventType?.signup_open_modal);
		}
	}, [modalType]);

	// const methodTypes: Array<{ text: string; type: MethodTypesE }> = [
	// 	{
	// 		text: 'E-mail',
	// 		type: MethodTypesE?.email
	// 	},
	// 	{
	// 		text: localizeText('social_media'),
	// 		type: MethodTypesE?.google || MethodTypesE?.telegram
	// 	}
	// ];

	// const [methodType, setMethodType] = useState<MethodTypesE>(methodTypes[0]?.type);

	const validationSchema = Yup.object().shape({
		email:
			// methodType === MethodTypesE?.email
			// 	?
			Yup.string()
				.email('invalid_email')
				.transform((value, originalValue) =>
					typeof originalValue === 'string'
						? originalValue.replace(/\s/g, '')
						: originalValue
				)
				.required('need_fill_field'),
		// : Yup.string(),

		// phone:
		// 	methodType === MethodTypesE?.phone
		// 		? Yup.string()
		// 				// .phone(countryCode as CountryCode, 'invalid_phone')
		// 				.required('need_fill_field')
		// 		: Yup.string(),

		password: Yup.string()
			.min(6, 'invalid_password_length')
			.max(40, 'invalid_password_length')
			.required('need_fill_field'),

		agreement: Yup.bool().oneOf([true], localizeText('need_approve')),

		name: !isTopXGo && !isTapX ? Yup.string() : Yup.string().required('need_fill_field'),
		birthday:
			!isTopXGo && !isTapX ? Yup.string() : Yup.string().required('need_fill_field'),
		address:
			!isTopXGo && !isTapX ? Yup.string() : Yup.string().required('need_fill_field'),

		agreement18:
			!isTopXGo && !isTapX
				? Yup.bool()
				: Yup.bool().oneOf([true], localizeText('need_approve')),
		agreement12:
			!isTopXGo && !isTapX
				? Yup.bool()
				: Yup.bool().oneOf([true], localizeText('need_approve'))
	});

	const handleSubmit = (values: RegisterValues) => {
		const signUpValues = {
			email: values?.email ? values?.email?.replace(/\s/g, '') : undefined,
			password: values?.password,
			phone: undefined,
			// methodType === MethodTypesE?.phone && values?.phone
			// 	? values?.phone?.includes('+')
			// 		? values?.phone
			// 		: `+88${values?.phone}`
			// 	:

			currency: selectedCurrency || values?.currency || locationData?.currency,
			timezone: locationData?.timezone || values?.timezone,
			rememberMe: values?.rememberMe
		};

		register(signUpValues);
	};

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			phone: '',
			currency: '',
			timezone: '',
			agreement: !(isTopXGo || isTapX),

			name: '',
			birthday: '',
			address: '',
			promocode: '',
			agreement18: false,
			agreement12: false,
			rememberMe: true
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		const creds = localStorage?.getItem('user_creds');

		if (creds) {
			const credsJSON = JSON.parse(creds);

			if (credsJSON?.email) {
				formik?.setFieldValue('email', credsJSON?.email);
			}

			if (credsJSON?.password) {
				formik?.setFieldValue('password', credsJSON?.password);
			}

			if (credsJSON?.phone) {
				formik?.setFieldValue(
					'phone',
					credsJSON?.phone ? credsJSON?.phone?.replace('+88', '') : ''
				);
			}
		}
	}, []);

	const register = async (values: RegisterValues) => {
		const successFunc = (isFirstLogin: boolean) => {
			if (values?.rememberMe) {
				localStorage?.setItem(
					'user_creds',
					JSON.stringify({
						email: values?.email,
						password: values?.password,
						phone: values?.phone
					})
				);
			}

			setAuthType(null);
			setModalType(null);
			setIsAuthPending(false);

			navigate &&
				navigate(
					isFirstLogin
						? langUrlPrefix + pathsConfig.payment.link
						: langUrlPrefix + pathsConfig.home.link
				);
		};

		await signUp(
			{
				...values,
				ref: !isNull(refQuery) ? refQuery : undefined,
				tgId: !isNull(tgIdQuery) ? +tgIdQuery : undefined,
				partnerId: !isNull(partnerIdQuery) ? partnerIdQuery : undefined,
				analyticalSpaceSessionId: !isNull(sessionIdQuery) ? sessionIdQuery : undefined,
				locale: SUPPORTED_LANGS?.includes(i18n?.language) ? i18n?.language : 'en',
				isApp: isApp
			},
			successFunc,
			formik.setFieldError
		);
	};

	const onAcceptCheckboxChange = (isAccepted: boolean) => {
		formik.setFieldValue('agreement', isAccepted);
	};

	const onAccept18CheckboxChange = (isAccepted: boolean) => {
		formik.setFieldValue('agreement18', isAccepted);
	};

	const onAccept12CheckboxChange = (isAccepted: boolean) => {
		formik.setFieldValue('agreement12', isAccepted);
	};

	const onCurrencyChange = (currency: string) => {
		formik.setFieldValue('currency', currency);
		setSelectedCurrency(currency);
	};

	const { renderLangSignIn, renderLangSignUp, renderBannerDescr } = useGetButtonsLang();

	const heightStyle = {
		'--height': isMobile ? mobileHeight + 'px' : 'auto'
	} as React.CSSProperties;

	return (
		<div className={cn(s.wrapper, { [s.isTopXGo]: isTopXGo || isTapX })}>
			<Div100vh className={cn(s.inner, { [s.isTopXGo]: isTopXGo || isTapX })}>
				<div className={s.headerBlock}>
					<div className={s.header}>
						<div className={s.headerWrapper}>
							<div className={s.headerTitle}>{renderLangSignUp()}</div>
							<div
								onClick={closeAuthModal}
								className={s.headerClose}
							>
								<CloseSvg />
							</div>
						</div>
					</div>

					<div className={s.content}>
						{!isTopXGo && !isTapX && (
							<div
								onClick={() => {
									bonusModalHandler();
									sendAnalyticsHandler(AnalyticsEventType?.advertising_click_banner, {
										banner_place: 'Форма регистрации',
										banner_name: 'Double deposite small',
										banner_position: 1
									});
								}}
								className={s.bonusWrapper}
							>
								<div className={s.bonus}>
									<img
										alt='bonus'
										src={'/assets/img/common/register_bonus.webp'}
										className={s.bonusIcon}
									/>
									<div className={s.bonusContent}>
										<div className={s.bonusTitle}>{localizeText('double_deposite')}</div>
										<div className={s.bonusDescription}>{renderBannerDescr()}</div>
									</div>
									<GreenCheckIconSvg className={s.bonusCheck} />
								</div>
							</div>
						)}
					</div>

					<div
						style={heightStyle}
						className={cn(s.tabs, { [s.isTopXGo]: isTopXGo || isTapX })}
					>
						<form
							onSubmit={formik.handleSubmit}
							className={s.form}
						>
							<div className={cn(s.redirectButtons)}>
								<SocialLoginBtns
									currency={selectedCurrency || formik?.values?.currency}
									analyticsHandler={(method: SocialLoginMethodTypesE) =>
										sendAnalyticsHandler(AnalyticsEventType.signin_click_social, {
											singnup_click_tab: method
										})
									}
								/>
							</div>

							{(isTopXGo || isTapX) && (
								<div>
									<Input
										name='name'
										type='text'
										value={formik?.values?.name}
										label={localizeText('name')}
										onChange={formik.handleChange}
										error={!!formik?.touched?.name && Boolean(formik?.errors?.name)}
									/>
									<FormError
										error={formik?.errors?.name && localizeText(formik?.errors?.name)}
										isTouched={!!formik?.touched?.name}
									/>
								</div>
							)}
							{(isTopXGo || isTapX) && (
								<div>
									<Input
										name='birthday'
										value={formik?.values?.birthday}
										label={localizeText('birthday')}
										onChange={formik.handleChange}
										type='date'
										error={
											!!formik?.touched?.birthday && Boolean(formik?.errors?.birthday)
										}
									/>
									<FormError
										error={
											formik?.errors?.birthday && localizeText(formik?.errors?.birthday)
										}
										isTouched={!!formik?.touched?.birthday}
									/>
								</div>
							)}
							{(isTopXGo || isTapX) && (
								<div>
									<Input
										name='address'
										type='text'
										value={formik?.values?.address}
										label={localizeText('address')}
										onChange={formik.handleChange}
										error={!!formik?.touched?.address && Boolean(formik?.errors?.address)}
									/>
									<FormError
										error={
											formik?.errors?.address && localizeText(formik?.errors?.address)
										}
										isTouched={!!formik?.touched?.address}
									/>
								</div>
							)}
							<div className={cn(s.emailBlock)}>
								<Input
									name='email'
									type='email'
									value={formik?.values?.email.trim()}
									label='E-mail'
									placeholder='*****@gmail.com'
									onChange={formik.handleChange}
									error={!!formik?.touched?.email && Boolean(formik?.errors?.email)}
									onFocus={() =>
										sendAnalyticsHandler(AnalyticsEventType?.registration_input_mail)
									}
									onBlur={() => formik?.setFieldTouched('email', true)}
								/>

								<FormError
									error={formik?.errors?.email && localizeText(formik?.errors?.email)}
									isTouched={!!formik?.touched?.email}
								/>

								{formik?.values?.email?.includes('@') &&
									EMAIL_ENDS?.filter((e) =>
										e?.includes(formik?.values?.email?.split('@')[1])
									)?.length > 0 &&
									!EMAIL_ENDS?.includes('@' + formik?.values?.email?.split('@')[1]) && (
										<div className={s.emailHelpDropDown}>
											{EMAIL_ENDS?.filter((e) =>
												e?.includes(formik?.values?.email?.split('@')[1])
											)?.map((e) => {
												return (
													<div
														onClick={() =>
															formik?.setFieldValue(
																'email',
																formik?.values?.email?.split('@')[0] + e
															)
														}
														className={s.emailHelpDropDown_item}
														key={e}
													>
														{formik?.values?.email?.split('@')[0] + e}
													</div>
												);
											})}
										</div>
									)}
							</div>
							<div className={cn(s.passInput)}>
								<Input
									name='password'
									type='password'
									placeholder={localizeText('enter_phone')}
									value={formik?.values?.password}
									label={localizeText('password')}
									onChange={formik.handleChange}
									error={!!formik?.touched?.password && Boolean(formik?.errors?.password)}
									onFocus={() =>
										sendAnalyticsHandler(AnalyticsEventType?.registration_input_password)
									}
								/>
								<FormError
									error={
										formik?.errors?.password && localizeText(formik?.errors?.password)
									}
									isTouched={!!formik?.touched?.password}
								/>
							</div>
							<CurrencySelect
								analyticsFunc={() =>
									sendAnalyticsHandler(AnalyticsEventType?.registration_click_currency)
								}
								analyticsFuncChoose={(val) =>
									sendAnalyticsHandler(AnalyticsEventType?.registration_choose_currency, {
										registration_currency: val
									})
								}
								onChange={onCurrencyChange}
							/>
							{(isTopXGo || isTapX) && (
								<div>
									<Input
										name='promocode'
										type='promocode'
										value={formik?.values?.promocode}
										label={localizeText('promocode')}
										onChange={formik.handleChange}
										error={
											!!formik?.touched?.promocode && Boolean(formik?.errors?.promocode)
										}
									/>
									<FormError
										error={
											formik?.errors?.promocode && localizeText(formik?.errors?.promocode)
										}
										isTouched={!!formik?.touched?.promocode}
									/>
								</div>
							)}
							{(isTopXGo || isTapX) && (
								<AcceptCheckbox
									initialValue={formik?.values?.agreement}
									onChange={onAcceptCheckboxChange}
								>
									<Trans
										ns='components/common/modals/translation'
										i18nKey={'AuthModal.i_accept_and_terms_of_service'}
										components={{
											1: (
												<Link
													className={s.termsandcondLink}
													to={
														isMobile
															? '#'
															: langUrlPrefix +
																pathsConfig.politics_terms_and_conditions?.link
													}
													onClick={() => {
														isMobile &&
															setModalType(ModalType.politicsTermsAndConditions);
													}}
													target={isMobile ? '_self' : '_blank'}
												/>
											)
										}}
									/>

									<FormError
										error={formik?.errors?.agreement}
										isTouched={!!formik?.touched?.agreement}
									/>
								</AcceptCheckbox>
							)}
							{(isTopXGo || isTapX) && (
								<AcceptCheckbox
									initialValue={formik?.values?.agreement18}
									onChange={onAccept18CheckboxChange}
								>
									{localizeText('i_accept_18_year')}
									<FormError
										error={formik?.errors?.agreement18}
										isTouched={!!formik?.touched?.agreement18}
									/>
								</AcceptCheckbox>
							)}
							{(isTopXGo || isTapX) && (
								<AcceptCheckbox
									initialValue={formik?.values?.agreement12}
									onChange={onAccept12CheckboxChange}
								>
									{localizeText('i_accept_12_month')}
									<FormError
										error={formik?.errors?.agreement12}
										isTouched={!!formik?.touched?.agreement12}
									/>
								</AcceptCheckbox>
							)}
							<Button
								type={ButtonType.primary}
								btnType='submit'
								rippleAnimation
								className={cn(s.button, s.buttonSignUp)}
								isDisabled={isAuthPending}
								handle={() =>
									sendAnalyticsHandler(AnalyticsEventType?.registration_click_sinup)
								}
							>
								{isAuthPending ? (
									<Preloader
										styles={{ height: '100%', marginTop: 10, marginBottom: 10 }}
										isWhite={true}
									/>
								) : (
									renderLangSignUp()
								)}
							</Button>

							{!isTopXGo && !isTapX && (
								<div>
									<AcceptCheckbox
										initialValue={formik?.values?.agreement}
										onChange={onAcceptCheckboxChange}
									>
										<Trans
											ns='components/common/modals/translation'
											i18nKey={'AuthModal.i_accept_and_terms_of_service'}
											components={{
												1: (
													<Link
														className={s.termsandcondLink}
														onClick={() => {
															isMobile &&
																setModalType(ModalType.politicsTermsAndConditions);
														}}
														to={
															isMobile
																? '#'
																: langUrlPrefix +
																	pathsConfig.politics_terms_and_conditions?.link
														}
														target={isMobile ? '_self' : '_blank'}
													/>
												)
											}}
										/>
										<FormError
											error={formik?.errors?.agreement}
											isTouched={!!formik?.touched?.agreement}
										/>
									</AcceptCheckbox>
								</div>
							)}
							<Button
								btnType='button'
								className={cn(s.button, s.buttonSignIn)}
								handle={() => {
									setModalType(ModalType.signIn);
									sendAnalyticsHandler(AnalyticsEventType?.registration_click_singin);
								}}
							>
								<span className={s.buttonSignInText}>
									{localizeText('do_you_have_account')}
								</span>
								<span className={s.buttonSignInTextBold}>{renderLangSignIn()}</span>
							</Button>
						</form>

						{!isTopXGo && !isTapX && (
							<div className={s.support}>
								<span className={s.supportTitle}>{localizeText('support_24_7')}</span>
								<div className={s.supportButtons}>
									<Button
										rippleAnimation
										className={cn(s.button, s.supportButton)}
										handle={() => {
											openCarrotChat();

											sendAnalyticsHandler(
												AnalyticsEventType?.registration_click_support
											);
										}}
									>
										<CommentSvg />
										{localizeText('support')}
									</Button>
								</div>
							</div>
						)}
					</div>
				</div>
			</Div100vh>

			<HowGetBonus
				isOpen={bonusModal}
				toggleModal={bonusModalHandler}
			/>
		</div>
	);
};

import React from 'react';
import { useLocales } from '@lib/hooks';
import s from './style.module.scss';

export const DescriptionBlock = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'BgamingPrizes'
	});

	return <div className={s.container}>{localizeText('bgaming_rules')}</div>;
};

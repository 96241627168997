import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@components/app';
import './scss/main.scss';
import 'react-tooltip/dist/react-tooltip.css';
import './i18n';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(<App />);

import { PaymentService } from '@lib/api/payment/PaymentService';
import { setPaymentProcessingStatus } from '@store/reducers/payment/dispathcers';
import { InvoicePaymentResponseDataType } from '@types';
import { ErrorToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';

// TODOнаписать прелоадер
export const paymentInvoiceTransctionData = async (
	id: string,
	params: {
		invoiceId?: string;
		transactionId?: string;
		trx_id?: string;
		client_details?: string;
	},
	sunccessFunc?: (data?: InvoicePaymentResponseDataType) => void
) => {
	setPaymentProcessingStatus(true);
	try {
		const { status, data } = await PaymentService.paymentInvoiceTransctionData(
			id,
			params
		);

		if (status === 201) {
			sunccessFunc && sunccessFunc(data);

			setPaymentProcessingStatus(false);
		}
	} catch {
		setPaymentProcessingStatus(false);
		toast((t) => ErrorToast({ t: t, text: 'something_wrong' }));
	}
};

import { useEffect, useState } from 'react';
import useDebounce from './useDebounce';

export default function useTrimData<T extends Record<string, unknown>>(
	data: T,
	delay = 1000
): T {
	const [result, setResult] = useState<T>(data);

	const debouncedData = useDebounce(data, delay);

	useEffect(() => {
		const trimmedData = Object.fromEntries(
			Object.entries(debouncedData).map(([key, value]) => [
				key,
				typeof value === 'string' ? value.trim() : value
			])
		) as T;

		setResult(trimmedData as T);
	}, [debouncedData]);

	return result;
}

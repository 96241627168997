import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { CloseSvg } from '@components/svg';
import s from './style.module.scss';
import cn from 'classnames';

type PropsType = {
	toggleModal: () => void;
	num: number | null;
};

export const DepositeBonusDescriptionModal = (props: PropsType) => {
	const { toggleModal, num } = props;

	const [innerOpened, setInnerOpened] = useState(false);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'AllBonus'
	});

	useEffect(() => {
		if (num) {
			setTimeout(() => setInnerOpened(true));
		} else {
			setTimeout(() => setInnerOpened(false));
		}
	}, [num]);

	useEffect(() => {
		const body = document.querySelector('body');

		if (num && body) {
			body.style.overflow = 'hidden';
		} else if (body) {
			body.style.overflow = 'auto';
		}
	}, [num]);

	return (
		<div
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) {
					toggleModal();
				}
			}}
			className={cn(s.wrapper, { [s.opened]: num })}
		>
			<div className={cn(s.inner, { [s.opened]: innerOpened })}>
				<div className={s.header}>
					<span className={s.header_title}>{localizeText('bonus_info')}</span>

					<div
						onClick={toggleModal}
						className={s.header_close}
					>
						<CloseSvg />
					</div>
				</div>
				<div className={s.body}>{localizeText(`dep_descr_${num}`)}</div>
			</div>
		</div>
	);
};

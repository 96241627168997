import React, { useRef, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { BackArrow, CloseSvg } from '@components/svg';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { Button, FormError, Input, Preloader } from '@components/common/elements';
import { useFormik } from 'formik';
import { ButtonType, ModalType } from '@types';
import { changePassword } from '@lib/api/user';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';

import s from './style.module.scss';
import cn from 'classnames';
import * as Yup from 'yup';

type PropsType = {
	isPasswordModal: boolean;

	passwordModalHandler: () => void;
};

const CodeScreen = (props: PropsType) => {
	const { isPasswordModal, passwordModalHandler } = props;
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Profile'
	});

	const [isLoading, setIsLoading] = useState(false);

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const disableLoader = () => setIsLoading(false);

	const closeModalHandler = () => {
		passwordModalHandler();
		setModalType(null);
	};

	const goRessetPasswordHandler = () => {
		passwordModalHandler();

		setModalType(null);
		setTimeout(() => setModalType(ModalType.resetPass), 50);
	};

	const handleSubmit = async (values: { password: string; password_new: string }) => {
		setIsLoading(true);
		const successFunc = () => {
			passwordModalHandler();
			disableLoader();
		};

		await changePassword(
			{
				oldPassword: values?.password,
				newPassword: values?.password_new
			},
			successFunc,
			disableLoader
		);
	};

	const ref = useRef(null);

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.min(6, localizeText('pass_length'))
			.max(20, localizeText('pass_length'))
			.required(localizeText('can_not_be_empty')),
		password_new: Yup.string()
			.min(6, localizeText('pass_length'))
			.max(20, localizeText('pass_length'))
			.required(localizeText('can_not_be_empty')),
		repeat_password: Yup.string()
			.oneOf([Yup.ref('password_new')], localizeText('not_the_same_password'))
			.required(localizeText('can_not_be_empty'))
	});

	const formik = useFormik({
		initialValues: {
			password: '',
			password_new: '',
			repeat_password: ''
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	return (
		<div
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) {
					passwordModalHandler();
				}
			}}
			className={cn(s.wrapper, { [s.opened]: isPasswordModal })}
		>
			{isMobile && (
				<div className={s.header}>
					<div className={s.headerWrapper}>
						<div
							onClick={passwordModalHandler}
							className={s.headerBack}
						>
							<BackArrow />
						</div>
						<div className={s.headerTitle}>{localizeText('change_password')}</div>
						<div
							onClick={closeModalHandler}
							className={s.headerClose}
						>
							<CloseSvg />
						</div>
					</div>
				</div>
			)}

			<div
				className={s.inner}
				ref={ref}
			>
				{!isMobile && (
					<div className={s.header}>
						<div className={s.headerTitle}>{localizeText('change_password')}</div>
						<div
							onClick={closeModalHandler}
							className={s.headerClose}
						>
							<CloseSvg />
						</div>
					</div>
				)}

				<form
					onSubmit={formik.handleSubmit}
					className={s.form}
				>
					<div className={s.descr}>{localizeText('change_password_desc_1')}</div>
					<div className={s.inputBlock}>
						<Input
							name='password'
							type='password'
							value={formik?.values?.password}
							label={localizeText('current_password')}
							onChange={formik.handleChange}
							error={!!formik?.touched?.password && Boolean(formik?.errors?.password)}
						/>
						{!(!!formik?.touched?.password && Boolean(formik?.errors?.password)) && (
							<button
								type='button'
								onClick={goRessetPasswordHandler}
								className={s.forgotPassBtn}
							>
								{localizeText('forgot_password')}
							</button>
						)}

						<FormError
							error={formik?.errors?.password}
							isTouched={!!formik?.touched?.password}
						/>
					</div>

					<div className={s.line} />

					<div className={s.descr}>{localizeText('change_password_desc_2')}</div>

					<div className={s.inputBlock}>
						<Input
							name='password_new'
							type='password'
							value={formik?.values?.password_new}
							label={localizeText('new_password')}
							onChange={formik.handleChange}
							error={
								!!formik?.touched?.password_new && Boolean(formik?.errors?.password_new)
							}
						/>

						<FormError
							error={formik?.errors?.password_new}
							isTouched={!!formik?.touched?.password_new}
						/>
					</div>

					<div className={s.inputBlock}>
						<Input
							name='repeat_password'
							type='password'
							value={formik?.values?.repeat_password}
							label={localizeText('repeat_password')}
							onChange={formik.handleChange}
							error={
								!!formik?.touched?.repeat_password &&
								Boolean(formik?.errors?.repeat_password)
							}
						/>

						<FormError
							error={formik?.errors?.repeat_password}
							isTouched={!!formik?.touched?.repeat_password}
						/>
					</div>

					<Button
						btnType='submit'
						type={ButtonType?.primary}
						rippleAnimation
						className={s.btn}
						isDisabled={isLoading}
						text={isLoading ? <Preloader /> : localizeText('confirm')}
					/>
				</form>
			</div>
		</div>
	);
};

export default CodeScreen;

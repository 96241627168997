import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocales, useUrlLang } from '@lib/hooks';
import { useSelector } from 'react-redux';
import { userSelectors } from '@store/reducers/user/selectors';
import { Button } from '@components/common/elements';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { AnalyticsEventType, ButtonType } from '@types';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { renderCurrencySymbol, renderGameUrl, sendAnalyticsHandler } from '@utils';
import s from './style.module.scss';
import cn from 'classnames';
import { NewFreespinsFlag } from '@components/svg';
import { setFreespinsVisited } from '@lib/api/bonus';
import { getImageByURL } from '@utils/getImageByURL';

export const FreespinsBonusBlock = () => {
	const { isTablet } = useSelector(commonUISelectors.commonUIInfo);
	const { currency } = useSelector(userSelectors.userInfo);

	const { freeSpinsInfo } = useSelector(bonusSelectors.bonusInfo);

	const totalFS = freeSpinsInfo?.reduce((acc, curr) => acc + curr.amount, 0);

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'AllBonus'
	});

	const listRef = useRef<HTMLDivElement>(null);

	const [notVisitedFlag, setNotVisitedFlag] = useState<boolean>(false);
	const [isMouseDown, setIsMouseDown] = useState(false);
	const mouseCoords = useRef({
		startX: 0,
		startY: 0,
		scrollLeft: 0,
		scrollTop: 0
	});

	const handleDragStart = (e: { pageX: number; pageY: number }) => {
		if (!listRef.current) return;

		const slider = listRef.current as HTMLElement;

		const startX = e.pageX - slider.offsetLeft;
		const startY = e.pageY - slider.offsetTop;
		const scrollLeft = slider.scrollLeft;
		const scrollTop = slider.scrollTop;

		mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
		setIsMouseDown(true);
		document.body.style.cursor = 'grabbing';
	};
	const handleDragEnd = () => {
		setIsMouseDown(false);
		if (!listRef.current) return;

		document.body.style.cursor = 'default';
	};
	const handleDrag = (e: {
		preventDefault: () => void;
		pageX: number;
		pageY: number;
	}) => {
		if (!isMouseDown || !listRef.current) return;

		e.preventDefault();

		const slider = listRef.current as HTMLElement;
		const x = e.pageX - slider.offsetLeft;
		const y = e.pageY - slider.offsetTop;
		const walkX = (x - mouseCoords.current.startX) * 1.5;
		const walkY = (y - mouseCoords.current.startY) * 1.5;

		slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
		slider.scrollTop = mouseCoords.current.scrollTop - walkY;
	};

	const btnClickHandler = (gameKey: string) => {
		sendAnalyticsHandler(AnalyticsEventType?.all_bonuses_freespin);
		if (freeSpinsInfo && freeSpinsInfo?.length > 0) {
			navigate(langUrlPrefix + renderGameUrl(gameKey));
		}
	};

	const setIsVisitedHandler = async () => {
		freeSpinsInfo &&
			freeSpinsInfo?.forEach(async (b) => {
				if (!b?.isVisited) {
					await setFreespinsVisited(b?.id);
				}
			});
	};

	useEffect(() => {
		if (notVisitedFlag) {
			setTimeout(setIsVisitedHandler, 2000);
		}
	}, [notVisitedFlag, freeSpinsInfo]);

	useEffect(() => {
		if (freeSpinsInfo && freeSpinsInfo?.length > 0) {
			const haveNotVisited = freeSpinsInfo?.find((e) => !e?.isVisited);

			setNotVisitedFlag(!!haveNotVisited);
		}
	}, [freeSpinsInfo]);

	const scrollToNotVisited = () => {
		if (notVisitedFlag) {
			const blocks = document?.querySelectorAll('.not-visited-freespins');

			if (blocks) {
				blocks[blocks?.length - 1].scrollIntoView({
					behavior: 'smooth',
					inline: 'center',
					block: 'center'
				});
				setNotVisitedFlag(false);
			}
		}
	};

	return (
		<div className={s.content}>
			<div className={s.bonusBlock}>
				{notVisitedFlag && freeSpinsInfo && freeSpinsInfo?.length > 1 ? (
					<button
						onClick={scrollToNotVisited}
						className={s.notVisitedFlag}
					>
						<NewFreespinsFlag />
					</button>
				) : null}
				<div className={s.bonusBlock_top}>
					<div
						id={'freespin_block'}
						className={s.titleBlock}
					>
						<span className={s.titleBlock_title}>
							{localizeText('freespin_bonus_title')}{' '}
							{totalFS &&
								totalFS
									?.toString()
									.split('')
									.map((digit, index) => (
										<React.Fragment key={index}>
											<b className={s.freespinCount}>{digit}</b>{' '}
										</React.Fragment>
									))}{' '}
							FS
						</span>
						<span className={s.titleBlock_descr}>
							{localizeText('freespin_bonus_descr')}
						</span>
					</div>

					<img
						alt='Freespin bonuse'
						className={s.image}
						src={getImageByURL('../../../../../../assets/img/bonus/freespin_bonus.webp')}
					/>
				</div>
				<div className={s.bonusList}>
					<div
						ref={listRef}
						onMouseDown={handleDragStart}
						onMouseUp={handleDragEnd}
						onMouseMove={handleDrag}
						className={cn(s.bonusBlock_bottom, {
							[s.padding]: freeSpinsInfo && freeSpinsInfo?.length > 1
						})}
					>
						{freeSpinsInfo?.map((fs, index) => {
							const isActive = index === 0;

							const { amount, gameKey, winAmount, id, isVisited } = fs;

							return (
								<div
									key={id}
									className={cn(s.invoiceItem, { ['not-visited-freespins']: !isVisited })}
								>
									<div
										className={cn(s.invoiceItem_top, {
											[s.active]: isActive
										})}
									>
										{!isActive && (
											<div className={cn(s.depNum, { [s.nonVisited]: !isVisited })}>
												{localizeText(isVisited ? 'next_freespins' : 'new_fs')}
											</div>
										)}
										<div className={s.invoiceItem_top_content}>
											<div className={s.descrBlock}>
												<span className={cn({ [s.disabled]: !isActive })}>
													{amount && amount < 100 ? null : (
														<b className={cn(s.numbers, { [s.disabled]: !isActive })}>
															{amount?.toString()[0]}
														</b>
													)}{' '}
													{amount && amount < 100 ? (
														amount && amount < 10 ? null : (
															<b className={cn(s.numbers, { [s.disabled]: !isActive })}>
																{amount?.toString()[0]}
															</b>
														)
													) : amount && amount > 100 ? (
														<b className={cn(s.numbers, { [s.disabled]: !isActive })}>
															{amount?.toString()[1]}
														</b>
													) : null}{' '}
													{amount && amount < 100 ? (
														amount && amount < 10 ? (
															<b className={cn(s.numbers, { [s.disabled]: !isActive })}>
																{amount?.toString()[0]}
															</b>
														) : (
															<b className={cn(s.numbers, { [s.disabled]: !isActive })}>
																{amount?.toString()[1]}
															</b>
														)
													) : amount && amount > 100 ? (
														<b className={cn(s.numbers, { [s.disabled]: !isActive })}>
															{amount?.toString()[2]}
														</b>
													) : (
														<b className={cn(s.numbers, { [s.disabled]: !isActive })}>
															{amount?.toString()[0]}
														</b>
													)}{' '}
													FS
												</span>
												<span className={cn({ [s.disabled]: !isActive })}>
													{localizeText(
														isActive
															? 'freespin_bonus_card_descr_active'
															: 'freespin_bonus_card_descr'
													)}
												</span>
											</div>

											<div className={s.divider} />

											<div className={cn(s.fsBlock, { [s.disabled]: !isActive })}>
												<b className={s.sum}>
													{renderCurrencySymbol(currency?.toLowerCase())}{' '}
													{Math.floor(winAmount / 100)}{' '}
												</b>{' '}
												{localizeText('won')}
											</div>
										</div>
									</div>
									<div className={s.invoiceItem_bottom}>
										<Button
											isDisabled={!isActive}
											className={cn(s.invoiceItem_bottom_btn, { [s.default]: !isActive })}
											type={ButtonType?.secondary}
											text={localizeText('play_for_fs')}
											handle={() => btnClickHandler(gameKey)}
											withoutGlare
										/>

										{isActive && (
											<img
												className={s.fsImg}
												alt='FS'
												src={getImageByURL(
													'../../../../../../assets/img/common/free_spins.webp'
												)}
											/>
										)}
									</div>
								</div>
							);
						})}
					</div>

					{!isTablet && <div className={s.shadow} />}
				</div>
			</div>
		</div>
	);
};

import React from 'react';
import Div100vh from 'react-div-100vh';
import { useSelector } from 'react-redux';
import { ReferralList, ReferralLink, Withdrawal } from './parts';
import { useLocales } from '@lib/hooks';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { authSelectors } from '@store/reducers/auth/selectors';
import { CloseSvg } from '@components/svg';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import s from './style.module.scss';
import cn from 'classnames';

const Referral = () => {
	const {
		referral: { link, rewards, pendingRewardsAmount, totalUserQty, totalRewardsSum }
	} = useSelector(bonusSelectors.bonusInfo);

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Referral'
	});

	const closeModal = () => {
		setModalType(null);
	};

	const Container = isMobile
		? Div100vh
		: (
				props: JSX.IntrinsicAttributes &
					React.ClassAttributes<HTMLDivElement> &
					React.HTMLAttributes<HTMLDivElement>
			) => <div {...props}></div>;

	return (
		<Container className={cn(s.content, { [s.desktop]: !isMobile })}>
			<div className={s.bodyBlock}>
				<div className={s.mainBlock}>
					<div className={s.header}>
						<div className={s.headerWrapper}>
							<div className={s.headerTitle}>{localizeText('refs_balance')}</div>
							{isMobile && (
								<div
									onClick={closeModal}
									className={s.headerClose}
								>
									<CloseSvg />
								</div>
							)}
						</div>
					</div>

					<section className={s.data}>
						<div className={s.block}>
							<Withdrawal
								totalRewardsSum={totalRewardsSum}
								totalUserQty={totalUserQty}
								pendingRewardsAmount={pendingRewardsAmount}
							/>

							<ReferralLink
								link={link}
								isAuthed={isAuthed}
							/>
						</div>

						<ReferralList
							rewards={rewards}
							localizeText={localizeText}
						/>
					</section>
				</div>
			</div>
		</Container>
	);
};

export default Referral;

import React, { ReactElement } from 'react';

import { WithdrawalResolverP } from '@components/routes/payment/type';
import { PaymentMethodType } from '@types';
import { WithdrawalFiatType } from './withdrawal-types';

export const WithdrawalResolver = (props: WithdrawalResolverP) => {
	const {
		paymentMethodType,
		localizeText,
		withdrawalCommission,
		amount,
		balance,
		cardNumber,
		isNotSuitableAmount,
		currentPaymentMethod,
		cancelModalHandler,
		innerPlatformFlow
	} = props;

	const getLayout = (): ReactElement => {
		switch (paymentMethodType) {
			case PaymentMethodType.fiat:
				return (
					<WithdrawalFiatType
						isNotSuitableAmount={isNotSuitableAmount}
						localizeText={localizeText}
						withdrawalCommission={withdrawalCommission}
						amount={amount}
						balance={balance}
						cardNumber={cardNumber}
						currentPaymentMethod={currentPaymentMethod}
						cancelModalHandler={cancelModalHandler}
						innerPlatformFlow={innerPlatformFlow}
					/>
				);
			default:
				return <></>;
		}
	};

	return getLayout();
};

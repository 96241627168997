import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { gameProviderSelectors } from '@store/reducers/game-provider/selectors';
import { AnalyticsEventType, BottomMenuKeys, IGameCategory, ModalType } from '@types';
import { GamesViewer } from '@components/common/games-viewer';
import { useNavigate, useParams } from 'react-router-dom';
import { PromoCards, WageringGames } from '..';
import { hyphenToUnderscore, sendAnalyticsHandler, underscoreToHyphen } from '@utils';
import { authSelectors } from '@store/reducers/auth/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { isTapX } from '@config/global';
import { useUrlLang } from '@lib/hooks';
import s from './style.module.scss';

export const GamesMenu = () => {
	const { gameCategories } = useSelector(gameProviderSelectors.gameProviderInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isMobile, isTablet } = useSelector(commonUISelectors.commonUIInfo);

	const params = useParams();

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const correctCategory = params?.category
		? hyphenToUnderscore(params?.category)
		: undefined;

	const [tabs, setTabs] = useState<ITab[]>([
		{
			title: 'All games',
			category: {
				id: 0,
				key: 'all',
				name: 'All games',
				gamesCount: 0,
				order: 1
			}
		}
	]);

	interface ITab {
		title: string;
		category: IGameCategory;
	}

	useEffect(() => {
		const arr = gameCategories?.map((category) => {
			return {
				title: category.name,
				category: {
					id: category.id,
					key: category.key,
					gamesCount: category?.gamesCount,
					name: category.name,
					order: category?.order
				}
			};
		}) || [];

		setTabs((t: ITab[]) => [...t, ...arr]);
	}, []);

	const [selectedTab, setSelectedTab] = useState(0);
	const [isFavorite, setIsFavorite] = useState(false);
	const [wasParsed, setWasParsed] = useState(false);

	const favoriteHandler = () => {
		isAuthed ? setIsFavorite(!isFavorite) : setModalType(ModalType.auth);

		sendAnalyticsHandler(AnalyticsEventType?.header_click_favourite);
	};

	useEffect(() => {
		if (correctCategory) {
			const findCateg = tabs?.find((t) => t?.category?.key === correctCategory);

			if (findCateg) {
				setSelectedTab(findCateg?.category?.id);
			} else {
				setSelectedTab(tabs[0]?.category?.id);
			}
		} else {
			if (!wasParsed) {
				setSelectedTab(tabs[0]?.category?.id);
			} else {
				setWasParsed(false);
			}
		}

		setIsFavorite(false);
	}, [params, tabs]);

	const onCategoryClick = (
		tabid: number,
		categoryKey: string,
		isDeleteCategory?: boolean
	) => {
		setIsFavorite(false);
		setSelectedTab(tabid);

		setWasParsed(true);
		navigate(
			langUrlPrefix +
				`/${underscoreToHyphen(BottomMenuKeys?.casino)}/${underscoreToHyphen(categoryKey)}`
		);

		if (!isDeleteCategory) {
			sendAnalyticsHandler(AnalyticsEventType?.filter_click_game_type, {
				game_filter: categoryKey
			});
		}
	};

	const renderGameViewer = () => {
		const findCateg = tabs?.find((e) => {
			return e?.category?.id === selectedTab;
		})?.category;

		if (findCateg) {
			return (
				<GamesViewer
					favoriteHandler={favoriteHandler}
					isFavorite={isFavorite}
					key={selectedTab}
					category={findCateg}
					allCategories={tabs}
					onCategoryClick={onCategoryClick}
				/>
			);
		}

		return null;
	};

	return (
		<section className={s.gamesMenu}>
			{isTablet && !isTapX && <PromoCards />}
			{isMobile && <WageringGames />}

			{tabs?.length > 1 && renderGameViewer()}
		</section>
	);
};

import React from 'react';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { CloseSvg } from '@components/svg';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import Div100vh from 'react-div-100vh';
import { getImageByURL } from '@utils/getImageByURL';

export const LicenseModal = () => {
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const exitHandler = () => {
		setModalType(null);
	};

	const Container = isMobile
		? Div100vh
		: (
				props: JSX.IntrinsicAttributes &
					React.ClassAttributes<HTMLDivElement> &
					React.HTMLAttributes<HTMLDivElement>
			) => <div {...props} />;

	return (
		<Container
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) exitHandler();
			}}
			className={s.wrapper}
		>
			<div className={s.inner}>
				<div className={s.body}>
					<div
						onClick={exitHandler}
						className={s.headerClose}
					>
						<CloseSvg />
					</div>
					<img
						src={getImageByURL(
							'../../../../../../assets/img/sections/info/license_1_page.webp'
						)}
						alt='License 1 Page'
					/>
					<img
						src={getImageByURL(
							'../../../../../../assets/img/sections/info/license_2_page.webp'
						)}
						alt='License 2 Page'
					/>
				</div>
			</div>
		</Container>
	);
};

import React from 'react';
import s from './style.module.scss';
import { getImageByURL } from '@utils/getImageByURL';

export const Banner = () => {
	return (
		<div className={s.banner}>
			<img
				className={s.money}
				alt='Money'
				src={getImageByURL('../../../../../../../../../assets/img/common/cashback.webp')}
			/>
			<img
				className={s.percents}
				alt='Percents'
				src={getImageByURL('../../../../../../../../../assets/img/common/percents.webp')}
			/>
		</div>
	);
};

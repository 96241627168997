import React, { useEffect } from 'react';
import {
	TitleBlock,
	TelegramBlock,
	HowGetMoney,
	AccrualRules,
	LiveRewards
} from './parts';
import { Ripple } from '@components/common/elements';
import { CloseSvg } from '@components/svg';
import { pathsConfig } from '@config/paths';
import { useLocales, useUrlLang } from '@lib/hooks';
import { useNavigate } from 'react-router-dom';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { AnalyticsEventType, SocialBonusTaskType } from '@types';
import { setReferralLink } from '@store/reducers/bonus/dispatchers';
import { isTapX } from '@config/global';
import { userSelectors } from '@store/reducers/user/selectors';
import { authSelectors } from '@store/reducers/auth/selectors';
import { getReferralRewards, getReferralsQtyAndPendingRewards } from '@lib/api/bonus';
import { Footer } from '@components/common/page';
import { sendAnalyticsHandler } from '@utils';

const ReferralBonus = () => {
	const navigate = useNavigate();

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { referralCode } = useSelector(userSelectors.userInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);

	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Referral'
	});

	const { socialBonusTasks } = useSelector(bonusSelectors.bonusInfo);

	const findTelegramBonusState = socialBonusTasks?.find(
		(e) => e?.task?.type === SocialBonusTaskType?.telegram_ref_bot
	);

	useEffect(() => {
		setReferralLink(
			isTapX
				? `https://tapxgames.net/?ref=${referralCode}`
				: `https://topx.one/?ref=${referralCode}`
		);
		isAuthed ? getReferralRewards() : undefined;
		isAuthed ? getReferralsQtyAndPendingRewards() : undefined;
	}, [isAuthed]);

	return (
		<div className={s.container}>
			{isMobile ? (
				<div className={s.header}>
					{localizeText('referral_program')}
					<button
						onClick={() => {
							navigate(langUrlPrefix + pathsConfig?.home?.tabs.casino.link);

							sendAnalyticsHandler(AnalyticsEventType?.close_back, {
								close_back: 'referral'
							});
						}}
					>
						<CloseSvg />
						<Ripple />
					</button>
				</div>
			) : null}
			<TitleBlock />

			<LiveRewards />

			<HowGetMoney />

			<AccrualRules />

			{!findTelegramBonusState?.isDone && <TelegramBlock />}

			<Footer />
		</div>
	);
};

export default ReferralBonus;

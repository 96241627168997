import React, { lazy, useEffect, useState } from 'react';
import { RoutePreRenderingWrap } from '@components/routes-wrapper';
import { getGames } from '@lib/api/game-provider';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Preloader, SearchInput } from '@components/common/elements';
import { AnalyticsEventType, ProviderGamesType } from '@types';
import { setProviderGames } from '@store/reducers/game-provider/dispatchers';
import { CloseSvg } from '@components/svg';
import { Helmet } from 'react-helmet';
import s from './style.module.scss';
import cn from 'classnames';
import { sendAnalyticsHandler } from '@utils';
import { useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';

const GameSearchBody = lazy(() => import('./GameSearch'));

export const GameSearch = () => {
	const initMethods: Array<Function> = [
		async () =>
			await getGames({ limit: 16, page: 1 }, true, ProviderGamesType.lastSeenGames)
	];

	const [countPage, setCountPage] = useState<number>(1);
	const [totalPageCount, setTotalPageCount] = useState<number>(0);
	const [searchValue, setSearchValue] = useState<string>('');
	const [filterLoading, setFilterLoading] = useState<boolean>(false);
	const [isLoadMore, setIsLoadMore] = useState<boolean>(false);

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const searchLoc = useLocales({
		path: 'components/games',
		node: 'search'
	});

	const searchInputHandler = (text: string | number) => {
		setFilterLoading(true);
		setSearchValue(text?.toString());
	};

	const onCloseHandler = () => {
		setSearchValue('');
	};

	const closeGamesSearch = () => {
		navigate(langUrlPrefix + pathsConfig.home.link);
	};

	const findGames = async (val: string, isSet: boolean) => {
		isSet && setFilterLoading(true);

		sendAnalyticsHandler(AnalyticsEventType?.search_result, {
			search_query: val,
			search_quantity: 10
		});

		const searchPattern = {
			$and: [
				{
					search: {
						$contL: val
					}
				}
			]
		};

		await getGames(
			{
				limit: 10,
				page: countPage,
				s: JSON.stringify(searchPattern)
			},
			isSet,
			ProviderGamesType.foundProviderGames,
			setTotalPageCount
		).finally(() => {
			setFilterLoading(false);
			setIsLoadMore(false);
		});
	};

	useEffect(() => {
		let timeoutId: any;

		const debounce = (func: (val: string, isSet: boolean) => void, delay: any) => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(func, delay);
		};

		if (searchValue) {
			setFilterLoading(true);
			setCountPage(1);
			debounce(() => findGames(searchValue, true), 500);
		} else {
			setFilterLoading(false);
			setProviderGames({
				providerGames: [],
				providerGamesType: ProviderGamesType.foundProviderGames
			});
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchValue]);

	useEffect(() => {
		if (countPage > 1) {
			findGames(searchValue, false);
		}
	}, [countPage]);

	const loadMore = () => {
		setCountPage((c) => c + 1);
		setIsLoadMore(true);
	};

	return (
		<>
			<Helmet>
				<link
					rel='canonical'
					href={window?.location?.href}
				/>
			</Helmet>
			<RoutePreRenderingWrap initMethods={initMethods}>
				<React.Suspense>
					<div className={s.wrapper}>
						<div className={s.searchBlock}>
							<SearchInput
								placeholder={searchLoc.localizeText('search_game_placeholder')}
								name='search_games'
								type='text'
								value={searchValue}
								onChange={searchInputHandler}
								onCloseHandler={onCloseHandler}
								rippleAnimation
							/>
							<button
								onClick={closeGamesSearch}
								className={s.closeButton}
							>
								<CloseSvg />
							</button>
						</div>
						{searchValue ? (
							<h2 className={s.title}>
								{searchLoc?.localizeText('game_searching_results')}
							</h2>
						) : (
							<h2 className={s.title}>
								{searchLoc?.localizeText('game_searching_recommends')}
							</h2>
						)}
						<GameSearchBody
							isFullyLoaded={totalPageCount <= countPage}
							isSearch={!!searchValue}
							filterLoading={filterLoading}
							isLoadMore={isLoadMore}
							loadMore={loadMore}
						/>
						{(filterLoading || isLoadMore) && (
							<Preloader className={cn(s.preloader, { [s.preloaderMore]: isLoadMore })} />
						)}
					</div>
				</React.Suspense>
			</RoutePreRenderingWrap>
		</>
	);
};

import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Trans } from 'react-i18next';
import { Button } from '@components/common/elements';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { CloseSvg } from '@components/svg';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import { userSelectors } from '@store/reducers/user/selectors';
import { sendAnalyticsHandler } from '@utils';
import { getImageByURL } from '@utils/getImageByURL';
import { isTapX } from '@config/global';
import s from './style.module.scss';

type DataType = {
	minutes: number;
	seconds: number;
};

export const DepositeBanners = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { createdAt, successInvoiceCount } = useSelector(userSelectors.userInfo);
	const { modalType, isSidebarOpened } = useSelector(commonUISelectors.commonUIInfo);

	const [isOpenedNonAuthed, setIsOpenedNonAuthed] = useState(false);

	const [isOpenedAuthed, setIsOpenedAuthed] = useState(false);

	const { localizeText } = useLocales({
		path: 'components/common/sections',
		node: 'ExtraOffers'
	});

	const renderer = ({ minutes, seconds }: DataType) => {
		return (
			<Trans
				ns='components/common/sections/translation'
				i18nKey={'ExtraOffers.left_time'}
				values={{
					time: `${minutes < 10 ? `0${minutes}` : minutes}:${
						seconds < 10 ? `0${seconds}` : seconds
					}`
				}}
				components={{ red: <span className={s.redText} /> }}
			/>
		);
	};

	useEffect(() => {
		const closedBonusModal = localStorage?.getItem('deposite_bonus_authed');

		if (
			isAuthed &&
			dayjs(createdAt).add(1, 'h').format() > dayjs().format() &&
			!closedBonusModal &&
			successInvoiceCount < 1
		) {
			setIsOpenedAuthed(true);

			sendAnalyticsHandler(AnalyticsEventType?.popup_show, {
				name_popup: 'Deposite bonus authed user'
			});
		}
	}, [isAuthed, createdAt]);

	useEffect(() => {
		if (!isAuthed) {
			setTimeout(() => {
				setIsOpenedNonAuthed(true);
				sendAnalyticsHandler(AnalyticsEventType?.popup_show, {
					name_popup: 'Deposite bonus non-authed user'
				});
			}, 15000);
		}
	}, [isAuthed]);

	const closeModal = () => {
		setIsOpenedNonAuthed(false);
	};

	const closeModalAuthed = () => {
		setIsOpenedAuthed(false);
		localStorage?.setItem('deposite_bonus_authed', 'true');

		sendAnalyticsHandler(AnalyticsEventType?.popup_close, {
			name_popup: isAuthed
				? 'Deposite bonus authed user'
				: 'Deposite bonus non-authed user'
		});
	};

	const rgisterHandler = () => {
		setModalType(ModalType.auth);

		sendAnalyticsHandler(AnalyticsEventType?.popup_click_to_action, {
			name_popup: 'Deposite bonus non-authed user'
		});
	};

	const reffilHandler = () => {
		navigate(langUrlPrefix + pathsConfig?.payment?.link);

		sendAnalyticsHandler(AnalyticsEventType?.popup_click_to_action, {
			name_popup: 'Deposite bonus authed user'
		});
	};

	const isShowModal = [
		langUrlPrefix + pathsConfig.home.tabs.casino.link,
		langUrlPrefix + pathsConfig.home.tabs.crash.link,
		langUrlPrefix + pathsConfig.home.tabs.live_dealers.link,
		langUrlPrefix + pathsConfig.home.tabs.tv_games.link
	].includes(location?.pathname);

	if (
		isOpenedAuthed &&
		isAuthed &&
		dayjs(createdAt).add(1, 'h').format() > dayjs().format() &&
		!modalType &&
		isShowModal &&
		!isSidebarOpened
	) {
		return (
			<div className={s.wrapperTop}>
				<button
					onClick={closeModalAuthed}
					className={s.closeBtn_authed}
				>
					<CloseSvg />
				</button>

				<div className={s.topAuthBlock}>
					<img
						alt='5X'
						className={s.image_5x_small}
						src={getImageByURL(
							`../../../../../assets/img/common/5xBanner${isTapX ? '-tapx' : ''}.webp`
						)}
					/>

					<div className={s.authDescr}>
						<div className={s.descrText}>{localizeText('reffil_double')}</div>
						<div className={s.counter}>
							<Countdown
								date={dayjs(createdAt).add(1, 'h').format()}
								renderer={renderer}
							/>
						</div>
					</div>
				</div>
				<Button
					className={s.registerBtn}
					type={ButtonType?.primary}
					rippleAnimation
					handle={reffilHandler}
					text={localizeText('get')}
				/>
			</div>
		);
	}

	if (!isAuthed && isOpenedNonAuthed && !modalType && isShowModal && !isSidebarOpened) {
		return (
			<div className={s.wrapper}>
				<img
					alt='5X'
					className={s.image_5x}
					src={getImageByURL(
						`../../../../../assets/img/common/5xBanner${isTapX ? '-tapx' : ''}.webp`
					)}
				/>

				<button
					onClick={closeModal}
					className={s.closeBtn}
				>
					<CloseSvg />
				</button>

				<div className={s.nonAuthBlock}>
					<span className={s.title}>
						<Trans
							ns='components/common/sections/translation'
							i18nKey={'ExtraOffers.doubledeposite'}
							components={{ red: <span className={s.redText} /> }}
						/>
					</span>
					<span className={s.descr}>{localizeText('doubledeposite_descr')}</span>
				</div>

				<Button
					className={s.registerBtn}
					type={ButtonType?.primary}
					rippleAnimation
					handle={rgisterHandler}
					text={localizeText('register')}
				/>
			</div>
		);
	}

	return null;
};

import React from 'react';
import { AnalyticsEventType } from '@types';
import { useUrlLang } from '@lib/hooks';
import { pathsConfig } from '@config/paths';
import { useNavigate } from 'react-router-dom';
import { sendAnalyticsHandler } from '@utils';
import s from './style.module.scss';

export const ReferralBanner = () => {
	const { langUrlPrefix } = useUrlLang();

	const navigate = useNavigate();

	const goToReferral = () => {
		navigate(langUrlPrefix + pathsConfig.referral.link);

		sendAnalyticsHandler(AnalyticsEventType?.advertising_click_banner, {
			banner_place: 'главная карусель',
			banner_name: 'Referral bonus',
			banner_position: 4
		});
	};

	return (
		<div
			onClick={goToReferral}
			className={s.wrapper}
		/>
	);
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { setIsSidebarOpened, setModalType } from '@store/reducers/common-ui/dispatchers';
import s from '../style.module.scss';
import Div100vh from 'react-div-100vh';
import cn from 'classnames';
import { ModalType } from '@types';
import SignInModal from '../parts/sign-in-modal';
import { authSelectors } from '@store/reducers/auth/selectors';

export const LoginModal = () => {
	const { modalType } = useSelector(commonUISelectors.commonUIInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);

	useEffect(() => {
		setIsSidebarOpened(false);
	}, [modalType]);

	useEffect(() => {
		const body = document.querySelector('body');

		if (modalType && body) {
			body.style.overflow = 'hidden';
		} else if (body) {
			body.style.overflow = 'auto';
		}
	}, [modalType]);

	useEffect(() => {
		if (isAuthed) {
			setModalType(null);
		}
	}, [isAuthed]);

	if (isAuthed) {
		return null;
	}

	return (
		<div
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) {
					setModalType(null);
				}
			}}
			className={cn(s.outer, {
				[s.active]: modalType === ModalType.signIn
			})}
		>
			<Div100vh className={cn(s.inner, s.signIn)}>
				<SignInModal />
			</Div100vh>
		</div>
	);
};

import React, { useEffect } from 'react';
import { Ripple } from '@components/common/elements';
import { useLocales } from '@lib/hooks';
import { AnalyticsEventType, CategoriesKeys, IGameCategory } from '@types';
import { CategoriesIcons, CloseSvg } from '@components/svg';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import Div100vh from 'react-div-100vh';
import s from './style.module.scss';
import cn from 'classnames';
import { gameProviderSelectors } from '@store/reducers/game-provider/selectors';
import { sendAnalyticsHandler } from '@utils';

interface ITab {
	title: string;
	category: IGameCategory;
}

type PropsTypes = {
	isOpen: boolean;
	closeModal: () => void;
	allCategories: ITab[];
	onCategoryClick: (
		tabid: number,
		categoryKey: string,
		isDeleteCategory?: boolean
	) => void;
};

export const CategoryListModal = (props: PropsTypes) => {
	const { allCategories, isOpen, closeModal, onCategoryClick } = props;
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { gameCount } = useSelector(gameProviderSelectors?.gameProviderInfo);

	const { localizeText } = useLocales({
		path: 'components/common/elements',
		node: 'GamesViewer'
	});

	const globalLocalize = useLocales({
		path: 'global',
		node: 'routes'
	});

	const categoryClickHandler = (tabid: number, categoryKey: string) => {
		onCategoryClick(tabid, categoryKey);
		closeModal();
	};

	function sumAllGames() {
		return allCategories.reduce((a, b) => {
			return a + b?.category?.gamesCount;
		}, 0);
	}

	useEffect(() => {
		const body = document.querySelector('body');

		if (isOpen && body) {
			body.style.overflow = 'hidden';
		} else if (body) {
			body.style.overflow = 'auto';
		}
	}, [isOpen]);

	const Container = isMobile
		? Div100vh
		: (
				props: JSX.IntrinsicAttributes &
					React.ClassAttributes<HTMLDivElement> &
					React.HTMLAttributes<HTMLDivElement>
			) => <div {...props} />;

	const closeHandler = () => {
		closeModal();
		sendAnalyticsHandler(AnalyticsEventType?.close_filter);
	};

	return (
		<Container
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) closeHandler();
			}}
			className={cn(s.wrapper, { [s.opened]: isOpen })}
		>
			<div className={s.inner}>
				<div className={s.body}>
					<div
						onClick={closeHandler}
						className={s.headerClose}
					>
						<CloseSvg />
					</div>
					<div className={s.titleBlock}>
						<div className={s.title}>
							{globalLocalize.localizeText('categories.linkName')}
						</div>
					</div>

					<div className={s.categList}>
						{allCategories?.map((cat, index) => {
							return (
								<div
									onClick={() =>
										categoryClickHandler(cat?.category?.id, cat?.category?.key)
									}
									key={cat?.category?.id}
									className={cn(s.categList_item, {
										[s.lastItem]: index === allCategories?.length - 1
									})}
								>
									<CategoriesIcons name={cat?.category?.key} />
									<div className={s.categoryName}>
										{localizeText(cat?.category?.name.replace(' ', '_'))}

										<span className={s.gameCount}>
											{cat?.category?.key === CategoriesKeys?.all
												? gameCount
													? gameCount
													: `${sumAllGames()}+`
												: cat?.category?.gamesCount}
										</span>
									</div>

									<Ripple />
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</Container>
	);
};

import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
	ButtonType,
	IPaymentMethod,
	InvoicePaymentResponseDataType,
	PaymentFlows,
	PaymentProvider
} from '@types';
import { Trans } from 'react-i18next';
import { Button, FormError, Input, Ripple } from '@components/common/elements';
import { useLocales } from '@lib/hooks';
import { useFormik } from 'formik';
import { paymentInvoiceTransctionData } from '@lib/api/payment';
import { paymentSelectors } from '@store/reducers/payment/selectors';
import { useSelector } from 'react-redux';
import { DefaultToast } from '@components/common/global-settings';
import { formatNumber, renderCurrencySymbol } from '@utils';
import { userSelectors } from '@store/reducers/user/selectors';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import s from './style.module.scss';
import { getImageByURL } from '@utils/getImageByURL';

type PropsType = {
	innerPlatformFlow: null | PaymentFlows;
	crossBtnHandler: () => void;
	dataForPay: InvoicePaymentResponseDataType | null;
	currentPaymentMethod?: IPaymentMethod;
};

export const FlowBlock = (props: PropsType) => {
	const { innerPlatformFlow, crossBtnHandler, dataForPay, currentPaymentMethod } = props;

	const { invoiceValue } = useSelector(paymentSelectors.paymentInfo);
	const { currency } = useSelector(userSelectors.userInfo);

	const [step, setStep] = useState(1);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Payment'
	});

	const nextStepHandler = () => {
		setStep((s) => s + 1);
	};

	const copyWalletHandler = () => {
		if (dataForPay) {
			toast((t) => (
				<DefaultToast
					t={t}
					text={'copied'}
				/>
			));
		}
	};

	const walletFieldName = currentPaymentMethod?.settings?.fields?.find(
		(f) => f?.field === 'wallet'
	)?.title;

	const handleSubmitFlowNativeConfirm = async (values: {
		transactionId: string;
		client_details: string;
	}) => {
		await paymentInvoiceTransctionData(
			dataForPay?.id || '',
			{
				transactionId:
					dataForPay?.provider === PaymentProvider?.octopay
						? undefined
						: values?.transactionId,
				invoiceId: dataForPay?.invoiceId,
				trx_id:
					dataForPay?.provider === PaymentProvider?.octopay
						? values?.transactionId
						: undefined,
				client_details:
					dataForPay?.provider === PaymentProvider?.octopay
						? values?.client_details
						: undefined
			},
			nextStepHandler
		);
	};

	const formikNativeConfirm = useFormik({
		initialValues: {
			transactionId: '',
			client_details: ''
		},
		onSubmit: handleSubmitFlowNativeConfirm
	});

	if (innerPlatformFlow === PaymentFlows?.flow_nagad) {
		return (
			<div className={s.blockFlow}>
				{step === 1 ? (
					<>
						<div className={s.howDepositeBlock}>
							<div className={s.nagadTitle}>
								<Trans
									ns='components/routes/translation'
									i18nKey={'Payment.how_to_deposite'}
								/>
							</div>
							<div className={s.nagadDescr}>
								<Trans
									ns='components/routes/translation'
									i18nKey={'Payment.nagad_flow_how_to_deposite'}
								/>
							</div>
						</div>

						<div className={s.walletBlock}>
							<div className={s.walletBlock_text}>
								<span>{localizeText('deposit_amount')}</span>
								<span>
									{renderCurrencySymbol(currency)} {formatNumber(Number(invoiceValue))}
								</span>
							</div>

							<CopyToClipboard
								onCopy={copyWalletHandler}
								text={invoiceValue}
							>
								<div className={s.copyBtn}>
									{localizeText('copy')} <Ripple />
								</div>
							</CopyToClipboard>
						</div>

						<div className={s.walletBlock}>
							<div className={s.walletBlock_text}>
								<span>{walletFieldName}</span>
								<span>{dataForPay?.walletForPay}</span>
							</div>

							<CopyToClipboard
								onCopy={copyWalletHandler}
								text={dataForPay?.walletForPay || ''}
							>
								<div className={s.copyBtn}>
									{localizeText('copy')} <Ripple />
								</div>
							</CopyToClipboard>
						</div>

						<div className={s.bntsBlock}>
							<Button
								rippleAnimation
								className={s.iPayed}
								type={ButtonType.secondary}
								text={localizeText('i_payed')}
								handle={nextStepHandler}
							/>
						</div>
					</>
				) : (
					<>
						<img
							src={getImageByURL(
								'../../../../../../../../assets/img/payments/money.webp'
							)}
							className={s.imageMoney}
						/>

						<div className={s.acceptedTitle}>{localizeText('accepted')}</div>

						<div className={s.stepItem}>
							<div>1</div>
							<div>{localizeText('we_accept_your_deposite')}</div>
						</div>

						<div className={s.bntsBlock}>
							<Button
								handle={crossBtnHandler}
								rippleAnimation
								className={s.payApp}
								type={ButtonType.primary}
								text={localizeText('continue_play')}
							/>
						</div>
					</>
				)}
			</div>
		);
	}

	if (innerPlatformFlow === PaymentFlows?.native_confirm) {
		return (
			<div className={s.blockFlow}>
				{step === 1 ? (
					<>
						<div className={s.howDepositeBlock}>
							<div className={s.nagadTitle}>
								<Trans
									ns='components/routes/translation'
									i18nKey={'Payment.how_to_deposite'}
								/>
							</div>
							<div className={s.nagadDescr}>
								<Trans
									ns='components/routes/translation'
									i18nKey={'Payment.nagad_flow_how_to_deposite'}
								/>
							</div>
						</div>

						<div className={s.walletBlock}>
							<div className={s.walletBlock_text}>
								<span>{localizeText('deposit_amount')}</span>
								<span>
									{renderCurrencySymbol(currency)} {formatNumber(Number(invoiceValue))}
								</span>
							</div>

							<CopyToClipboard
								onCopy={copyWalletHandler}
								text={invoiceValue}
							>
								<div className={s.copyBtn}>
									{localizeText('copy')} <Ripple />
								</div>
							</CopyToClipboard>
						</div>

						<div className={s.walletBlock}>
							<div className={s.walletBlock_text}>
								<span>
									{localizeText('account_number')}{' '}
									{dataForPay?.valid_till ? (
										<span className={s.valid_till}>
											{
												<Trans
													i18nKey='Payment.valid_till'
													ns='components/routes/translation'
													values={{
														date: dayjs(dataForPay?.valid_till * 1000).format(
															'DD-MM-YYYY HH:mm'
														)
													}}
												/>
											}
										</span>
									) : (
										''
									)}
								</span>
								<span>{dataForPay?.walletForPay || dataForPay?.target_card_number}</span>
							</div>

							<CopyToClipboard
								onCopy={copyWalletHandler}
								text={dataForPay?.walletForPay || ''}
							>
								<div className={s.copyBtn}>
									{localizeText('copy')} <Ripple />
								</div>
							</CopyToClipboard>
						</div>

						<div className={s.bntsBlock}>
							<Button
								rippleAnimation
								className={s.iPayed}
								type={ButtonType.secondary}
								text={localizeText('i_payed')}
								handle={nextStepHandler}
							/>
						</div>
					</>
				) : step === 2 ? (
					<>
						<div className={s.for_deposite_text}>
							{localizeText('for_deposite_send_money')}
						</div>
						<form
							onSubmit={formikNativeConfirm.handleSubmit}
							className={s.body}
						>
							{dataForPay?.provider === PaymentProvider?.octopay && (
								<div className={s.inputBlock}>
									<Input
										name='client_details'
										type='text'
										value={formikNativeConfirm?.values?.client_details}
										label={localizeText('account_phone_number')}
										onChange={formikNativeConfirm.handleChange}
										error={
											!!formikNativeConfirm?.touched?.client_details &&
											Boolean(formikNativeConfirm?.errors?.client_details)
										}
										onEnterDownHandler={formikNativeConfirm?.submitForm}
									/>
									<FormError
										error={
											formikNativeConfirm?.errors?.client_details &&
											localizeText(formikNativeConfirm?.errors?.client_details)
										}
										isTouched={!!formikNativeConfirm?.touched?.client_details}
									/>
								</div>
							)}

							<div className={s.inputBlock}>
								<Input
									name='transactionId'
									type='text'
									value={formikNativeConfirm?.values?.transactionId}
									label={'12 Digit UTR/Transaction ID/Ref ID'}
									onChange={formikNativeConfirm.handleChange}
									error={
										!!formikNativeConfirm?.touched?.transactionId &&
										Boolean(formikNativeConfirm?.errors?.transactionId)
									}
									onEnterDownHandler={formikNativeConfirm?.submitForm}
								/>
								<FormError
									error={
										formikNativeConfirm?.errors?.transactionId &&
										localizeText(formikNativeConfirm?.errors?.transactionId)
									}
									isTouched={!!formikNativeConfirm?.touched?.transactionId}
								/>
							</div>
						</form>
						<div className={s.bntsBlock}>
							<Button
								handle={() => formikNativeConfirm?.submitForm()}
								rippleAnimation
								className={s.payApp}
								type={ButtonType.primary}
								text={localizeText('confirm')}
							/>
						</div>
					</>
				) : (
					<>
						<img
							src={getImageByURL(
								'../../../../../../../../assets/img/payments/money.webp'
							)}
							className={s.imageMoney}
						/>

						<div className={s.acceptedTitle}>{localizeText('accepted')}</div>

						<div className={s.stepItem}>
							<div>1</div>
							<div>{localizeText('we_accept_your_deposite')}</div>
						</div>

						<div className={s.stepItem}>
							<div>2</div>
							<div>{localizeText('we_accept_your_deposite_problem')}</div>
						</div>

						<div className={s.bntsBlock}>
							<Button
								handle={crossBtnHandler}
								rippleAnimation
								className={s.payApp}
								type={ButtonType.primary}
								text={localizeText('continue_play')}
							/>
						</div>
					</>
				)}
			</div>
		);
	}

	return null;
};

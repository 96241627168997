import { StatisticsService } from './StatisticsService';
import {
	setCurrentUserTransactions,
	updateCurrentUserTransactions
} from '@store/reducers/common-statistics/dispatchers';

export type TransRequestOptions = {
	page: number;
};

export const getCurrentUserTransactions = async (
	params: TransRequestOptions,
	setPageCount?: (count: number) => void,
	isLoadMore?: boolean
) => {
	const {
		status,
		data: { data: transactions, pageCount }
	} = await StatisticsService.currentUserTransactions(params);

	setPageCount && setPageCount(pageCount);

	if (status === 200)
		isLoadMore
			? updateCurrentUserTransactions(transactions)
			: setCurrentUserTransactions(transactions);
};

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { pathsConfig } from '@config/paths';
import {
	formatNumber,
	getWaggeringGames,
	renderCurrencySymbol,
	sendAnalyticsHandler
} from '@utils';
import { useLocales, useUrlLang } from '@lib/hooks';
import { InfoWarn, ShevronLevels } from '@components/svg';
import { Button, FreespinBlock } from '@components/common/elements';
import { userSelectors } from '@store/reducers/user/selectors';
import { setIsSidebarOpened, setModalType } from '@store/reducers/common-ui/dispatchers';
import {
	AnalyticsEventType,
	ButtonType,
	IProviderGame,
	LocalizeText,
	ModalType,
	UserLevelObj
} from '@types';
import s from './style.module.scss';
import { ProgressBar } from '@components/common/elements/progress-bar';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { Hamburger } from '@components/common/page/header/parts';
import useShowSidebarButton from '@lib/hooks/useShowSidebarButton';
import { getImageByURL } from '@utils/getImageByURL';
import { LottieComponent } from '@components/common/elements/lottie';

type UserP = {
	localizeText: LocalizeText;
	isSidebarOpened: boolean;
};

export const User = (props: UserP) => {
	const { localizeText, isSidebarOpened } = props;
	const navigate = useNavigate();

	const { langUrlPrefix } = useUrlLang();
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const [currentLevel, setCurrentLevel] = useState<UserLevelObj | null>(null);

	const [wageringGames, setWageringGames] = useState<IProviderGame[]>();

	const localizeTextPayment = useLocales({
		path: 'components/routes',
		node: 'Payment'
	});

	const localizeTextCashbackBonuses = useLocales({
		path: 'components/routes',
		node: 'CashbackBonuses'
	});

	const { balance, userLevels, bonusBalance, userLevelId, currency, bonusBalanceWager } =
		useSelector(userSelectors.userInfo);

	const { bonusBalances } = useSelector(bonusSelectors.bonusInfo);
	const wagerBonus = bonusBalances[0]?.bonusBalanceWager;
	const buttonRef = useRef<HTMLButtonElement | null>(null);

	const currentValue = Number(
		(100 - (bonusBalanceWager * 100) / (wagerBonus / 100))?.toFixed(0)
	);

	const maxValue = Number((100 - (bonusBalanceWager * 100) / wagerBonus)?.toFixed(0));

	const goPaymentHandler = (withdrawal: boolean) => {
		setIsSidebarOpened(false);
		navigate(langUrlPrefix + pathsConfig?.payment?.link, { state: { withdrawal } });

		withdrawal
			? sendAnalyticsHandler(AnalyticsEventType?.menu_mobile_payment_out)
			: sendAnalyticsHandler(AnalyticsEventType?.menu_mobile_payment_in);
	};

	useEffect(() => {
		if (userLevelId && userLevels) {
			const level = userLevels?.find((e) => e?.id === userLevelId);

			level && setCurrentLevel(level);
		}
	}, [userLevelId, userLevels]);

	useEffect(() => {
		if (bonusBalance && isSidebarOpened) {
			getWaggeringGames(setWageringGames, true);
		}
	}, [bonusBalance, isSidebarOpened]);

	useShowSidebarButton({
		isSidebarOpened,
		buttonRef,
		element: 'auth-sidebar'
	});

	const currencySymbol = renderCurrencySymbol(currency?.toLowerCase());

	const openLevelsModal = () => {
		setIsSidebarOpened(false);
		navigate(langUrlPrefix + pathsConfig?.levels?.link);
		sendAnalyticsHandler(AnalyticsEventType?.menu_mobile_levels);
	};

	const clickWageringGamesHandler = () => {
		setModalType(ModalType.wageringGames);
		sendAnalyticsHandler(AnalyticsEventType?.menu_mobile_games);
	};

	const clickOpenInfoModal = () => {
		setModalType(ModalType.bonusMoneyTooltip);
		sendAnalyticsHandler(AnalyticsEventType?.menu_mobile_bonus);
	};

	return (
		<div className={cn(s.wrapper)}>
			<div className={s.header}>
				<div
					onClick={openLevelsModal}
					className={s.user}
				>
					{currentLevel && currentLevel?.level && currentLevel?.level < 21 && (
						<img
							className={s.user_level}
							src={getImageByURL(
								`../../../../../../../assets/img/levels/level_${currentLevel?.level}.webp`
							)}
						/>
					)}
					<div className={s.user_info}>
						<span className={s.user_info_name}>
							{currentLevel?.name} <ShevronLevels />
						</span>
						<span className={s.user_info_level}>
							{localizeTextCashbackBonuses.localizeText('level_number', {
								level: currentLevel?.level
							})}
						</span>
					</div>
				</div>
				{isMobile && (
					<button
						ref={buttonRef}
						onClick={(e) => {
							e.stopPropagation();
							setIsSidebarOpened(!isSidebarOpened);
						}}
						className={cn(s.menuBtn)}
					>
						<Hamburger />
					</button>
				)}
			</div>

			{bonusBalance === 0 && (
				<div className={cn(s.balance, s.main_balance)}>
					<div className={s.balance_info}>
						<div className={s.balance_info_data}>
							<span>{localizeText('user_balance')}</span>
							<span>
								{currencySymbol} {formatNumber(balance)}
							</span>
							{balance <= 0 && (
								<img
									className={s.moneyImg}
									src={getImageByURL(
										'../../../../../../../assets/img/common/sidebar_register_bonus.webp'
									)}
								/>
							)}
						</div>
					</div>
				</div>
			)}

			{Boolean(bonusBalance) && (
				<div className={cn(s.balance, s.bonus_balance, s.active)}>
					<div className={cn(s.balance_info, s.bonus)}>
						<div className={s.topBlock}>
							<div className={s.balance_info_wrapper}>
								<div className={s.balance_info_data}>
									<span>{localizeText('user_balance')}</span>
									<span>
										{currencySymbol} {formatNumber(balance)}
									</span>
								</div>
								<img
									className={s.vectorImg}
									src={getImageByURL(
										'../../../../../../../assets/img/common/vector.webp'
									)}
								/>
								<div className={cn(s.balance_info_data, s.bonusBalance)}>
									<span>
										{localizeText('user_bonus_balance')}
										<img
											className={s.coinImg}
											src={getImageByURL(
												'../../../../../../../assets/img/common/coin_without_space.webp'
											)}
										/>
									</span>
									<span>
										{currencySymbol} {formatNumber(bonusBalance)}
									</span>
								</div>
							</div>

							<div
								onClick={clickOpenInfoModal}
								className={s.info_icon}
							>
								<InfoWarn />
							</div>
						</div>

						{bonusBalance > 0 && (
							<>
								<ProgressBar
									containerClassName={s.progressBarContainer}
									currentValue={currentValue}
									maxValue={maxValue}
									customBg='rgba(0, 0, 0, 0.5)'
									isSidebarOpened={true}
								/>

								<div className={s.wagering_withdrawal}>
									{localizeText('wagering_withdrawal')}
									<div className={s.wagering_withdrawal_wrapper}>
										{currentValue}
										<div className={s.wagering_withdrawal_counter}>/100%</div>
									</div>
								</div>
							</>
						)}
					</div>
					{bonusBalance > 0 && wageringGames && wageringGames?.length > 0 && (
						<div
							onClick={clickWageringGamesHandler}
							className={s.wagering_games}
						>
							<div className={s.wagering_games_descr}>
								{localizeText('games_for_wagering')}{' '}
								<LottieComponent
									path={'../../../../../../../assets/lotties/arrows_lottie.json'}
									style={{ width: 44, height: 14 }}
								/>
							</div>

							<div className={s.wagering_games_images}>
								<img
									src={getImageByURL(
										'../../../../../../../assets/img/common/wagering-games.webp'
									)}
								/>
							</div>
						</div>
					)}
				</div>
			)}

			<FreespinBlock
				onOpenFunc={() => {
					setIsSidebarOpened(false);
					sendAnalyticsHandler(AnalyticsEventType?.menu_mobile_free_spin);
				}}
				withoutPadding
			/>

			<div className={s.wrapperButtons}>
				{balance >= 1 && (
					<Button
						type={ButtonType.secondary}
						text={localizeTextPayment.localizeText('amount_withdrawal')}
						handle={() => goPaymentHandler(true)}
					/>
				)}

				<Button
					rippleAnimation
					handle={goPaymentHandler}
					className={cn(s.reffilBtn, s.buttonWithGift)}
					type={ButtonType.primary}
					text={localizeText('user_reffil')}
				/>
			</div>
		</div>
	);
};

import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';

const CurrentProviderGameBody = lazy(() => import('./CurrentProviderGame'));

export const CurrentProviderGame = () => (
	<>
		<Helmet>
			<link
				rel='canonical'
				href={window?.location?.href}
			/>
		</Helmet>
		<React.Suspense fallback={<></>}>
			<CurrentProviderGameBody />
		</React.Suspense>
	</>
);

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocales } from '@lib/hooks';
import { commonStatisticsSelectors } from '@store/reducers/common-statistics/selectors';
import { getCurrentUserTransactions } from '@lib/api/statistics';
import { AnalyticsEventType, ICurrentUserTransaction, PaymentType } from '@types';
import { userSelectors } from '@store/reducers/user/selectors';
import s from './style.module.scss';
import { Preloader, Ripple } from '@components/common/elements';
import { formatNumber, renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { TransTypeComponents } from '@components/svg';
import dayjs from 'dayjs';
import convertToUsers from '@utils/convertToUsers';

const CurrentUserTransactions = () => {
	const { currentUserTransactions } = useSelector(
		commonStatisticsSelectors.commonStatisticsInfo
	);

	const { currency } = useSelector(userSelectors.userInfo);

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [loading, setLoading] = useState(true);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'CurrentUserTransactions'
	});

	const GamesViewerLocalize = useLocales({
		path: 'components/common/elements',
		node: 'GamesViewer'
	});

	useEffect(() => {
		getCurrentUserTransactions({ page }, setTotalPages, page > 1).finally(() =>
			setLoading(false)
		);
	}, [page]);

	const loadMoreBets = () => {
		sendAnalyticsHandler(AnalyticsEventType?.transaction_click_more);
		setTimeout(() => {
			setLoading(true);
			setPage((p) => p + 1);
		}, 200);
	};

	const getTransactionStatusTextAndColor = (
		status: ICurrentUserTransaction['status']
	): { text: string; color: string } => {
		switch (status) {
			case 'Done':
				return {
					text: localizeText('done'),
					color: 'var(--main-tertiary)'
				};
			case 'Processing':
				return {
					text: localizeText('processing'),
					color: 'var(--main-tertiary)'
				};
			case 'Cancelled':
				return {
					text: localizeText('cancelled'),
					color: 'var(--elements-status-invalid)'
				};
			case 'Error':
				return {
					text: localizeText('error'),
					color: 'var(--elements-status-invalid)'
				};
			default:
				return { text: '', color: '' };
		}
	};

	const renderTransLabel = (type: PaymentType) => {
		switch (type) {
			case PaymentType['rmw-bonus']:
				return localizeText('account_bonus');
			case PaymentType['promo_money']:
				return localizeText('account_bonus');
			case PaymentType['task']:
				return localizeText('account_bonus');
			case PaymentType['referral']:
				return localizeText('account_bonus');
			case PaymentType['freespin_win']:
				return localizeText('account_bonus');
			case PaymentType['freeze_balance_update']:
				return localizeText('account_cancel_bonus');
			case PaymentType['cashback']:
				return localizeText('account_cashback');
			case PaymentType['invoice']:
				return localizeText('account_deposit');
			case PaymentType['withdrawal']:
				return localizeText('withdrawal');
			case PaymentType['withdraw_invoice']:
				return localizeText('withdrawal');
			case PaymentType['withdraw']:
				return localizeText('withdrawal');
			case PaymentType['transfer_bonus']:
				return localizeText('transfer_bonus');
			case PaymentType['bonus_expire']:
				return localizeText('bonus_expire');
			case PaymentType['level_upgrade_bonus']:
				return localizeText('level_upgrade_bonus');
			case PaymentType['bonus_balance']:
				return localizeText('bonus_balance');
			default:
				return type;
		}
	};

	return (
		<div className={s.betsList}>
			{currentUserTransactions.length === 0 ? (
				!loading && (
					<div className={s.emptyBlock}>
						<h3 className={s.emptyBet}>{localizeText('not_transactions')}</h3>
					</div>
				)
			) : (
				<>
					{currentUserTransactions.map((transaction, i) => {
						const isInvoice = transaction.type === PaymentType.invoice;

						const isBonus =
							transaction.type === PaymentType['rmw-bonus'] ||
							transaction.type === PaymentType['promo_money'] ||
							transaction.type === PaymentType['task'] ||
							transaction.type === PaymentType['referral'] ||
							transaction.type === PaymentType['freespin_win'] ||
							transaction.type === PaymentType['cashback'] ||
							transaction.type === PaymentType['transfer_bonus'] ||
							transaction.type === PaymentType['level_upgrade_bonus'];

						const isUpgradeBonusBalance =
							transaction.type === PaymentType['bonus_balance'] &&
							transaction.bonusBalance > 0;

						const isCashback = transaction.type === PaymentType['cashback'];

						let amount =
							Number(transaction?.amount) < 0
								? transaction?.amount * -1
								: transaction?.amount;

						if (
							transaction.type === PaymentType['bonus_expire'] ||
							transaction?.amount === 0
						) {
							amount =
								Number(transaction?.bonusBalance) < 0
									? transaction?.bonusBalance * -1
									: transaction?.bonusBalance;
						}

						return (
							<div
								key={i}
								className={s.betItem}
							>
								<div className={s.betCoef}>
									<TransTypeComponents name={transaction.type} />
								</div>

								<div style={{ width: '100%' }}>
									<div className={s.betInfoBlock}>
										<div className={s.nameDate}>
											<span className={s.name}>{renderTransLabel(transaction.type)}</span>
											<span className={s.date}>
												{dayjs(transaction.createdAt).format('DD.MM.YYYY, HH:mm')}
											</span>
										</div>

										<div className={s.sumBlock}>
											<span
												className={s.winAmount}
												style={{
													color:
														isInvoice || isBonus || isCashback || isUpgradeBonusBalance
															? 'var(--elements-status-success)'
															: 'var(--main-secondary)'
												}}
											>
												{isInvoice || isBonus || isCashback || isUpgradeBonusBalance
													? '+ '
													: '- '}
												{renderCurrencySymbol(currency?.toLowerCase())}{' '}
												{formatNumber(amount ? convertToUsers(amount) : 0)}
											</span>
											<span
												className={s.betAmount}
												style={{
													color: `${
														getTransactionStatusTextAndColor(transaction.status).color
													}`
												}}
											>
												{getTransactionStatusTextAndColor(transaction.status).text}
											</span>
										</div>
									</div>
								</div>
							</div>
						);
					})}
					{totalPages > page && !loading && (
						<div className={s.btnBlock}>
							<button
								className={s.loadMoreButton}
								onClick={loadMoreBets}
							>
								{GamesViewerLocalize?.localizeText('load_more')}
								<Ripple />
							</button>
						</div>
					)}
				</>
			)}

			{loading && (
				<div className={s.preloaderBlock}>
					<Preloader className={s.preloader} />
				</div>
			)}
		</div>
	);
};

export default CurrentUserTransactions;

import { useEffect } from 'react';
import { isTapX } from '@config/global';
import { useTranslation } from 'react-i18next';

export const WebimChat = () => {
	const { i18n } = useTranslation();

	useEffect(() => {
		if (!isTapX) {
			const scriptTag = document.createElement('script');

			scriptTag.type = 'text/javascript';

			const location = {
				pt: 'loceng',
				en: 'loceng',
				bn: 'locbg',
				ru: 'locru',
				hi: 'lochi'
			};

			const loc = location[i18n?.language as keyof typeof location];

			if (loc) {
				scriptTag.innerHTML = `
		webim = {
			accountName: 'topx',
			domain: 'topx.webim2.ru',
			location: '${loc}'
		};

		(function () {
			var s = document.createElement('script');
			s.type = 'text/javascript';
			s.src = 'https://topx.webim2.ru/js/button.js';
			document.getElementsByTagName('head')[0].appendChild(s);
		})();
		`;

				document.body.appendChild(scriptTag);
			}
		}
	}, [i18n?.language]);

	return null;
};

import React from 'react';
import { useLocales } from '@lib/hooks';
import { userSelectors } from '@store/reducers/user/selectors';
import { renderCurrencySymbol } from '@utils';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import s from './style.module.scss';
import cn from 'classnames';
import { getImageByURL } from '@utils/getImageByURL';

export const HowGetMoney = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'TelegramBonuses'
	});

	const { currency } = useSelector(userSelectors.userInfo);

	return (
		<div className={s.container}>
			<div className={s.text_block}>
				<h1 className={s.text_block_title}>
					<Trans
						ns='components/routes/translation'
						i18nKey={'TelegramBonuses.how_to_get_money'}
						values={{ curr: renderCurrencySymbol(currency) || '৳' }}
						components={{ b: <b /> }}
					/>
				</h1>
			</div>

			<div className={s.cards_block}>
				<div className={s.cards_block_item}>
					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/telegram-bonus/card_1.webp')}
						alt='Found an error?'
					/>

					<div className={s.cards_block_item_text}>
						<div className={s.title}>{localizeText('how_get_money_card_title_1')}</div>
						<div className={s.descr}>{localizeText('how_get_money_card_descr_1')}</div>
					</div>
				</div>
				<div className={cn(s.cards_block_item, s.color_2)}>
					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/telegram-bonus/card_2.webp')}
						alt='Found an error?'
					/>

					<div className={s.cards_block_item_text}>
						<div className={s.title}>{localizeText('how_get_money_card_title_2')}</div>
						<div className={s.descr}>{localizeText('how_get_money_card_descr_2')}</div>
					</div>
				</div>

				<div className={cn(s.cards_block_item, s.color_3)}>
					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/telegram-bonus/card_3.webp')}
						alt='Found an error?'
					/>

					<div className={s.cards_block_item_text}>
						<div className={s.title}>
							{localizeText('how_get_money_card_title_3', {
								curr: renderCurrencySymbol(currency) || '৳'
							})}
						</div>
						<div
							className={s.descr}
							style={{ color: 'rgba(255, 255, 255, 0.75)' }}
						>
							{localizeText('how_get_money_card_descr_3', {
								curr: renderCurrencySymbol(currency) || '৳'
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

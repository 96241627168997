import { StatisticsService } from './StatisticsService';
import {
	setCurrentUserBets,
	updateCurrentUserBets
} from '@store/reducers/common-statistics/dispatchers';

export type BetsRequestOptions = {
	page: number;
};

export const getCurrentUserBets = async (
	params: BetsRequestOptions,
	setPageCount?: (count: number) => void,
	isLoadMore?: boolean
) => {
	const {
		status,
		data: { data: bets, pageCount }
	} = await StatisticsService.currentUserBets(params);

	setPageCount && setPageCount(pageCount);

	if (status === 200) isLoadMore ? updateCurrentUserBets(bets) : setCurrentUserBets(bets);
};

import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { EditPencil, InfoWarn } from '@components/svg';
import { Button, FormError, Input } from '@components/common/elements';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import s from './style.module.scss';
import { confirmEmail, updateCurrentUserData } from '@lib/api/user';
import { AnalyticsEventType, Settings } from '@types';
import { sendAnalyticsHandler } from '@utils';

type PropsType = {
	codeOpenModalHandler: (type: 'phone' | 'email') => void;
};

const EmailBlock = (props: PropsType) => {
	const { codeOpenModalHandler } = props;

	const { email, isVerified } = useSelector(userSelectors.userInfo);

	const [isEdit, setIsEdit] = useState(false);

	const editHandler = () => setIsEdit((e) => !e);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Profile'
	});

	const handleSubmit = async (values: { email: string }) => {
		sendAnalyticsHandler(AnalyticsEventType?.profile_confirm_mail);
		const successFunc = async () => {
			codeOpenModalHandler('email');
			await confirmEmail();
		};

		await updateCurrentUserData(Settings.email, values?.email, successFunc);
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string().email('wrong_format').required(localizeText('can_not_be_empty'))
	});

	const formik = useFormik({
		initialValues: {
			email: email || ''
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		if (email) {
			formik?.setFieldValue('email', email);
		}
	}, [email]);

	return (
		<div className={s.block}>
			<div className={s.formBlock}>
				<form
					onSubmit={formik.handleSubmit}
					className={s.form}
				>
					<Input
						name='email'
						type='email'
						value={formik?.values?.email}
						label='E-mail'
						onChange={formik.handleChange}
						error={!!formik?.touched?.email && Boolean(formik?.errors?.email)}
						isDisabled={isVerified && !isEdit}
					/>

					{!isVerified || isEdit ? (
						<Button
							rippleAnimation
							className={s.button}
							btnType='submit'
							text={localizeText('confirm')}
							isDisabled={isVerified && formik?.values?.email === email}
						/>
					) : (
						<button
							onClick={() => {
								editHandler();

								sendAnalyticsHandler(AnalyticsEventType?.profile_edit_mail);
							}}
							type='button'
							className={s.editBtn}
						>
							<EditPencil />
						</button>
					)}
				</form>

				<FormError
					error={formik?.errors?.email}
					isTouched={!!formik?.touched?.email}
				/>
			</div>

			{!isVerified ? (
				<div className={s.needConfirm}>
					<InfoWarn />
					{localizeText('need_confirm_email')}
				</div>
			) : (
				!isEdit && (
					<div className={s.confirmed}>
						<InfoWarn />
						{localizeText('email_confirmed')}
					</div>
				)
			)}
		</div>
	);
};

export default EmailBlock;

import React, { useEffect, useState } from 'react';
import {
	FormError,
	Button,
	Preloader,
	Input,
	PhoneInput
} from '@components/common/elements';
import { useLocales, useUrlLang } from '@lib/hooks';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { CloseSvg, GoogleIcon } from '@components/svg';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';
import { resetPassword } from '@lib/api/auth';
import { pathsConfig } from '@config/paths';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useNavigate } from 'react-router-dom';
import { sendAnalyticsHandler } from '@utils';
import { EMAIL_ENDS } from '@config/global';
import s from './style.module.scss';
import * as Yup from 'yup';
import cn from 'classnames';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import { getPhoneNumberByRegion } from '@utils/phoneNumberUtil';
import { getCountries } from 'react-phone-number-input';
import { userSelectors } from '@store/reducers/user/selectors';
import { CountryCode } from 'libphonenumber-js';
import { getImageByURL } from '@utils/getImageByURL';

enum MethodTypesE {
	email = 'email',
	phone = 'phone'
}

export const ResetPasswordModal = () => {
	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();
	const { locationData } = useSelector(userSelectors.userInfo);

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const [isSuccess, setIsSuccess] = useState(false);

	const { isAuthPending, isAuthed, nextAttemptResetPassword } = useSelector(
		authSelectors.authInfo
	);

	const { isMobile, mobileHeight } = useSelector(commonUISelectors.commonUIInfo);

	const methodTypes: Array<{ text: string; type: MethodTypesE }> = [
		{
			text: 'E-mail',
			type: MethodTypesE?.email
		},
		{
			text: localizeText('phone'),
			type: MethodTypesE?.phone
		}
	];

	const [methodType, setMethodType] = useState<MethodTypesE>(methodTypes[0]?.type);
	const [counter, setCounter] = useState(0);
	const [countryCode, setCountryCode] = useState(
		locationData?.countryCode || getCountries()[0]
	);

	const { nationalPrefix } = getPhoneNumberByRegion(countryCode);

	useEffect(() => {
		if (nextAttemptResetPassword) {
			const date1 = dayjs(nextAttemptResetPassword);
			const date2 = dayjs();

			setCounter(date1.diff(date2, 'seconds'));
		}
	}, [nextAttemptResetPassword]);

	useEffect(() => {
		counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
	}, [counter]);

	const closeAuthModal = () => {
		const func = () => {
			if (isAuthed) {
				if (!isMobile) {
					navigate(langUrlPrefix + pathsConfig.profile.link);
					setModalType(null);
				} else {
					setModalType(ModalType?.profileSettings);
				}
			} else {
				setModalType(ModalType?.signIn);
			}

			setIsSuccess(false);
		};

		setTimeout(func, 200);
	};

	const validationSchema = Yup.object().shape({
		email:
			methodType === MethodTypesE?.email
				? Yup.string()
						.email('invalid_email')
						.transform((value, originalValue) =>
							typeof originalValue === 'string'
								? originalValue.replace(/\s/g, '')
								: originalValue
						)
						.required('need_fill_field')
				: Yup.string(),

		phone:
			methodType === MethodTypesE?.phone
				? Yup.string()
						.phone(countryCode as CountryCode, 'invalid_phone')
						.required('need_fill_field')
				: Yup.string()
	});

	const handleSubmit = (values: { email: string; phone: string }) => {
		sendAnalyticsHandler(AnalyticsEventType?.new_password_click_to_send, {
			new_password_switch: methodType
		});

		const countrycode = `+${nationalPrefix}`;

		const newValues = {
			email:
				methodType === MethodTypesE?.email
					? values?.email?.replace(/\s/g, '')
					: undefined,
			phone:
				methodType === MethodTypesE?.phone
					? `${countrycode}${values?.phone}`.replace(/[\s-]/g, '')
					: undefined
		};

		resetPassword(newValues, formik.setFieldError, setIsSuccess, methodType);
	};

	const formik = useFormik({
		initialValues: {
			email: '',
			phone: ''
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	const heightStyle = {
		'--height': isMobile ? mobileHeight - 40 + 'px' : 'auto'
	} as React.CSSProperties;

	return (
		<div className={s.wrapper}>
			<div
				style={heightStyle}
				className={s.inner}
			>
				<div className={s.header}>
					<div className={s.headerWrapper}>
						<div className={s.headerTitle}>{localizeText('reset')}</div>
						<div
							onClick={closeAuthModal}
							className={s.headerClose}
						>
							<CloseSvg />
						</div>
					</div>
				</div>
				<form
					onSubmit={formik.handleSubmit}
					className={s.content}
				>
					{isSuccess ? (
						<>
							<div className={s.successBlock}>
								<img
									src={getImageByURL(
										'../../../../../../assets/img/auth/reset-pass-success.webp'
									)}
									alt='Success Reset Password'
									className={s.img}
								/>

								<span className={s.successBlock_text}>
									{localizeText(
										methodType === MethodTypesE?.email
											? 'on_your_email_will_send_new_password'
											: 'on_your_phone_will_send_new_password'
									)}
								</span>
							</div>

							{methodType === MethodTypesE?.email && (
								<a
									target='_blank'
									href='https://www.gmail.com/'
									className={s.googleBtn}
									rel='noreferrer'
									onClick={() =>
										sendAnalyticsHandler(AnalyticsEventType?.new_password_сlick_google)
									}
								>
									<GoogleIcon />
									{localizeText('go_to_google')}
								</a>
							)}
							<Button
								handle={closeAuthModal}
								type={
									methodType === MethodTypesE?.email
										? ButtonType.secondary
										: ButtonType.primary
								}
								btnType='button'
								className={s.button}
								withoutGlare
								rippleAnimation
								text={localizeText('go_to_main')}
							/>
						</>
					) : (
						<>
							<div className={s.description}>
								{localizeText('write_email_or_phone_from_registration')}
							</div>

							<div
								className={cn(s.tabs, { [s.phone]: methodType === MethodTypesE?.phone })}
							>
								{methodTypes.map((tab) => (
									<span
										key={tab?.type}
										className={cn(s.tab, {
											[s.tabActive]: tab?.type === methodType
										})}
										onClick={() => {
											setMethodType(tab.type);
										}}
									>
										{tab.text}
									</span>
								))}
							</div>

							<div
								className={cn(s.inputBlock, {
									[s.hidden]: methodType !== MethodTypesE?.phone
								})}
							>
								<PhoneInput
									error={!!formik?.touched?.phone && Boolean(formik?.errors?.phone)}
									onNumberChange={(num) => formik.setFieldValue('phone', num)}
									setCountryCode={setCountryCode}
									onBlur={(isValid) => {
										formik?.setFieldTouched('phone', !isValid);
										formik?.setFieldError(
											'phone',
											isValid ? '' : localizeText('enter_valide_phone')
										);
									}}
									initValue={formik?.values?.phone}
								/>

								<FormError
									error={
										formik?.errors?.phone === 'bad_password_account'
											? ' '
											: formik?.errors?.phone && localizeText(formik?.errors?.phone)
									}
									isTouched={!!formik?.touched?.phone}
								/>
							</div>

							<div
								className={cn(s.inputBlock, {
									[s.hidden]: methodType !== MethodTypesE?.email
								})}
							>
								<Input
									name='email'
									type='text'
									value={formik?.values?.email.trim()}
									label={'E-mail'}
									placeholder='*****@gmail.com'
									onChange={(e) => {
										formik.handleChange(e);
									}}
									error={!!formik?.touched?.email && Boolean(formik?.errors?.email)}
								/>
								<FormError
									error={
										formik?.errors?.email === 'bad_password_account'
											? ' '
											: formik?.errors?.email && localizeText(formik?.errors?.email)
									}
									isTouched={!!formik?.touched?.email}
								/>

								{formik?.values?.email?.includes('@') &&
									EMAIL_ENDS?.filter((e) =>
										e?.includes(formik?.values?.email?.split('@')[1])
									)?.length > 0 &&
									!EMAIL_ENDS?.includes('@' + formik?.values?.email?.split('@')[1]) && (
										<div className={s.emailHelpDropDown}>
											{EMAIL_ENDS?.filter((e) =>
												e?.includes(formik?.values?.email?.split('@')[1])
											)?.map((e) => {
												return (
													<div
														onClick={() =>
															formik?.setFieldValue(
																'email',
																formik?.values?.email?.split('@')[0] + e
															)
														}
														className={s.emailHelpDropDown_item}
														key={e}
													>
														{formik?.values?.email?.split('@')[0] + e}
													</div>
												);
											})}
										</div>
									)}
							</div>

							<div className={s.buttonWrapper}>
								<Button
									type={ButtonType.primary}
									btnType='submit'
									isDisabled={counter > 0}
									className={s.button}
									rippleAnimation
									withoutGlare
									text={
										counter > 0 ? (
											<Trans
												ns='components/routes/translation'
												i18nKey={'Profile.verify_code_again_timer'}
												values={{
													time: `0:${counter < 10 ? '0' + counter : counter}`
												}}
											/>
										) : isAuthPending ? (
											<Preloader
												styles={{ height: '100%' }}
												isWhite={true}
											/>
										) : (
											localizeText('restore_password')
										)
									}
								/>
							</div>
						</>
					)}
				</form>
			</div>
		</div>
	);
};

import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import { isTapX } from '@config/global';
import { useUrlLang } from '@lib/hooks';
import { userSelectors } from '@store/reducers/user/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { DropdownLang } from '@components/common/elements';
import { NavbarItem } from './NavbarItem';
import { MenuSideBarNames, ModalType } from '@types';
import s from './style.module.scss';

type NavbarP = {
	isAuthed: boolean;
};

export const Navbar = (props: NavbarP) => {
	const { isAuthed } = props;

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { isVerified } = useSelector(userSelectors.userInfo);

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const navbarItems = [
		{
			link: '',
			nameKey: MenuSideBarNames.settings,
			isRoute: false,
			show: isAuthed && !isVerified,
			line: true,
			handle: () =>
				!isMobile
					? navigate(langUrlPrefix + pathsConfig.profile.link)
					: setModalType(ModalType.profileSettings)
		},

		{
			link: pathsConfig?.home?.tabs.casino.link,
			nameKey: MenuSideBarNames.casino,
			isRoute: true,
			show: true,
			line: false
		},

		{
			link: pathsConfig.bonus.link,
			nameKey: MenuSideBarNames.bonuces,
			isRoute: true,
			show: !isTapX,
			line: false
		},

		{
			link: '',
			nameKey: MenuSideBarNames.settings,
			isRoute: false,
			show: isAuthed && isVerified,
			line: false,
			handle: () =>
				!isMobile
					? navigate(langUrlPrefix + pathsConfig.profile.link)
					: setModalType(ModalType.profileSettings)
		},

		{
			link: '',
			nameKey: MenuSideBarNames.bets_and_tarns,
			isRoute: false,
			show: isAuthed,
			line: false,
			handle: () =>
				!isMobile
					? navigate(langUrlPrefix + pathsConfig.transaction.link)
					: setModalType(ModalType?.transAndBets)
		},

		{
			link: '',
			nameKey: MenuSideBarNames.promocode,
			isRoute: false,
			show: isAuthed,
			line: false,
			handle: () => setModalType(ModalType.promocode)
		},

		{
			link: '',
			nameKey: MenuSideBarNames.info,
			isRoute: false,
			show: true,
			line: true,
			handle: () =>
				!isMobile
					? navigate(langUrlPrefix + pathsConfig.information.link)
					: setModalType(ModalType?.information)
		}
	];

	return (
		<>
			<nav className={s.navbar}>
				{navbarItems.map((route, i) => {
					if (route?.show) {
						return (
							<Fragment key={i}>
								<NavbarItem {...route} />

								{route?.line && (
									<div className={s.devider}>
										<div className={s.devider_line} />
									</div>
								)}
							</Fragment>
						);
					}

					return null;
				})}
			</nav>

			<div className={s.changeLangBlock}>
				<DropdownLang menu />
			</div>
			<div className={s.devider}>
				<div className={s.devider_line} />
			</div>
		</>
	);
};

import React from 'react';
import { Header, Main, MobileSidebar, Sidebar } from '@components/common/page';
import { RoutesWrapper } from '@components/routes-wrapper';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { BonusOpenGame } from '@components/common/modals';
import { useLocation } from 'react-router-dom';
import { pathsConfig } from '@config/paths';

export const ContentWrapper = () => {
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { pathname } = useLocation();

	return (
		<>
			{pathsConfig?.sign_up_1?.link &&
				!pathname?.includes(pathsConfig?.sign_up_1?.link) && <Header />}

			<Main>
				<RoutesWrapper />
			</Main>

			{pathsConfig?.sign_up_1?.link &&
				!pathname?.includes(pathsConfig?.sign_up_1?.link) &&
				isMobile && <MobileSidebar />}

			{pathsConfig?.sign_up_1?.link &&
				!pathname?.includes(pathsConfig?.sign_up_1?.link) &&
				!isMobile && <Sidebar />}

			<BonusOpenGame />
		</>
	);
};

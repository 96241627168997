import React from 'react';
import { useLocales } from '@lib/hooks';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';
import s from './style.module.scss';

export const BannersSignUp_3_4 = () => {
	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	return (
		<div
			onClick={() => {
				sendAnalyticsHandler(AnalyticsEventType?.advertising_click_banner, {
					banner_place: 'Форма регистрации',
					banner_name: 'Double deposite small',
					banner_position: 1
				});
			}}
			className={s.bonusWrapper}
		>
			<div className={s.bonusV}>
				<img
					alt='bonusV1'
					src='/assets/img/common/signup3-4.webp'
					className={s.bonusVIcon}
				/>
				<div className={s.bonusVContent}>
					<div className={s.bonusVTitle1}>{localizeText('depositx5')}</div>
					<div className={s.bonusVDescriptionV1}>{localizeText('depositx5descr')}</div>
				</div>
			</div>
		</div>
	);
};

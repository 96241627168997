import React from 'react';
import s from './style.module.scss';
import { getImageByURL } from '@utils/getImageByURL';

export const Banner = () => {
	return (
		<div className={s.banner}>
			<img
				className={s.bg_1}
				alt='Coins'
				src={getImageByURL('../../../assets/img/referral/bg_1.webp')}
			/>
			<img
				className={s.bg_2}
				alt='Coins'
				src={getImageByURL('../../../assets/img/referral/bg_2.webp')}
			/>
			<img
				className={s.body}
				alt='Body'
				src={getImageByURL('../../../assets/img/referral/body.webp')}
			/>
		</div>
	);
};

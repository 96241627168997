import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { FormError, PhoneInput } from '@components/common/elements';
import { getCountries } from 'react-phone-number-input';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { PhoneNumberUtil } from 'google-libphonenumber';

type PropsTypes = {
	phoneError: string | undefined;
	phoneTouch: boolean | undefined;
	phone: string;
	setFieldTouched: (
		field: string,
		value: any,
		shouldValidate?: boolean
	) => Promise<void> | any;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean
	) => Promise<void> | any;

	setExternalCountryCode: (code: string) => void;
};

export const PhoneBlockSignUp = (props: PropsTypes) => {
	const {
		phoneError,
		phoneTouch,
		setFieldTouched,
		setFieldValue,
		setExternalCountryCode,
		phone
	} = props;

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const { locationData } = useSelector(userSelectors.userInfo);

	const [countryCode, setCountryCode] = useState(
		locationData?.countryCode || getCountries()[0]
	);

	const phoneUtil = PhoneNumberUtil.getInstance();

	const countryCodePhone = phoneUtil.getCountryCodeForRegion(
		locationData?.countryCode || getCountries()[0]
	);

	const phoneWithoutCode = phone?.replace(`+${countryCodePhone}`, '').trim();

	useEffect(() => {
		if (countryCode) {
			setExternalCountryCode(countryCode);
		}
	}, [countryCode]);

	return (
		<div>
			<PhoneInput
				onNumberChange={(num) => setFieldValue('phone', num)}
				setCountryCode={setCountryCode}
				onBlur={(isValid) => {
					setFieldTouched('phone', !isValid);
				}}
				initValue={phoneWithoutCode}
				error={!!phoneTouch && Boolean(phoneError)}
			/>

			<FormError
				error={
					phoneError === 'bad_password_account'
						? ' '
						: phoneError && localizeText(phoneError)
				}
				isTouched={!!phoneTouch}
			/>
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { Button, Input } from '@components/common/elements';
import { ButtonType } from '@types';
import { CloseSvg, InfoWarn, ShevronSvg } from '@components/svg';
import Div100vh from 'react-div-100vh';
import s from './style.module.scss';
import '../../../../../../../../node_modules/video-react/dist/video-react.css';
import { useLocales } from '@lib/hooks';
import cn from 'classnames';
import { paymentSelectors } from '@store/reducers/payment/selectors';
import { useSelector } from 'react-redux';
import useTrimData from '@lib/hooks/useTrimData';

type PropsType = {
	openAdditionalFieldsModal: () => void;
	fields: Array<{ field: string; title: string }>;
	setWalletData: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
	walletData: Record<string, unknown>;
	description: string;
	paymentHandler: () => void;
	providerId: number;
};

export const AdditionalFelds = (props: PropsType) => {
	const {
		openAdditionalFieldsModal,
		fields,
		setWalletData,
		walletData,
		description,
		paymentHandler,
		providerId
	} = props;

	const [openDescr, setOpenDescr] = useState(false);

	const openDescrHandler = () => setOpenDescr((d) => !d);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Payment'
	});

	const { selectedPaymentMethodId } = useSelector(paymentSelectors.paymentInfo);
	const trimmedWalletData = useTrimData(walletData);

	useEffect(() => {
		const walletsNumbers = window?.localStorage.getItem('wallets_numbers');

		if (walletsNumbers) {
			const walletsNumbersJSON: Array<{
				providerId: number;
				fields: Record<string, unknown>;
			}> = JSON.parse(walletsNumbers);

			if (walletsNumbersJSON && walletsNumbersJSON?.length > 0) {
				const haveWalletItem = walletsNumbersJSON?.find(
					(e) => e?.providerId === providerId
				);

				if (haveWalletItem && haveWalletItem?.fields) {
					setWalletData && setWalletData(haveWalletItem?.fields);
				} else {
					setWalletData && setWalletData({});
				}
			}
		} else {
			setWalletData && setWalletData({});
		}
	}, [selectedPaymentMethodId]);

	useEffect(() => {
		setWalletData(trimmedWalletData);
	}, [trimmedWalletData]);

	return (
		<Div100vh
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) {
					openAdditionalFieldsModal();
				}
			}}
			className={s.videoInstructionModal}
		>
			<div className={s.modalInner}>
				<div className={s.videoModalHeadBlock}>
					<h2>{localizeText('account_info')}</h2>

					<button
						onClick={openAdditionalFieldsModal}
						className={s.videoModalHeadBlock_close}
					>
						<CloseSvg />
					</button>
				</div>
				<div className={s.videoModalBottomBlock}>
					{description && (
						<div className={cn(s.comissionBlock, { [s.open]: openDescr })}>
							<InfoWarn />
							<div className={s.comissionBlock_text}>{description}</div>

							{description?.length > 140 && (
								<button
									onClick={openDescrHandler}
									className={s.shevron}
								>
									<ShevronSvg />
								</button>
							)}
						</div>
					)}

					{fields?.map((f) => {
						if (walletData && setWalletData) {
							return (
								<div
									key={f?.field}
									className={s.inputBlock}
								>
									<Input
										type={'text'}
										name='wallet'
										label={f?.title}
										value={walletData[f?.field] as string}
										onChange={(e) => {
											const updatedData: Record<string, unknown> = {
												...walletData,
												[f?.field as string]: e.currentTarget.value as unknown
											};

											setWalletData(updatedData);
										}}
									/>
								</div>
							);
						}

						return null;
					})}

					<Button
						handle={paymentHandler}
						className={s.videoModalBottomBlock_btn}
						type={ButtonType?.primary}
						text={localizeText('deposit')}
						rippleAnimation
					/>
				</div>
			</div>
		</Div100vh>
	);
};

import React from 'react';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Trans } from 'react-i18next';
import { renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { pathsConfig } from '@config/paths';
import { useNavigate } from 'react-router-dom';
import s from './style.module.scss';
import { Banner } from './parts';
import { AnalyticsEventType } from '@types';

type PropsType = { goFunc?: () => void; isMenu?: boolean };

export const FreeMoney = (props: PropsType) => {
	const { localizeText } = useLocales({
		path: 'components/common/sections',
		node: 'ExtraOffers'
	});

	const { goFunc, isMenu } = props;

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { currency } = useSelector(userSelectors.userInfo);

	return (
		<div
			onClick={() => {
				navigate(langUrlPrefix + pathsConfig.telegram_bonus.link);

				goFunc && goFunc();

				sendAnalyticsHandler(
					isMenu
						? AnalyticsEventType?.menu_advertising_click_banner
						: AnalyticsEventType?.advertising_click_banner,
					{
						banner_place: 'Хедер реклама',
						banner_name: 'Telegram bonus',
						banner_position: 1
					}
				);
			}}
			className={s.card}
		>
			<div className={s.textBlock}>
				<span>
					<Trans
						ns='components/common/sections/translation'
						i18nKey={'ExtraOffers.free_money_title'}
						values={{ sum: `${renderCurrencySymbol(currency?.toLowerCase())} 100` }}
					/>
				</span>
				<span>{localizeText('free_money_descr')}</span>
			</div>

			<Banner />
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InvoiceResolver } from './InvoiceResolver';
import { paymentSelectors } from '@store/reducers/payment/selectors';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { InvoiceP } from '../../../../type';

export const Invoice = (props: InvoiceP) => {
	const {
		localizeText,
		currentPaymentMethod,
		setInnerPlatformFlow,
		setDataForPay,
		calculateFSBonusHandler,
		innerPlatformFlow,
		paymentType,
		openVideoHandler,
		doubleDeposBonus
	} = props;
	const {
		invoiceValue,
		paymentMethodType,
		cryptoInvoiceDetails,
		invoiceActiveCryptoCurrency
	} = useSelector(paymentSelectors.paymentInfo);
	const {
		promocode: {
			inputValue: promocodeValue,
			isForbiddenForUsing: isPromocodeForbiddenForUsing
		}
	} = useSelector(bonusSelectors.bonusInfo);

	const [disabledBtn, setDisabledBtn] = useState(false);

	useEffect(() => {
		if (
			+invoiceValue < currentPaymentMethod?.settings?.minInvoice / 100 ||
			+invoiceValue > currentPaymentMethod?.settings?.maxInvoice / 100
		) {
			setDisabledBtn(true);
		} else {
			setDisabledBtn(false);
		}
	}, [invoiceValue]);

	return (
		<InvoiceResolver
			calculateFSBonusHandler={calculateFSBonusHandler}
			setInnerPlatformFlow={setInnerPlatformFlow}
			currentPaymentMethod={currentPaymentMethod}
			paymentMethodType={paymentMethodType}
			invoiceValue={invoiceValue}
			localizeText={localizeText}
			promocodeValue={promocodeValue}
			isNotSuitableAmount={disabledBtn}
			cryptoInvoiceDetails={cryptoInvoiceDetails}
			invoiceActiveCryptoCurrency={invoiceActiveCryptoCurrency}
			isPromocodeForbiddenForUsing={isPromocodeForbiddenForUsing}
			setDataForPay={setDataForPay}
			innerPlatformFlow={innerPlatformFlow}
			paymentType={paymentType}
			openVideoHandler={openVideoHandler}
			doubleDeposBonus={doubleDeposBonus}
		/>
	);
};

import React, { useMemo } from 'react';
import { isTapX } from '@config/global';
import { TapXLogo, TopXHeaderLogo } from '@components/svg';
import s from './style.module.scss';
import cn from 'classnames';

type PreloaderP = {
	isActive?: boolean;
};

export const PagePreloader = (props: PreloaderP) =>
	useMemo(() => {
		const { isActive } = props;

		return (
			<>
				<div className={cn(s.wrapper, { [s.wrapperActive]: isActive })}>
					{!isTapX ? <TopXHeaderLogo /> : <TapXLogo />}

					<div className={s.ringSpiner}>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</>
		);
	}, [props.isActive]);

import React from 'react';
import { DescriptionBlock, TimerBlock } from './parts';
import { Ripple } from '@components/common/elements';
import { CloseSvg } from '@components/svg';
import { pathsConfig } from '@config/paths';
import { useLocales, useUrlLang } from '@lib/hooks';
import { useNavigate } from 'react-router-dom';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { Footer } from '@components/common/page';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';
import s from './style.module.scss';

const BgamingPrizes = () => {
	const navigate = useNavigate();

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'BgamingPrizes'
	});

	return (
		<>
			<div className={s.container}>
				{isMobile ? (
					<div className={s.header}>
						{localizeText('promotion')}
						<button
							onClick={() => {
								navigate(langUrlPrefix + pathsConfig?.home?.tabs.casino.link);

								sendAnalyticsHandler(AnalyticsEventType?.close_back, {
									close_back: 'bgaming prizes'
								});
							}}
						>
							<CloseSvg />
							<Ripple />
						</button>
					</div>
				) : null}

				<TimerBlock />

				<div className={s.divider} />

				<DescriptionBlock />
			</div>
			<Footer />
		</>
	);
};

export default BgamingPrizes;

import React from 'react';
import { useLocales } from '@lib/hooks';
import { Button } from '@components/common/elements';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { useSelector } from 'react-redux';
import { userSelectors } from '@store/reducers/user/selectors';
import { renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { authSelectors } from '@store/reducers/auth/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import s from './style.module.scss';
import cn from 'classnames';
import { getImageByURL } from '@utils/getImageByURL';

export const CashbackRulesBlock = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'CashbackBonuses'
	});

	const { userLevels, userLevelId, currency } = useSelector(userSelectors.userInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isTablet } = useSelector(commonUISelectors.commonUIInfo);

	const currentUserLevel = userLevels?.find((lev) => lev?.id === userLevelId);

	const renderBtn = (
		<Button
			rippleAnimation
			className={s.contactBtn}
			type={ButtonType?.secondary}
			handle={() => {
				setModalType(ModalType?.cashbackRulesTable);
				sendAnalyticsHandler(AnalyticsEventType?.new_cashback_rules);
			}}
		>
			{localizeText('accrual_rules')}
		</Button>
	);

	return (
		<div className={s.container}>
			<div className={s.text_block}>
				<div className={s.imgBlock}>
					<img
						className={s.img}
						src={getImageByURL(
							'../../../../../../assets/img/cashback/cashback_rules.webp'
						)}
						alt='Cashback'
					/>
				</div>

				<div className={s.text_block_title}>
					{localizeText('cashback_rules_title', {
						curr: renderCurrencySymbol(currency?.toLowerCase())
					})}
				</div>
				<div className={s.text_block_descr}>{localizeText('cashback_rules_descr')}</div>

				{!isTablet && renderBtn}
			</div>

			<div className={s.counterWrapper}>
				{(currentUserLevel && currentUserLevel?.cashbackPercentage <= 10) || !isAuthed ? (
					<div
						className={cn(s.cashbackCard, {
							[s.active]:
								(currentUserLevel && currentUserLevel?.cashbackPercentage === 10) ||
								!isAuthed
						})}
					>
						<div className={s.cashbackCard_top}>
							<span>10%</span>
							<span>
								{localizeText('max_cashback_sum', {
									sum: '25,000',
									curr: renderCurrencySymbol(currency) || '৳'
								})}
							</span>
						</div>

						<div className={s.cashbackCard_bottom}>
							<span>
								{localizeText(
									(currentUserLevel && currentUserLevel?.cashbackPercentage === 10) ||
										!isAuthed
										? 'your_cashback'
										: 'for_level',
									{ level: '6' }
								)}
							</span>

							<img
								className={s.coinImg}
								alt='Coins'
								src={getImageByURL('../../../../../../assets/img/cashback/coins_1.webp')}
							/>
						</div>
					</div>
				) : null}
				{(currentUserLevel && currentUserLevel?.cashbackPercentage <= 15) || !isAuthed ? (
					<div
						className={cn(s.cashbackCard, {
							[s.active]: currentUserLevel && currentUserLevel?.cashbackPercentage === 15
						})}
					>
						<div className={s.cashbackCard_top}>
							<span>15%</span>
							<span>
								{localizeText('max_cashback_sum', {
									sum: '25,000',
									curr: renderCurrencySymbol(currency) || '৳'
								})}
							</span>
						</div>

						<div className={s.cashbackCard_bottom}>
							<span>
								{localizeText(
									currentUserLevel && currentUserLevel?.cashbackPercentage === 15
										? 'your_cashback'
										: 'for_level',
									{ level: '6' }
								)}
							</span>

							<img
								className={s.coinImg}
								alt='Coins'
								src={getImageByURL('../../../../../../assets/img/cashback/coins_2.webp')}
							/>
						</div>
					</div>
				) : null}
				{(currentUserLevel && currentUserLevel?.cashbackPercentage <= 20) || !isAuthed ? (
					<div
						className={cn(s.cashbackCard, {
							[s.active]: currentUserLevel && currentUserLevel?.cashbackPercentage === 20
						})}
					>
						<div className={s.cashbackCard_top}>
							<span>20%</span>
							<span>
								{localizeText('max_cashback_sum', {
									sum: '100,000',
									curr: renderCurrencySymbol(currency) || '৳'
								})}
							</span>
						</div>

						<div className={s.cashbackCard_bottom}>
							<span>
								{localizeText(
									currentUserLevel && currentUserLevel?.cashbackPercentage === 20
										? 'your_cashback'
										: 'for_level',
									{ level: '11' }
								)}
							</span>

							<img
								className={s.coinImg}
								alt='Coins'
								src={getImageByURL('../../../../../../assets/img/cashback/coins_3.webp')}
							/>
						</div>
					</div>
				) : null}
				{(currentUserLevel && currentUserLevel?.cashbackPercentage <= 30) || !isAuthed ? (
					<div
						className={cn(s.cashbackCard, {
							[s.active]: currentUserLevel && currentUserLevel?.cashbackPercentage === 30,
							[s.last]: currentUserLevel && currentUserLevel?.cashbackPercentage === 30
						})}
					>
						<div className={s.cashbackCard_top}>
							<span>30%</span>
							<span>
								{localizeText('max_cashback_sum', {
									sum: '500,000',
									curr: renderCurrencySymbol(currency) || '৳'
								})}
							</span>
						</div>
						<div className={s.cashbackCard_bottom}>
							<span>
								{localizeText(
									currentUserLevel && currentUserLevel?.cashbackPercentage === 30
										? 'your_cashback'
										: 'for_level',
									{ level: '16' }
								)}
							</span>

							<img
								className={s.coinImg}
								alt='Coins'
								src={getImageByURL('../../../../../../assets/img/cashback/coins_4.webp')}
							/>
						</div>
					</div>
				) : null}
			</div>

			{isTablet && renderBtn}
		</div>
	);
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelectors } from '@store/reducers/user/selectors';
import { renderCurrencySymbol } from '@utils';
import { GreenLight } from '@components/svg';
import { useLocales } from '@lib/hooks';
import s from './style.module.scss';
import cn from 'classnames';
import { getImageByURL } from '@utils/getImageByURL';

const arr = [
	{ id: 1, name: 'Arut Sagor', sum: '1,200', classname: '' },
	{ id: 2, name: 'Kiaan Hannan', sum: '7,000', classname: '' },
	{ id: 3, name: 'Neerav Raju', sum: '7,000', classname: '' },
	{ id: 4, name: 'Aayush Bappy', sum: '4,200', classname: '' },
	{ id: 5, name: 'Pranay Momin', sum: '6,000', classname: '' },
	{ id: 6, name: 'Vihaan Mahmud', sum: '12,000', classname: '' },
	{ id: 7, name: 'Aniruddh Sarkar', sum: '7,200', classname: '' },

	{ id: 8, name: 'Ranbir Ahmad', sum: '5,400', classname: '' },
	{ id: 9, name: 'Faiyaz Ahammed', sum: '2,600', classname: '' },
	{ id: 10, name: 'Chirag Sharmin', sum: '5,000', classname: '' },

	{ id: 11, name: 'Divit Dutta', sum: '2,600', classname: '' },
	{ id: 12, name: 'Dhruv Mridha', sum: '2,600', classname: '' },
	{ id: 13, name: 'Ojas Nayem', sum: '2,300', classname: '' },

	{ id: 14, name: 'Bhavin Kalam', sum: '3,000', classname: '' },
	{ id: 15, name: 'Priyansh Sarmin', sum: '8,500', classname: '' },
	{ id: 16, name: 'Reyansh Barua', sum: '3,200', classname: '' },
	{ id: 17, name: 'Mitul Al mamun', sum: '1,200', classname: '' },
	{ id: 18, name: 'Tushar Hosain', sum: '6,200', classname: '' },
	{ id: 19, name: 'Hiran Ghosh', sum: '1,200', classname: '' }
];

export const LiveRewards = () => {
	const [newsItems, setNewsItems] = useState([
		{ id: 1, name: 'Arut Sagor', sum: '1 200', classname: '' },
		{ id: 2, name: 'Kiaan Hannan', sum: '7 000', classname: '' },
		{ id: 3, name: 'Neerav Raju', sum: '7 000', classname: '' },
		{ id: 4, name: 'Aayush Bappy', sum: '4 200', classname: '' },
		{ id: 5, name: 'Pranay Momin', sum: '6 000', classname: '' },
		{ id: 6, name: 'Vihaan Mahmud', sum: '12 000', classname: '' },
		{ id: 7, name: 'Aniruddh Sarkar', sum: '7 200', classname: '' }
	]);

	const { currency } = useSelector(userSelectors.userInfo);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Referral'
	});

	const positionsRef = useRef<any>({});

	const addNewsItem = () => {
		const newItem = {
			...arr[newsItems?.length],
			classname: s.new
		};

		// First: Capture the initial positions
		capturePositions();

		// Update the list (Last)
		setNewsItems((prevItems) => [
			newItem,
			...(prevItems
				? prevItems.map((e) => {
						return { ...e, classname: '' };
					})
				: prevItems)
		]);
	};

	const capturePositions = () => {
		newsItems.forEach((item) => {
			const domNode = document.getElementById(`news-item-${item.id}`);

			if (domNode) {
				positionsRef.current[item.id] = domNode.getBoundingClientRect();
			}
		});
	};

	useEffect(() => {
		newsItems.forEach((item) => {
			const domNode = document.getElementById(`news-item-${item.id}`);

			if (domNode) {
				const first = positionsRef.current[item.id];
				const last = domNode.getBoundingClientRect();
				const invert = first?.left - last?.left;

				if (invert) {
					domNode.style.transform = `translateX(${invert}px)`;
					domNode.style.transition = 'transform 0s';
					requestAnimationFrame(() => {
						domNode.style.transform = '';
						domNode.style.transition = 'transform 0.25s ease';
					});
				}
			}
		});
	}, [newsItems]);

	useEffect(() => {
		let intervalId: any;

		if (newsItems?.length < 19) {
			intervalId = setInterval(addNewsItem, 8000);
		}

		return () => intervalId && clearInterval(intervalId);
	}, [newsItems]);

	return (
		<div className={s.warapper}>
			<div className={cn(s.divider, s.top)} />

			<div className={s.LiveRewards}>
				<GreenLight />
				{localizeText('live_rewards')}
			</div>
			<div className={s.list}>
				{newsItems.map((item) => (
					<div
						key={item.id}
						id={`news-item-${item.id}`}
						className={cn(s.item, item?.classname)}
					>
						<img
							alt='avatar'
							src={getImageByURL(
								`../../../../../../../assets/img/referral/ref_${item?.id}.webp`
							)}
						/>

						<div className={s.user}>
							<span>{item.name}</span>
							<span>
								+ {renderCurrencySymbol(currency) || '৳'} {item.sum}
							</span>
						</div>
					</div>
				))}
			</div>

			<div className={cn(s.divider, s.bottom)} />
		</div>
	);
};

import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Button, Ripple } from '@components/common/elements';
import { InfoWarn } from '@components/svg';
import { pathsConfig } from '@config/paths';
import { useNavigate } from 'react-router-dom';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { userSelectors } from '@store/reducers/user/selectors';
import { renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import s from './style.module.scss';
import cn from 'classnames';
import { getImageByURL } from '@utils/getImageByURL';

export const TelegramBonusCard = () => {
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { currency, telegramChannelBotLink } = useSelector(userSelectors.userInfo);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'AllBonus'
	});

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const btnClickHandler = () => {
		isAuthed
			? window.open(telegramChannelBotLink, '_blank')
			: setModalType(ModalType.auth);

		if (!isAuthed) {
			sendAnalyticsHandler(AnalyticsEventType?.all_bonuses_click_registration, {
				all_bonuses_block: 'Телеграм'
			});
		} else {
			sendAnalyticsHandler(AnalyticsEventType?.all_bonuses_click_tg);
		}
	};

	const clickInfoHandler = () => {
		navigate(langUrlPrefix + pathsConfig?.telegram_bonus?.link);
	};

	return (
		<div className={s.card}>
			<div
				onClick={btnClickHandler}
				className={s.card_top}
			>
				<img
					className={s.card_top_img}
					alt='Tg Bonus'
					src={getImageByURL(
						'../../../../../../../../assets/img/telegram-bonus/title.webp'
					)}
				/>

				<div className={s.card_top_descr}>
					<span className={s.card_top_descr_text}>
						{localizeText('bonus_card_text_dep_tg', {
							curr: renderCurrencySymbol(currency?.toLowerCase())
						})}
					</span>
					<span className={s.card_top_descr_small}>
						{localizeText('bonus_card_text_dep_tg_desc')}
					</span>
				</div>

				<img
					className={s.divider}
					alt='Divider'
					src={getImageByURL(
						'../../../../../../../../assets/img/bonus/bonus_card_devider.webp'
					)}
				/>
			</div>

			<div className={s.card_bottom}>
				<Button
					className={cn(s.card_bottom_button, s.color_tg)}
					type={ButtonType?.primary}
					text={
						isAuthed
							? localizeText('click_and_get', {
									curr: renderCurrencySymbol(currency?.toLowerCase())
								})
							: localizeText('register_btn')
					}
					handle={btnClickHandler}
				/>

				<button
					onClick={clickInfoHandler}
					className={s.infoBtn}
				>
					<InfoWarn />
					<Ripple />
				</button>
			</div>
		</div>
	);
};

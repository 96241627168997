import React from 'react';
import { Button } from '@components/common/elements';
import { useLocales } from '@lib/hooks';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { logout } from '@lib/api/auth';
import { AnalyticsEventType, ButtonType } from '@types';
import { sendAnalyticsHandler } from '@utils';
import { CloseSvg } from '@components/svg';
import s from './style.module.scss';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import Div100vh from 'react-div-100vh';
import { useNavigate } from 'react-router-dom';

export const LogoutModal = () => {
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const navigate = useNavigate();

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const continuePlay = () => {
		sendAnalyticsHandler(AnalyticsEventType?.modal_logout_click_stay);
		setModalType(null);
	};

	const exitHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.modal_logout_click_close);
		setModalType(null);
	};

	const logoutHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.modal_logout_click_logout);
		setModalType(null);
		logout(navigate);
	};

	const Container = isMobile
		? Div100vh
		: (
				props: JSX.IntrinsicAttributes &
					React.ClassAttributes<HTMLDivElement> &
					React.HTMLAttributes<HTMLDivElement>
			) => <div {...props} />;

	return (
		<Container
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) exitHandler();
			}}
			className={s.wrapper}
		>
			<div className={s.inner}>
				<div className={s.body}>
					<div
						onClick={exitHandler}
						className={s.headerClose}
					>
						<CloseSvg />
					</div>
					<div className={s.titleBlock}>
						<div className={s.title}>{localizeText('logout_modal_title')}</div>
						<div className={s.descr}>{localizeText('logout_modal_descr')}</div>
					</div>

					<div className={s.btnsBlock}>
						<Button
							className={s.btn}
							text={localizeText('logout_modal_btn')}
							rippleAnimation
							handle={logoutHandler}
						/>

						<Button
							className={s.btn}
							text={localizeText('logout_modal_continue_play')}
							type={ButtonType?.secondary}
							handle={continuePlay}
						/>
					</div>
				</div>
			</div>
		</Container>
	);
};

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import s from './style.module.scss';
import { APP_COMPANY, APP_EMAIL, APP_PROD_LINK, isTopXGo } from '@config/global';

const PoliticsTermsAndConditions = () => {
	return (
		<div>
			<p className={s.text}>
				<b>TERMS OF USE OF THE SERVICES</b>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>1. INTRODUCTION: KEY DEFINITIONS AND WHAT MAKES UP YOUR TERMS OF USE</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					The following definitions are used in these terms and conditions:
				</p>
			</p>

			{isTopXGo && (
				<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
					<p className={s.text}>Topxgo N.V. is the Operator of the website</p>
				</p>
			)}

			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Access Device"</b> means any electronic means of accessing the Services,
					including, but not limited to, computers, smartphone devices, feature phones,
					tablet devices, touch devices or any home entertainment system such as video
					games consoles and smart TVs (or by any other remote means);
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Bonus Terms"</b> means any terms and conditions and/or rules with regard to
					promotions, bonuses and special offers which may apply to any part of the
					Services from time to time;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Supervision institution"</b> means the supervision institution of commercial
					gambling in Curacao;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"General Terms"</b> means the terms and conditions set out in this document;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Group"</b>
					includes (EU company) , registration no. (000000) , address (address) and
					<b>{APP_COMPANY}</b>
					<b> N.V.</b>, registration No., registered address is at Hanchi Snoa 19 Trias
					Building, Curacao. The division of competence of group companies is detailed in
					this Terms and Conditions;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Operator"</b>
					means Group of companies -<b>{APP_COMPANY} </b>
					<b>N.V.</b>, which is registered at Dr. H. Fergusonweg 1, Curaçao, with company
					number 147039, and having gaming license 8048/JAZ2018-040 , being owned and
					fully controlled by the License Holder and acting under license agreement
					concluded with <b>{APP_COMPANY}</b>
					<b> N.V. </b>
					The company (EU company) was founded in (country) , and laws of (country) apply
					to its activity;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Payment Processor"</b>
					means (EU company) , a company registered in (address) with registered company
					number <b>(000000)</b>;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Privacy Policy"</b>
					means the Operator’s privacy policy accessed via the Privacy Policy link, which
					is an unseparable part of these Terms and Conditions;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Rules"</b>
					means the Betting Rules and the Game Rules specifically applicable to the
					relevant type of betting and/or gaming, as identified in more detail in
					paragraph 1.3;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Refund"</b>
					means a reversal of a means as per request of a Player deposited in the Player
					Account not used for the Services.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Services"</b>
					means, as appropriate, the services offered for the time being by the Operator
					through the Website and/or via any Access Device application;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Terms of Use"</b>
					means (a) the General Terms; (b) the Privacy Policy; (c) where appropriate under
					paragraph 1.3, the relevant Rules, Bonus Terms and Additional Terms applicable
					to the Services that are being used by You; and
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>"Website"</b>
					means the website or any respective page, subpage, subdomain or section thereof
					from time to time, located at or accessible via the domain name:
					<b>
						<a
							href='https://top-x-go.com/'
							target='_blank'
							rel='noreferrer'
						>
							https://top-x-go.com/
						</a>
					</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					1.2 By using and/or visiting any section of the Website, or by opening an
					account with the Operator through the Website, You agree to be bound by the
					Terms of Use and You accordingly: (a) agree to the use of electronic
					communications in order to enter into contracts; and (b) waive any applicable
					rights or requirements which require a signature by hand, to the extent
					permitted by any applicable law; (c) agree, that in order to use our service you
					are requested to provide us with certain personal information which shall be
					processed on the basis of our Privacy Policy. &nbsp;The Terms of Use do not
					affect your statutory rights.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					1.3 In addition, where You play any game, or place a bet using the Services, or
					otherwise use the Services, You agree to be bound by Rules of any game You play
					("Game Rules"), as set out under the relevant general Help section and any Rules
					tabs, in respect of any new games, the rules applicable to such game; any Bonus
					Terms; any terms and conditions relating to withdrawals and any other terms
					applicable to the Services and/or which You are required to confirm Your
					agreement to as part of the Services.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					1.4 The original text of the Terms of Use are in English and any interpretation
					of them will be based on the original English text. If the Terms of Use or any
					documents or notices related to them are translated into any other language, the
					original English version will prevail.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					1.5 Please read the Terms of Use carefully before accepting them. Once You have
					accepted the Terms of Use, please print the Terms of Use and store them, along
					with all confirmation emails, additional terms, transaction data, game rules,
					fair deal rules and payment methods relevant to Your use of the Website. Please
					note that the Terms of Use are subject to change, as set out in paragraph 3
					below.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					1.6 If You do not agree to accept and be bound by the Terms of Use please do not
					open an account, and/or continue to use Your Account. Your continued use of any
					of the Services will constitute acceptance of the Terms of Use which we have
					notified You are in force from time to time.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					1.7 For the avoidance of doubt, each and all sections of the Website are
					governed by the Terms of Use, and You should ensure at all times that Your use
					of the Services is in accordance with the Terms of Use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>GENERAL TERMS</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>2. CONTRACTING PARTIES</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					2.1 The Terms of Use shall be agreed between You and the Operator and the
					Payment processor.
					<br />
					All information on the Website is provided by the provider of services on the
					Website, a company {APP_COMPANY} N.V. (hereinafter&nbsp;
					<a
						href={APP_PROD_LINK}
						target='_blank'
						rel='noreferrer'
					>
						<b>{APP_PROD_LINK}</b>
					</a>
					), is a company operating&nbsp;
					<a
						href={APP_PROD_LINK}
						target='_blank'
						rel='noreferrer'
					>
						<b>{APP_PROD_LINK}.</b>
					</a>
					Services are provided to card holder by (EU company) located at (address) . In
					the event of any inquiries and complaints, please direct them to (EU company).
					<b> {APP_COMPANY} </b>
					<b>N.V. </b>
					is incorporated under the laws of Curacao, Netherlands Antilles and operators
					its regulated activities in terms of License No. №00000000000000.
					<b>{APP_COMPANY}</b>
					<b> N.V. </b>
					and (EU company) , a limited partnership incorporated under the laws of
					Scotland, UK entered into a partnership agreement where the Parties agreed that
					(EU company would act as an agent of
					<b>{APP_COMPANY}</b>
					<b> N.V. </b>
					to promote products and services offered by
					<b>{APP_COMPANY}</b>
					<b> N.V. </b>
					(EU company) is fully liable for any acts of their employees, agents or
					affiliated entities. (EU company) as Payment processor and
					<b>{APP_COMPANY} </b>
					<b>N.V. </b>
					as Operator in these Terms and Conditions is referred to as
					<b>{APP_COMPANY}</b>,&nbsp;
					<a
						href={APP_PROD_LINK}
						target='_blank'
						rel='noreferrer'
					>
						<b>{APP_PROD_LINK}</b>
					</a>
					, "We", "Us", "Our", "Management", "Site" or "Company" that you enter contract
					with. The Player and registered Account Holder shall be referred to as "You",
					"Yours", "Customer" or "The Player".
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					2.2 in the case of terms and conditions relating to monies held in Your Account
					from time to time, to any Operator Group company which holds such money and
					shall (where appropriate) be deemed to include our agents, partners, and
					suppliers.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>3. CHANGES TO THE TERMS OF USE</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					3.1 We may need to change the Terms of Use from time to time for a number of
					reasons, including (without limitation) for commercial reasons, to comply with
					law or regulations, to comply with instructions, guidance or recommendations
					from a regulatory body, or for customer service reasons. The most up-to-date
					Terms of Use can be accessed from the Terms and Conditions &nbsp;link in the
					footer section of the Website.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					3.2 Where we wish to make substantial changes to the Terms of Use, we will give
					You as much prior notice of such changes as is reasonably practicable via one of
					the methods set out in paragraph 3.3. For minor or insubstantial changes, we may
					not give You any notice of such changes, so You are advised to review the Terms
					of Use through the Terms and Conditions &nbsp;link on the Website on a regular
					basis.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					3.3 Where we make changes to the Terms of Use which we wish to notify You of, we
					will do so by such method of notification as we may, in our discretion, deem
					appropriate.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>4. OPENING YOUR ACCOUNT</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.1 In order to place a bet or play a game using the Services, You will need to
					open an account with the Operator ("<b> Your Account </b>" or "<b> Account </b>
					").
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.2 In order to open Your Account for use with the Services, You can:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.2.1 click on&nbsp; Join Now on the Website and follow the on-screen
					instructions;or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.2.2 open by such other Account opening method as shall, from time to time be
					offered by the Operator;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.3 When You open Your Account You will be asked to provide us with personal
					information, including Your name and date of birth and appropriate contact
					details, including an address, telephone number and e-mail address ("
					<b> Your Contact Details </b>
					"). You may update Your Contact Details from time to time by contacting Customer
					Services; or through the My Account management page on the Website: or by such
					other method as shall, from time to time, be offered by the Operator.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>4.4 In opening Your Account You warrant that:</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.4.1 You understand and accept the risk that, by using the Services, You may,
					as well as winning money, lose money;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.4.2 You are: (a) over 18 years of age; and (b) above the age at which gambling
					or gaming activities are legal under the law or jurisdiction that applies to You
					(the "<b> Relevant Age </b>);
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.4.3 gambling is not illegal in the territory where You reside;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>4.4.4 You are legally able to enter into contracts;</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>4.4.5 You have not been excluded from gambling; and</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.4.6 You have not already had an Account closed by us for any reason.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.5 Your Account must be registered in Your own, correct, name and personal
					details and it shall only be issued once for You and not duplicated through any
					other person, family, household, address (postal or IP), email address, Access
					Device or any environment where Access Devices are shared (e.g. schools,
					workplaces, public libraries etc) and/or account in respect of the Services. Any
					other accounts which You open with us, or which are beneficially owned by You in
					relation to the Services shall be "<b>Duplicate Accounts</b>
					". We may close any Duplicate Account (but shall not be obliged to do so). If we
					close a Duplicate Account:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.5.1 all bonuses, free bets and winnings accrued from such bonuses and free
					bets obtained using that Duplicate Account will be void and forfeited by You;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.5.2 we may, at our entire discretion, void all winnings and refund all
					deposits (less amounts in respect of void winnings) made in respect of that
					Duplicate Account and, to the extent not recovered by us from the relevant
					Duplicate Account, any amounts to be refunded to us by You in respect of a
					Duplicate Account may be recovered by us directly from any other of Your
					Accounts (including any other Duplicate Account); or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					4.5.3 we may, at our entire discretion, allow usage of the Duplicate Account to
					be deemed valid in which case all losses and stakes placed by or for You through
					the Duplicate Account shall be retained by us.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>5. VERIFICATION OF YOUR IDENTITY; ANTI-MONEY LAUNDERING REQUIREMENTS</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>5.1 You warrant that:</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					5.1.1 the name and address You supply when opening Your Account are correct; and
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					5.1.2 You are the rightful owner of the money which You at any time deposit in
					Your Account.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					5.2 By agreeing to the Terms of Use You authorise us to undertake any such
					verification checks from time to time as we may require ourselves or may be
					required by third parties (including, but not limited to, regulatory bodies) to
					confirm these facts (the "<b>Checks</b>
					"). You agree that from time to time, upon our request (including requests from
					Payment processor), You may be required to provide additional details in respect
					of any of such information You have provided us, including in relation to any
					deposits which You have made into Your Account.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					5.3 Whilst we are undertaking any Checks from time to time, we may restrict You
					from withdrawing funds from Your Account and/or prevent access to all or certain
					parts of the Website. Please note that we may from time to time re-perform the
					Checks for regulatory, security or other business reasons. If any such
					restrictions cause You a problem, please contact us -&nbsp;
					<a
						href={`mailto:${APP_EMAIL}`}
						target='_top'
					>
						<b>{APP_EMAIL} </b>
					</a>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					5.4 In certain circumstances we may have to contact You and ask You to provide
					further information to us directly in order to complete the Checks. For this
					purpose, we will be entitled, at our sole discretion, to require that You
					provide us with a notarised ID or any equivalent certified ID according to the
					applicable law of Your jurisdiction or otherwise, proof of address, utility
					bills, bank details, bank statements and bank references and any documentation
					that validates your source of funds. Until such information has been supplied to
					our satisfaction we may prevent any activity to be undertaken by You in relation
					to the Account or we may, where we reasonably believe that deliberately
					incorrect information has been provided by You, keep any amount deposited on the
					Account following the closure of the Account by us.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					5.5 It may be an offence for persons under the Relevant Age to make use of the
					Website. If we are unable to confirm that You are the Relevant Age then we may
					suspend Your Account until such time that we are able to confirm that You are
					the Relevant Age. If You are subsequently proven to have been under the Relevant
					Age at the time You made any gambling or gaming transactions with us, then:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>5.5.1 Your Account will be closed;</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					5.5.2 all transactions made whilst You were underage will be made void, and all
					related funds deposited by You will be returned by the payment method used for
					the deposit of such funds, wherever practicable;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					5.5.3 any deposits made whilst You were under the Relevant Age will be returned
					to You; and
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					5.5.4 any winnings which You have accrued during such time when You were under
					the Relevant Age will be forfeited by You (and shall be deducted from the amount
					of any deposit returned under paragraph 5.5.3) and You will return to us on
					demand any such funds which have been withdrawn from Your Account.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>6. KNOW YOUR CLIENT POLICY</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					To make the Age Verification process as easy as possible, here are a few hints
					for when you send in your documents:
				</p>
			</p>
			<ul>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>Each document must be sent as a separate image</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
						<p className={s.text}>
							Photo Captured images are preferred, but if you’d rather scan your
							documents, send them as a .jpeg
						</p>
					</p>
				</li>
			</ul>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>When you take your picture:</p>
			</p>
			<ul>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Be sure that the first 6 and the last 4 digits of your credit card number
							should be visible, and CVV should be hidden
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Your picture must be in focus and all the text easy to read
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Your full passport photo page or ID must be in shot, don’t leave any bits
							out or cover with your hand or fingers
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
						<p className={s.text}>
							Your lighting must be good, to stop any glare don’t use flash
						</p>
					</p>
				</li>
			</ul>

			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					We need all of this because it’s part and parcel of gambling responsibly - and
					the applicable law requires it.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>6.1. Why do I need to verify my account?</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					When you sign up for a new account we check that you are over 18 and we verify
					that you are who you say you are, (a process called Know Your Customer ‘KYC’).
					It’s part and parcel of gambling responsibly, and something that the law
					requires us to do.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>6.2. What do I need to provide?</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					To verify your age we will accept one of the following documents:
				</p>
			</p>
			<ul>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>Passport (recommended)</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>Driving license</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>National ID Card</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Birth Certificate (verification takes up to 24 hours)
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
						<p className={s.text}>
							Payment card (note that first 6 and last 4 digits should be visible and CVV
							should be hidden).
						</p>
					</p>
				</li>
			</ul>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					Card data shall not be collected and stored by Us without PCI DSS.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					To verify your identity (KYC) we&nbsp;may need&nbsp;additional documents which
					could also include:
				</p>
			</p>
			<ul>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>Signed Credit Agreement</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>Utility Bill (less than 6 months old)</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
						<p className={s.text}>Bank Statement (less than 6 months old)</p>
					</p>
				</li>
			</ul>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>Make sure your documents are in acceptable condition.</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>6.3. How can I send you my documents?</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					The quickest and easiest way to verify your account is online.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>You can also send the documents via email.</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>Here are a few tips on how to get it right first time:</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>Sending your documents via email</b>
				</p>
			</p>
			<ul>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Take a photo of each of your documents and save the photos (or, scan them
							and save as a .jpeg)
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Open the email account that you used to register for our Website
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Open a new email, addressed to
							<b> {APP_EMAIL} </b>
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>Attach the photos</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Enter your account number or username in the ‘Subject’ bar at the top of
							your email
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
						<p className={s.text}>Press "Send"</p>
					</p>
				</li>
			</ul>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					When you register for an account, on the account registration page, we’ll need
					you to provide&nbsp;certain information&nbsp;for security reasons:
				</p>
			</p>
			<ul>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Your name and contact details, including a valid email address which needs
							to be unique to your account
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Your date of birth - you must be at least 18 years old
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							A unique username (your username may contain only letters, numbers,
							underscore and hyphen symbols) and a password which means only you can
							access your account
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>Your betting currency and a deposit limit</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
						<p className={s.text}>A security question and the answer</p>
					</p>
				</li>
			</ul>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					Our registration page checks your details as you enter them and will let you
					know if there’s a problem, so you can make any changes you need to before you
					click on the
					<b> "Create My Account" </b>
					button.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					For legal reasons when travelling abroad you may not be able to access your
					account.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>
						We are unable to accept accounts or offer any of our products or services to
						clients resident in the following countries (include link to list of
						countries):
					</b>
				</p>
			</p>
			<table
				cellPadding={5}
				cellSpacing={0}
				style={{ marginTop: '0.07in' }}
			>
				<colgroup>
					<col width={180} />
					<col width={430} />
				</colgroup>
				<tbody>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Game provider</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Restricted territories</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={951}
							style={{ border: 'none', padding: '0in' }}
						>
							<p style={{ marginTop: '0.07in', marginBottom: '0.07in' }}>
								<p className={s.text}>
									<p className={s.text}>
										<p>NETENT games</p>
									</p>
								</p>
							</p>
							<p style={{ marginTop: '0.07in' }}>
								<p className={s.text}>&nbsp;</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p>
										<p>
											<p className={s.text}>
												<p>
													<b>1. Absolute Restriction</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													<b>
														United States of America, United Kingdom, Spain, France and
														Italy.
													</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													<b>2. Blacklisted Territories</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													<b>
														NetEnt content shall not be served in the following
														territories:
													</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													Afghanistan, Albania, Algeria, Angola, Australia, Bahamas,
													Botswana, Cambodia, Ecuador, Ethiopia, Ghana, Guyana, Hong Kong,
													Iran, Iraq, Israel, Kuwait, Laos, Myanmar, Namibia, Nicaragua,
													North Korea, Pakistan, Panama, Papua New Guinea, Philippines,
													Singapore, Sri Lanka, Sudan, Syria, Taiwan, Trinidad and Tobago,
													Tunisia, Uganda, Yemen, Zimbabwe.
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													<b>
														3. Regulated Territories (only permitted to offer if receipt
														of a license from the local regulator which has been seen and
														approved by NetEnt):
													</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													Belgium, Bulgaria, Czech Republic, Denmark, Estonia, France,
													Italy, Latvia, Lithuania, Mexico, Portugal, Romania, Serbia,
													Spain, Sweden, Switzerland, United Kingdom, United States of
													America.
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													<b>4. Branded Game Territories</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													<b>
														4.1 Jumanji, emojiplanet, Guns &amp; Roses, Jimi Hendrix &amp;
														Motörhead cannot be offered in the following territories:
													</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													Afghanistan, Albania, Algeria, Angola, Australia, Bahamas,
													Botswana, Cambodia, China, Ecuador, Ethiopia, Ghana, Guyana,
													Hong Kong, Iran, Iraq, Israel, Kuwait, Laos, Myanmar, Namibia,
													Nicaragua, North Korea, Pakistan, Panama, Papua New Guinea,
													Philippines, Singapore, Sri Lanka, Sudan, Syria, Taiwan,
													Trinidad and Tobago, Tunisia, Uganda, Yemen, Zimbabwe. Belgium,
													Bulgaria, Czech Republic, Denmark, Estonia, France, Italy,
													Latvia, Lithuania Mexico, Portugal, Romania, Serbia, Spain,
													Sweden, Switzerland, United Kingdom, United States of America.
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													<b>
														4.2 In addition to the jurisdictions set out in paragraph 4.1,
														Planet of the Apes Video Slot must not be offered in the
														following territories:
													</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													Azerbaijan, China, India, Malaysia, Qatar, Russia, Thailand,
													Turkey, Ukraine.
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													<b>
														4.3 Vikings Video Slot must not be offered in the following
														jurisdictions:
													</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													Afghanistan, Albania, Algeria, Angola, Australia, Azerbaijan,
													Cambodia, China, Ecuador, France, Guyana, Hong Kong, India,
													Indonesia, Iran, Iraq, Israel, Kuwait, Laos, Malaysia, Myanmar,
													Namibia, North Korea, Pakistan, Papua New Guinea, Philippines,
													Qatar, Russia, Singapore, South Korea, Sudan, Syria, Taiwan,
													Thailand, Tunisia, Turkey, Ukraine, United States of America,
													Uganda.
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													<b>
														4.4 In addition to the jurisdictions set out in paragraph 4.1,
														Narcos Video Slot must not be offered in the following
														territories:
													</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>China, Indonesia, South Korea.</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													<b>
														5. Universal Monsters (Frankenstein, the Bride of
														Frankenstein, Dracula, The Mummy, The Wolf Man, Creature from
														the Black Lagoon and The Invisible Man), can only be played in
														the following territories:
													</b>
												</p>
											</p>
											<p>
												<p>
													<br />{' '}
												</p>
											</p>
											<p className={s.text}>
												<p>
													Andorra, Armenia, Azerbaijan, Belarus, Bosnia and Herzegovina,
													Brazil, Georgia, Iceland, Liechtenstein, Moldova, Monaco,
													Montenegro, Norway, Russia, San Marino, Serbia, Switzerland,
													Ukraine, Croatia, Macedonia, Turkey, Austria, Bulgaria, Cyprus,
													Czech Republic, Finland, France, Germany, Greece, Hungary,
													Ireland, Latvia, Lithuania, Luxembourg, Malta, Netherlands,
													Peru, Poland, Slovakia, Slovenia, and Sweden.
												</p>
											</p>
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={69}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>1 x 2 Network</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Afghanistan, Algeria, Cuba,
											Egypt, France, Haiti, Iran, Iraq, Israel, Laos, Libya, Netherlands,
											North Korea, Pakistan, Palestine, Panama, Philippines, Rwanda,
											Somalia, Syria, USA
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={109}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Amatic</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Afghanistan, Antigua, Australia,
											Austria, Belgium, Bosnia, Cambodia, China, Croatia, Cuba, Cyprus,
											Estonia, France, Georgia, Germany, Greece, Hong Kong, Hungary, Iran,
											Iraq, Israel, Liechtenstein, Libya, Macau, Mexico, Netherland
											Antilles, Peru, Serbia, Slovakia, South Africa, Sudan, Switzerland,
											Syria, US, Vietnam
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Apollo Games</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Armenia, Georgia, Czech Republic
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Asia Gaming</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Hongkong, Macao, Philippines,
											Singapore, Taiwan, USA
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={49}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Authentic Gaming</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Afghanistan, China, Curacao,
											Cyprus, Ethiopia, France, Hong Kong, Iran, Iraq, Israel, Kuwait,
											Somalia, Syria, USA, Yemen
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Bet Construct</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from USA</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={129}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>BetFair</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Australia, Bulgaria, Canada,
											Cyprus, the Czech Republic, Democratic People’s Republic of Korea,
											Denmark, France (and French territories), Gibraltar, Greece, India,
											Pakistan, Sri Lanka, Bangladesh, Iran, Ireland, Italy, Malta, New
											Zealand, Portugal, Romania, Spain, Turkey, the United Kingdom, the
											United States of America (and US territories)
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={49}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Booming Games</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Australia, Belgium, Cayman
											Islands, France, Iran, Iraq, Israel, Italy, North Korea, Saudi
											Arabia, Singapore, Turkey, UK, USA, Vatican, Vietnam
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>EGT</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from USA, Guam, Puerto Rico, Georgia,
											UK, Mariana Islands, American Samoa
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={89}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Eyecon</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Cyprus, Hong Kong, Israel,
											Macau, Philippines, The Republic of Turkey, Singapore, USA and its
											territories, Latvia (Live Only), Ukraine, Denmark, Bulgaria, Czech
											Republic, Estonia, France, Italy, Portugal, Romania, Spain, Great
											Britain
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={629}
							style={{ border: 'none', padding: '0in' }}
						>
							<p style={{ marginTop: '0.07in', marginBottom: '0.07in' }}>
								<p className={s.text}>
									<p className={s.text}>
										<p>Edict</p>
									</p>
								</p>
							</p>
							<p style={{ marginTop: '0.07in' }}>
								<p className={s.text}>&nbsp;</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Afghanistan, Algeria, American
											Samoa, Angola, Argentina, Armenia, Aruba, Australia, Azerbaijan,
											Bahamas, Bahrain, Bangladesh, Barbados, Belarus, Belgium, Bolivia,
											Bosnia and Herzegovina, Brunei, Burundi, Cambodia, Canada, Cayman
											Islands, China (People’s Republic of), Christmas Island, Cocos
											Island, Colombia, Congo (Republic of the), Cook Islands, Cote
											D’Ivoire (Ivory Coast), Cuba, Czech Republic, Denmark, Dominica,
											Ecuador, Egypt, Eritrea, Ethiopia, Fiji, Finland, France, French
											Guiana, French Polynesia, French Southern Territories, Germany,
											Ghana, Grenada, Guadeloupe, Guam, Guatemala, Guinea, Guyana, Haiti,
											Heard and McDonald Island, Holy See (Vatican City), Hong Kong,
											Hungary, Indonesia, Iran, Iraq, Israel, Italy, Japan, Jordan, Kenya,
											Korea (North), Korea (South), Kuwait, Kyrgyzstan, Lao People’s
											Democratic Republic, Lebanon, Liberia, Libya, Macao, Malawi,
											Malaysia, Marshall Islands, Martinique, Mayotte, Micronesia,
											Mongolia, Mozambique, Myanmar / Burma, Namibia, Nauru, Netherlands,
											New Caledonia, Nicaragua, Niger, Nigeria, Niue, Norfolk Island,
											Northern Mariana Islands,&nbsp; Norway, Pakistan, Palestinian
											Territories, Palestinian Territory, Occupied, Panama, Papua New
											Guinea, Paraguay, Philippines, Poland, Portugal, Puerto Rico, Qatar,
											Republic of China (ROC) / Taiwan, Reunion, Russia, Rwanda, Saint
											Barthelemy, Saint Kitts &amp; Nevis, Saint Martin, Saint Pierre and
											Miquelon, Sao Tome and Principe, Saudi Arabia, Sierra Leone,
											Singapore, Slovakia, Somalia, Spain, Sri Lanka, St Pierre &amp;
											Miquelon, St Vincent &amp; Grenadine, Sudan, Suriname, Switzerland,
											Syrian Arab Republic, Tajikistan, Tanzania, Thailand, Timor-Leste,
											Trinidad and Tobago, Tunisia, Turkey, Turkmenistan, Uganda, United
											Kingdom, United States of America, United States Minor Outlying
											Islands, US Minor Islands, Uzbekistan, Venezuela, Vietnam, Virgin
											Islands (US), Wallis Futuna, Yemen Republic of, Zaire, Honduras
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Endorphina</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from France</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Evolution Gaming</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from Latvia, USA</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Extreme Live</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Israel, France, USA, Australia
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Ezugi</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from Israel, Netherlands, USA</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>GameArt</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Belgium, Bulgaria, Cyprus,
											Estonia, France, Hong Kong, Israel, Italy, Spain, Turkey, USA
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Ganapati</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from Japan, UK, USA</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Gamevy</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from USA, Australia</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={49}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Genii</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Australia, Bulgaria, Denmark,
											Estonia, Spain, United Kingdom, Italy, Poland, Portugal, Romania,
											South Africa, USA
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Habanero</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Cyprus, Philippines, Singapore,
											South Africa, UK, USA, France, Bulgaria
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={89}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>iSoftBet</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Afghanistan, Antigua and
											Barbuda, Belgium, China, Cuba, Cyprus, Denmark, France, Hong Kong,
											Iran, Iraq, Israel, Kahnawake, Libya, Macau, Malaysia, Netherlands
											Antilles, Philippines, Romania, Serbia, Singapore, Spain, Sudan,
											Syria, Turkey, USA
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Kiron Interactive</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Australia, China, India,
											Singapore, South Africa
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Livegames</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from USA, France, South Korea</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>MediaLive</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from USA, France</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={49}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>NoLimitCity</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from USA, Iran, North Korea, Cuba,
											Sudan and Syria; and The Netherlands and The Dutch Antilles or any
											country in which gambling is expressly illegal.
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={69}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>PariPlay</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Belgium, Cayman, Denmark,
											France, Iran, Iraq, Israel, Italy, Japan, Netherlands, Korea,
											Poland, Saudi Arabia, Singapore, Spain, Turkey, UK, USA, Vatican
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={129}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Pinnacle</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from USA and its territories; the
											French Republic and its territories; the United Kingdom; the
											Netherlands (including Curacao and other countries and territories
											that form part of the Kingdom of Netherlands); Spain; Germany;
											Singapore; the Democratic People’s Republic of Korea; Denmark;
											Philippines; Syria; Turkey; Poland; Ireland; Slovenia; and the Czech
											Republic;
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Platipus</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from USA, Iran, Israel</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Playson</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Cuba, Iran, Israel, North Korea,
											Sudan, Syria, USA
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={169}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>PlayNGo</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p>
										<p>
											<p className={s.text}>
												<p>
													No Customers shall be accepted from Afghanistan; Alderney;
													America Samoa; Angola; Australia; Belgium; Bosnia and
													Herzegovina; Cambodia; Croatia; Czech Republic; Democratic
													People´s Republic of Korea (DPRK); Denmark (Prohibited Games In
													Denmark: - Hugo); Dutch Carribean - including Curacao and Aruba;
													Ethiopia; Hungary; Iraq; Islamic Republic of Iran; Italy; Lao´s
													People’s Democratic Republic; Latvia; Lithuania; Romania;
													Singapore; Syrian Arab Republic; Uganda; United Kingdom; USA;
													Vanatu; Yemen.
												</p>
											</p>
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Pragmatic Play</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Bahamas, Denmark, France,
											Gibraltar, Great Britain, Israel, Taiwan, Ukraine, USA
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={49}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Red Rake Gaming</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Croatia, Denmark, France, Italy,
											Portugal, Romania, Spain, UK and Asia (except Turkey, Russia and
											Armenia)
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Oriental Game</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from Philippines, USA</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={69}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Quickspin</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Australia, Belgium, Bulgaria,
											Cyprus, Czech Republic, Denmark, Estonia, France, Hong Kong, Israel,
											Italy, Macau, Philippines, Portugal, Romania, Singapore, Spain,
											Turkey, UK, Ukraine, USA
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>SA Gaming</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from Italy, South Korea</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Spinomenal</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from USA, Israel and Australia.</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={89}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Spinola Gaming</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from American Samoa, Cuba, French
											Guiana, Iraq, Iran, Korea, Libyan Arab Jamahiriya, Myanmar, Macau,
											Northern Mariana Islands, Nigeria, French Polynesia, Sudan, French
											Southern Territories, USA, Virgin Islands, Zimbabwe
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Superlotto</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from Latvia</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>TomHorn</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Israel, Italy, Switzerland, USA
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={29}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Vivo Gaming</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>
											No Customers shall be accepted from Argentina, Colombia, Costa Rica,
											Israel, S. Korea, USA
										</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
					<tr>
						<td
							width={180}
							height={9}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>Wazdan</p>
									</p>
								</p>
							</p>
						</td>
						<td
							width={430}
							style={{ border: 'none', padding: '0in' }}
						>
							<p>
								<p className={s.text}>
									<p className={s.text}>
										<p>No Customers shall be accepted from USA</p>
									</p>
								</p>
							</p>
						</td>
					</tr>
				</tbody>
			</table>
			<p
				style={{
					lineHeight: '100%',
					orphans: 0,
					widows: 0,
					marginTop: '0.07in',
					marginBottom: '0.07in'
				}}
			>
				<br />
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					The list of game providers and territories, where from the Customers are not
					accepts may be amended time from time.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>7. USERNAME, PASSWORD, PIN and CUSTOMER INFORMATION</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					7.1 After opening Your Account, You must take all reasonable steps to avoid
					disclosing (whether deliberately or accidentally) Your username, password and/or
					account number to anyone else, including (where practicable) ensuring that
					up-to-date security software is downloaded onto Your Access Device.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					7.2 All transactions made where Your username and password and/or account number
					have been entered correctly will be regarded as valid, whether or not authorised
					by You, and we shall not be liable for any claims in the event that You disclose
					Your username, password or account number to anyone else (whether deliberately
					or accidentally).
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					7.3 If You have lost or forgotten Your Account details, or have a reason to
					believe that such details are known to an unauthorised third party, please
					contact us immediately for a replacement through
					<b>{APP_EMAIL} </b>, details of which can be found in the Contact Us.
				</p>
			</p>

			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<br />
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>8. DEPOSITS, WITHDRAWALS AND REFUNDS FROM YOUR ACCOUNT</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.1&nbsp; If You wish to participate in the Services, You must deposit monies
					into Your Account from an account or source of which You are the account holder.
					Such monies may (subject to paragraph 5) then be used by You to place bets or
					play games. If You use a payment method in respect of which You are not the
					account holder, we reserve the right to treat any deposit into the Account as
					being invalid (and any winnings arising from such deposit as void) pending the
					satisfactory completion of all relevant Checks.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginBottom: '0in' }}>
				<p className={s.text}>
					We accept payments in EUR, USD, BRL, as well as the following crypto-currencies
					- Bitcoin, Litecoin, Dogecoin, Dash, Ethereum, Monero, Zcash, Nem, Digibyte,
					Bitcoingold, Verge, Qtum, Stratis, Tron, Ripple, USDC, TUSD, Pax, USDT,
					Bitcoincash, Link, Omg, Bat, Ethereum Classic, Bitshares and BNB.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					Withdrawals (including - Refunds) shall be provided within 7 (seven) days time
					after completion of KYC and approval of Your Account. Please note, that Refunds
					are performed only if there was no game activity on Your account after deposit
					of respective amount of monies that are reclaimed by you as Refund. All other
					withdrawals from Your Account will be considered as pay-out of your winnings
					generated as a result of use of the Services.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginBottom: '0in' }}>
				<p className={s.text}>
					You can not request withdrawal in FIAT if you have deposited in Cryptocurrency
					and vice-versa.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginBottom: '0in' }}>
				<p className={s.text}>
					Please pay attention that value of cryptocurrencies may change dramatically.
					Rates of the cryptocurrencies are available on the Website and may change
					without any notice.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					In order to request a Refund or any other withdrawal, please contact our support
					by email to
					<b> {APP_EMAIL} </b>
					and provide all documents as requested by the support in order to complete Your
					KYC. Should You require Our contact details, please open section "Contact Us".
					List of the documents required to complete Your KYC procedure shall be denoted
					in the email sent to You by our support.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					Please note, that Refunds may be requested not later than 7 (seven) days after
					deposit of respective means to Your Account. After this term all withdrawals
					shall be considered as pay-out of your winnings generated a result of use of the
					Services.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.2 Withdrawals shall be provided within 7 (seven) days time after completion of
					KYC and approval of Your Account. For the avoidance of doubt Your Account shall
					not be used by You as a bank account and, should we become aware of deposits
					into and withdrawals (including charge-back) from Your Account without
					commensurate betting or gaming activity, we reserve the right to deduct an
					administration charge (whether or not we close or suspend the account). Monies
					deposited with us in Your Account shall not attract interest. The time period,
					when you can expect your withdrawal at your personal bank account, depends on
					operation of respective payment service provider or bank. For avoidance of doubt
					upon your request we can confirm pay-out of the monies requested by You, should
					that be necessary.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.3 To the extent required by Your local law or tax or other authorities You are
					responsible for reporting Your winnings and losses arising from the Services.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.4 You can set a deposit limit on Your Account in any one day. For details of
					how to set up a deposit limit please contact
					<b>{APP_EMAIL} </b>.
				</p>
			</p>
			<p className={s.text}>
				<p>Any confirmed reductions to your deposit limit will be of immediate effect.</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.5 Subject to terms of Closure of Your Account or any other terms set out in
					these Terms and Conditions or any amendments thereof, You may request withdrawal
					of funds from Your Account at any time provided that:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.5.1 all payments made into Your Account have been confirmed as cleared and
					none have been charged-back, reversed or otherwise cancelled;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.5.2 any Checks referred to Verification of Your Identity; Money Laundering
					Requirements have been completed by us to our satisfaction; and
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.5.3 You have complied with any other relevant withdrawal conditions affecting
					Your Account (e.g. any applicable Bonus Terms).
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.6 On any withdrawal approved by us, provided that You give us sufficient
					information as to how the funds should be transferred to You, we will return the
					relevant funds to You (less charges incurred or any other amount required to be
					deducted from Your withdrawal in order to comply with any applicable law).
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.7 We will attempt to accommodate Your request regarding the payment method and
					currency of payment of Your withdrawal. This, however, cannot be guaranteed.
					Therefore, we may process and pay withdrawals in a different payment method than
					the one requested by You, such as through different payment providers, a bank
					draft or wire transfer (any charges associated with relevant payment methods are
					set out in the Website). Similarly, in certain cases, the currency of Your
					withdrawal may not be the currency in which Your deposit was made or that was
					otherwise requested by You and, in circumstances where we are required to
					convert Your deposits between different currencies, the conversion rate used by
					us will set upon our discretion.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					8.8 Inactive Account Fee. If You do not use Your Account for betting or gaming,
					making a deposit, withdrawal or transfer, or if it is otherwise inactive, for a
					period of at least 13 consecutive months then it will be an "
					<b>Inactive Account</b>". All Inactive Accounts will incur a fee ("Inactive
					Account Fee"), the details of which can be found in the Help section of the
					Website. We will notify You when Your Account becomes an Inactive Account, and
					at least 14 days before any Inactive Account Fee is deducted from it.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>8.9 LIMITATIONS:</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>The min. size of deposit is 10 EUR.</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>The min. size of withdrawal is 10 EUR.</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					All payout requests are processed in the order of the queue within 72 hours if
					the requested amount does not exceed 1000 EUR or the equivalent in another
					currency and up to 7 days in case the requested amount exceeds 1000 EUR.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					Withdrawals are processed without commission. Commission for the deposit depends
					only on the user’s payment system.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>8.10 PAYMENT SYSTEM RESTRICTED COUNTRIES</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>8.10.1 Visa / Mastercard</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				We support gaming/gambling payouts via OCT (Original Credit Transfer) from Visa or
				also via Payment Transfer (Mastercard). Prerequisite for paying out are that an
				initial pay-in was done and for Mastercard this pay-in needs to have been made via
				a 3-D Secure MID. Additional requirements are that the respective credit card is
				not a corporate credit card and the respective card is issued in a country that is
				supported by the respective card scheme (see below). The per transaction limits
				are 60000,00 EUR for Visa and 5000,00 EUR for Mastercard.
			</p>
			<p>
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>8.10.2 Visa</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				For Visa (OCT) there is a list of countries not supported. List of
				non-participating countries for OCT:
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>USA</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Australia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Hongkong</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>India</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Indonesia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Japan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Korea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Malaysia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Singapore</p>
			</p>
			<p>
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>8.10.3 Mastercard</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				Mastercard PT list of countries supported. List of participating countries for PT:
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Andorra</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Austria</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Belgium</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Cyprus</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Czech republic</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Denmark</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Estonia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>France</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Germany</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Gibraltar</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Greece</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Hungary</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Iceland</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Ireland</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Italy</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Latvia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Luxembourg</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Malta</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Monaco</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Netherlands</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Norway</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>San marino</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Slovakia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Slovenia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Spain</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Sweden</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Switzerland</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Turkey</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>United kingdom</p>
			</p>
			<p>
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>8.10.4 Skrill</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				Countries restricted for registration. Residents of this country category can
				access the websites, but cannot register/sign up an account:
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Afghanistan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Angola</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Barbados</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Benin</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Bonaire</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Burkina Faso</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Cape Verde</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Cuba</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Comoros</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Djibouti</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>East Timor</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Eritrea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Faroe Islands</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>French Polynesia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Gambia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Greenland</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Grenada</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Guadeloupe</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Guyana</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Iran</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Iraq</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Japan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Kyrgyzstan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Lao People’s Democratic Republic</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Libya</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Macao</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Martinique</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Namibia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Nauru</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>New Caledonia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Niger</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>North Korea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Palau</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Reunion</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Saint Barthelemy</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Saint Martin (Sint Maarten)</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Samoa</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>South Sudan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Sudan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Suriname</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Syria</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Tajikistan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Togo</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Turkmenistan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>US Minor Outlying Islands</p>
			</p>
			<p>
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>8.10.5 Skrill</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>
					Banned countries. Residents of this country category cannot register an account,
					nor access the Skrill domains:
				</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Afghanistan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Cuba</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Eritrea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Iran</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Iraq</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Japan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Kyrgyzstan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Libya</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>North Korea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>South Sudan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Sudan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Syria</p>
			</p>
			<p>
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>8.10.6 Skrill</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Restricted for Gaming and Binary:</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>American Samoa (relevant state licenses required)</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Brazil</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Canada</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>China (Pr)</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Guam (relevant state licenses required)</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Hong Kong</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Israel</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Macao</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Puerto Rico (relevant state licenses required)</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Northern Mariana Island (relevant state licenses required)</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Singapore</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Turkey</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>United Arab Emirates</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>United States Of America</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>US Minor Outlying Islands</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>US Virgin Islands</p>
			</p>
			<p>
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>8.10.7 Neteller</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>Does not provide service to the following countries:</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Afghanistan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Benin</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Bonaire</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Burundi</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Central African Republic</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Chad</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>China</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Congo Republic</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Crimea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Cuba</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Djibouti</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Equatorial Guinea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Eritrea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Gabon</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Gambia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Guinea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Guinea-Bissau</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Guyana</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Iran</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Iraq</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Kazakhstan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Kyrgyzstan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Lao People’s Democratic Republic</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Liberia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Libya</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Madagascar</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Malawi</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Mali</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Mauritania</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Mongolia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Montserrat</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Myanmar</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Nauru</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Niger</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Niue</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Norfolk Island</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>North Korea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Palau</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Papua New Guinea</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Saint Barthelemy</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Saint Kitts and Nevis</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Saint Martin</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Sint Maarten</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Sierra Leone</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Sudan (North and South)</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Suriname</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Syria</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Tajikistan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Timor-Leste</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Togo</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Turkmenistan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Uzbekistan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Western Sahara</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Yemen</p>
			</p>
			<p>
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>8.10.8 Neteller</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>Requires local license for specific countries:</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Australia</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Belgium</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>France</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Germany</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Japan</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Spain</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>Turkey</p>
			</p>
			<p>
				<br />
			</p>
			<p className={s.text}>
				<p>UK</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>9. BONUSES</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					9.1.
					<b> {APP_COMPANY} </b>
					reserves the right to remove, add or change any bonus that is available in the
					account of any Customer and has not been claimed. Any claimed and active bonus
					will not be removed by
				</p>
			</p>
			<p className={s.text}>
				<b>{APP_COMPANY} </b>
				under regular circumstances.
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					9.2. Bonuses might have wagering requirements attached to them. These
					requirements will be appropriately displayed before the Customer decides to use
					a bonus when wagering requirements are applicable. Withdrawal amounts will be
					only possible for the real money balance the bonus money balance upon withdrawal
					will be lost.
				</p>
			</p>
			<p className={s.text}>
				<b>{APP_COMPANY} </b>
				has the right to choose which game and service contributes what amount to the
				wagering requirements. The wagering requirements have a minimum of 25 times the
				amount of bonus and the real money sum contributing to the bonus figure.
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					9.3. Bonuses can be received once per person (Customer), per household, per
					address, per mobile phone, per shared computer and per shared IP address.
					Risk-free bets on any games or services do not count toward the wagering
					requirements. Winnings from free spins are added to the real money balance.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					9.4. The maximum amount of money to be won from a no deposit free spin bonus and
					gameplay with mentioned bonus funds is 50 EUR or any other currency equivalent.
					Any winnings exceeding 50 EUR without making a real money deposit will be void
					and will be kept by
				</p>
			</p>
			<p className={s.text}>
				<b>{APP_COMPANY}</b>
				Example, a customer receives a no deposit free spins bonus. The free spins get
				played out and the customer wins 10 EUR. The customer proceeds to play other games
				and wins 90 EUR, totaling 100 EUR in the
			</p>
			<p className={s.text}>
				<b>{APP_COMPANY} </b>
				cashier. In this case the customer has exceeded the cap of 50 EUR winnings, 50 EUR
				will be available for withdrawing and 50 EUR will be kept by
				<b>{APP_COMPANY}</b>.
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					9.5. The maximum bet when having an active bonus is EUR 5 per spin or EUR 0.5
					per bet line.
				</p>
			</p>
			<p className={s.text}>
				<b>{APP_COMPANY} </b>
				reserves the right to void bets and wager turnovers resulting from larger bets
				made on games and/or services of
				<b>{APP_COMPANY}</b>.
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					9.6.
					<b> {APP_COMPANY} </b>
					reserves the right to impose various restrictions to different countries where
					Customers may reside regarding bonuses for reasons undisclosed. We further
					reserve the right to offer specific bonuses to individual customers or customer
					groups and their termination, change or other alteration without explanation
					and/or compensations in any form.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					9.7. If a player or a group of players are abusing casino bonuses
					<b> {APP_COMPANY} </b>
					has the right to confiscate any wins thereof and to close the players
					account(s). Abusive behaviour towards bonuses and/or bonus abuse means:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					i) A Player places bets and/or wagers amounting to the maximum allowed per bonus
					terms to raise the variance in order increase the balance
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					ii) Player lowers the bet and/or wager size after a big win and changes the game
					to one with a lower volatility
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					iii) Player makes deposits to cap out the bonus amount but not more. Applicable
					to match bonuses of 50% and above
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					After the account has been flagged as bonus abusing it is forbidden to use any
					other bonuses and/or participate in promotions at
					<b> {APP_COMPANY}</b>.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>10. LEGAL USE OF THE WEBSITE</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					10.1 Access to or use of the Website or any of the Services via the Website may
					not be legal for some or all residents of or persons in certain countries. We do
					not intend that the Website should be used for betting, gaming or any other
					purposes by persons in countries in which such activities are illegal, which
					includes the United States of America and those territories listed in the
					Website. The fact that the Website is accessible in any such country, or appears
					in the official language of any such country shall not be construed as a
					representation or warranty with respect to the legality or otherwise of the
					access to and use of the Website, and the making of deposits or receipt of any
					winnings from Your Account. The availability of the Website does not constitute
					an offer, solicitation or invitation by us for the use of or subscription to
					betting, gaming or other services in any jurisdiction in which such activities
					are prohibited by law.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					10.2 It is Your responsibility to determine the law that applies in the location
					in which You are present. You should ensure that You will be acting legally in
					Your jurisdiction in opening Your Account and/or using the Website and You
					represent, warrant and agree that You will do so.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					10.3 If it becomes apparent to us that You are resident in a country in which
					the use of the Website is not legal or You are using the Website from a country
					in which the use of the Website is not legal, we shall be entitled immediately
					to close Your Account, in which case any balance on the Account on the date of
					such closure will be refunded to You as soon as it is practicable for us to do
					so.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>11. PLACING YOUR BET AND/OR GAMING USING THE SERVICES</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					11.1 In order to place a bet or access a Service you should follow the
					instructions provided at the respective section of the Website.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					11.2 It is Your responsibility to ensure that the details of any bet, stake or
					similar transaction that you place using the Services (a "<b>Transaction</b>
					") are correct when using the Website (either directly, through an application
					or otherwise) in accordance with the relevant Game Rules, as appropriate.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					11.3 Your Transaction history can be accessed by you by clicking My Account on
					the Website, or through our Customer Services team (including by opting to
					receive a written statement).
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					11.4 We reserve the right to refuse the whole or part of any Transaction
					requested by You at any time in our sole discretion, or where You have breached
					the Terms of Use. No Transaction is accepted by us until You have given the
					appropriate confirmation (or it has otherwise been accepted by us) in accordance
					with paragraph 11.2. If You are in any doubt as to whether a Transaction has
					been accepted successfully, You should contact
					<b> {APP_EMAIL} </b>.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					11.5 Once a Transaction has been accepted by us, You cannot cancel the
					transaction unless we agree otherwise.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					11.6 In respect of gaming, the relevant Game Rules shall set out the point at
					which no further stakes or bets will be accepted by us.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					11.7 We may cancel or amend a Transaction due to Collusion, Cheating, Fraud and
					Criminal Activity, Errors or Omissions, as well because terms set out for
					Verification of Your Identity.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>12. REMOTE GAMING OR BETTING</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					12.1 Where You are accessing the Services via an electronic form of
					communication You should be aware that:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					12.1.1 in relation to Your use of the Website for the placing of bets or playing
					of games:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					12.1.1.1 You may be using a connection or equipment which is slower than such
					equipment used by others and this may affect Your performance in time critical
					events offered via the Website;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					12.1.1.2 You may encounter system flaws, faults, errors or service interruption
					which will be dealt with in accordance with IT Failure as below;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					12.1.1.3 the Game Rules for each event or game offered via the Website are
					available and should be considered by You prior to Your use of the Services
					offered via the Website.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>13. COLLUSION, CHEATING, FRAUD AND CRIMINAL ACTIVITY</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.1 The following practices (or any of them) in relation to the Services:
				</p>
			</p>
			<ul>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>abuse of bonuses or other promotions; and/or</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							using unfair external factors or influences (commonly known as cheating);
							and/or
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>taking unfair advantage (as defined below);</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>opening any Duplicate Accounts; and/or</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
						<p className={s.text}>
							undertaking fraudulent practice or criminal activity (as defined below),
						</p>
					</p>
				</li>
			</ul>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					constitute "<b>Prohibited Practices</b>" and are not permitted and will
					constitute a material breach of the Terms of Use. We will take all reasonable
					steps to prevent and detect such practices and to identify the relevant players
					concerned if they do occur. Subject to the above, however, we will not be liable
					for any loss or damage which You may incur as a result of any Prohibited
					Practices, and any action we take in respect of the same will be at our sole
					discretion.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.2 If You suspect a person is engaged in any Prohibited Practice, You shall as
					soon as reasonably practicable report it to us by e-mailing us or telephoning
					Customer Services.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.3 You agree that You shall not participate in or be connected with any form
					of Prohibited Practice in connection with Your access to or use of the Services.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>13.4 If:</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.4.1 we have reasonable grounds to believe that You have participated in or
					have been connected with any form of Prohibited Practice (and the basis of our
					belief shall include the use by us (and by our gaming partners and our other
					suppliers) of any fraud, cheating and collusion detection practices which are
					used in the gambling and gaming industry at the relevant time); or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.4.2 You have placed bets and/or played online games with any other online
					provider of gambling services and are suspected (as a result of such play) of
					any Prohibited Practice or otherwise improper activity; or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.4.3 we become aware that You have "charged back" or denied any of the
					purchases or deposits that You made to Your Account; or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.4.4 in our reasonable opinion your continued use of the Services may be
					detrimental to our regulated status, including our continued ability to be
					licensed by the Licensing Authority; or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.4.5 You become bankrupt or suffer analogous proceedings anywhere in the
					world, then, (including in connection with any suspension and/or termination of
					Your Account) we shall have the right, in respect of Your Account (and/or any
					other account held by You with an Operator Group company) to withhold the whole
					or part of the balance and/or recover from the account the amount of any
					deposits, pay-outs, bonuses or winnings which have been affected by or are in
					any way attributable to any of the event(s) contemplated in this paragraph 13.4.
					The rights set out in this paragraph 13.4 are without prejudice to any other
					rights (including any common law rights) that we may have against You, whether
					under the Terms of Use or otherwise.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>13.5 For the purposes of this paragraph 12:</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.5.1 "<b>fraudulent practice</b>" means any fraudulent activity engaged in by
					You or by any person acting on Your behalf or in collusion with You, and shall
					include, without limitation: (a) fraudulent charge-backs and rake-back activity;
					(b) the use by You or any other person who was participating in the same game as
					You at any time, of a stolen, cloned or otherwise unauthorised credit or debit
					card, as a source of funds; (c) the collusion by You with others in order to
					gain an unfair advantage (including through bonus schemes or similar incentives
					offered by us); (d) any attempt to register false or misleading account
					information; and (e) any actual or attempted act by You which is reasonably
					deemed by us to be illegal in any applicable jurisdiction, made in bad faith, or
					intended to defraud us and/or circumvent any contractual or legal restrictions,
					regardless of whether such act or attempted act actually causes us any damage or
					harm;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.5.2 "<b>criminal activity</b>" shall include, without limitation, money
					laundering and any offence under any law or regulation in Your country, where
					you are playing from or where your IP has been registered; and
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.5.3 "<b>unfair advantage</b>" shall include, without limitation:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.5.3.1 the exploitation of a fault, loophole or error in our or any third
					party’s software used by You in connection with the Services (including in
					respect of any game);
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.5.3.2 the use of third party software or analysis systems; or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.5.3.3 the exploitation by You, of an Error as defined below, in any case
					either to Your advantage and/or to the disadvantage of us or others.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.6 In exercising any of our rights under paragraph 12.4 in relation to a
					Prohibited Practice, we shall use all reasonable endeavours to ensure that,
					while complying with our regulatory and other legal obligations, we exercise
					such rights in a manner which is fair to You and to our other customers.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					13.7 We reserve the right to inform relevant authorities, other online gaming or
					gambling operators, other online service providers and banks, credit card
					companies, electronic payment providers or other financial institutions of Your
					identity and of any suspected Prohibited Practice by You, and You shall
					cooperate fully with us to investigate any such activity.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>14. CLOSURE OF YOUR ACCOUNT; TERMINATION OF THE TERMS OF USE</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>CLOSURE AND TERMINATION BY YOU</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					14.1 Provided that Your Account does not show that a balance is due to us, You
					are entitled to close Your Account and terminate the Terms of Use on not less
					than twenty four hours’ notice to us at any time, by contacting us through
					<b> {APP_EMAIL} </b>:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>14.1.1 indicating Your wish to close Your Account; and</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					14.1.2 stating the reasons why You wish to close Your Account, in particular if
					You are doing so because of concerns over the level of Your use of the same.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					We will respond to Your request, confirming closure of Your Account and the date
					on which such closure will be effective, within a reasonable time, provided that
					You continue to assume responsibility for all activity on Your Account until
					such closure has been carried out by us (at which point the Terms of Use shall
					terminate).
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					14.2 When You request closure of Your Account under paragraph 14.1 we will,
					subject to paragraph 13.3, return any outstanding balance in Your Account to
					You.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					14.3 Upon any termination of Your Account under this paragraph 14 we shall be
					entitled (without limiting our rights under paragraph 14.6) to withhold, from
					the repayment of the outstanding balance on Your Account, any monies: (a)
					pursuant to Collusion, Cheating, Fraud and Criminal Activity); (b) pursuant to
					Breach of the Terms of Use; (c) as otherwise provided by the Terms of Use; or
					(d) as required by law or regulation.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					14.4 When repaying the outstanding balance on Your Account, we shall use the
					same method of payment which You provided upon registration of Your Account, or
					such other payment method as we may reasonably select.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					14.5 Where You have closed Your Account, we may in certain circumstances be able
					to re-open Your Account with the same account details as before if You request
					us to do so. In such circumstances, while Your Account will have the same
					account details as before, it will be subject to the Terms of Use which are in
					force at the date of any such re-opening and any prior entitlements (including,
					but without limitation, to bonuses or contingent winnings) will no longer be
					valid.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>CLOSURE AND TERMINATION BY US</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					14.6 We are, at any time (and notwithstanding any other provisions contained in
					the Terms of Use), entitled to close Your Account and terminate the Terms of Use
					on written notice (or attempted notice) to You using Your Contact Details. In
					the event of any such termination by us we shall, subject to paragraph 14.7, as
					soon as reasonably practicable following a request by You, refund the balance of
					Your Account.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					14.7 Where we close Your Account and terminate the Terms of Use pursuant to
					Collusion, Cheating, Fraud and Criminal Activity or Breach of the Terms of Use,
					the balance of Your Account will be non-refundable and deemed to be forfeited by
					You to the extent of any claim that we may have against You as at the date of
					such closure (whether under Your Account, a Duplicate Account or otherwise).
					Closure of Your Account and Termination of the Terms of Use, other than pursuant
					to paragraphs 12 or 21 of these General Terms, will not affect any outstanding
					bets, provided that such outstanding bets are valid and You are not in breach of
					the Terms of Use in any way. For the avoidance of doubt, we will not credit any
					bonuses into Your Account, nor will You be entitled to any contingent winnings,
					at any time after the date on which it has been closed (whether by us pursuant
					to the Terms of Use, or in response to Your request).
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					14.8 The following paragraphs shall survive any termination of the Terms of Use:
					20, 21, 22, 23, 24, 26, 27, 29, 30, 31, 32, 33 and 35 and any other paragraphs
					which are required for the purposes of interpretation; together with any
					relevant sections of the Game Rules, the Privacy Policy and the Additional
					Terms.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>SUSPENSION BY US</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					14.9 We shall be entitled to suspend Your Account in the circumstances expressly
					set out in the Terms of Use. Upon the suspension of Your Account: (a) no
					activity shall be permitted (including deposits, withdrawals, betting or gaming)
					until the date upon which it is re-activated by us; (b) no bonuses or contingent
					winnings will be credited to the Account; and (c) we shall address the issue
					that has given rise to the Account suspension with a view to resolving it as
					soon as reasonably practicable so that the Account can, as appropriate, either
					be re-activated or closed.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>15. ACCESS TO, AND USE OF, THE SERVICES</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					15.1 You are solely responsible for the supply and maintenance of all of Your
					Access Devices and related equipment, networks and internet access services that
					You need to use in order to access the Services. We will not be liable in any
					way whatsoever for any losses caused to You (whether resulting from loss of
					service, poor internet connectivity, insufficient bandwidth or otherwise) by the
					internet or any telecommunications service provider that You have engaged in
					order to access the Services. For the avoidance of doubt, the Operator does not
					make any representation or give any warranty as to the compatibility of the
					Services with any particular third party software or hardware, including (for
					the avoidance of doubt) third party analysis which promise certain results from
					any of the Services.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					15.2 Under no circumstances should You use the Services for any purpose which is
					or is likely to be considered to be defamatory, abusive, obscene, unlawful, of a
					racist, sexist or other discriminatory nature, or which could cause offence. You
					must not use any abusive or aggressive language or images, swear, threaten,
					harass or abuse any other person, including other users, via the Website, or
					attempt to pass Yourself off as being any other person, or behave in such a
					manner towards any Operator staff used to provide the Services, Customer
					Services, or any helpdesk or support function which we make available to You.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					15.3 You shall use the Website for personal entertainment only and shall not be
					allowed to provide access or reproduce the Website or any part of it in any form
					whatsoever without our express consent, including creating links to it.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					15.4 Any material (other than Software under paragraph 17) downloaded by You
					from the Website shall be downloaded entirely at Your own risk and the Operator
					shall not be liable in respect of any loss of data or other damage caused by any
					such download.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					15.5 Where we have reason to believe that Your use of the Services is in breach
					of any of paragraphs 15.2, 15.3, 15.4 we shall, without prejudice to any of our
					other rights, be entitled forthwith to remove from the Website any offending
					content.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>16. BETTING AND GAMING TERMS</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					16.1 Expressions used in the betting and gaming industry are numerous. Should
					You be in any doubt as to the meaning of any expression, You should:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					16.1.1 look up its meaning in relating to the event or game You are betting or
					gaming on;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					16.1.2 if You are still in any doubt, contact
					<b> {APP_EMAIL} </b>
					for clarification; and
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					16.1.3 not place any bet or game on any event until its meaning is understood to
					Your satisfaction,
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					because we cannot accept any responsibility if You place a bet or game via the
					products offered via the Services in circumstances where You do not understand
					any of the terms involved in or relating to the bet or game.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>17. ALTERATION OF THE WEBSITE</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					We may, in our absolute discretion, alter or amend any product or service
					(including any prices offered) available through the Website at any time for the
					purpose of ensuring the ongoing provision of the Website, but without prejudice
					to any games and/or bets already in progress at the time of such amendment. From
					time to time, we may restrict You from accessing some parts of the Website for
					the purpose of maintenance of the Website and/or alteration or amendment of any
					of the games and/or products available through the Website.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>18. THIRD PARTY SOFTWARE</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					18.1 In order to use the products offered through the Services, You may be
					required to download and install software supplied by third parties on to Your
					Access Device ("Software"). Software may include, but is not limited to: Access
					Device applications, our download Casino and Poker products and any promotional,
					marketing and/or facility applications, products and software.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					18.2 In such circumstances, You may be required to enter into a separate
					agreement with the owner or licensor of such Software in respect of Your use of
					the same (a " Third Party Software Agreement"). In case of any inconsistency
					between the Terms of Use and any Third Party Software Agreement, the Terms of
					Use will prevail in so far as the inconsistency relates to the relationship
					between You and the Operator.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					18.3 It is Your responsibility to ensure that any Software is downloaded onto
					Your Access Device in a manner compatible with Your own Access Device’s specific
					set-up. For the avoidance of doubt, we shall not be liable to the extent that
					the incorrect downloading of any Software has an adverse effect on the operation
					of Your Access Device.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					18.4 Notwithstanding that the Services provided via any Access Device
					application shall be subject to the Terms of Use, the terms under which any
					application ("App") is downloaded or installed onto Your Access Device shall be
					governed by the agreement entered into between You and the supplier of the
					relevant App but, in case of any inconsistency between the Terms of Use and any
					such agreement, the Terms of Use will prevail in so far as the inconsistency
					relates to the relationship between You and the Operator.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>19. IT FAILURE</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					Where problems occur in the software or hardware used by us to provide the
					Services we will take all reasonable steps to remedy the problem as soon as
					reasonably practicable. Where such problems cause a game to be interrupted in
					circumstances where it cannot be restarted from exactly the same position
					without any detriment to You or other players, we will take all reasonable steps
					to treat You in a fair manner (which may include reinstating the balance on Your
					Account to the position existing following completion of the last bet or game
					logged on the Operator’s server immediately prior to the occurrence of the
					problem).
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>20. ERRORS OR OMISSIONS</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.1 A number of circumstances may arise where a bet or wager is accepted, or a
					payment is made, by us in error. A non-exhaustive list of such circumstances is
					as follows:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.1.1 where we mis-state any odds or terms of a bet or gaming wager to You as a
					result of obvious error or omission in inputting the information or setting up a
					market, or as a result of a computer malfunction;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.1.2 where we have made a ‘palpable error’. A palpable error occurs where:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.1.2.1 in relation to bets placed prior to an event taking place, the
					prices/terms offered are materially different from those available in the
					general market; or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.1.2.2 in relation to any event, the price/terms offered at the time the bet
					is placed are clearly incorrect given the probability of the event occurring;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.1.3 where we have continued to accept bets on a market which should have been
					suspended, including where the relevant event is in progress (except where
					‘in-running’ bets are accepted) or had already finished (sometimes referred to
					as ‘late bets’);
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.1.4 where an error has been made as a result of a Prohibited Practice;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.1.5 where we should not have accepted, or have the right to cancel or
					re-settle, a bet pursuant to the Betting Rules (for example due to ‘Related
					Contingencies’);
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.1.6 where an error is made by us as to the amount of winnings/returns that
					are paid to You, including as a result of a manual or computer input error; or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.1.7 where an error has been made by us as to the amount of free bets and/or
					bonuses that are credited to Your Account,
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					such circumstances being referred to as an "<b>Error</b>"
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>20.2 We reserve the right to:</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.2.1 correct any Error made on a bet placed and re-settle the same at the
					correct price or terms which were available or should have been available
					through the Operator (absent the publishing error) at the time that the bet was
					placed and the bet will be deemed to have taken place on the terms which were
					usual for that bet; or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.2.2 where it is not reasonably practicable to correct and re-settle under
					20.2.1 above, to declare the bet void and return Your stake into Your Account;
					or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.2.3 in circumstances where the Error has resulted from a Prohibited Practice,
					to take the steps set out in there Terms of Use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.3 Any monies which are credited to Your Account, or paid to You as a result
					of an Error shall be deemed to be held by You on trust for us and shall be
					immediately repaid to us when a demand for payment is made by us to You. Where
					such circumstances exist, if You have monies in Your Account we may reclaim
					these monies from Your Account pursuant Terms of Use. We agree that we shall use
					all reasonable endeavours to detect any Errors and inform You of them as soon as
					reasonably practicable.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.4 Neither we (including our employees or agents) nor our partners or
					suppliers shall be liable for any loss, including loss of winnings, that results
					from any Error by us or an error by You.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.5 You shall inform us as soon as reasonably practicable should You become
					aware of any Error.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					20.6 Where You have used monies which have been credited to Your Account or
					awarded to You as a result of an Error to place subsequent bets or play games,
					we may cancel such bets and/or withhold any winnings which You may have won with
					such monies, and if we have paid out on any such bets or gaming activities, such
					amounts shall be deemed to be held by You on trust for us and You shall
					immediately repay to us any such amounts when a demand for repayment is made by
					us to You.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>21. EXCLUSION OF OUR LIABILITY</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					21.1 Your access to and use of the Services is at Your sole option, discretion
					and risk. We shall not be liable for any attempts by You to use the Services by
					methods, means or ways not intended by us.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					21.2 We will provide the Services with reasonable skill and care and
					substantially as described in the Terms of Use. We do not make any other
					promises or warranties regarding the Services, or any products or services
					forming a part of the Services, and hereby exclude (to the extent permitted by
					law) all implied warranties in respect of the same (including implied warranties
					as to satisfactory quality and/or fitness for Your purpose). In particular, we
					do not warrant that the Website will have uninterrupted availability or that it
					will be free of bugs, viruses or other errors.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					21.3 WE (INCLUDING OUR GROUP COMPANIES, PAYMENT PROCESSOR, AFFILIATES, OFFICERS,
					DIRECTORS, AGENTS AND EMPLOYEES) SHALL NOT BE LIABLE TO YOU, WHETHER SUCH
					LIABILITY ARISES IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, IN
					RESPECT OF ANY:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>21.3.1 LOSS OF DATA;</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>21.3.2 LOSS OF PROFITS;</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>21.3.3 LOSS OF REVENUE;</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>21.3.4 LOSS OF BUSINESS OPPORTUNITY;</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>21.3.5 LOSS OF OR DAMAGE TO GOODWILL OR REPUTATION;</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>21.3.6 BUSINESS INTERRUPTION; OR</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					21.3.7 ANY INDIRECT, SPECIAL OR CONSEQUENTIAL LOSS OR DAMAGE, EVEN WHERE SUCH
					LOSS OR DAMAGE HAS BEEN NOTIFIED TO US AS BEING POSSIBLE, ARISING OUT OF THE
					TERMS OF USE OR ANY USE WHATSOEVER BY YOU OF THE SERVICES.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<br />
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<br />
				<br />
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>22. BREACH OF THE TERMS OF USE</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					22.1 You will fully indemnify, defend and hold us and our officers, directors,
					employees, agents, contractors and suppliers harmless from and against any and
					all losses, costs, expenses, claims, demands, liabilities and damages (including
					legal fees), however caused, that may arise, whether or not reasonably
					foreseeable, as a result of or in connection with:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					22.1.1 the access to and use of the Services by You or by anyone else using Your
					username and password; and/or
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					22.1.2 any breach by You of any of the terms and provisions of the Terms of Use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					22.2 Where You are in breach of the Terms of Use, we may at our sole discretion,
					prior to any suspension or termination of Your Account, notify You (using Your
					Contact Details) that You are in breach, requiring You to stop the relevant act
					or failure to act, and/or requiring You to put right an act or fault on Your
					part and warning You of our intended action if You do not do so, provided always
					that such notification shall not be a pre-condition to any suspension or
					termination of Your Account.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					22.3 We have the right to disable any user identification code or password,
					whether chosen by You or allocated by us, at any time, if in our reasonable
					opinion You have failed to comply with any of the provisions of the Terms of
					Use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					22.4 In addition to any other remedy available, if You breach any of the Terms
					of Use we shall be entitled to recover from Your Account any positive balance to
					the extent of any amount reasonably claimed against You pursuant to paragraph
					21.1.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>23. INTELLECTUAL PROPERTY RIGHTS</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					23.1 All website design, text, graphics, music, sound, photographs, video, the
					selection and arrangement thereof, software compilations, underlying source
					code, software and all other material forming part of the Services are subject
					to copyright and other proprietary rights which are either owned by us or used
					under licence from third party rights owners. To the extent that any material
					comprised within the Services may be downloaded or printed then such material
					may be downloaded to a single device only (and hard copies may be printed)
					solely for Your own personal, non-commercial use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					23.2 Under no circumstances shall the use of the Services grant to You any
					interest in any intellectual property rights (for example copyright, know-how or
					trade marks) owned by us or by any third party whatsoever, other than a
					personal, non-exclusive, non-sub-licensable licence to use such intellectual
					property rights in connection with Your personal, non-commercial use of the
					Services pursuant to the Terms of Use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					23.3 No rights whatsoever are granted to use or reproduce any trade marks or
					logos which appear on the Website except as specifically permitted in accordance
					with the Terms of Use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					23.4 You must not, nor must You allow any other person to copy, store, publish,
					rent, licence, sell, distribute, alter, add to, delete, remove or tamper with
					the Website or any part of it in any way or directly or indirectly disrupt or
					interfere (or attempt to disrupt or interfere) with or alter the Website, other
					than in the course of viewing or using the Website in accordance with the Terms
					of Use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					23.5 All intellectual property rights in the name
					<b>"{APP_COMPANY}"</b>, the logos, designs, trade marks and other distinctive
					brand features of the Operator and any content provided by the Operator or any
					third party for inclusion on the Website vest in the Operator or the applicable
					third party. You agree not to display or use such logos, designs, trade marks
					and other distinctive brand features in any manner without our prior written
					consent.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>24. VIRUSES, HACKING AND OTHER OFFENCES</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>24.1 You shall not:</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>24.1.1 corrupt the Website;</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					24.1.2 attempt to gain unauthorised access to the Website, the servers on which
					the Website is stored or any server, computer or database connected to the
					Website;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					24.1.3 flood the Website with information, multiple submissions or "spam";
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					24.1.4 knowingly or negligently use any features which may affect the function
					of the Website in any way for example (but not limited to) releasing or
					propagating viruses, worms, trojans, logic bombs or similar material that is
					malicious or harmful;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					24.1.5 interfere or tamper with, remove or otherwise alter in any way, any
					information in any form which is included on the Website;
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					24.1.6 attack the Website via a denial-of-service attack or a distributed
					denial-of-service attack. We will report any suspected breach to the relevant
					law enforcement authorities and we will co-operate with those authorities by
					disclosing Your identity to them. In the event of such a breach, Your right to
					use the Website will cease immediately.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					24.2 We will not be liable for any loss or damage caused by a distributed
					denial-of-service attack, viruses or other technologically harmful material that
					may infect Your Access Device and related equipment, computer programs, data or
					other proprietary material due to Your use of the Website or to Your downloading
					of any material posted on such Website, or on any website linked to the Website.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>24.3 Website is free from malicious software.</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>25. YOUR PERSONAL INFORMATION</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					25.1 All information on Your Account held by us is securely data warehoused and
					remains confidential except where otherwise stated in the Terms of Use
					(including, for the avoidance of doubt, the Privacy Policy).
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					25.2 We are required by law to comply with data protection requirements in the
					way in which we use any personal information collected from You in Your use of
					the Services. We therefore take very seriously our obligations in relation to
					the way in which we use Your personal information.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					25.3 Prior to Your use of and when You use the Services it will be necessary for
					us to collect certain information about You, including Your name and date of
					birth, Your Contact Details, and may also include information about Your
					marketing preferences (all of which shall be known as "
					<b>Your Personal Information</b>
					").
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					25.4 By providing us with Your Personal Information, You consent to our
					processing Your Personal Information, including any of the same which is
					particularly sensitive:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					25.4.1 for the purposes set out in the Terms of Use (including the Privacy
					Policy); and
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					25.4.2 for other purposes where we (including Payment Processor) need to process
					Your Personal Information for the purposes of operating the Services,
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					including by sharing it with our service providers, employees and agents for
					these purposes, for example to our providers of postal services, marketing
					services and Customer Services agents. We may also disclose Your Personal
					Information in order to comply with a legal or regulatory obligation.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					25.5 We may retain copies of any communications that You send to us (including
					copies of any emails) in order to maintain accurate records of the information
					that we have received from You.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>26. USE OF ‘COOKIES’ ON THE WEBSITE</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					26.1 The Website may use ‘cookies’ to track Your use of the internet and to
					assist the functionality of the Website. A cookie is a small file of text which
					is downloaded onto Your Access Device when You access the Website and it allows
					us to recognise when You come back to the Website. We use or shall use cookies
					for the operation of the Website, including (for example) to allow You to remain
					logged in as You browse between, and use Your Account to bet on or play games
					on, different parts of the Website. We also use cookies for our own analytical
					purposes so that we can identify where customers have encountered technical
					problems on the Website, and therefore help us improve our customers’
					experience.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					26.2 If You object to cookies or want to delete any cookies that are already
					stored on Your Access Device, we recommend that You follow the instructions for
					deleting existing cookies and disabling future cookies on Your file management
					and internet browsing software. Further information on deleting or controlling
					cookies is available within our Privacy Policy or at&nbsp;
					<a
						href='http://www.aboutcookies.org/'
						target='_top'
					>
						www.aboutcookies.org
					</a>
					. Please note that by deleting our cookies or disabling future cookies You may
					not be able to access certain areas or features of the Website.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>27. COMPLAINTS AND NOTICES</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>27.1 No claim or dispute with regard to:</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					27.1.1 the acceptance or settlement of a bet which You have made using the
					Services will be considered more than thirty days after the date of the original
					transaction; and
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					27.1.2 a game which You have played using the Services will be considered more
					than twelve weeks after the date on which the relevant transaction or game play
					took place.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					27.2 Should You wish to make a complaint regarding the Services, as a first step
					You should, as soon as reasonably practicable, contact
					<b> {APP_EMAIL} </b>
					about Your complaint, which will be escalated as necessary within our Support
					team until resolution.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					27.3 You acknowledge that our random number generator will determine the outcome
					of the games played through the Services and You accept the outcomes of all such
					games. You further agree that in the unlikely event of a disagreement between
					the result that appears on Your screen and the game server used by the Operator,
					the result that appears on the game server will prevail, and You acknowledge and
					agree that our records will be the final authority in determining the terms and
					circumstances of Your participation in the relevant online gaming activity and
					the results of this participation.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					27.4 When we wish to contact You, we may do so using any of Your Contact
					Details. Notices will be deemed to have been properly served and received by You
					immediately after an email is sent or after we have communicated with You
					directly by telephone (including where we leave You a voicemail), or three days
					after the date of posting of any letter. In proving the service of any notice,
					it will be sufficient to prove, in the case of a letter, that such letter was
					properly addressed, stamped and placed in the post; in the case of an email,
					that such email was sent to the specified email address (if any) in Your Contact
					Details at the time that any such email was sent.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					27.5 If there is no reaction on your complaint or notice from us or in your
					opinion the issue is not yet resolved, you can file a complaint or notice to the
					licensing and supervision organization contacting&nbsp;
					<b>
						<a
							href='mailto:complaints@gaminglicences.com'
							target='_top'
						>
							complaints@gaminglicences.com
						</a>
					</b>
					<b>.</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>28. TRANSFER OF RIGHTS AND OBLIGATIONS</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					28.1 We reserve the right to transfer, assign, sublicense or pledge the Terms of
					Use (an "assignment"), in whole or in part, to any person without notice to You,
					provided that any such assignment will be on the same terms or terms that are no
					less advantageous to You.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					28.2 You may not assign, sublicense or otherwise transfer in any manner
					whatsoever any of Your rights or obligations under the Terms of Use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>29. EVENTS OUTSIDE OUR CONTROL</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					29.1 We will not be liable or responsible for any failure to perform, or delay
					in performance of, any of our obligations under the Terms of Use that is caused
					by events outside our reasonable control, including (without limitation) any
					telecommunications network failures, power failures, failures in third party
					computer (or other) equipment, fire, lightning, explosion, flood, severe
					weather, industrial disputes or lock-outs, terrorist activity and acts of
					government or other competent authorities (a "<b>Force Majeure Event</b>
					").
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					29.2 Our performance is deemed to be suspended for the period that the Force
					Majeure Event continues, and we will have an extension of time for performance
					for the duration of that period. We will use our reasonable endeavours to bring
					the Force Majeure Event to a close or to find a solution by which our
					obligations may be performed despite the Force Majeure Event.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>30. WAIVER</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					29.1 If we fail to insist upon strict performance of any of Your obligations or
					if we fail to exercise any of the rights or remedies to which we are entitled,
					this shall not constitute a waiver of such rights or remedies and shall not
					relieve You from compliance with such obligations.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					30.2 A waiver by us of any default shall not constitute a waiver of any
					subsequent default.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					30.3 No waiver by us of any of the provisions of the Terms of Use shall be
					effective unless it is expressly stated to be a waiver and is communicated to
					You in writing in accordance with paragraph 27 (Complaints and Notices) above.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>31. SEVERABILITY</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					31.1 If any of the Terms of Use are determined by any competent authority to be
					invalid, unlawful or unenforceable to any extent, such term, condition or
					provision will to that extent be severed from the remaining terms, conditions
					and provisions which will continue to be valid to the fullest extent permitted
					by law.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					31.2 In such cases, the part deemed invalid or unenforceable shall be amended in
					a manner consistent with the applicable law to reflect, as closely as possible,
					the Operator’s original intent.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>32. ENTIRE AGREEMENT</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					32.1 The Terms of Use and any document expressly referred to in them represent
					the entire agreement between You and us and supersede any prior agreement,
					understanding or arrangement between You and us, whether oral or in writing.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					32.2 We each acknowledge that neither of us has relied on any representation,
					undertaking or promise given by the other or implied from anything said or
					written in negotiations between us except as expressly stated in the Terms of
					Use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					32.3 Neither party shall have any remedy in respect of any untrue statement made
					by the other, whether orally or in writing, prior to the date of the contract
					(unless such untrue statement was made fraudulently) and the other party’s only
					remedy shall be for breach of contract as provided in the Terms of Use.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>33. THIRD PARTY RIGHTS</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					33.1 .Unless these Terms of Use expressly state otherwise a person who is not a
					party to these Terms of Use has no right to enforce any of the terms. It is
					expressly forbidden for End Users to transfer any assets of value of any kind to
					a third party, including but not limited, it is forbidden to transfer ownership
					of account(s), winnings, deposits, bets, rights and/or claims in connection with
					these assets, legal, commercial or of any other character. Such prohibition
					includes, but is not limited to encumbrance, pledging, assigning, usufruct,
					trading, brokering, hypothecation and/or gifting either separately or in
					cooperation with a fiduciary or any other third party, company, natural or legal
					individual, foundation and/or association in any way or shape of form.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>34. LAW AND JURISDICTION</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					34.1 These Terms of Use shall (subject to paragraph 25.2) be governed by and
					interpreted in accordance with the laws of the Scotland, UK.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>35. RESPONSIBLE GAMING/GAMBLING</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.1 For those customers who wish to restrict their gambling, we provide a
					voluntary self-exclusion policy, which enables You to close Your Account or
					restrict Your ability to place bets or game on the Website for a minimum period
					of six months. Once Your Account has been self-excluded You will be unable to
					reactivate the Account under any circumstances until the expiry of the period
					chosen under this paragraph. At the expiry of the self-exclusion period You will
					be entitled to re-commence use of the Services by contacting
					<b> {APP_EMAIL} .</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.2 In case you are concerned about your gambling practices there are a number
					of guidelines you may find useful:
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.2.1 try and establish limits for the amounts you want to wager or deposit.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.2.2 consider how long you wish any wagering session to last before commencing
					and be sure to keep an eye on the clock.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.2.3 try not to let gambling interfere with your daily responsibilities.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.3 Mind that gambling is not advised if you are recovering for any form of
					dependency or if you are under the influence of alcohol or any other substance
					including some prescription medication.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.4 You should always be aware, that gambling is a form of recreation and
					should not be viewed as an alternative source of income or a way to recoup debt.
					Recognising that you may be developing a gambling problem is the first step to
					regaining control.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>35.5 Please bear in mind, that:</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.5.1 Gambling should be entertaining and not seen as a way of making money
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>35.5.2 you should avoid chasing losses</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>35.5.3 Only gamble what you can afford to lose</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.5.4 Keep track of time and the amount you spend gambling
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.6 If You require any information relating to this contact our Support
					{APP_EMAIL} or view our Responsible Gambling &nbsp;section.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					35.7 The Operator is committed to supporting&nbsp; Responsible Gambling
					initiatives. We suggest to visit&nbsp;
					<a
						href='http://www.responsiblegambling.org/'
						target='_top'
					>
						www.responsiblegambling.org
					</a>
					&nbsp;for further help, if you feel that gambling starts to become a problem for
					you.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>36. LINKS</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					Where we provide hyperlinks to other websites, we do so for information purposes
					only. You use any such links at your own risk and we accept no responsibility
					for the content or use of such websites, or for the information contained on
					them. You may not link to this site, nor frame it without our express written
					permission.
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					<b>37. CONTACTING US</b>
				</p>
			</p>
			<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
				<p className={s.text}>
					The Operator can be contacted by e-mail
					<b> {APP_EMAIL} </b>
					and/or telephone +1(123) 456-75-90 Please note that all calls may be recorded
					for training and security purposes. If You are concerned about your gambling
					practices there are a number of guidelines you may find useful:
				</p>
			</p>
			<ul>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Consider how long you wish any wagering session to last before commencing
							and be sure to keep an eye on the clock.
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Try not to let gambling interfere with your daily responsibilities.
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0in' }}>
						<p className={s.text}>
							Gambling is not advised if you are recovering for any form of dependency or
							if you are under the influence of alcohol or any other substance including
							some prescription medication. We recommend that you consult your GP for more
							information.
						</p>
					</p>
				</li>
				<li>
					<p style={{ lineHeight: '100%', marginTop: '0.07in', marginBottom: '0.07in' }}>
						<p className={s.text}>
							Gambling is a form of recreation and should not be viewed as an alternative
							source of income or a way to recoup debt.
						</p>
					</p>
				</li>
			</ul>
		</div>
	);
};

export default PoliticsTermsAndConditions;

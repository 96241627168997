import React, { useState } from 'react';
import { useLocales } from '@lib/hooks';
import { userSelectors } from '@store/reducers/user/selectors';
import { renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import cn from 'classnames';
import { AnalyticsEventType, Currencies, Languages } from '@types';
import { VideoBlock } from './parts/video';
import { useTranslation } from 'react-i18next';
import { getImageByURL } from '@utils/getImageByURL';

export const HowGetMoney = () => {
	const { i18n } = useTranslation();
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Referral'
	});

	const { currency } = useSelector(userSelectors.userInfo);

	const [videoOpen, setVideoOpen] = useState(false);

	const openVideoHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.referral_open_video);
		setVideoOpen(!videoOpen);
	};

	const videoUrl =
		currency === Currencies?.inr
			? 'https://www.youtube.com/embed/iiYkI4ni-iY?si=XH6ExW0sX2zJ1ztA'
			: 'https://www.youtube.com/embed/zJ0dk57nMUM?si=jAE4e6cTL02a71tl';

	const renderVideoInstructionImage = (lang: Languages | string) => {
		switch (lang) {
			case 'en':
				return getImageByURL(
					'../../../../../../assets/img/referral/video-instruction-en.webp'
				);
			case 'ru':
				return getImageByURL(
					'../../../../../../assets/img/referral/video-instruction.webp'
				);
			case 'bn':
				return getImageByURL(
					'../../../../../../assets/img/referral/video-instruction-bn.webp'
				);
			default:
				return getImageByURL(
					'../../../../../../assets/img/referral/video-instruction-en.webp'
				);
		}
	};

	return (
		<div className={s.container}>
			<div className={s.text_block}>
				<h1 className={s.text_block_title}>
					{localizeText('how_to_get_money_referral')}
				</h1>

				<div className={s.text_block_descr}>{localizeText('view_short_video')}</div>
			</div>

			<div className={s.videoInstructionBlock}>
				<button
					onClick={openVideoHandler}
					className={s.videoInstructionBtn}
				>
					<img
						alt='video instruction'
						src={renderVideoInstructionImage(i18n?.language)}
					/>
				</button>
			</div>

			<div className={s.cards_block}>
				<div className={s.cards_block_item}>
					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/referral/card_1.webp')}
						alt='Found an error?'
					/>

					<div className={s.cards_block_item_text}>
						<div className={s.title}>{localizeText('how_get_money_card_title_1')}</div>
						<div className={s.descr}>{localizeText('how_get_money_card_descr_1')}</div>
					</div>
				</div>
				<div className={cn(s.cards_block_item, s.color_2)}>
					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/referral/card_2.webp')}
						alt='Found an error?'
					/>

					<div className={s.cards_block_item_text}>
						<div className={s.title}>{localizeText('how_get_money_card_title_2')}</div>
						<div className={s.descr}>{localizeText('how_get_money_card_descr_2')}</div>
					</div>
				</div>

				<div className={cn(s.cards_block_item, s.color_3)}>
					<img
						className={s.img}
						alt='Found an error?'
						src={getImageByURL('../../../../../../assets/img/referral/card_3.webp')}
					/>

					<div className={s.cards_block_item_text}>
						<div className={s.title}>
							{localizeText('how_get_money_card_title_3', {
								curr: renderCurrencySymbol(currency) || '৳'
							})}
						</div>
						<div
							className={s.descr}
							style={{ color: 'rgba(255, 255, 255, 0.75)' }}
						>
							{localizeText('how_get_money_card_descr_3', {
								curr: renderCurrencySymbol(currency) || '৳'
							})}
						</div>
					</div>
				</div>
			</div>

			{videoOpen && (
				<VideoBlock
					openVideoHandler={openVideoHandler}
					videoUrl={videoUrl}
				/>
			)}
		</div>
	);
};

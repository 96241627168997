import React from 'react';
import { AnalyticsEventType, ButtonType } from '@types';
import { sendAnalyticsHandler } from '@utils';
import { pathsConfig } from '@config/paths';
import { useLocales, useUrlLang } from '@lib/hooks';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/common/elements';
import { Trans } from 'react-i18next';
import s from './style.module.scss';

export const BGamingBanner = () => {
	const { langUrlPrefix } = useUrlLang();

	const navigate = useNavigate();

	const { localizeText } = useLocales({
		path: 'components/common/sections',
		node: 'ExtraOffers'
	});

	const goToBGamingPage = () => {
		navigate(langUrlPrefix + pathsConfig.bgaming.link);

		sendAnalyticsHandler(AnalyticsEventType?.advertising_click_banner, {
			banner_place: 'главная карусель',
			banner_name: 'Bgaming',
			banner_position: 1
		});
	};

	return (
		<div
			onClick={goToBGamingPage}
			className={s.wrapper}
		>
			<div className={s.container}>
				<span className={s.title}>
					<Trans
						ns='components/common/sections/translation'
						i18nKey={'ExtraOffers.bgaming_banner_title'}
						components={{ 1: <b /> }}
					/>
				</span>

				<Button
					type={ButtonType.primary}
					text={localizeText('play')}
					className={s.btn}
					rippleAnimation={false}
					withoutGlare
					handle={goToBGamingPage}
				/>
			</div>
		</div>
	);
};

import { useEffect } from 'react';

export const LicenseInit = () => {
	useEffect(() => {
		setTimeout(
			() => (window as any)?.apg_b22b4add_ecab_4996_b526_4e309f7609fd?.init(),
			1000
		);
	}, []);

	return null;
};

import { PaymentService } from '@lib/api/payment/PaymentService';
import {
	finishWithdrawal,
	setPaymentProcessingStatus
} from '@store/reducers/payment/dispathcers';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { store } from '@store/index';
import { AnalyticsEventType, ModalType, PaymentUIType } from '@types';
import { detectDeviceType, saveFieldsForProvider, sendAnalyticsHandler } from '@utils';
import { ErrorToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';

export const paymentWithdrawal = async (
	params: {
		amount: number;
		cardNumber?: string;
		wallet?: string;
		fields?: Record<string, any>;
		responseType?: PaymentUIType;
		currentPaymentError?: string;
	},
	isMobile: boolean,
	notVerifiedFunc: () => void
) => {
	setPaymentProcessingStatus(true);
	const {
		payment: { selectedPaymentMethodId }
	} = store.getState();

	const body = {
		amount: params.amount,
		cardNumber: params?.cardNumber,
		wallet: params?.wallet,
		fields: params?.fields,
		providerId: selectedPaymentMethodId ?? 0,

		deviceType: detectDeviceType()
	};

	try {
		const { status, data } = await PaymentService.paymentWithdrawal(body);

		if (params?.fields) {
			saveFieldsForProvider(body?.providerId, params?.fields);
		}

		if (status === 200) {
			sendAnalyticsHandler(AnalyticsEventType?.withdrawal_success);
			if (data) finishWithdrawal();
		}
	} catch (e: any) {
		setPaymentProcessingStatus(false);

		sendAnalyticsHandler(AnalyticsEventType?.withdrawal_error);

		if (e?.response?.data?.message === 'NO_INVOICE_TRANSACTION') {
			toast((t) => ErrorToast({ t: t, text: 'withdrawal_dont_allowed_not_invoices' }));
		} else if (e?.response?.data?.message === 'WITHDRAW_IN_PROGRESS') {
			toast((t) =>
				ErrorToast({ t: t, text: 'withdrawal_dont_allowed_have_in_progress' })
			);
		} else if (e?.response?.data?.message === 'WITHDRAW_IS_BLOCKED') {
			setModalType(ModalType.withdrawalBlocked);
		} else if (
			e?.response?.data?.message === 'PHONE_NOT_VERIFIED' ||
			e?.response?.data?.message === 'EMAIL_NOT_VERIFIED'
		) {
			!isMobile
				? notVerifiedFunc && notVerifiedFunc()
				: setModalType(ModalType.profileSettings);
		} else if (e?.response?.data?.message === 'INCORRECT_FIELD_VALUE') {
			return;
		} else {
			toast((t) => ErrorToast({ t: t, text: 'withdrawal_dont_allowed' }));
		}

		return;
	}
};

import React from 'react';
import { useLocales } from '@lib/hooks';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { CloseSvg } from '@components/svg';
import Div100vh from 'react-div-100vh';
import s from './style.module.scss';
import { Button } from '@components/common/elements';
import { ButtonType } from '@types';
import { Trans } from 'react-i18next';

export const WithdrawalBlockedModal = () => {
	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'WithdrawalBlockedModal'
	});

	const AuthModalLocalizeText = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const closeModal = () => {
		setModalType(null);
	};

	return (
		<div className={s.wrapper}>
			<Div100vh className={s.inner}>
				<div className={s.header}>
					<div className={s.headerWrapper}>
						<div className={s.headerTitle}>{localizeText('suspicious_activity')}</div>
						<div
							onClick={closeModal}
							className={s.headerClose}
						>
							<CloseSvg />
						</div>
					</div>
				</div>

				<div className={s.content}>
					<div className={s.description}>
						<Trans
							ns='components/common/modals/translation'
							i18nKey={'WithdrawalBlockedModal.suspicious_activity_descr'}
							components={{ 1: <a href='mailto: security@topx.one' /> }}
						/>
					</div>

					<Button
						handle={closeModal}
						rippleAnimation
						className={s.button}
						type={ButtonType.secondary}
						text={AuthModalLocalizeText.localizeText('continue')}
					/>
				</div>
			</Div100vh>
		</div>
	);
};

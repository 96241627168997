import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isNull from 'lodash/isNull';
import { commonUiConfig } from '@config/common-ui';
import { PagePreloader } from '@components/common/page';
import { cookies, getStringQueryFromUrl, removeGamesData, scrollPageToTop } from '@utils';
import { loadMetaData } from './utils';
import { useAsyncState, useMediaQuery } from '@lib/hooks';
import { authSelectors } from '@store/reducers/auth/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import {
	setIsMobile,
	setMobileHeight,
	setIsSidebarDesktopOpened,
	setIsTablet,
	setIsAndroidBanner
} from '@store/reducers/common-ui/dispatchers';
import { PaymentResultsType, SessionStorageParams, UrlQueries } from '@types';
import { use100vh } from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { ANDROID_APP_LINK, isTopXGo } from '@config/global';
import { confirmEmailCode } from '@lib/api/user';
import { SupportWidget } from '../support-widget';
import {
	AnalyticsTests,
	FingerprintComponent,
	LicenseInit,
	OnesignalComponent,
	SocketComponent,
	WebimChat
} from './parts';
import { getAuthToken } from '@lib/browser-storage';
import { SuccessToast } from '@components/common/global-settings';
import { pathsConfig } from '@config/paths';
import {
	setAuthToken,
	setIsSendedConfirm,
	setnNextAttemptResetPassword
} from '@store/reducers/auth/dispathcers';
import { isAndroid } from 'react-device-detect';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';

type MainContentP = {
	children: ReactNode;
};

export const MainContent = ({ children }: MainContentP) => {
	const mobile = useMediaQuery('max-width', commonUiConfig.mediaSmallWidth);
	const tablet = useMediaQuery('max-width', commonUiConfig.mediaTabletWidth);
	const height = use100vh();

	const { isAuthed, isSendedConfirm } = useSelector(authSelectors.authInfo);

	const {
		isFullScreenGaming,
		isSidebarOpened,
		modalType,
		isMobile,
		isUpdateGamesList,
		isApp
	} = useSelector(commonUISelectors.commonUIInfo);

	const [isRebootSocket, setIsRebootSocket] = useState(true);

	const [isLoadedData, setStatusLoadedData] = useAsyncState<boolean>(false);
	const [isPreloader, setIsPreloader] = useAsyncState<boolean>(true);

	useEffect(() => {
		loadMetaData(() => setTimeout(() => setStatusLoadedData(true), 350));
	}, []);

	useEffect(() => {
		const nextAttemptPassword = cookies?.getCookie('nextAttempt');

		if (nextAttemptPassword) {
			if (dayjs(nextAttemptPassword).format() > dayjs().format()) {
				setnNextAttemptResetPassword(nextAttemptPassword);
			} else {
				cookies?.eraseCookie('nextAttempt');
			}
		}
	}, []);

	useEffect(() => {
		if (isUpdateGamesList) {
			removeGamesData();
		}
	}, [isUpdateGamesList]);

	useEffect(() => {
		setIsMobile(mobile);
		setIsTablet(tablet);
		if (height) {
			setMobileHeight(height);
		}
	}, [mobile, tablet, height]);

	useEffect(() => {
		// TODOперенести все состояния модальных окон в commonUI
		if (
			!isNull(modalType) ||
			isFullScreenGaming ||
			(isSidebarOpened && isMobile) ||
			!isLoadedData
		) {
			document.body.style.overflow = 'hidden';

			if (isFullScreenGaming) {
				scrollPageToTop();
			}
		} else {
			// @ts-ignore
			document.body.style.overflow = null;
		}
	}, [modalType, isFullScreenGaming, isSidebarOpened, isLoadedData]);

	useEffect(() => {
		const verifyEmailCodeExternal = getStringQueryFromUrl(UrlQueries.verifyEmailCode);
		const accessTokenExternal =
			getStringQueryFromUrl(UrlQueries.accessToken) ||
			getStringQueryFromUrl(UrlQueries.token);

		if (isAuthed || accessTokenExternal) {
			const token = getAuthToken() || accessTokenExternal;

			if (token) {
				setAuthToken(token);
			}
		}

		if (verifyEmailCodeExternal && !isSendedConfirm) {
			confirmEmailCode(verifyEmailCodeExternal);
			setIsSendedConfirm(true);
		}

		setIsRebootSocket(true);
		setTimeout(() => setIsRebootSocket(false), 100);
	}, [isAuthed]);

	useEffect(() => {
		const apkBanner = sessionStorage?.getItem(SessionStorageParams?.apkBanner);

		setIsAndroidBanner(
			isMobile && isAuthed && !isApp && !apkBanner && isAndroid && !!ANDROID_APP_LINK
		);
	}, [isMobile, isAuthed, isApp]);

	const { ready } = useTranslation(
		[
			'global/translation',
			'components/common/modals/translation',
			'components/common/elements/translation',
			'components/common/game/translation',
			'components/common/page/translation',
			'components/common/sections/translation',
			'components/games/translation',
			'components/providers/translation',
			'components/routes/translation'
		],
		{ useSuspense: false }
	);

	useEffect(() => {
		if (isLoadedData) {
			if (ready) {
				setTimeout(() => setIsPreloader(false), 1500);
			}
		}
	}, [isLoadedData, ready]);

	useEffect(() => {
		const dataFromLocalStorage = window?.localStorage?.getItem('isSidebarDesktopOpened');
		const isSidebarDesktopOpened = dataFromLocalStorage === 'true';

		if (dataFromLocalStorage) {
			setIsSidebarDesktopOpened(isSidebarDesktopOpened);
		}

		const resultPayment = getStringQueryFromUrl(UrlQueries.resultPayment);

		if (resultPayment && resultPayment === PaymentResultsType?.success) {
			toast((t) => (
				<SuccessToast
					t={t}
					text={'success_invoice_wait_please'}
					translated={true}
				/>
			));

			window.history.pushState({}, document.title, '/');
		}
	}, []);

	useEffect(() => {
		const popup = window?.parent;

		popup?.postMessage('redirect', '*');
	}, []);

	return (
		<>
			{pathsConfig?.sign_up_1?.link &&
				!window?.location?.pathname?.includes(pathsConfig?.sign_up_1?.link) && (
					<PagePreloader isActive={isPreloader} />
				)}

			{isAuthed && (
				<>
					<OnesignalComponent />
					<AnalyticsTests />
					<FingerprintComponent />
				</>
			)}

			{isTopXGo && <LicenseInit />}

			{!isRebootSocket && <SocketComponent />}

			<SupportWidget />
			<WebimChat />

			{!isLoadedData ? null : children}
		</>
	);
};

import React from 'react';
import { useLocales, useUrlLang } from '@lib/hooks';
import { useSelector } from 'react-redux';
import { renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { userSelectors } from '@store/reducers/user/selectors';
import { useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import s from './style.module.scss';
import { AnalyticsEventType, ButtonType } from '@types';
import { Button } from '@components/common/elements';
import { getImageByURL } from '@utils/getImageByURL';

export const LevelsBlock = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'CashbackBonuses'
	});

	const navigate = useNavigate();

	const { langUrlPrefix } = useUrlLang();

	const { currency } = useSelector(userSelectors.userInfo);

	return (
		<div className={s.container}>
			<div
				onClick={() => {
					navigate(langUrlPrefix + pathsConfig?.levels?.link);
				}}
				className={s.inner}
			>
				<div className={s.text_block}>
					<h1 className={s.text_block_title}>{localizeText('levels_promo_title')}</h1>

					<div className={s.text_block_descr}>
						{localizeText('levels_promo_descr', {
							curr: renderCurrencySymbol(currency?.toLowerCase())
						})}
					</div>

					<Button
						rippleAnimation
						className={s.contactBtn}
						type={ButtonType?.primary}
						handle={() => {
							navigate(langUrlPrefix + pathsConfig?.referral?.link);
							sendAnalyticsHandler(AnalyticsEventType?.new_cashback_learnmore);
						}}
					>
						{localizeText('find_out_more')}
					</Button>
				</div>

				<img
					className={s.img}
					src={getImageByURL('../../../../../../assets/img/level-bonus/title.webp')}
					alt='Levels'
				/>
			</div>
		</div>
	);
};

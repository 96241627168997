import React from 'react';
import { Languages, LocalizeText } from '@types';
import { Button } from '@components/common/elements';
import { CommentSvg } from '@components/svg';
import { openCarrotChat } from '@utils';
import s from './style.module.scss';

type BottomP = {
	currentLangCode: Languages;
	localizeText: LocalizeText;
};

export const Bottom = (props: BottomP) => {
	const { localizeText } = props;

	return (
		<div className={s.wrapper}>
			<div className={s.support}>
				<span className={s.support_title}>{localizeText('support_24_7')}</span>
				<div className={s.support_btns}>
					<Button
						rippleAnimation
						className={s.btn}
						handle={openCarrotChat}
					>
						<CommentSvg />
						{localizeText('support')}
					</Button>
				</div>
			</div>
		</div>
	);
};

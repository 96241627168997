import React from 'react';
import { useLocales, useUrlLang } from '@lib/hooks';
import { AnalyticsEventType } from '@types';
import { CloseSvg } from '@components/svg';
import { sendAnalyticsHandler } from '@utils';
import { useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';

import s from './style.module.scss';

export const HeaderSignUp = () => {
	const { langUrlPrefix } = useUrlLang();
	const navigate = useNavigate();

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const closeAuthModal = () => {
		sendAnalyticsHandler(AnalyticsEventType?.close_back, { close_back: 'signup' });

		navigate(langUrlPrefix + pathsConfig?.home?.link);
	};

	return (
		<div className={s.header}>
			<div className={s.headerWrapper}>
				<div className={s.headerTitle}>{localizeText('sign_up')}</div>
				<div
					onClick={closeAuthModal}
					className={s.headerClose}
				>
					<CloseSvg />
				</div>
			</div>
		</div>
	);
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import isNull from 'lodash/isNull';
import { MobileSlider } from './parts';
import {
	CashbackBanner,
	MediaBonusBanner,
	ReferralBanner,
	DepositBanner,
	LevelsBanner,
	BGamingBanner
} from './banners';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { userSelectors } from '@store/reducers/user/selectors';
import { authSelectors } from '@store/reducers/auth/selectors';
import { isTapX } from '@config/global';
import s from './style.module.scss';

export const ExtraOffers = () => {
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { successInvoiceCount } = useSelector(userSelectors.userInfo);

	const defaultSlides = isTapX
		? [<DepositBanner key={1} />]
		: [
				<BGamingBanner key={0} />,
				<CashbackBanner key={2} />,
				<MediaBonusBanner key={3} />,
				<ReferralBanner key={4} />,
				<LevelsBanner key={5} />
			];

	// TODOэта логика уже есть в карточке игры, надо совмстить
	const sectionRef = useRef<HTMLDivElement>(null);
	const [sectionHeight, setSectionHeight] = useState<number>(0);
	const [sectionWidth, setSectionWidth] = useState<number>(0);
	const [mobileSlides, setMobileSlides] = useState(defaultSlides);

	useEffect(() => {
		if (!isNull(sectionRef.current)) {
			setSectionWidth(sectionRef.current.offsetWidth);
		}
	}, [sectionRef.current?.offsetWidth]);
	useEffect(() => {
		if (!isTapX) {
			if (sectionWidth !== 0) {
				// TODOлучше в конфиг
				const sidesRatio = isMobile ? 0.57 : 0.35; // height / width
				const adjustedHeight = Math.trunc(sectionWidth * sidesRatio);

				setSectionHeight(adjustedHeight);
			}
		}
	}, [sectionWidth]);

	useEffect(() => {
		if (!isTapX) {
			if (successInvoiceCount !== 0 && isAuthed) {
				setMobileSlides(defaultSlides);
			} else {
				if (mobileSlides?.length === 5) {
					const slides = mobileSlides;

					slides.splice(1, 0, <DepositBanner key={1} />);

					setMobileSlides(slides);
				}
			}
		}
	}, [successInvoiceCount, isAuthed]);

	return (
		<section
			ref={sectionRef}
			className={s.wrapper}
		>
			<MobileSlider
				sectionHeight={sectionHeight}
				sectionWidth={sectionWidth}
				slides={mobileSlides}
			/>
		</section>
	);
};

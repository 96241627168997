import React, { useState } from 'react';
import { TelegramLinkBot } from '@config/global';
import {
	AngleBottom,
	AngleTop,
	CancelSvg,
	CommentNewOnlineSvg,
	TelegramNewSvg
} from '@components/svg';
import { CommentNewSvg } from '@components/svg';
import { openCarrotChat, sendAnalyticsHandler } from '@utils';
import s from './style.module.scss';
import cn from 'classnames';
import { AnalyticsEventType } from '@types';

export const SupportWidget = () => {
	const [isOpenedSupport, setIsOpenedSupport] = useState(false);

	return (
		<>
			<div
				className={cn(s.supportWrapper, { [s.hovered]: isOpenedSupport })}
				onClick={() => setIsOpenedSupport(!isOpenedSupport)}
			>
				<AngleTop className={s.angleTop} />
				<div className={s.imageWrapper}>
					{isOpenedSupport ? <CancelSvg /> : <CommentNewSvg />}
				</div>
				<AngleBottom className={s.angleBottom} />
			</div>
			{isOpenedSupport && (
				<div className={s.modalWrapper}>
					<button
						onClick={() => {
							setIsOpenedSupport(!isOpenedSupport);
							openCarrotChat();

							sendAnalyticsHandler(AnalyticsEventType?.click_support_choose_online_support);
						}}
					>
						<CommentNewOnlineSvg />
						<span>Online Support</span>
					</button>
					<div className={s.dividerLine} />
					<button
						onClick={() => {
							setIsOpenedSupport(!isOpenedSupport);
							window.open(TelegramLinkBot, '_blank');

							sendAnalyticsHandler(AnalyticsEventType?.click_support_choose_tg);
						}}
					>
						<TelegramNewSvg />
						<span>Telegram</span>
					</button>
				</div>
			)}
		</>
	);
};

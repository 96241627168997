import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { getProviders } from '@lib/api/game-provider';
import { useSelector } from 'react-redux';
import { gameProviderSelectors } from '@store/reducers/game-provider/selectors';
import s from './style.module.scss';
import cn from 'classnames';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';

//

export const Providers = () => {
	const { providers } = useSelector(gameProviderSelectors.gameProviderInfo);
	const { isMobile, isUpdateGameProviders } = useSelector(commonUISelectors.commonUIInfo);

	useEffect(() => {
		providers?.length === 0 && getProviders({ limit: 100, page: 1 }, false);
	}, []);

	useEffect(() => {
		if (isUpdateGameProviders) {
			getProviders({ limit: 100, page: 1 }, false);
		}
	}, [isUpdateGameProviders]);

	return (
		<div>
			<div className={s.slider}>
				{providers?.length &&
					providers?.map((provider, i) => {
						const countOfProviders = isMobile ? 5 : 10;

						if (i < countOfProviders) {
							return (
								<div
									key={i}
									className={s.item}
								>
									{!isEmpty(provider.image) ? (
										<img
											src={provider.image}
											alt={provider.name}
										/>
									) : (
										<h3 className={s.item_name}>{provider.name}</h3>
									)}
								</div>
							);
						}

						return null;
					})}
			</div>

			{isMobile && <div className={s.footerDivider} />}

			{isMobile && providers?.length > 5 && (
				<div className={cn(s.slider, s.bottom)}>
					{providers?.length > 5 &&
						providers?.map((provider, i) => {
							if (i > 4 && i < 10) {
								return (
									<div
										key={i}
										className={s.item}
									>
										{!isEmpty(provider.image) ? (
											<img
												src={provider.image}
												alt={provider.name}
											/>
										) : (
											<h3 className={s.item_name}>{provider.name}</h3>
										)}
									</div>
								);
							}

							return null;
						})}
				</div>
			)}
		</div>
	);
};

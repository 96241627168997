import React from 'react';
import { useLocales } from '@lib/hooks';
import { Button } from '@components/common/elements';
import { ButtonType, Currencies, ModalType } from '@types';
import { ListOutlined } from '@components/svg';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { userSelectors } from '@store/reducers/user/selectors';
import { renderCurrencySymbol } from '@utils';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import s from './style.module.scss';
import { getImageByURL } from '@utils/getImageByURL';

export const AccrualRules = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Referral'
	});

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { currency } = useSelector(userSelectors.userInfo);

	const infoModalHandler = () => {
		setModalType(ModalType?.referralAwards);
	};

	const image =
		currency === Currencies?.inr
			? getImageByURL('../../../../../../assets/img/referral/chat.webp')
			: getImageByURL('../../../../../../assets/img/referral/chat_bdt.webp');

	const renderImage = () => (
		<img
			className={s.img}
			src={image}
			alt='Accrual Rules'
		/>
	);

	return (
		<div className={s.container}>
			<div className={s.text_block}>
				<span className={s.text_block_slogan}>
					{localizeText('accrual_rules_slogan')}
				</span>
				<h1 className={s.text_block_title}>
					{localizeText('accrual_rules_title', {
						curr: renderCurrencySymbol(currency) || '৳'
					})}
				</h1>

				{isMobile && renderImage()}
				<div className={s.text_block_descr}>
					{localizeText('accrual_rules_descr', {
						curr: renderCurrencySymbol(currency) || '৳'
					})}
				</div>

				<Button
					rippleAnimation
					className={s.contactBtn}
					type={ButtonType?.secondary}
					handle={infoModalHandler}
				>
					<ListOutlined />
					{localizeText('accrual_rules_open_table')}
				</Button>
			</div>
			{!isMobile && renderImage()}
		</div>
	);
};

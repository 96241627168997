import React, { useRef } from 'react';
import s from './style.module.scss';
import cn from 'classnames';
import { TopXHeaderLogo } from '@components/svg';
import useShowSidebarButton from '@lib/hooks/useShowSidebarButton';
import { useSelector } from 'react-redux';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { Hamburger } from '@components/common/page/header/parts';
import { setIsSidebarOpened } from '@store/reducers/common-ui/dispatchers';

export const SidebarHeader = () => {
	const { isSidebarOpened, isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const buttonRef = useRef<HTMLButtonElement | null>(null);

	useShowSidebarButton({
		isSidebarOpened,
		buttonRef,
		element: 'non-auth-sidebar'
	});

	return (
		<>
			<div className={cn(s.wrapperHeader)}>
				<TopXHeaderLogo className={s.logo} />
				{isMobile && (
					<button
						ref={buttonRef}
						onClick={(e) => {
							e.stopPropagation();
							setIsSidebarOpened(!isSidebarOpened);
						}}
						className={cn(s.menuBtn)}
					>
						<Hamburger />
					</button>
				)}
			</div>
		</>
	);
};

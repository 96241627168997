import React from 'react';
import s from './style.module.scss';
import cn from 'classnames';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';

export const Hamburger = () => {
	const { isSidebarOpened } = useSelector(commonUISelectors.commonUIInfo);

	return (
		<div className={cn(s.burger, { [s.open]: isSidebarOpened })}>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
};

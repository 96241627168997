import React from 'react';
import { useSelector } from 'react-redux';
import { useLocales } from '@lib/hooks';
import { CloseSvg } from '@components/svg';
import { userSelectors } from '@store/reducers/user/selectors';
import { formatNumber, renderCurrencySymbol } from '@utils';
import { Trans } from 'react-i18next';
import { authSelectors } from '@store/reducers/auth/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import cn from 'classnames';
import s from './style.module.scss';
import convertToUsers from '@utils/convertToUsers';
import { getImageByURL } from '@utils/getImageByURL';

export const ReferralLevelAwardsModal = () => {
	const { userLevels, currency } = useSelector(userSelectors.userInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'ReferralLevelAwardsModal'
	});

	const levels = [...userLevels];

	return (
		<div
			className={cn(s.wrapper, {
				[s.nonAuthed]: !isAuthed,
				[s.desktop]: !isMobile
			})}
		>
			<div className={s.header}>
				<div className={s.headerWrapper}>
					<div className={s.headerTitle}>{localizeText('rules_reff')}</div>
					<div
						onClick={() => setModalType(null)}
						className={s.headerClose}
					>
						<CloseSvg />
					</div>
				</div>
			</div>

			<p className={s.description}>{localizeText('referral_description')}</p>

			<div className={cn(s.levels, { [s.desktop]: !isMobile })}>
				<h2 className={s.title}>{localizeText('levels_table')}</h2>
				{levels
					?.sort((a, b) => a?.level - b?.level)
					?.map((level, i) => (
						<div
							key={i}
							className={s.item}
						>
							<div className={s.leftBlock}>
								{level && level?.level < 21 && (
									<img
										className={s.user_level}
										src={getImageByURL(
											`../../../../../../../assets/img/levels/level_${level?.level}.webp`
										)}
									/>
								)}
								<div className={s.leftBlock_text}>
									<span className={s.cell}>{level.name}</span>
									<span className={s.cell}>
										<Trans
											ns='components/common/modals/translation'
											i18nKey={'ReferralLevelAwardsModal.need_play'}
											values={{
												value: `${renderCurrencySymbol(currency?.toLowerCase())} ${formatNumber(
													convertToUsers(level.wager)
												)}`
											}}
										/>
									</span>
								</div>
							</div>

							<div className={s.rightBlock}>
								<span>
									{renderCurrencySymbol(currency?.toLowerCase())}{' '}
									{formatNumber(convertToUsers(level.referralBonus))}
								</span>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

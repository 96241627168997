import React, { useEffect } from 'react';
import { useLocales, useUrlLang } from '@lib/hooks';
import { CloseSvg, FirstIcon, SecondIcon } from '@components/svg';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/common/elements/button';
import { ButtonType } from '@types';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { useSelector } from 'react-redux';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { Trans } from 'react-i18next';
import { formatNumber, renderCurrencySymbol, getImageByURL } from '@utils';
import { userSelectors } from '@store/reducers/user/selectors';
import { pathsConfig } from '@config/paths';
import s from './style.module.scss';
import cn from 'classnames';
import Div100vh from 'react-div-100vh';

enum BonusTypes {
	CASHBACK = 'cashback',
	PROMO = 'promo'
}

export const BonusMoneyTooltipModal = () => {
	const { bonusBalances } = useSelector(bonusSelectors.bonusInfo);
	const { currency, bonusBalanceEnableValue } = useSelector(userSelectors.userInfo);
	const navigate = useNavigate();

	const { type } = bonusBalances[0];
	const currencySymbol = renderCurrencySymbol(currency?.toLowerCase());

	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/common/page',
		node: 'Sidebar'
	});

	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, []);

	const renderTitle = (type: string) => {
		if (type === BonusTypes.CASHBACK) {
			return localizeText('cashback');
		}

		return localizeText('bonus_balance');
	};

	const renderDescription = (type: string) => {
		if (type === BonusTypes.CASHBACK) {
			return localizeText('cashback_descr');
		}

		return localizeText('bonus_descr');
	};

	const renderImage = (type: string) => {
		if (type === BonusTypes.CASHBACK) {
			return getImageByURL('../../../../../assets/img/common/RedInfoBonus.webp');
		}

		return getImageByURL('../../../../../assets/img/common/GreenInfoBonus.webp');
	};

	const renderBtnText = (type: string) => {
		if (type === BonusTypes.CASHBACK) {
			return localizeText('about_cashback');
		}

		return localizeText('view_all_bonus');
	};

	const bonusesModalHandler = (type?: string): void => {
		setModalType(null);

		if (type === BonusTypes.CASHBACK) {
			navigate(langUrlPrefix + pathsConfig.cashback_bonus.link);
		} else {
			navigate(langUrlPrefix + pathsConfig.bonus.link);
		}
	};

	const closeModalOutside = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setModalType(null);
		}
	};

	if (!bonusBalances) {
		return null;
	}

	return (
		<Div100vh
			onClick={(e) => closeModalOutside(e)}
			className={cn(s.modalWrapper)}
		>
			<div className={s.inner}>
				<button
					onClick={() => setModalType(null)}
					className={s.closeIcon}
				>
					<CloseSvg />
				</button>
				<div className={s.bonusModalWrapper}>
					<img
						className={s.bonusModalImg}
						src={renderImage(type)}
					/>
				</div>
				<div className={s.main_info}>
					<span>{renderTitle(type)}</span>
					<span>
						<Trans
							ns='components/common/page/translation'
							i18nKey={renderDescription(type)}
							values={{
								sum: formatNumber(bonusBalanceEnableValue),
								cur: currencySymbol
							}}
							components={{ 1: <b className={s.red} /> }}
						/>
					</span>
				</div>

				<div className={cn(s.win_money, s.win_money_border)}>
					<FirstIcon />

					<div className={s.win_money_title}>{localizeText('bets_money')}</div>

					<div className={s.win_money_descr}>{localizeText('bets_amount')}</div>
				</div>

				<div className={cn(s.win_money)}>
					<SecondIcon />

					<div className={s.win_money_title}>{localizeText('bonus_time')}</div>

					<div className={s.win_money_descr}>{localizeText('play_bonus')}</div>
				</div>

				<div className={s.bottomButtonsWrapper}>
					<Button
						handle={() => bonusesModalHandler(type)}
						rippleAnimation
						type={ButtonType.default}
						text={renderBtnText(type)}
					/>
					<Button
						handle={() => setModalType(null)}
						rippleAnimation
						type={ButtonType.secondary}
						text={localizeText('close_button')}
					/>
				</div>
			</div>
		</Div100vh>
	);
};

import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { CloseSvg, ShevronLevels } from '@components/svg';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { ChangePasswordScreen, CodeScreen } from './parts';
import { useSelector } from 'react-redux';
import { GoHome } from '@components/common/elements';
import { userSelectors } from '@store/reducers/user/selectors';
import { UserLevelObj, ModalType } from '@types';
import { Helmet } from 'react-helmet';
import Div100vh from 'react-div-100vh';
import ProfileBody from './Profile';
import s from './style.module.scss';
import cn from 'classnames';
import { getImageByURL } from '@utils/getImageByURL';

export const Profile = () => {
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { userLevels, userLevelId } = useSelector(userSelectors.userInfo);

	const { localizeText } = useLocales({
		path: 'global',
		node: 'routes'
	});

	const SidebarLocalize = useLocales({
		path: 'components/common/page',
		node: 'Sidebar'
	});

	const closeModal = () => {
		setTimeout(() => {
			setModalType(null);
		}, 200);
	};

	const [isCodeModal, setIsCodeModal] = useState(false);
	const [isPasswordModal, setIsPasswordModal] = useState(false);
	const [codeModalType, setCodeModalType] = useState<'phone' | 'email' | ''>('');

	const [currentLevel, setCurrentLevel] = useState<UserLevelObj | null>(null);

	const codeCloseModalHandler = () => {
		setIsCodeModal(false);
		setCodeModalType('');
	};

	const passwordCloseModalHandler = () => {
		setIsPasswordModal(false);
	};

	const codeOpenModalHandler = (type: 'phone' | 'email') => {
		setIsCodeModal(true);
		setCodeModalType(type);
	};

	const passwordOpenModalHandler = () => {
		setIsPasswordModal(true);
	};

	const openLevelsTableHandler = () => {
		setModalType(ModalType?.levelsTable);
	};

	useEffect(() => {
		if (userLevelId && userLevels) {
			const level = userLevels?.find((e) => e?.id === userLevelId);

			level && setCurrentLevel(level);
		}
	}, [userLevelId, userLevels]);

	const Container = isMobile
		? Div100vh
		: (
				props: JSX.IntrinsicAttributes &
					React.ClassAttributes<HTMLDivElement> &
					React.HTMLAttributes<HTMLDivElement>
			) => <div {...props}></div>;

	return (
		<>
			<Helmet>
				<link
					rel='canonical'
					href={window?.location?.href}
				/>
			</Helmet>
			<div className={s.wrapperProfile}>
				{!isMobile && <GoHome />}
				<div className={s.wrapper}>
					<div
						className={cn(s.bg, {
							[s.opened]: (codeModalType && isCodeModal) || isPasswordModal
						})}
					/>
					<Container className={s.inner}>
						<div className={s.header}>
							<div className={s.headerWrapper}>
								<div className={s.headerTitle}>{localizeText('settings.title')}</div>
								{isMobile && (
									<div
										onClick={closeModal}
										className={s.headerClose}
									>
										<CloseSvg />
									</div>
								)}
							</div>
						</div>

						<ProfileBody
							passwordOpenModalHandler={passwordOpenModalHandler}
							codeOpenModalHandler={codeOpenModalHandler}
						/>
					</Container>

					{!isMobile && (
						<div className={s.desktopLogoutBlock}>
							<div className={s.user}>
								{currentLevel && currentLevel?.level && currentLevel?.level < 21 && (
									<img
										className={s.user_level}
										src={getImageByURL(
											`../../../../../../../assets/img/levels/level_${currentLevel?.level}.webp`
										)}
									/>
								)}
								<div className={s.user_info}>
									<span className={s.user_info_name}>{currentLevel?.name}</span>
									<button
										onClick={openLevelsTableHandler}
										className={s.user_info_settings}
									>
										<span>{SidebarLocalize.localizeText('all_levels')}</span>
										<ShevronLevels />
									</button>
								</div>
							</div>
							<div className={s.line} />
						</div>
					)}
				</div>

				<CodeScreen
					codeModalHandler={codeCloseModalHandler}
					isCodeModal={isCodeModal}
					codeModalType={codeModalType}
				/>

				<ChangePasswordScreen
					passwordModalHandler={passwordCloseModalHandler}
					isPasswordModal={isPasswordModal}
				/>
			</div>
		</>
	);
};

interface IApiConfig {
	events: {
		user: {
			current: string;
			users: string
		};
	};
}

export const apiConfig: IApiConfig = {
	events: {
		user: {
			current: '/current-user',
			users: '/users'
		}
	}
};

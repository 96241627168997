import React from 'react';
import { Cashback, FreeMoney, InviteFriend, RegisterBonus } from './parts';
import s from './style.module.scss';
import cn from 'classnames';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { AnalyticsTestsNameE, SocialBonusTaskType } from '@types';
import { userSelectors } from '@store/reducers/user/selectors';
import { RegisterBonusSidebar } from './parts/register-bonus-sidebar';
import { valueOfYandexMetrikaTest } from '@utils';

enum YandexMetrikaTestEnum {
	control = 'control',
	not_visible = 'not_visible'
}

type PropsType = {
	desktopMenu?: boolean;
	goFunc?: () => void;
	isMenu?: boolean;
	isSidebar?: boolean;
};

export const PromoCards = (props: PropsType) => {
	const yandexMetricatestValue = valueOfYandexMetrikaTest(
		AnalyticsTestsNameE?.promoCardsTest
	);

	const { desktopMenu, goFunc, isMenu, isSidebar } = props;

	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { successInvoiceCount } = useSelector(userSelectors.userInfo);
	const { socialBonusTasks } = useSelector(bonusSelectors.bonusInfo);

	const findTelegramBonusState = socialBonusTasks?.find(
		(e) => e?.task?.type === SocialBonusTaskType?.telegram_ref_bot
	);

	if (YandexMetrikaTestEnum?.not_visible === yandexMetricatestValue) {
		return null;
	}

	return (
		<div
			className={cn(s.container, {
				[s.desktopMenu]: desktopMenu,
				[s.nonAuthed]: !isAuthed || successInvoiceCount < 1,
				[s.hideOverflow]: isAuthed && successInvoiceCount >= 5
			})}
		>
			{isAuthed && successInvoiceCount >= 5 ? (
				<>
					<Cashback
						isMenu={isMenu}
						goFunc={goFunc}
					/>

					{!findTelegramBonusState?.isDone && (
						<FreeMoney
							isMenu={isMenu}
							goFunc={goFunc}
						/>
					)}

					{findTelegramBonusState?.isDone && (
						<InviteFriend
							isMenu={isMenu}
							goFunc={goFunc}
							withTg={!findTelegramBonusState?.isDone}
						/>
					)}
				</>
			) : isSidebar ? (
				<RegisterBonusSidebar
					isMenu={isMenu}
					goFunc={goFunc}
				/>
			) : (
				<RegisterBonus
					isMenu={isMenu}
					goFunc={goFunc}
				/>
			)}
		</div>
	);
};

import React from 'react';
import { useLocales } from '@lib/hooks';
import { Button } from '@components/common/elements';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import s from './style.module.scss';
import dayjs from 'dayjs';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';

type PropsType = {
	passwordOpenModalHandler: () => void;
};

const PasswordBlock = (props: PropsType) => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Profile'
	});

	const { passwordOpenModalHandler } = props;
	const { passwordChangeAt } = useSelector(userSelectors.userInfo);

	return (
		<div className={s.block}>
			<div className={s.infoBlock}>
				<span>{localizeText('password')}</span>
				{passwordChangeAt && (
					<span>
						<Trans
							ns='components/routes/translation'
							i18nKey={'Profile.change_data'}
							values={{
								date: dayjs(passwordChangeAt).format('DD MMMM YYYY')
							}}
						/>
					</span>
				)}
			</div>

			<Button
				rippleAnimation
				className={s.button}
				handle={() => {
					passwordOpenModalHandler();
					sendAnalyticsHandler(AnalyticsEventType?.profile_edit_password);
				}}
				text={localizeText('change_password')}
			/>
		</div>
	);
};

export default PasswordBlock;

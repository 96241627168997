import React, { useState } from 'react';
import { useLocales } from '@lib/hooks';
import { Trans } from 'react-i18next';
import { Button } from '@components/common/elements';
import { AnalyticsEventType, ButtonType, Currencies, ModalType } from '@types';
import { PlayIcon, TelegramSvg } from '@components/svg';
import { userSelectors } from '@store/reducers/user/selectors';
import { renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { VideoBlock } from './parts/video';
import s from './style.module.scss';
import { getImageByURL } from '@utils/getImageByURL';

export const TitleBlock = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'TelegramBonuses'
	});

	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { currency, telegramChannelBotLink } = useSelector(userSelectors.userInfo);

	const [videoOpen, setVideoOpen] = useState(false);

	const openVideoHandler = () => {
		setVideoOpen(!videoOpen);
	};

	const videoUrl =
		currency === Currencies?.inr
			? 'https://www.youtube.com/embed/Lza9WvvzMhU?si=XH6ExW0sX2zJ1ztA'
			: 'https://www.youtube.com/embed/H7V4h_8nQcY?si=jAE4e6cTL02a71tl';

	return (
		<div className={s.container}>
			<div className={s.text_block}>
				<h1 className={s.text_block_title}>
					<Trans
						ns='components/routes/translation'
						i18nKey={
							isAuthed
								? 'TelegramBonuses.telegram_bonuses_title'
								: 'TelegramBonuses.telegram_bonuses_title_nonAuthed'
						}
						values={{ curr: renderCurrencySymbol(currency) || '৳' }}
						components={{ b: <b /> }}
					/>
				</h1>
				<div className={s.text_block_descr}>
					{localizeText('telegram_bonuses_description')}
				</div>

				{isMobile && (
					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/telegram-bonus/title.webp')}
						alt='Found an error?'
					/>
				)}

				<Button
					rippleAnimation
					className={s.contactBtn}
					type={ButtonType?.primary}
					handle={() => {
						isAuthed
							? window.open(telegramChannelBotLink, '_blank')
							: setModalType(ModalType.auth);

						if (!isAuthed) {
							sendAnalyticsHandler(AnalyticsEventType?.tg_click_registration);
						} else {
							sendAnalyticsHandler(AnalyticsEventType?.tg_click_go_to_tg);
						}
					}}
				>
					{isAuthed ? (
						<>
							<TelegramSvg />
							{localizeText('click_and_get', {
								curr: renderCurrencySymbol(currency) || '৳'
							})}
						</>
					) : (
						localizeText('register_btn')
					)}
				</Button>

				<Button
					rippleAnimation
					className={s.videoIsnructionBtn}
					type={ButtonType?.secondary}
					handle={() => {
						openVideoHandler();
						sendAnalyticsHandler(AnalyticsEventType?.tg_click_video);
					}}
				>
					<PlayIcon />
					{localizeText('video_instruction')}
				</Button>
			</div>
			{!isMobile && (
				<img
					className={s.img}
					src={getImageByURL('../../../../../../assets/img/telegram-bonus/title.webp')}
					alt='Found an error?'
				/>
			)}

			{videoOpen && (
				<VideoBlock
					openVideoHandler={openVideoHandler}
					videoUrl={videoUrl}
				/>
			)}
		</div>
	);
};

import React from 'react';
import Div100vh from 'react-div-100vh';
import convertToUsers from '@utils/convertToUsers';
import { useSelector } from 'react-redux';
import { useLocales } from '@lib/hooks';
import { formatNumber, renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { Trans } from 'react-i18next';
import { authSelectors } from '@store/reducers/auth/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { CloseSvg } from '@components/svg';
import { userSelectors } from '@store/reducers/user/selectors';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { ProgressBar } from '@components/common/elements/progress-bar';
import s from './style.module.scss';
import cn from 'classnames';
import { AnalyticsEventType } from '@types';
import { getImageByURL } from '@utils/getImageByURL';

export const LevelAwardsModal = () => {
	const { userLevels, currency, userLevelId, userLevelSum } = useSelector(
		userSelectors.userInfo
	);

	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { allRMWBonus } = useSelector(bonusSelectors.bonusInfo);

	const closeModalHanler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.level_click_list_close);
		setModalType(null);
	};

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'ReferralLevelAwardsModal'
	});

	const levels = [...userLevels];

	const currentLevel = userLevels?.find((e) => e?.id === userLevelId);
	const nextLevel =
		currentLevel && userLevels?.find((e) => e?.level === currentLevel?.level + 1);

	return (
		<Div100vh className={cn(s.content, { [s.nonAuthed]: !isAuthed })}>
			<div
				className={cn(s.wrapper, s.opened, {
					[s.nonAuthed]: !isAuthed
				})}
			>
				<div className={s.header}>
					<div className={s.headerWrapper}>
						<div className={s.headerTitle}>{localizeText('your_level')}</div>
						<div
							onClick={closeModalHanler}
							className={s.headerClose}
						>
							<CloseSvg />
						</div>
					</div>
				</div>

				{currentLevel && (
					<div className={s.current_level_block}>
						<img
							className={s.current_level_block_img}
							src={getImageByURL(
								`../../../../../../../assets/img/levels/level_${currentLevel?.level}.webp`
							)}
						/>

						<div className={s.current_level_block_info}>
							<span>{currentLevel.name}</span>
							<span>
								<Trans
									ns='components/common/modals/translation'
									i18nKey={'ReferralLevelAwardsModal.current_award_sum'}
									values={{
										value: `${renderCurrencySymbol(currency?.toLowerCase())} ${formatNumber(
											convertToUsers(userLevelSum)
										)}`
									}}
								/>
							</span>
						</div>
					</div>
				)}

				<div className={s.levels}>
					{levels
						?.sort((a, b) => a?.level - b?.level)
						?.map((level) => {
							const bonuses = allRMWBonus?.find(
								(bonus) =>
									bonus?.config?.bonus &&
									bonus?.config?.freespin &&
									bonus?.config?.userLevel === level?.level
							);

							const isPrevious = currentLevel && currentLevel?.level > level?.level;

							const isCurrent = currentLevel && currentLevel?.level === level?.level;

							return (
								<div
									className={cn(s.itemWrapper, { [s.current]: isCurrent })}
									key={level?.id}
								>
									<div
										className={cn(s.item, {
											[s.current]: isCurrent
										})}
									>
										{level?.cashbackPercentage ? (
											<div className={cn(s.сashback, { [s.current]: isCurrent })}>
												<b>{level?.cashbackPercentage}%</b>{' '}
												<span>{localizeText('сashback')}</span>
											</div>
										) : null}
										<div className={s.item_top}>
											{level && level?.level < 21 && (
												<img
													className={s.levelImg}
													src={getImageByURL(
														`../../../../../../../assets/img/levels/level_${level?.level}.webp`
													)}
													alt={`Level ${level?.level}`}
												/>
											)}

											<div className={s.levelInfo}>
												<span className={s.levelInfo_name}>{level?.name}</span>
												{userLevelSum !== undefined && isCurrent ? (
													<ProgressBar
														containerClassName={s.progressBarContainer}
														currentValue={userLevelSum - level?.wager}
														maxValue={
															nextLevel
																? nextLevel?.wager - level?.wager
																: userLevelSum - level?.wager
														}
														customBg='rgba(231, 236, 245, 0.06)'
													/>
												) : null}
												<span
													className={cn(s.levelInfo_won, {
														[s.active]: isPrevious || isCurrent
													})}
												>
													{`${localizeText(isPrevious ? 'won' : isCurrent ? 'win' : 'to_win')} ` +
														`${renderCurrencySymbol(currency) || '৳'} ` +
														`${isCurrent ? formatNumber(convertToUsers(nextLevel ? userLevelSum : currentLevel?.wager)) : formatNumber(convertToUsers(level?.wager))}`}
													{isCurrent && userLevelSum !== undefined ? (
														<b>{` / ${renderCurrencySymbol(currency) || '৳'} ${formatNumber(convertToUsers(nextLevel ? nextLevel?.wager : currentLevel?.wager))}`}</b>
													) : null}
												</span>

												{bonuses && (
													<div
														className={cn(s.levelInfo_bottom, {
															[s.active]: isPrevious || isCurrent
														})}
													>
														{bonuses?.config?.bonus?.amount ? (
															<div className={s.bonusBlock}>
																{renderCurrencySymbol(currency) || '৳'}{' '}
																{formatNumber(
																	convertToUsers(bonuses?.config?.bonus?.amount)
																)}
																<img
																	className={s.coinImg}
																	src={getImageByURL(
																		'../../../../../../assets/img/common/coin_without_space.webp'
																	)}
																/>
															</div>
														) : null}

														{bonuses?.config?.bonus?.amount &&
														bonuses?.config?.freespin?.amount ? (
															<div className={s.plusBlock}>+</div>
														) : null}

														{bonuses?.config?.freespin?.amount ? (
															<div className={s.freespinBlock}>
																{bonuses?.config?.freespin?.amount} FS
																<img
																	className={s.fsImg}
																	src={getImageByURL(
																		'../../../../../../assets/img/common/free_spins.webp'
																	)}
																/>
															</div>
														) : null}
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</Div100vh>
	);
};

import {
	FINGERPRINT_API_KEY,
	FINGERPRINT_ENDPOINT,
	FINGERPRINT_SCRIPT_URL_PATTERN
} from '@config/global';
import {
	FpjsProvider,
	defaultEndpoint,
	defaultScriptUrlPattern
} from '@fingerprintjs/fingerprintjs-pro-react';
import React, { useEffect } from 'react';
import { FingerprintBlock } from './parts';
import { getFpjsRequestId, getFpjsVisitorId } from '@lib/browser-storage';
import { setFingerprintIdsHandler } from '@lib/api/auth';

export const FingerprintComponent = () => {
	const visitorId = getFpjsVisitorId();
	const requestId = getFpjsRequestId();

	useEffect(() => {
		if (visitorId && requestId) {
			setFingerprintIdsHandler({ visitorId, requestId });
		}
	}, [visitorId, requestId]);

	if (visitorId && requestId) {
		return null;
	}

	return (
		<FpjsProvider
			loadOptions={{
				apiKey: FINGERPRINT_API_KEY,
				endpoint: [FINGERPRINT_ENDPOINT, defaultEndpoint],
				scriptUrlPattern: [FINGERPRINT_SCRIPT_URL_PATTERN, defaultScriptUrlPattern],
				region: 'eu'
			}}
		>
			<FingerprintBlock />
		</FpjsProvider>
	);
};

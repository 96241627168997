import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { Button } from '@components/common/elements';
import { Notifications, NotificationsGranted } from '@components/svg';
import s from './style.module.scss';
import { OneSignalNotificationsE } from '@types';
import OneSignal from 'react-onesignal';
import { DefaultToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';
import { setOnesignalExternalIdHandler } from '@lib/api/auth';

const PasswordBlock = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Profile'
	});

	const [permission, setPermissions] = useState(false);

	useEffect(() => {
		setPermissions(
			OneSignal?.Notifications?.permissionNative === OneSignalNotificationsE?.granted
		);
	}, []);

	const onSubscribeHandler = () => {
		if (OneSignal?.Notifications?.permissionNative === OneSignalNotificationsE?.denied) {
			toast((t) => (
				<DefaultToast
					t={t}
					title={localizeText('notifications_blocked')}
					text={localizeText('notifications_blocked_descr')}
					translated={true}
				/>
			));

			return;
		}

		OneSignal?.Notifications?.requestPermission().then(async () => {
			if (OneSignal?.Notifications?.permission && OneSignal.User.PushSubscription.id) {
				setOnesignalExternalIdHandler({
					subscriptionId: OneSignal.User.PushSubscription.id
				});

				setPermissions(
					OneSignal?.Notifications?.permissionNative === OneSignalNotificationsE?.granted
				);
			}
		});
	};

	return (
		<div className={s.block}>
			<div className={s.nameBlock}>
				{permission ? <NotificationsGranted /> : <Notifications />}

				{localizeText(permission ? 'notification_granted' : 'notification')}
			</div>
			{!permission && (
				<Button
					rippleAnimation
					className={s.button}
					handle={onSubscribeHandler}
					text={localizeText('subscribe')}
				/>
			)}
		</div>
	);
};

export default PasswordBlock;

import React, { useState } from 'react';
import { useLocales } from '@lib/hooks';
import { Trans } from 'react-i18next';
import { Button, Ripple } from '@components/common/elements';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { userSelectors } from '@store/reducers/user/selectors';
import { formatNumber, renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { RightArrowSwiper } from '@components/svg';
import { DefaultToast } from '@components/common/global-settings';
import CopyToClipboard from 'react-copy-to-clipboard';
import s from './style.module.scss';
import cn from 'classnames';
import convertToUsers from '@utils/convertToUsers';
import toast from 'react-hot-toast';
import { getImageByURL } from '@utils/getImageByURL';

const refLink = 'https://topx.one/?ref=';

export const TitleBlock = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Referral'
	});

	const {
		referral: { link, pendingRewardsAmount }
	} = useSelector(bonusSelectors.bonusInfo);

	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { currency, telegramChannelBotLink } = useSelector(userSelectors.userInfo);

	const [isShowMyLink, setIsShowMyLink] = useState(false);

	const showMyLinkToggle = () => setIsShowMyLink(!isShowMyLink);

	const openReferralModal = () => setModalType(ModalType?.referral);

	return (
		<div className={s.container}>
			<div className={s.text_block}>
				<h1 className={s.text_block_title}>
					<Trans
						ns='components/routes/translation'
						i18nKey={
							isAuthed
								? 'Referral.referral_bonuses_title'
								: 'Referral.referral_bonuses_title_nonAuthed'
						}
						values={{ curr: renderCurrencySymbol(currency) || '৳' }}
						components={{ b: <b /> }}
					/>
				</h1>
				<div className={s.text_block_descr}>
					{localizeText('referral_bonuses_description')}
				</div>

				{isMobile && (
					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/referral/title.webp')}
						alt='Referral Bonus'
					/>
				)}

				{isAuthed ? (
					<div className={s.linkBlock}>
						<div className={cn(s.linkBlock_link, { [s.show]: isShowMyLink })}>
							<span>{localizeText('your_ref_link')}</span>
							<span>
								{isShowMyLink ? (
									link
								) : (
									<>
										{refLink}
										<b>xxxxxx</b>
									</>
								)}
							</span>

							{isShowMyLink && (
								<div className={s.copyblock}>
									<div className={s.copyblock_gradient} />
									<div className={s.copyblock_btnblock}>
										<CopyToClipboard
											text={link}
											onCopy={() =>
												toast((t) => (
													<DefaultToast
														t={t}
														text={'link_copied'}
													/>
												))
											}
										>
											<div className={s.copyblock_btnblock_btn}>
												{localizeText('copy')}

												<Ripple />
											</div>
										</CopyToClipboard>
									</div>
								</div>
							)}
						</div>
						{isShowMyLink ? (
							<div
								onClick={openReferralModal}
								className={s.refBalanceBlock}
							>
								<div className={s.refBalanceBlock_sum}>
									<span>
										{renderCurrencySymbol(currency?.toLowerCase())}{' '}
										{formatNumber(convertToUsers(pendingRewardsAmount))}
									</span>
									<span>{localizeText('refs_balance')}</span>
								</div>

								<RightArrowSwiper />
							</div>
						) : (
							<Button
								rippleAnimation
								className={s.contactBtn}
								type={ButtonType?.primary}
								handle={() => {
									isAuthed ? showMyLinkToggle() : setModalType(ModalType.auth);

									isAuthed
										? sendAnalyticsHandler(AnalyticsEventType?.referral_getlink)
										: sendAnalyticsHandler(
												AnalyticsEventType?.referral_click_registration
											);
								}}
							>
								{isAuthed
									? localizeText('click_and_get_link')
									: localizeText('register_btn')}
							</Button>
						)}
					</div>
				) : (
					<Button
						rippleAnimation
						className={s.contactBtn}
						type={ButtonType?.primary}
						handle={() =>
							isAuthed
								? window.open(telegramChannelBotLink, '_blank')
								: setModalType(ModalType.auth)
						}
					>
						{isAuthed ? localizeText('click_and_get_link') : localizeText('register_btn')}
					</Button>
				)}
			</div>
			{!isMobile && (
				<img
					className={s.img}
					src={getImageByURL('../../../../../../assets/img/referral/title.webp')}
					alt='Found an error?'
				/>
			)}
		</div>
	);
};

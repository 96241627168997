import React from 'react';
import { StaticContainer } from '@components/common/layout';
import { Button, Dropdown } from '@components/common/elements';
import { ButtonType, ModalType } from '@types';
import { CommentSvg } from '@components/svg';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { Providers } from '@components/common/sections';
import { useLocales, useUrlLang } from '@lib/hooks';
import { useSelector } from 'react-redux';
import { openCarrotChat } from '@utils';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { pathsConfig } from '@config/paths';
import { useNavigate } from 'react-router-dom';
import { APP_EMAIL, isTapX, isTopXGo, topXGoFooterText } from '@config/global';
import cn from 'classnames';
import s from './style.module.scss';

export const Footer = () => {
	const { isMobile, isTablet } = useSelector(commonUISelectors.commonUIInfo);
	const { localizeText } = useLocales({
		path: 'components/common/page',
		node: 'Footer'
	});

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const policyRoutes: Array<{ name: string; type: ModalType | string }> = [
		{
			name: localizeText('politics_terms'),
			type: !isMobile ? pathsConfig?.politics_terms?.link : ModalType.politicsTerms
		},
		{
			name: localizeText('politics_privacy_policy'),
			type: !isMobile
				? pathsConfig?.politics_privacy_policy?.link
				: ModalType.politicsPrivacyPolicy
		},

		{
			name: localizeText('politics_self_policy'),
			type: !isMobile
				? pathsConfig?.politics_self_policy?.link
				: ModalType.politicsSelfPolicy
		},
		{
			name: localizeText('politics_aml_kyc_policy'),
			type: !isMobile
				? pathsConfig?.politics_aml_kyc_policy?.link
				: ModalType.politicsAmlKycPolicy
		},
		{
			name: localizeText('politics_terms_and_conditions'),
			type: !isMobile
				? pathsConfig?.politics_terms_and_conditions?.link
				: ModalType.politicsTermsAndConditions
		}
	];

	const policyBlock = () => {
		return (
			<div className={s.policy}>
				{policyRoutes.map((route, i) => (
					<button
						key={i}
						onClick={() =>
							!isMobile
								? navigate(langUrlPrefix + route?.type)
								: setModalType(route?.type as ModalType)
						}
						className={s.policy_route}
					>
						{route.name}
					</button>
				))}
			</div>
		);
	};

	return (
		<StaticContainer>
			<footer className={s.footer}>
				<div className={s.footerDivider} />

				<Providers />

				{isTablet && (
					<Dropdown
						title={localizeText('rules')}
						component={policyBlock()}
					/>
				)}

				{isTablet && (
					<div className={s.footerContacts}>
						<Button
							rippleAnimation
							className={s.footerContactsButton}
							type={ButtonType.secondary}
							handle={openCarrotChat}
						>
							<CommentSvg />
							{localizeText('support')}
						</Button>

						{(isTopXGo || isTapX) && (
							<a
								className={s.supportEmailLink}
								href={`mailto:${APP_EMAIL}`}
							>
								{APP_EMAIL}
							</a>
						)}
					</div>
				)}

				{isTablet && isTopXGo && (
					<div className={cn(s.bottomDescktopBlock_item, s.topxgotext)}>
						{topXGoFooterText}
					</div>
				)}

				{!isTablet && <div className={s.footerDivider} />}

				{!isTablet && (
					<div className={s.bottomDescktopBlock}>
						<div className={s.bottomDescktopBlock_item}>
							<h3>{localizeText('rules')}</h3>

							{policyBlock()}
						</div>
						<div className={s.bottomDescktopBlock_item}>
							<div className={s.contacts}>
								<Button
									rippleAnimation
									className={s.footerContactsButton}
									type={ButtonType.secondary}
									handle={openCarrotChat}
								>
									<CommentSvg />
									{localizeText('support')}
								</Button>
								{(isTopXGo || isTapX) && (
									<a
										className={s.supportEmailLink}
										href={`mailto:${APP_EMAIL}`}
									>
										{APP_EMAIL}
									</a>
								)}
							</div>
						</div>

						{isTopXGo && (
							<div className={cn(s.bottomDescktopBlock_item, s.topxgotext)}>
								{topXGoFooterText}
							</div>
						)}
					</div>
				)}

				{isTopXGo && (
					<div className={s.licenceBlock}>
						<div
							id='apg-b22b4add-ecab-4996-b526-4e309f7609fd'
							data-apg-seal-id='b22b4add-ecab-4996-b526-4e309f7609fd'
							data-apg-image-size='64'
							data-apg-image-type='basic-light-large'
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: '100%'
							}}
						></div>

						<div className={s.onlyAdult}>18+</div>
					</div>
				)}
			</footer>
		</StaticContainer>
	);
};

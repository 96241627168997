import React from 'react';
import { useSelector } from 'react-redux';
import { SocialBonusTaskType } from '@types';
import { useLocales } from '@lib/hooks';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { TelegramBonusCard, ReferralBonusCard, LevelBonusCard } from './parts';
import s from './style.module.scss';

export const OtherBonuses = () => {
	const { socialBonusTasks } = useSelector(bonusSelectors.bonusInfo);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'AllBonus'
	});

	const findTelegramBonusState = socialBonusTasks?.find(
		(e) => e?.task?.type === SocialBonusTaskType?.telegram_ref_bot
	);

	return (
		<div className={s.content}>
			<div
				id={'other_block'}
				className={s.titleBlock}
			>
				<span>{localizeText('other_bonuses_title')}</span>
				<span>{localizeText('other_bonuses_descr')}</span>
			</div>
			<div className={s.cards}>
				<LevelBonusCard />
				{!findTelegramBonusState?.isDone && <TelegramBonusCard />}

				<ReferralBonusCard />
			</div>
		</div>
	);
};

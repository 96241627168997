import React from 'react';
import Div100vh from 'react-div-100vh';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { useLocales, useUrlLang } from '@lib/hooks';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { Button, GoHome } from '@components/common/elements';
import { useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { CloseSvg } from '@components/svg';
import s from './style.module.scss';
import { metaConfig } from '@config/meta';
import { casinoType } from '@utils/casinoType';
import { sendAnalyticsHandler } from '@utils';
import { authSelectors } from '@store/reducers/auth/selectors';
import { getImageByURL } from '@utils/getImageByURL';

export const InformationModal = () => {
	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();
	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'InformationModal'
	});

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);

	const closeAuthModal = () => {
		setModalType(null);
	};

	const goHomeModal = () => {
		setModalType(null);
		navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link);
	};

	const policyRoutes: Array<{ name: string; type: ModalType | string }> = [
		{
			name: localizeText('politics_terms'),
			type: !isMobile ? pathsConfig?.politics_terms?.link : ModalType.politicsTerms
		},
		{
			name: localizeText('politics_privacy_policy'),
			type: !isMobile
				? pathsConfig?.politics_privacy_policy?.link
				: ModalType.politicsPrivacyPolicy
		},

		{
			name: localizeText('politics_self_policy'),
			type: !isMobile
				? pathsConfig?.politics_self_policy?.link
				: ModalType.politicsSelfPolicy
		},
		{
			name: localizeText('politics_aml_kyc_policy'),
			type: !isMobile
				? pathsConfig?.politics_aml_kyc_policy?.link
				: ModalType.politicsAmlKycPolicy
		},
		{
			name: localizeText('politics_terms_and_conditions'),
			type: !isMobile
				? pathsConfig?.politics_terms_and_conditions?.link
				: ModalType.politicsTermsAndConditions
		}
	];

	const policyBlock = () => {
		return (
			<div className={s.policy}>
				{policyRoutes.map((route, i) => (
					<button
						key={i}
						onClick={() => {
							!isMobile
								? navigate(langUrlPrefix + route?.type)
								: setModalType(route?.type as ModalType);

							sendAnalyticsHandler(AnalyticsEventType?.information_open_block, {
								information_block: route?.type
							});
						}}
						className={s.policy_route}
					>
						{route.name}
					</button>
				))}
			</div>
		);
	};

	const Container = isMobile
		? Div100vh
		: (
				props: JSX.IntrinsicAttributes &
					React.ClassAttributes<HTMLDivElement> &
					React.HTMLAttributes<HTMLDivElement>
			) => <div {...props}></div>;

	return (
		<>
			<Helmet>
				<title>{metaConfig.metatags[casinoType()].information?.title}</title>
				<meta
					name='description'
					content={metaConfig.metatags[casinoType()].information?.description}
				/>

				<link
					rel='canonical'
					href={window?.location?.href}
				/>
			</Helmet>
			<div
				className={cn(s.wrapper, {
					[s.nonAuthed]: !isAuthed
				})}
			>
				{!isMobile && <GoHome />}
				<Container className={s.inner}>
					<div className={cn(s.header, { [s.headerAuthed]: isAuthed })}>
						<div className={s.headerWrapper}>
							<div className={s.headerTitle}>{localizeText('title')}</div>
							{isMobile && (
								<div
									onClick={closeAuthModal}
									className={s.headerClose}
								>
									<CloseSvg />
								</div>
							)}
						</div>
					</div>
					<div className={s.body}>
						{policyBlock()}
						<Button
							type={ButtonType?.primary}
							rippleAnimation
							text={localizeText('go_home')}
							className={s.btn}
							handle={goHomeModal}
						/>
					</div>

					<div className={s.license}>
						<img
							onClick={() => {
								setModalType(ModalType.license);
								sendAnalyticsHandler(AnalyticsEventType?.click_info_icon);
							}}
							alt='licence'
							src={getImageByURL(
								'../../../../../../assets/img/sections/info/license.webp'
							)}
						/>

						<span>{localizeText('license_text')}</span>
					</div>
				</Container>
			</div>
		</>
	);
};

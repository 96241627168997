import React, { ReactNode, useEffect } from 'react';
import isUndefined from 'lodash/isUndefined';

import { useAsyncState } from '@lib/hooks';
import { getLastPartFromCurrentPath } from '@utils';

type RoutePreRenderingWrapP = {
	children: ReactNode;
	initMethods?: Array<Function | undefined>;
	isSection?: boolean;
};

export const RoutePreRenderingWrap = (props: RoutePreRenderingWrapP) => {
	const { children, initMethods, isSection = false } = props;

	const [isReadyToRender, setIsReadyToRender] = useAsyncState(false);
	const lastPart = getLastPartFromCurrentPath();

	// TODOвынести в либу -> ux
	const pause = (seconds: number) =>
		new Promise((resolve) => setTimeout(resolve, seconds * 1000));

	function promiseChain() {
		return (
			Promise.resolve()
				.then(() => setIsReadyToRender(false))
				// eslint-disable-next-line consistent-return
				.then(async () => {
					// просто для ui
					await pause(0.2);

					if (initMethods) {
						const promises = initMethods.map(
							(method) => !isUndefined(method) && method()
						);

						return Promise.all(promises);
					}
				})
				.then(() => setIsReadyToRender(true))
		);
	}

	useEffect(() => {
		promiseChain();
	}, [lastPart]);

	// незагруженные секции не показываем вообще
	return isSection && !isReadyToRender ? (
		<></>
	) : (
		<div
			id='route-pre-rendering-wrapper'
			style={{
				opacity: `${isReadyToRender ? 1 : 0}`,

				visibility: `${isReadyToRender ? 'visible' : 'hidden'}`,
				transition: '.2s'
			}}
		>
			{children}
		</div>
	);
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { renderCurrencySymbol, sendAnalyticsHandler, setFloatNumber } from '@utils';
import { setInvoiceValue } from '@store/reducers/payment/dispathcers';
import { userSelectors } from '@store/reducers/user/selectors';
import { AmountInputP } from '../../../../../../type';
import { PresetButton } from '@components/common/elements';
import { paymentSelectors } from '@store/reducers/payment/selectors';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import s from './style.module.scss';
import cn from 'classnames';
import { AnalyticsEventType } from '@types';
import { getImageByURL } from '@utils/getImageByURL';

export const AmountInput = (props: AmountInputP) => {
	const {
		invoiceValue,
		localizeText,
		calculateFSBonusHandler,
		amountError,
		doubleDeposBonus
	} = props;

	const { currency, successInvoiceCount } = useSelector(userSelectors.userInfo);
	const { allRMWBonus } = useSelector(bonusSelectors.bonusInfo);

	const isAllowedToCalculate = successInvoiceCount < 5;

	const [textWidth, setTextWidth] = useState(0);

	const inputRef = useRef<HTMLInputElement>(null);
	const hiddenDivRef = useRef<HTMLDivElement>(null);

	const invoiceHandler = (value: string) => setInvoiceValue(setFloatNumber(value));

	const { paymentMethodsInvoice, selectedPaymentMethodId } = useSelector(
		paymentSelectors.paymentInfo
	);

	const currentPaymentMethod = paymentMethodsInvoice?.find(
		(e) => e?.id === selectedPaymentMethodId
	);

	const freespins = allRMWBonus?.find(
		(e) => e?.isActive && Boolean(e?.config?.depositMap)
	);

	useEffect(() => {
		const pressetNumber = successInvoiceCount <= 4 ? successInvoiceCount : 4;

		if (
			currentPaymentMethod?.settings?.presets &&
			currentPaymentMethod?.settings?.presets?.length > pressetNumber
		) {
			const presset = currentPaymentMethod?.settings?.presets[pressetNumber];

			if (!invoiceValue) {
				if (typeof presset === 'number') {
					invoiceHandler(`${Number(presset) / 100}`);
				} else {
					invoiceHandler(`${presset?.amount / 100}`);
				}
			}
		}

		if (inputRef) {
			inputRef?.current?.focus();
		}
	}, [currentPaymentMethod?.settings?.presets]);

	const countOfFSInvoiceValue = calculateFSBonusHandler
		? calculateFSBonusHandler(Number(invoiceValue) * 100)
		: 0;

	useEffect(() => {
		if (hiddenDivRef && hiddenDivRef?.current && inputRef && inputRef?.current) {
			hiddenDivRef.current.innerText = inputRef.current.value;

			setTextWidth(hiddenDivRef?.current?.clientWidth);
		}
	}, [hiddenDivRef, inputRef, invoiceValue]);

	const inputStyle = {
		'--textWidth': textWidth + 'px',
		'--marginRight': textWidth === 0 ? 0 + 'px' : 12 + 'px',
		'--textAlign': textWidth === 0 ? 'start' : 'center'
	} as React.CSSProperties;

	const changeInvoiceHandler = (value: string) => {
		const reg = /^\d+$/;

		if (reg.test(value) || value?.length === 0) {
			if (currentPaymentMethod?.settings?.maxInvoice) {
				if (Number(value) * 100 <= currentPaymentMethod?.settings?.maxInvoice) {
					invoiceHandler(value);
				} else {
					invoiceHandler((currentPaymentMethod?.settings?.maxInvoice / 100)?.toString());
				}
			} else {
				invoiceHandler(value);
			}
		}
	};

	if (currentPaymentMethod) {
		return (
			<div className={s.wrapper}>
				<div>
					<div
						style={inputStyle}
						className={s.inputBlockPrice}
					>
						<input
							autoComplete='new-password'
							ref={inputRef}
							className={s.amountInput}
							inputMode={'decimal'}
							placeholder='0'
							name='amount'
							value={invoiceValue}
							onChange={(e) => changeInvoiceHandler(e.currentTarget.value)}
							onBlur={() =>
								sendAnalyticsHandler(
									AnalyticsEventType?.payment_input_replenishment_amount,
									{
										payment_input_amount: invoiceValue
									}
								)
							}
						/>

						<span className={s.inputCurrency}>{renderCurrencySymbol(currency)} </span>

						<div
							ref={hiddenDivRef}
							className={s.hiddendiv}
						/>
					</div>

					<div
						className={cn(s.bonusBlock, {
							[s.withMargin]:
								(amountError && amountError?.length > 0) ||
								((doubleDeposBonus || freespins) &&
									invoiceValue &&
									successInvoiceCount === 0) ||
								(doubleDeposBonus && invoiceValue) ||
								countOfFSInvoiceValue > 0
						})}
					>
						{amountError && amountError?.length > 0 && (
							<span className={s.error}>{amountError}</span>
						)}

						{(doubleDeposBonus || freespins) &&
							invoiceValue &&
							successInvoiceCount === 0 && (
								<span className={s.bonusBlock_text}>{localizeText('you_get')}:</span>
							)}
						{isAllowedToCalculate && invoiceValue && (
							<div className={s.doubleDeposite}>
								{renderCurrencySymbol(currency)} {Number(invoiceValue)?.toFixed(0)} +{' '}
								<span className={s.doubleDeposite_gold}>
									{renderCurrencySymbol(currency)} {(Number(invoiceValue) * 4).toFixed(0)}
									<img
										className={s.doubleDeposite_icon}
										src={getImageByURL('../../../assets/img/payments/InvoiceX5Icon.webp')}
										alt='Payment Method'
									/>
								</span>{' '}
								<span className={s.doubleDeposite_green}>
									= {renderCurrencySymbol(currency)}{' '}
									{(Number(invoiceValue) * 5).toFixed(0)}
								</span>
							</div>
						)}
						{countOfFSInvoiceValue > 0 && (
							<div className={s.freeSpinInput}>
								<span>+</span>
								<div>{countOfFSInvoiceValue} Free Spin</div>
							</div>
						)}
					</div>
				</div>

				{currentPaymentMethod?.settings?.presets?.length > 0 && (
					<div className={s.options}>
						{currentPaymentMethod?.settings?.presets.map((option, index) => {
							let amount = 0;

							if (typeof option === 'number') {
								amount = Number(option);
							} else {
								amount = option?.amount;
							}

							const countOfFs = calculateFSBonusHandler
								? calculateFSBonusHandler(amount)
								: 0;

							return (
								<PresetButton
									key={index}
									countOfFs={countOfFs}
									invoiceHandler={invoiceHandler}
									amount={amount}
									currency={currency}
								/>
							);
						})}
					</div>
				)}
			</div>
		);
	}

	return null;
};

export const getPaymentMethodPageBySubmitForm = (
	link: string,
	params?: Array<{ name: string; value: string }>
) => {
	const body: Record<string, string> = {};

	params?.forEach((p) => {
		body[p?.name] = p?.value;
	});

	const form = document.createElement('form');

	form.action = link;
	form.method = 'post';

	for (const key in body) {
		if (Object.prototype.hasOwnProperty.call(body, key)) {
			const input = document.createElement('input');

			input.type = 'hidden';
			input.name = key;
			input.value = body[key];
			form.appendChild(input);
		}
	}

	document.body.appendChild(form);
	form.submit();
};

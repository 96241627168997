import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setWithdrawalValue } from '@store/reducers/payment/dispathcers';
import { WithdrawalAmountInputP } from '../../../../../../type';
import { FormError, Input } from '@components/common/elements';
import s from './style.module.scss';
import { paymentSelectors } from '@store/reducers/payment/selectors';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';

export const AmountInput = (props: WithdrawalAmountInputP) => {
	const {
		amount,
		localizeText,
		setWalletData,
		walletData,
		providerId,
		errors,
		setErrors,
		setIsError
	} = props;

	const { paymentMethodsWithdraw, selectedPaymentMethodId } = useSelector(
		paymentSelectors.paymentInfo
	);

	const currentPaymentMethod = paymentMethodsWithdraw?.find(
		(e) => e?.id === selectedPaymentMethodId
	);

	const changeWithdrawalHandler = (value: string) => {
		const reg = /^\d+$/;

		if (reg.test(value) || value?.length === 0) {
			if (currentPaymentMethod?.settings?.maxWithdraw) {
				if (Number(value) <= currentPaymentMethod?.settings?.maxWithdraw / 100) {
					setWithdrawalValue(value);
				} else {
					setWithdrawalValue(
						(currentPaymentMethod?.settings?.maxWithdraw / 100)?.toString()
					);
				}
			} else {
				setWithdrawalValue(value);
			}
		}
	};

	useEffect(() => {
		const walletsNumbers = window?.localStorage.getItem('wallets_numbers');

		if (walletsNumbers) {
			const walletsNumbersJSON: Array<{
				providerId: number;
				fields: Record<string, unknown>;
			}> = JSON.parse(walletsNumbers);

			if (walletsNumbersJSON && walletsNumbersJSON?.length > 0) {
				const haveWalletItem = walletsNumbersJSON?.find(
					(e) => e?.providerId === providerId
				);

				if (haveWalletItem && haveWalletItem?.fields) {
					setWalletData && setWalletData(haveWalletItem?.fields);
				} else {
					setWalletData && setWalletData({});
				}
			}
		} else {
			setWalletData && setWalletData({});
		}
	}, [selectedPaymentMethodId]);

	if (currentPaymentMethod) {
		return (
			<div className={s.wrapper}>
				{currentPaymentMethod?.settings?.fields?.map((f) => {
					if (walletData && setWalletData) {
						return (
							<div
								key={f?.field}
								className={s.inputBlock}
							>
								<Input
									type={'text'}
									name='wallet'
									label={f?.title}
									error={!!errors[f.field]}
									placeholder={f.placeholder}
									value={walletData[f?.field] as string}
									onChange={(e) => {
										const updatedData: Record<string, unknown> = {
											...walletData,
											[f?.field as string]: e.currentTarget.value as unknown
										};

										if (errors[f.field]) {
											setErrors((prevErrors: Record<string, string | undefined>) => ({
												...prevErrors,
												[f.field]: undefined
											}));

											setIsError(false);
										}

										setWalletData(updatedData);
									}}
									onFocus={() =>
										sendAnalyticsHandler(AnalyticsEventType?.payment_input_acc_number)
									}
								/>

								<FormError
									error={errors[f.field]}
									isTouched={!!errors[f.field]}
								/>
							</div>
						);
					}

					return null;
				})}

				<Input
					type={'number'}
					inputMode={'decimal'}
					name='amount'
					label={localizeText('withdrawal_amount')}
					value={amount}
					onChange={(e) => changeWithdrawalHandler(e.currentTarget.value)}
					onBlur={() =>
						sendAnalyticsHandler(AnalyticsEventType?.payment_input_withdrawal_amount, {
							payment_input_amount: amount
						})
					}
				/>
			</div>
		);
	}

	return null;
};

import React, { Fragment } from 'react';
import Slider, { Settings } from 'react-slick';

import style from './style.module.scss';

type MobileSliderP = {
	sectionWidth?: number;
	sectionHeight?: number;
	slides: Array<JSX.Element>;
};

export const MobileSlider = (props: MobileSliderP) => {
	const { slides } = props;

	const sliderSettings: Settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		appendDots: (dots) => (
			<ul
				style={{
					height: '27px',
					padding: '10px',
					position: 'absolute',
					bottom: '0',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '6px'
				}}
			>
				{' '}
				{dots}{' '}
			</ul>
		),
		customPaging: () => (
			<div
				style={{
					width: '32px',
					height: '3px',
					backgroundColor: '#ffffff',
					opacity: '0.3',
					borderRadius: '8px'
				}}
			></div>
		)
	};

	return (
		<div
			className={style.slider}
			style={{
				overflow: 'hidden'
			}}
		>
			<Slider {...sliderSettings}>
				{slides.map((slide, i) => (
					<Fragment key={i}>{slide}</Fragment>
				))}
			</Slider>
		</div>
	);
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';

const backendOptionsObj = {
	defaultVersion: process.env.npm_package_version,
	loadPath: '/locales/{{lng}}/{{ns}}.json',
	expirationTime: 24 * 60 * 60 * 1000,
	store: typeof window !== 'undefined' ? window.localStorage : null
};

i18n
	.use(Backend)
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		debug: false,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		},
		react: {
			useSuspense: true
		},
		detection: {
			order: ['localStorage', 'path'],
			lookupFromPathIndex: 0
		},
		backend: {
			backends: [HttpApi],
			backendOptions: [backendOptionsObj]
		}
	});

export default i18n;

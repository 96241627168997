import React from 'react';
import { useLocales } from '@lib/hooks';
import { userSelectors } from '@store/reducers/user/selectors';
import { renderCurrencySymbol } from '@utils';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import { getImageByURL } from '@utils/getImageByURL';

export const LastPayments = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'TelegramBonuses'
	});

	const { currency } = useSelector(userSelectors.userInfo);

	return (
		<>
			<div className={s.container}>
				<div className={s.text_block}>
					<img
						alt='Last paid'
						className={s.img}
						src={getImageByURL(
							'../../../../../../assets/img/telegram-bonus/last_payment.webp'
						)}
					/>
					<h1 className={s.text_block_title}>
						{renderCurrencySymbol(currency) || '৳'} 456,200
					</h1>

					<span className={s.text_block_descr}>{localizeText('last_paid_descr')}</span>
				</div>

				<div className={s.divider} />
			</div>
		</>
	);
};

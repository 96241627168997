import React, { useEffect, useRef, useState } from 'react';
import { setBonusBalancesCancel, setBonusBalancesVisited } from '@lib/api/bonus';
import { Button } from '@components/common/elements';
import { ButtonType, IProviderGame, ModalType } from '@types';
import { useLocales } from '@lib/hooks';
import { useSelector } from 'react-redux';
import { userSelectors } from '@store/reducers/user/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { formatNumber, getWaggeringGames, renderCurrencySymbol } from '@utils';
import { Trans } from 'react-i18next';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import {
	CloseSvg,
	DeclineBonus,
	NewBonusBalanceFlag,
	RightArrowSwiper
} from '@components/svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import s from './style.module.scss';
import cn from 'classnames';
import convertToUsers from '@utils/convertToUsers';
import Countdown from 'react-countdown';
import dayjs from 'dayjs';
import 'react-circular-progressbar/dist/styles.css';
import Div100vh from 'react-div-100vh';
import { getImageByURL } from '@utils/getImageByURL';

type DataType = {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	formatted: { days: string; hours: string; minutes: string; seconds: string };
};

export const BonusBalancesBlock = () => {
	const { isTablet } = useSelector(commonUISelectors.commonUIInfo);

	const { bonusBalances } = useSelector(bonusSelectors.bonusInfo);

	const { bonusBalanceWager } = useSelector(userSelectors.userInfo);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'AllBonus'
	});

	const [bonusModal, setBonusModal] = useState<boolean>(false);
	const [notVisitedFlag, setNotVisitedFlag] = useState<boolean>(false);
	const [isMouseDown, setIsMouseDown] = useState(false);
	const [wageringGames, setWageringGames] = useState<IProviderGame[]>();

	const [hideProgress, setHideProgress] = useState<boolean>(false);

	const bonusInfoModalHandler = () => {
		setBonusModal(!bonusModal);
	};

	const listRef = useRef<HTMLDivElement>(null);

	const mouseCoords = useRef({
		startX: 0,
		startY: 0,
		scrollLeft: 0,
		scrollTop: 0
	});

	const handleDragStart = (e: { pageX: number; pageY: number }) => {
		if (!listRef.current) return;

		const slider = listRef.current as HTMLElement;

		const startX = e.pageX - slider.offsetLeft;
		const startY = e.pageY - slider.offsetTop;
		const scrollLeft = slider.scrollLeft;
		const scrollTop = slider.scrollTop;

		mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
		setIsMouseDown(true);
		document.body.style.cursor = 'grabbing';
	};
	const handleDragEnd = () => {
		setIsMouseDown(false);
		if (!listRef.current) return;

		document.body.style.cursor = 'default';
	};
	const handleDrag = (e: {
		preventDefault: () => void;
		pageX: number;
		pageY: number;
	}) => {
		if (!isMouseDown || !listRef.current) return;

		e.preventDefault();

		const slider = listRef.current as HTMLElement;
		const x = e.pageX - slider.offsetLeft;
		const y = e.pageY - slider.offsetTop;
		const walkX = (x - mouseCoords.current.startX) * 1.5;
		const walkY = (y - mouseCoords.current.startY) * 1.5;

		slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
		slider.scrollTop = mouseCoords.current.scrollTop - walkY;
	};

	const renderer = (data: DataType) => {
		const { formatted } = data;

		const { hours, minutes, seconds } = formatted;

		return (
			<div className={s.counter}>
				<span>{localizeText('running_out_in')}</span>
				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{hours}</div>
					</div>
				</div>
				:
				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{minutes}</div>
					</div>
				</div>
				:
				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{seconds}</div>
					</div>
				</div>
			</div>
		);
	};

	const setIsVisitedHandler = async () => {
		bonusBalances?.forEach(async (b) => {
			if (!b?.isVisited) {
				await setBonusBalancesVisited(b?.id);
			}
		});
	};

	useEffect(() => {
		if (notVisitedFlag) {
			setTimeout(setIsVisitedHandler, 2000);
		}
	}, [notVisitedFlag, bonusBalances]);

	useEffect(() => {
		if (bonusBalances && bonusBalances?.length > 0) {
			getWaggeringGames(setWageringGames, true, 7);

			const haveNotVisited = bonusBalances?.find((e) => !e?.isVisited);

			setNotVisitedFlag(!!haveNotVisited);
		}
	}, [bonusBalances]);

	const scrollToNotVisited = () => {
		if (notVisitedFlag) {
			const blocks = document?.querySelectorAll('.not-visited-bonus');

			if (blocks) {
				blocks[blocks?.length - 1].scrollIntoView({
					behavior: 'smooth',
					inline: 'center',
					block: 'center'
				});
				setNotVisitedFlag(false);
			}
		}
	};

	const declineBonusHandler = () => {
		setBonusBalancesCancel();
		bonusInfoModalHandler();

		setHideProgress(true);

		setTimeout(() => {
			setHideProgress(false);
		}, 2000);
	};

	return (
		<div className={s.content}>
			<div className={s.bonusBlock}>
				{notVisitedFlag ? (
					<button
						onClick={scrollToNotVisited}
						className={s.notVisitedFlag}
					>
						<NewBonusBalanceFlag />
					</button>
				) : null}
				<div className={s.bonusBlock_top}>
					<div
						id={'bonus_balances_block'}
						className={s.titleBlock}
					>
						<span className={s.titleBlock_title}>
							{localizeText('bonus_balances_title')}
						</span>
						<span className={s.titleBlock_descr}>
							{localizeText('bonus_balances_descr')}
						</span>
					</div>

					<img
						alt='bonus balance'
						className={s.image}
						src={getImageByURL('../../../../../../assets/img/bonus/bonus_balances.webp')}
					/>
				</div>
				<div className={s.bonusList}>
					<div
						ref={listRef}
						onMouseDown={handleDragStart}
						onMouseUp={handleDragEnd}
						onMouseMove={handleDrag}
						className={cn(s.bonusBlock_bottom, {
							[s.padding]: bonusBalances?.length > 1
						})}
					>
						{bonusBalances?.map((balance) => {
							const { status, id, currency, bonusBalance, type, isVisited, expiresAt } =
								balance;

							const isActive = status === 'active';

							return (
								<div
									key={id}
									className={cn(s.invoiceItem, { ['not-visited-bonus']: !isVisited })}
								>
									<div
										className={cn(s.invoiceItem_top, {
											[s.active]: isActive
										})}
									>
										{!isActive && (
											<div className={cn(s.depNum, { [s.active]: !isVisited })}>
												{localizeText(isVisited ? 'next_bonus' : 'new_bonus')}
											</div>
										)}
										<div className={s.invoiceItem_top_content}>
											<div className={s.progressBlocK}>
												<div className={s.descrBlock}>
													<span className={cn({ [s.disabled]: !isActive })}>
														{renderCurrencySymbol(currency?.toLowerCase())}{' '}
														{formatNumber(convertToUsers(bonusBalance))}
													</span>
													<span className={cn({ [s.disabled]: !isActive })}>
														{localizeText(`${type}_bonus`)}
													</span>
												</div>
												{isActive && !hideProgress ? (
													<div style={{ width: 48, height: 48 }}>
														<CircularProgressbar
															value={
																100 -
																(bonusBalanceWager * 100) /
																	(balance?.bonusBalanceWager / 100)
															}
															text={`${(100 - (bonusBalanceWager * 100) / (balance?.bonusBalanceWager / 100))?.toFixed(0)}%`}
															styles={buildStyles({
																pathColor: '#0DD414',
																textColor: '#0DD414',
																trailColor: 'rgba(231, 236, 245, 0.08)',
																backgroundColor: 'none'
															})}
														/>
													</div>
												) : null}
											</div>

											<button
												onClick={bonusInfoModalHandler}
												className={s.cancelBonus}
												disabled={!isActive}
											>
												<DeclineBonus />
												{localizeText('decline_bonus')}
											</button>
										</div>

										<div>
											{isActive ? (
												<Countdown
													date={dayjs(expiresAt).format()}
													renderer={renderer}
													daysInHours
												/>
											) : (
												<div className={cn(s.counter, { [s.disabled]: !isActive })}>
													<Trans
														ns='components/routes/translation'
														i18nKey={'AllBonus.will_be_active'}
														components={{ b: <b className={s.hours} /> }}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							);
						})}
					</div>

					{!isTablet && <div className={s.shadow} />}
				</div>
			</div>

			<div
				onClick={() => setModalType(ModalType.wageringGames)}
				className={s.waggeringGames}
			>
				<div className={s.waggeringGames_titleBlock}>
					<div className={s.title}>
						<img
							className={s.icon}
							alt='Bonus icon'
							src={getImageByURL(
								'../../../../../../assets/img/common/bonus_balances_icon.webp'
							)}
						/>
						{localizeText('games_for_wagering')}
					</div>

					<div className={s.allGames}>
						{localizeText('all_games')}
						<RightArrowSwiper />
					</div>
				</div>

				<div className={s.gamesImagesList}>
					{wageringGames?.map((game, index) => {
						const { id, image } = game;

						const isFirst = index === 0;
						const img =
							!image || image === 'null' || image === 'undefined'
								? '/assets/img/common/game-card-image-default.svg'
								: image;

						const imageStyle = {
							'--background': isFirst
								? `url(${img}) lightgray 50% / cover no-repeat`
								: `linear-gradient(270deg, rgba(9, 15, 30, 0.00) 0%, #090F1E 100%), url(${img}) lightgray 50% / cover no-repeat`
						} as React.CSSProperties;

						return (
							<div
								key={id}
								style={imageStyle}
								className={cn(s.gameImage, { [s.first]: isFirst })}
							/>
						);
					})}
				</div>
			</div>

			<Div100vh
				onClick={(e) => {
					if (e.target === e.currentTarget) {
						bonusInfoModalHandler();
					}
				}}
				className={cn(s.cancelWrapper, { [s.show]: bonusModal })}
			>
				<div className={s.inner}>
					<button
						onClick={bonusInfoModalHandler}
						className={s.closeCancel}
					>
						<CloseSvg />
					</button>
					<div className={s.cancelDecr}>
						<span>{localizeText('decline_bonus')}</span>
						<span>{localizeText('decline_bonus_descr')}</span>
					</div>

					<div className={s.cancelBtnsBlock}>
						<Button
							handle={declineBonusHandler}
							rippleAnimation
							type={ButtonType.primary}
							text={localizeText('decline_bonus')}
						/>
						<Button
							handle={bonusInfoModalHandler}
							rippleAnimation
							type={ButtonType.secondary}
							text={localizeText('continue_play')}
						/>
					</div>
				</div>
			</Div100vh>
		</div>
	);
};

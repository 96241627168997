import { PaymentService } from '@lib/api/payment/PaymentService';
import { linkToRemoteUrl } from '@lib/ux';
import {
	setInvoiceValue,
	setPaymentProcessingStatus,
	setQrPaymentInfo,
	setSelectedPaymentMethodId,
	setUserConfirmedPayment
} from '@store/reducers/payment/dispathcers';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import {
	AnalyticsEventType,
	Currencies,
	InvoicePaymentResponseDataType,
	ModalType,
	PaymentResultsType,
	PaymentTypeBE,
	PaymentUIType,
	Roles
} from '@types';
import { store } from '@store/index';
import { detectDeviceType, saveFieldsForProvider, sendAnalyticsHandler } from '@utils';
import {
	ErrorPaymentMethodNotFound,
	ErrorToast
} from '@components/common/global-settings';
import toast from 'react-hot-toast';
import { getPaymentMethodPageBySubmitForm } from './getPaymentMethodPageBySubmitForm';
import { getPaymentProviders } from '.';

type InvoiceDataType = {
	amount: number;
	responseType?: PaymentUIType;
	fields?: Record<string, unknown>;
	isTelegram?: boolean;
	currency: Currencies;
	userRole: Roles;
};

type InvoiceBodyDataType = {
	amount: number;
	providerId: number;
	fields?: Record<string, unknown>;
	successUrl?: string;
	failUrl?: string;
	deviceType: string;
	isTelegram?: boolean;
};

export const paymentInvoice = async (
	params: InvoiceDataType,
	sunccessFunc?: (data?: InvoicePaymentResponseDataType) => void
) => {
	setPaymentProcessingStatus(true);
	const {
		payment: { selectedPaymentMethodId }
	} = store.getState();

	const body: InvoiceBodyDataType = {
		amount: params.amount,
		providerId: selectedPaymentMethodId ?? 0,

		successUrl:
			window?.location?.origin + `?resultPayment=${PaymentResultsType?.success}`,
		failUrl: window?.location?.origin + `?resultPayment=${PaymentResultsType?.error}`,

		deviceType: detectDeviceType(),

		isTelegram: params?.isTelegram
	};

	if (params?.fields) {
		body['fields'] = params?.fields;
	}

	try {
		const { status, data } = await PaymentService.paymentInvoice(body);

		if (status === 201) {
			if (data.isSuccess) {
				sendAnalyticsHandler(AnalyticsEventType?.replenishment_success);

				if (params?.fields) {
					saveFieldsForProvider(body?.providerId, params?.fields);
				}

				if (
					params.responseType === PaymentUIType.native ||
					params?.responseType === PaymentUIType.redirect
				) {
					if (data.link && data?.type !== PaymentTypeBE?.post_redirect) {
						if (params?.isTelegram && (window as any)?.Telegram.WebApp) {
							(window as any)?.Telegram.WebApp.close();

							return;
						}

						linkToRemoteUrl(data.link, false);
					}

					if (data.link && data?.type === PaymentTypeBE?.post_redirect) {
						getPaymentMethodPageBySubmitForm(data.link, data?.fields);
					}

					if (data?.qr) {
						setQrPaymentInfo({ step: 1, qr: data?.qr, id: data?.id });
						setModalType(ModalType?.paymentQR);
						setPaymentProcessingStatus(false);
						setUserConfirmedPayment(false);
					}

					setTimeout(() => {
						setPaymentProcessingStatus(false);
						setUserConfirmedPayment(false);
					}, 5000);

					return;
				}

				if (data) {
					sunccessFunc && sunccessFunc(data);
				}

				setPaymentProcessingStatus(false);
			} else {
				setModalType(null);

				setInvoiceValue('');
				setPaymentProcessingStatus(false);
				setSelectedPaymentMethodId(null);
				toast((t) => ErrorToast({ t: t, text: 'reffil_dont_allowed' }));
				sendAnalyticsHandler(AnalyticsEventType?.replenishment_error, {
					replenishment_error_provider: data.provider
				});
			}
		}
	} catch (error: any) {
		sendAnalyticsHandler(AnalyticsEventType?.replenishment_error, {
			replenishment_error_provider: body.providerId
		});

		if (
			error?.response?.status === 404 &&
			error?.response?.data?.message === 'PAYMENT_PROVIDER_NOT_FOUND'
		) {
			getPaymentProviders(params?.currency, params?.userRole);
			setTimeout(() => setPaymentProcessingStatus(false), 350);

			toast((t) => ErrorPaymentMethodNotFound({ t: t }));

			return;
		}

		setPaymentProcessingStatus(false);

		toast((t) => ErrorToast({ t: t, text: 'something_wrong' }));
	}
};

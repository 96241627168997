import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AllRoutes } from './all-routes';
import { scrollPageToTop } from '@utils';
import { setIsFullScreenGaming } from '@store/reducers/common-ui/dispatchers';

export const RoutesWrapper = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		scrollPageToTop();
		!pathname.split('/').includes('games') && setIsFullScreenGaming(false);
	}, [pathname]);

	return <AllRoutes />;
};

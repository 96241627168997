import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pathsConfig } from '@config/paths';
import {
	Home,
	Payment,
	Providers,
	NotFoundPage,
	GameSearch,
	AllBonus,
	CurrentUserTransactions,
	Profile,
	TelegramBonus,
	ReferralBonus,
	CashbackBonus,
	PaymentRedirectPage,
	RegistrationPage,
	BgamingPrizes
} from '@components/routes';
import { CurrentProviderGame } from '@components/routes/current-provider-game';
import { authSelectors } from '@store/reducers/auth/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { ModalType, RegisterPageTypesE } from '@types';
import { userSelectors } from '@store/reducers/user/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { PoliticsRouteContentWrapper } from '../wrappers';
import { DEFAULT_LANG, isTapX, SUPPORTED_LANGS } from '@config/global';
import { useUrlLang } from '@lib/hooks';
import { useTranslation } from 'react-i18next';
import { LevelBonus } from '@components/routes/level-bonus';
import InformationModal from '@components/common/modals/parts/information-modal';

export const AllRoutes = () => {
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { hasPassword, successInvoiceCount, locale } = useSelector(
		userSelectors.userInfo
	);
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { i18n } = useTranslation();

	const { path, langUrlPrefix, lang } = useUrlLang();

	const pathPrefix = path.replace(/\/$/, '');

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location?.hash && location?.hash === '#sign-up') {
			if (isAuthed) {
				navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link);
			} else {
				setModalType(ModalType.auth);
			}
		}

		if (location?.hash && location?.hash === '#sign-in') {
			if (isAuthed) {
				navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link);
			} else {
				setModalType(ModalType.signIn);
			}
		}

		if (location?.hash && location?.hash === '#recover') {
			if (isAuthed) {
				navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link);
			} else {
				setModalType(ModalType.renewPassword);
			}
		}
	}, [location]);

	useEffect(() => {
		if (!isAuthed && window.location.pathname.includes('profile')) {
			navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link);
		}
	}, [isAuthed, navigate]);

	useEffect(() => {
		if (lang && lang === DEFAULT_LANG) {
			const regex = new RegExp(`/${DEFAULT_LANG}(?=\\s|$|\\W)`, 'g');

			const currentPathname = window.location.pathname?.replace(regex, '');

			navigate(
				`${currentPathname}${window?.location?.search ? window?.location?.search : ''}`
			);
		}
	}, [lang]);

	useEffect(() => {
		if (isAuthed) {
			if (successInvoiceCount > 1 && !hasPassword) {
				setTimeout(() => setModalType(ModalType.setPassword), 5000);
			}
		}
	}, [isAuthed, successInvoiceCount, hasPassword]);

	useEffect(() => {
		if (isAuthed && locale && locale !== i18n.language) {
			const i18nLang = SUPPORTED_LANGS?.includes(i18n.language)
				? i18n.language
				: undefined;

			const currentPathname = i18nLang
				? window.location.pathname?.replace(`/${i18n.language}`, '')
				: window.location.pathname;

			const isDefault = locale === DEFAULT_LANG ? '' : `/${locale}`;

			i18n?.changeLanguage(locale);

			navigate(
				`${currentPathname?.includes(isDefault) ? '' : isDefault}${currentPathname}${window?.location?.search ? window?.location?.search : ''}`
			);
		}
	}, [isAuthed, locale]);

	const renderRegisterRoutes = () =>
		(Object.keys(RegisterPageTypesE) as Array<keyof typeof RegisterPageTypesE>)?.map(
			(key) => {
				return (
					<Route
						key={pathPrefix + pathsConfig[key]?.link}
						path={pathPrefix + pathsConfig[key]?.link}
						element={<RegistrationPage type={RegisterPageTypesE[key]} />}
					/>
				);
			}
		);

	return (
		<>
			<Routes>
				{renderRegisterRoutes()}

				<Route
					path={pathPrefix + pathsConfig.paymentRedirect.link}
					element={<PaymentRedirectPage />}
				/>

				<Route
					path={pathPrefix + pathsConfig.home.link}
					element={<Home />}
				/>

				<Route
					path={pathPrefix + pathsConfig.home.tabs?.casino?.path}
					element={<Home />}
				/>

				<Route
					path={pathPrefix + pathsConfig.gameSearch.link}
					element={<GameSearch />}
				/>

				<Route
					path={pathPrefix + pathsConfig.payment.link}
					element={<Payment />}
				/>

				<Route
					path={pathPrefix + pathsConfig.providers.init.link}
					element={<Providers />}
				/>

				<Route
					path={pathPrefix + pathsConfig.providerGame.link}
					element={<CurrentProviderGame />}
				/>

				<Route
					path={pathPrefix + pathsConfig.referral.link}
					element={<ReferralBonus />}
				/>

				{!isTapX && (
					<Route
						path={pathPrefix + pathsConfig.bonus.link}
						element={<AllBonus />}
					/>
				)}

				{!isTapX && (
					<Route
						path={pathPrefix + pathsConfig.telegram_bonus.link}
						element={<TelegramBonus />}
					/>
				)}

				{!isTapX && (
					<Route
						path={pathPrefix + pathsConfig.cashback_bonus.link}
						element={<CashbackBonus />}
					/>
				)}

				{!isTapX && (
					<Route
						path={pathPrefix + pathsConfig.bgaming.link}
						element={<BgamingPrizes />}
					/>
				)}

				{!isTapX && (
					<Route
						path={pathPrefix + pathsConfig.levels.link}
						element={<LevelBonus />}
					/>
				)}

				{!isMobile && (
					<Route
						path={pathPrefix + pathsConfig.transaction.link}
						element={<CurrentUserTransactions />}
					/>
				)}

				{!isMobile && (
					<Route
						path={pathPrefix + pathsConfig.information.link}
						element={<InformationModal />}
					/>
				)}

				{!isMobile && (
					<Route
						path={pathPrefix + pathsConfig.profile.link}
						element={<Profile />}
					/>
				)}

				<Route
					path={pathPrefix + pathsConfig.politics_terms.link}
					element={
						<PoliticsRouteContentWrapper
							localizeNode={'PoliticsTerms'}
							paragraphQty={23}
						/>
					}
				/>

				{!isMobile && (
					<Route
						path={pathPrefix + pathsConfig.politics_privacy_policy.link}
						element={
							<PoliticsRouteContentWrapper
								localizeNode={'PoliticsPrivacyPolicy'}
								paragraphQty={5}
							/>
						}
					/>
				)}

				{!isMobile && (
					<Route
						path={pathPrefix + pathsConfig.politics_self_policy.link}
						element={
							<PoliticsRouteContentWrapper
								localizeNode={'PoliticsSelfPolicy'}
								paragraphQty={3}
							/>
						}
					/>
				)}

				{!isMobile && (
					<Route
						path={pathPrefix + pathsConfig.politics_aml_kyc_policy.link}
						element={
							<PoliticsRouteContentWrapper
								localizeNode={'PoliticsAmlKycPolicy'}
								paragraphQty={5}
							/>
						}
					/>
				)}

				{!isMobile && (
					<Route
						path={pathPrefix + pathsConfig.politics_terms_and_conditions.link}
						element={
							<PoliticsRouteContentWrapper
								localizeNode={'PoliticsTermsAndConditions'}
								paragraphQty={17}
							/>
						}
					/>
				)}

				<Route
					path={'*'}
					element={<NotFoundPage />}
				/>
			</Routes>
			<Outlet />
		</>
	);
};

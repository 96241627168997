import isEmpty from 'lodash/isEmpty';
import { PaymentService } from './PaymentService';
import {
	setPaymentMethodsInvoice,
	setPaymentMethodsWithdraw
} from '@store/reducers/payment/dispathcers';
import { Currencies, Roles } from '@types';

const getPaymentProvidersInvoice = async (currency: Currencies) => {
	const { status, data } = await PaymentService.paymentProvidersInvoice(currency);

	if (status === 200) {
		if (!isEmpty(data)) {
			setPaymentMethodsInvoice(data);
		} else {
			setPaymentMethodsInvoice([]);
		}
	}
};

const getPaymentProvidersWithdraw = async (currency: Currencies) => {
	const { status, data } = await PaymentService.paymentProvidersWithdraw(currency);

	if (status === 200) {
		if (!isEmpty(data)) {
			setPaymentMethodsWithdraw(data);
		} else {
			setPaymentMethodsWithdraw([]);
		}
	}
};

const getPaymentGroupsInvoice = async (currency: Currencies) => {
	const { status, data } = await PaymentService.paymentGroupsInvoice(currency);

	if (status === 200) {
		if (!isEmpty(data)) {
			setPaymentMethodsInvoice(data);
		} else {
			setPaymentMethodsInvoice([]);
		}
	}
};

const getPaymentGroupsWithdraw = async (currency: Currencies) => {
	const { status, data } = await PaymentService.paymentGroupsWithdraw(currency);

	if (status === 200) {
		if (!isEmpty(data)) {
			setPaymentMethodsWithdraw(data);
		} else {
			setPaymentMethodsWithdraw([]);
		}
	}
};

export const getPaymentProviders = async (currency: Currencies, role: Roles) => {
	const promiseArr =
		role === Roles.admin
			? [getPaymentGroupsInvoice(currency), getPaymentGroupsWithdraw(currency)]
			: [getPaymentProvidersInvoice(currency), getPaymentProvidersWithdraw(currency)];

	await Promise?.all(promiseArr);
};

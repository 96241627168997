import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocales, useUrlLang } from '@lib/hooks';
import { commonStatisticsSelectors } from '@store/reducers/common-statistics/selectors';
import { userSelectors } from '@store/reducers/user/selectors';
import s from './style.module.scss';
import cn from 'classnames';
import { formatNumber, renderCurrencySymbol, renderGameUrl } from '@utils';
import { getCurrentUserBets } from '@lib/api/statistics';
import { Preloader, Ripple } from '@components/common/elements';
import dayjs from 'dayjs';
import convertToUsers from '@utils/convertToUsers';

const CurrentUserBets = () => {
	const { currentUserBets } = useSelector(commonStatisticsSelectors.commonStatisticsInfo);
	const { currency } = useSelector(userSelectors.userInfo);

	const { langUrlPrefix } = useUrlLang();

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [loading, setLoading] = useState(true);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'CurrentUserBets'
	});

	const GamesViewerLocalize = useLocales({
		path: 'components/common/elements',
		node: 'GamesViewer'
	});

	useEffect(() => {
		getCurrentUserBets({ page }, setTotalPages, page > 1).finally(() =>
			setLoading(false)
		);
	}, [page]);

	const loadMoreBets = () => {
		setTimeout(() => {
			setLoading(true);
			setPage((p) => p + 1);
		}, 200);
	};

	return (
		<div className={s.betsList}>
			{currentUserBets.length === 0 ? (
				!loading && (
					<div className={s.emptyBlock}>
						<h3 className={s.emptyBet}>{localizeText('not_found_bet')}</h3>
					</div>
				)
			) : (
				<>
					{currentUserBets.map((bet, i) => {
						const isSuccess = bet.winAmount ?? 0 > 0;

						return (
							<div
								key={i}
								className={s.betItem}
							>
								<div className={cn(s.betCoef, { [s.success]: isSuccess })}>
									<span>x{bet.coefficient ?? 0}</span>
								</div>

								<div className={s.betInfoBlock}>
									<div className={s.nameDate}>
										<span className={s.name}>
											<Link to={langUrlPrefix + renderGameUrl(bet.gameKey)}>
												{bet.gameName}
											</Link>
										</span>
										<span className={s.date}>
											{dayjs(bet.createdAt).format('DD.MM.YYYY, hh:mm')}
										</span>
									</div>

									<div className={s.sumBlock}>
										<span className={cn(s.winAmount, { [s.success]: isSuccess })}>
											{isSuccess ? '+ ' : ''}
											{renderCurrencySymbol(currency?.toLowerCase())}{' '}
											{formatNumber(bet.winAmount ? convertToUsers(bet.winAmount) : 0)}
										</span>
										<span className={s.betAmount}>
											{localizeText('bet_amount')}{' '}
											{renderCurrencySymbol(currency?.toLowerCase())}{' '}
											{formatNumber(bet.amount ? convertToUsers(bet.amount) : 0)}
										</span>
									</div>
								</div>
							</div>
						);
					})}
					{totalPages > page && !loading && (
						<div className={s.btnBlock}>
							<button
								className={s.loadMoreButton}
								onClick={loadMoreBets}
							>
								{GamesViewerLocalize?.localizeText('load_more')}
								<Ripple />
							</button>
						</div>
					)}
				</>
			)}

			{loading && (
				<div className={s.preloaderBlock}>
					<Preloader className={s.preloader} />
				</div>
			)}
		</div>
	);
};

export default CurrentUserBets;

import React, { useRef, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { Button, FormError, Input, Preloader } from '@components/common/elements';
import { useLocales } from '@lib/hooks';
import { useFormik } from 'formik';
import { Settings } from '@types';
import { updateCurrentUserData } from '@lib/api/user';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { DefaultToast } from '@components/common/global-settings';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import s from './style.module.scss';

export const SetPasswordModal = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.min(6, 'invalid_password_length')
			.max(40, 'invalid_password_length')
			.required('need_fill_field')
	});

	const handleSubmit = async (values: { password: string }) => {
		const successFunc = () => {
			toast((t) => (
				<DefaultToast
					t={t}
					text={'password_success_set'}
				/>
			));

			setIsLoading(false);
			setModalType(null);
		};

		setIsLoading(true);
		await updateCurrentUserData(Settings.password, values?.password, successFunc);
	};

	const formik = useFormik({
		initialValues: {
			password: ''
		},
		validationSchema: validationSchema,
		onSubmit: handleSubmit
	});

	const ref = useRef(null);

	return (
		<div className={s.wrapper}>
			<Div100vh
				className={s.inner}
				ref={ref}
			>
				<form
					onSubmit={formik.handleSubmit}
					className={s.body}
				>
					<div className={s.titleBlock}>
						<div className={s.title}>{localizeText('set_pass_title')}</div>
						<div className={s.descr}>{localizeText('set_pass_descr')}</div>
					</div>

					<div className={s.inputBlock}>
						<Input
							name='password'
							type='password'
							value={formik?.values?.password}
							label={localizeText('password')}
							onChange={formik.handleChange}
							error={!!formik?.touched?.password && Boolean(formik?.errors?.password)}
						/>
						<FormError
							error={formik?.errors?.password && localizeText(formik?.errors?.password)}
							isTouched={!!formik?.touched?.password}
						/>
					</div>

					<Button
						className={s.btn}
						isDisabled={formik?.values?.password?.length < 1 || isLoading}
						text={
							isLoading ? (
								<Preloader styles={{ height: 12 }} />
							) : (
								localizeText('set_password')
							)
						}
						btnType='submit'
						rippleAnimation
					/>
				</form>
			</Div100vh>
		</div>
	);
};

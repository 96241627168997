import React from 'react';
import Countdown from 'react-countdown';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Button } from '@components/common/elements';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import { userSelectors } from '@store/reducers/user/selectors';
import { ProgressBar } from '@components/common/elements/progress-bar';
import { millisecondsUntilNextFriday, sendAnalyticsHandler, getImageByURL } from '@utils';
import { pathsConfig } from '@config/paths';
import { useNavigate } from 'react-router-dom';
import s from './style.module.scss';

type DataType = {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
};

export const CashbackBlock = ({ levelsBonus }: { levelsBonus?: boolean }) => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'LevelsBonuses'
	});

	const { langUrlPrefix } = useUrlLang();

	const navigate = useNavigate();

	const { userLevels, userLevelId } = useSelector(userSelectors.userInfo);

	const { isAuthed } = useSelector(authSelectors.authInfo);

	const currentLevel = userLevels?.find((e) => e?.id === userLevelId);

	const renderer = ({ days, hours, minutes, seconds }: DataType) => {
		return (
			<div className={s.counter}>
				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{days > 9 ? days?.toString()[0] : 0}</div>
						<div>{days > 9 ? days?.toString()[1] : days}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('day')}</span>
				</div>

				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{hours > 9 ? hours?.toString()[0] : 0}</div>
						<div>{hours > 9 ? hours?.toString()[1] : hours}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('hours')}</span>
				</div>

				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{minutes > 9 ? minutes?.toString()[0] : 0}</div>
						<div>{minutes > 9 ? minutes?.toString()[1] : minutes}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('minutes')}</span>
				</div>

				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{seconds > 9 ? seconds?.toString()[0] : 0}</div>
						<div>{seconds > 9 ? seconds?.toString()[1] : seconds}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('seconds')}</span>
				</div>
			</div>
		);
	};

	const msUntilNextFriday = millisecondsUntilNextFriday();

	return (
		<div className={s.container}>
			<div className={s.text_block}>
				<div
					id={'cashback_block'}
					className={s.imgBlock}
				>
					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/level-bonus/casback.webp')}
						alt='Cashback'
					/>
				</div>

				<div className={s.titleDescrBlock}>
					<h1 className={s.text_block_title}>{localizeText('cashback_title')}</h1>

					<div className={s.text_block_descr}>{localizeText('cashback_descr')}</div>
				</div>
			</div>

			<div className={s.counterBlock}>
				<div className={s.counterBlock_top}>
					<div className={s.text}>
						{isAuthed ? (
							<>
								<span>{currentLevel?.cashbackPercentage}%</span>
								<span>{localizeText('current_cashback')}</span>
							</>
						) : (
							<>
								<span>{localizeText('cashback_30')}</span>
								<span>{localizeText('cashback_30_descr')}</span>
							</>
						)}
					</div>

					{isAuthed && currentLevel ? (
						<div className={s.progress}>
							<ProgressBar
								currentValue={currentLevel?.cashbackPercentage}
								maxValue={30}
								customBg='var(--elements-status-fill-12-transperancy)'
								containerClassName={s.progress_container}
								fillerClassName={s.progress_filler}
								labelClassName={s.progress_label}
							/>

							<div className={s.percents}>
								<div>0%</div>
								<div>10%</div>
								<div>20%</div>
								<div>30%</div>
							</div>
						</div>
					) : null}

					<Button
						rippleAnimation
						className={s.contactBtn}
						type={ButtonType?.primary}
						handle={() => {
							isAuthed
								? navigate(langUrlPrefix + pathsConfig.cashback_bonus.link)
								: setModalType(ModalType.auth);

							if (!isAuthed) {
								levelsBonus
									? sendAnalyticsHandler(
											AnalyticsEventType?.levels_click_registration_cashback
										)
									: sendAnalyticsHandler(
											AnalyticsEventType?.all_bonuses_click_registration,
											{
												all_bonuses_block: 'Кешбек'
											}
										);
							} else {
								levelsBonus
									? sendAnalyticsHandler(
											AnalyticsEventType?.levels_click_payment_cashback
										)
									: sendAnalyticsHandler(AnalyticsEventType?.all_bonuses_click_cashback);
							}
						}}
					>
						{localizeText(isAuthed ? 'how_increase_cashback' : 'register_btn')}
					</Button>

					<img
						className={s.divider}
						alt='divider'
						src={getImageByURL(
							'../../../../../../assets/img/bonus/bonus_card_devider.webp'
						)}
					/>
				</div>

				<div className={s.counterBlock_bottom}>
					<div className={s.timer}>
						<div className={s.timer_title}>{localizeText('timer_title')}</div>

						<Countdown
							date={new Date(Date.now() + msUntilNextFriday)}
							renderer={renderer}
							daysInHours
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

import React from 'react';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Trans } from 'react-i18next';
import { Button } from '@components/common/elements';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { userSelectors } from '@store/reducers/user/selectors';
import { formatNumber, renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import s from './style.module.scss';
import cn from 'classnames';
import { ProgressBar } from '@components/common/elements/progress-bar';
import convertToUsers from '@utils/convertToUsers';
import { getImageByURL } from '@utils/getImageByURL';

export const TitleBlock = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'LevelsBonuses'
	});

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { isAuthed } = useSelector(authSelectors.authInfo);

	const { currency, userLevels, userLevelId, userLevelSum } = useSelector(
		userSelectors.userInfo
	);

	const currentLevel = userLevels?.find((e) => e?.id === userLevelId);
	const nextLevel =
		currentLevel && userLevels?.find((e) => e?.level === currentLevel?.level + 1);

	const currencySymbol = renderCurrencySymbol(currency?.toLowerCase());

	return (
		<div className={s.container}>
			<div className={s.text_block}>
				<h1 className={cn(s.text_block_title, { [s.authed]: isAuthed })}>
					<Trans
						ns='components/routes/translation'
						i18nKey={
							isAuthed
								? 'LevelsBonuses.levels_bonuses_title'
								: 'LevelsBonuses.levels_bonuses_title_nonAuthed'
						}
						values={{ curr: renderCurrencySymbol(currency) || '৳' }}
						components={{ 1: <b className={s.green} />, 2: <b className={s.gold} /> }}
					/>
				</h1>
				<div className={s.text_block_descr}>
					{localizeText('level_bonuses_description')}
				</div>

				<div className={s.btnsBlock}>
					{isAuthed ? (
						<div className={s.levelBlock}>
							<div
								onClick={() => setModalType(ModalType?.levelsTable)}
								className={s.withimg}
							>
								{currentLevel && currentLevel?.level && currentLevel?.level < 21 && (
									<img
										className={s.levelImg}
										src={getImageByURL(
											`../../../../../../../assets/img/levels/level_${currentLevel?.level}.webp`
										)}
									/>
								)}
								<div className={s.infoBlock}>
									<div className={s.infoBlock_name}>{currentLevel?.name}</div>
									{currentLevel && (
										<>
											<img
												src={getImageByURL(
													'../../../../../../assets/img/common/coin_without_space.webp'
												)}
												alt='2X'
												className={s.coin_img_button}
											/>
											<ProgressBar
												currentValue={userLevelSum - currentLevel?.wager}
												maxValue={
													nextLevel
														? nextLevel?.wager - currentLevel?.wager
														: userLevelSum - currentLevel?.wager
												}
												isDesktop
											/>
											<div style={{ display: 'flex' }}>
												<span
													className={s.infoBlock_betSum}
												>{`${localizeText('win')} ${currencySymbol} ${formatNumber(convertToUsers(nextLevel ? userLevelSum : currentLevel?.wager))} `}</span>
												<span
													className={s.infoBlock_wager}
													style={{ marginLeft: '2px', marginRight: '2px' }}
												>
													/
												</span>
												<span
													className={s.infoBlock_wager}
												>{` ${currencySymbol} ${formatNumber(convertToUsers(nextLevel ? nextLevel?.wager : currentLevel?.wager))}`}</span>
											</div>
										</>
									)}
								</div>
							</div>

							<Button
								rippleAnimation
								className={s.contactBtn}
								type={ButtonType?.primary}
								handle={() => {
									sendAnalyticsHandler(AnalyticsEventType?.levels_click_payment);

									navigate(langUrlPrefix + pathsConfig?.payment?.link);
								}}
							>
								{localizeText('top_up_btn')}
							</Button>
						</div>
					) : (
						<Button
							rippleAnimation
							className={s.contactBtn}
							type={ButtonType?.primary}
							handle={() => {
								sendAnalyticsHandler(AnalyticsEventType?.levels_click_registration_main);
								setModalType(ModalType.auth);
							}}
						>
							{localizeText('register_btn')}
						</Button>
					)}

					<Button
						rippleAnimation
						className={s.videoIsnructionBtn}
						type={ButtonType?.secondary}
						handle={() => {
							sendAnalyticsHandler(AnalyticsEventType?.levels_click_list);
							setModalType(ModalType.levelsTable);
						}}
					>
						{localizeText('level_list')}
					</Button>
				</div>
			</div>

			<img
				className={s.img}
				src={getImageByURL('../../../../../../assets/img/level-bonus/title.webp')}
				alt='Level bonuses'
			/>
		</div>
	);
};

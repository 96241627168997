import React, { useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { useSelector } from 'react-redux';
import { useLocales } from '@lib/hooks';
import { getWaggeringGames } from '@utils';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { CloseSvg } from '@components/svg';
import { userSelectors } from '@store/reducers/user/selectors';
import { IProviderGame, ProviderGamesType } from '@types';
import { CardPreloader, ProviderGameCard } from '@components/common/elements';

import s from './style.module.scss';
import cn from 'classnames';

export const WageringGamesModal = () => {
	const { bonusBalance } = useSelector(userSelectors.userInfo);

	const closeModalHanler = () => {
		setModalType(null);
	};

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'WageringGamesModal'
	});

	const [wageringGames, setWageringGames] = useState<IProviderGame[]>();

	useEffect(() => {
		if (bonusBalance) {
			getWaggeringGames(setWageringGames);
		}
	}, [bonusBalance]);

	return (
		<Div100vh className={cn(s.content)}>
			<div className={cn(s.wrapper, s.opened)}>
				<div className={s.header}>
					<div className={s.headerWrapper}>
						<div className={s.headerTitle}>{localizeText('games_list')}</div>
						<div
							onClick={closeModalHanler}
							className={s.headerClose}
						>
							<CloseSvg />
						</div>
					</div>
				</div>

				<div className={s.description}>{localizeText('description')}</div>

				<div className={s.games}>
					{wageringGames?.length === 0 ? (
						<CardPreloader length={6} />
					) : (
						wageringGames?.map((game) => (
							<ProviderGameCard
								className={s.gamesViewerCard}
								key={game.key}
								providerName={game.providerName}
								route={game.key}
								name={game.name}
								img={game.image}
								onlyImage={true}
								isFavourite={game?.isFavourite}
								id={game?.id}
								providerGamesType={ProviderGamesType.providerGames}
								isDemo={game?.isDemo}
								isCloseModal
								isFreezeBalancePlay={game?.isFreezeBalancePlay}
							/>
						))
					)}
				</div>
			</div>
		</Div100vh>
	);
};

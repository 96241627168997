import { instance } from '@lib/api';
import {
	AxiosResponsePromise,
	CommonResponse,
	ICommonStatisticsInitialState
} from '@types';
import { BetsRequestOptions } from './getCurrentUserBets';
import { TransRequestOptions } from './getCurrentUserTransactions';

type GetCurrentUserTransactions = (
	params: TransRequestOptions
) => AxiosResponsePromise<
	CommonResponse<{ data: ICommonStatisticsInitialState['currentUserTransactions'] }>
>;
type GetCurrentUserBets = (
	params: BetsRequestOptions
) => AxiosResponsePromise<
	CommonResponse<{ data: ICommonStatisticsInitialState['currentUserBets'] }>
>;

interface ICommonStatisticsService {
	currentUserTransactions: GetCurrentUserTransactions;
	currentUserBets: GetCurrentUserBets;
}

enum CommonStatisticsEndpoints {
	currentUserTransactions = '/payment/history/transactions',
	currentUserBets = '/games/my/bets'
}

export const StatisticsService: ICommonStatisticsService = {
	currentUserTransactions: async (params) =>
		await instance.get(CommonStatisticsEndpoints.currentUserTransactions, { params }),
	currentUserBets: async (params) =>
		await instance.get(CommonStatisticsEndpoints.currentUserBets, { params })
};

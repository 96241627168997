import React, { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLocales } from '@lib/hooks';
import {
	setIsSidebarOpened,
	setIsSidebarTabletOpened
} from '@store/reducers/common-ui/dispatchers';
import s from './style.module.scss';
import cn from 'classnames';
import { MenuSidebarIcons } from '@components/svg';
import { Ripple } from '@components/common/elements';
import { AnalyticsEventType, MenuSideBarNames } from '@types';
import { userSelectors } from '@store/reducers/user/selectors';
import { sendAnalyticsHandler } from '@utils';
import { useSelector } from 'react-redux';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { authSelectors } from '@store/reducers/auth/selectors';
import { getImageByURL } from '@utils/getImageByURL';

export type NavbarItemP = {
	isRoute: boolean;
	link: string;
	nameKey: keyof typeof MenuSideBarNames;
	handle?: () => void;
	isSideBarOpened: boolean;
};

export const NavbarItem = (props: NavbarItemP) => {
	const { isVerified } = useSelector(userSelectors.userInfo);
	const { isRoute, nameKey, link, handle, isSideBarOpened } = props;

	const { isAuthed } = useSelector(authSelectors.authInfo);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'linkNames'
	});

	const renderNameKey = (name: string) => {
		switch (name) {
			default:
				return localizeText(name);
		}
	};

	const itemHandler = () => {
		handle ? handle() : () => null;

		sendAnalyticsHandler(AnalyticsEventType?.main_menu_click, {
			section: nameKey
		});
	};

	return (
		<>
			<NavbarItemWrapper
				isRoute={isRoute}
				link={link}
				handle={itemHandler}
				isSideBarOpened={isSideBarOpened}
				section={nameKey}
			>
				<div className={s.navbar_item_icon}>
					<MenuSidebarIcons name={nameKey} />
				</div>

				{isSideBarOpened && (
					<span className={s.navbar_item_text}>{renderNameKey(nameKey)}</span>
				)}

				{isSideBarOpened &&
					MenuSideBarNames?.settings === nameKey &&
					!isVerified &&
					isAuthed && (
						<div className={s.confirmAccount}>
							{localizeText('confirm_warn')}
							<img
								src={getImageByURL('../../../../../../../assets/img/common/Mail.webp')}
								alt='2X'
							/>
						</div>
					)}

				<Ripple />
			</NavbarItemWrapper>
		</>
	);
};

type NavbarItemWrapperP = {
	isRoute: boolean;
	link: string;
	handle: () => void;
	isSideBarOpened?: boolean;
	children: ReactNode;
	section?: string;
};

const NavbarItemWrapper = (props: NavbarItemWrapperP) => {
	const { isRoute, link, handle, children, isSideBarOpened, section } = props;
	const { isTablet } = useSelector(commonUISelectors.commonUIInfo);

	const location = useLocation();

	return isRoute ? (
		<Link
			onClick={() => {
				setIsSidebarOpened(false);
				sendAnalyticsHandler(AnalyticsEventType?.main_menu_click, {
					section
				});
				isTablet && isSideBarOpened && setIsSidebarTabletOpened(!isSideBarOpened);
			}}
			className={cn(s.navbar_item, {
				[s.current]: location?.pathname + location?.search === link
			})}
			to={link}
		>
			{children}
		</Link>
	) : (
		<div
			className={s.navbar_item}
			onClick={handle}
		>
			{children}
		</div>
	);
};

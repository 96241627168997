/* eslint-disable react-hooks/rules-of-hooks */
import { RefObject, useEffect, useRef } from 'react';
import s from '../../components/common/page/header/style.module.scss';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';

const useShowSidebarButton = (args: {
	isSidebarOpened: boolean;
	buttonRef: RefObject<HTMLButtonElement>;
	element: 'header' | 'auth-sidebar' | 'non-auth-sidebar';
	shouldHide?: boolean;
}) => {
	const { isSidebarOpened, buttonRef, element, shouldHide } = args;

	const { pathname } = useLocation();
	const paths = ['/all-bonuses', '/payment'];
	const { isAuthed } = useSelector(authSelectors.authInfo);

	const pathsApprove = paths.some((e) => pathname.includes(e));
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	const updateButtonDisplay = (displayAttribute: string, delay: number) => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		timerRef.current = setTimeout(() => {
			if (buttonRef.current) {
				const classesToRemove = [
					'menuBtnHide',
					'menuBtnShow',
					'menuBtnHidden',
					'menuBtnVisible'
				];

				classesToRemove.forEach((className) => {
					buttonRef.current?.classList.remove(s[className]);
				});

				const className = s[displayAttribute];

				buttonRef.current.classList.add(className);
			}
		}, delay);
	};

	useEffect(() => {
		const handleUpdateButtonDisplay = () => {
			switch (element) {
				case 'header':
					if (isSidebarOpened && !shouldHide) {
						updateButtonDisplay('menuBtnHidden', 400);
					} else if (shouldHide || (pathsApprove && isAuthed)) {
						updateButtonDisplay('menuBtnHidden', 0);
					} else {
						updateButtonDisplay('menuBtnVisible', 0);
					}

					break;
				case 'auth-sidebar':
					updateButtonDisplay(
						isSidebarOpened ? 'menuBtnShow' : 'menuBtnHide',
						isSidebarOpened ? 400 : 0
					);

					break;
				case 'non-auth-sidebar':
					updateButtonDisplay(
						isSidebarOpened ? 'menuBtnShow' : 'menuBtnHide',
						isSidebarOpened ? 400 : 0
					);
					break;
				default:
					break;
			}
		};

		handleUpdateButtonDisplay();

		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [isSidebarOpened, shouldHide, element, buttonRef, isAuthed, pathname, pathsApprove]);
};

export default useShowSidebarButton;

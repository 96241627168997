import React from 'react';
import { AnalyticsEventType } from '@types';
import { isTapX } from '@config/global';
import { sendAnalyticsHandler } from '@utils';
import { pathsConfig } from '@config/paths';
import { useUrlLang } from '@lib/hooks';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import s from './style.module.scss';

export const CashbackBanner = () => {
	const { langUrlPrefix } = useUrlLang();

	const navigate = useNavigate();

	const goToCashback = () => {
		navigate(langUrlPrefix + pathsConfig.cashback_bonus.link);

		sendAnalyticsHandler(AnalyticsEventType?.advertising_click_banner, {
			banner_place: 'главная карусель',
			banner_name: 'Cashback',
			banner_position: 2
		});
	};

	return (
		<div
			onClick={goToCashback}
			className={cn(s.wrapper, {
				[s.tapX]: isTapX
			})}
		/>
	);
};

import React from 'react';
import { useLocales } from '@lib/hooks';
import { userSelectors } from '@store/reducers/user/selectors';
import { formatNumber, renderCurrencySymbol } from '@utils';
import { useSelector } from 'react-redux';
import { Button } from '@components/common/elements';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { ButtonType, ModalType } from '@types';
import s from './style.module.scss';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import cn from 'classnames';
import { ProgressBar } from '@components/common/elements/progress-bar';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import convertToUsers from '@utils/convertToUsers';
import { getImageByURL } from '@utils/getImageByURL';

export const ListLevels = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'LevelsBonuses'
	});

	const { currency, userLevels, userLevelId, userLevelSum } = useSelector(
		userSelectors.userInfo
	);
	const { isTablet } = useSelector(commonUISelectors.commonUIInfo);
	const { allRMWBonus } = useSelector(bonusSelectors.bonusInfo);

	const levels = [...userLevels];

	const currentLevel = userLevels?.find((e) => e?.id === userLevelId);
	const nextLevel =
		currentLevel && userLevels?.find((e) => e?.level === currentLevel?.level + 1);

	return (
		<>
			<div className={s.container}>
				<div className={s.block}>
					<div className={s.text_block}>
						<img
							alt='Last paid'
							className={s.img}
							src={getImageByURL('../../../../../../assets/img/level-bonus/levels.webp')}
						/>
						<h1 className={s.text_block_title}>{localizeText('gift_title')}</h1>

						<span className={s.text_block_descr}>
							{localizeText('gift_text', { curr: renderCurrencySymbol(currency) || '৳' })}
						</span>

						{!isTablet && (
							<Button
								rippleAnimation
								className={s.levelListBtn}
								type={ButtonType?.secondary}
								handle={() => setModalType(ModalType.levelsTable)}
							>
								{localizeText('level_list')}
							</Button>
						)}
					</div>

					<div className={s.levelsList}>
						{levels?.map((l) => {
							const bonuses = allRMWBonus?.find(
								(bonus) =>
									bonus?.config?.bonus &&
									bonus?.config?.freespin &&
									bonus?.config?.userLevel === l?.level
							);

							const isPrevious = currentLevel && currentLevel?.level > l?.level;

							const isCurrent = currentLevel && currentLevel?.level === l?.level;

							return (
								<div
									className={cn(s.itemWrapper, { [s.current]: isCurrent })}
									key={l?.id}
								>
									<div className={cn(s.levelsList_item, { [s.current]: isCurrent })}>
										{l?.cashbackPercentage ? (
											<div className={cn(s.сashback, { [s.current]: isCurrent })}>
												<b>{l?.cashbackPercentage}%</b>{' '}
												<span>{localizeText('сashback')}</span>
											</div>
										) : null}
										<div className={s.levelsList_item_top}>
											{l && l?.level < 21 && (
												<img
													className={s.levelImg}
													src={getImageByURL(
														`../../../../../../../assets/img/levels/level_${l?.level}.webp`
													)}
												/>
											)}

											<div className={s.levelInfo}>
												<span className={s.levelInfo_name}>{l?.name}</span>
												{userLevelSum !== undefined && isCurrent ? (
													<ProgressBar
														containerClassName={s.progressBarContainer}
														currentValue={userLevelSum - l?.wager}
														maxValue={
															nextLevel
																? nextLevel?.wager - l?.wager
																: userLevelSum - l?.wager
														}
														customBg='rgba(231, 236, 245, 0.06)'
													/>
												) : null}
												<span
													className={cn(s.levelInfo_won, {
														[s.active]: isPrevious || isCurrent
													})}
												>
													{`${localizeText(isPrevious ? 'won' : isCurrent ? 'win' : 'to_win')} ` +
														`${renderCurrencySymbol(currency) || '৳'} ` +
														`${isCurrent ? formatNumber(convertToUsers(nextLevel ? userLevelSum : l?.wager)) : formatNumber(convertToUsers(l?.wager))}`}
													{isCurrent && userLevelSum !== undefined ? (
														<b>{` / ${renderCurrencySymbol(currency) || '৳'} ${formatNumber(convertToUsers(nextLevel ? nextLevel?.wager : currentLevel?.wager))}`}</b>
													) : null}
												</span>

												{bonuses && (
													<div
														className={cn(s.levelInfo_bottom, {
															[s.active]: isPrevious || isCurrent
														})}
													>
														{bonuses?.config?.bonus?.amount ? (
															<div className={s.bonusBlock}>
																{renderCurrencySymbol(currency) || '৳'}{' '}
																{formatNumber(
																	convertToUsers(bonuses?.config?.bonus?.amount)
																)}
																<img
																	className={s.coinImg}
																	src={getImageByURL(
																		'../../../../../../assets/img/common/coin_without_space.webp'
																	)}
																/>
															</div>
														) : null}

														{bonuses?.config?.bonus?.amount &&
														bonuses?.config?.freespin?.amount ? (
															<div className={s.plusBlock}>+</div>
														) : null}

														{bonuses?.config?.freespin?.amount ? (
															<div className={s.freespinBlock}>
																{bonuses?.config?.freespin?.amount} FS
																<img
																	className={s.fsImg}
																	src={getImageByURL(
																		'../../../../../../assets/img/common/free_spins.webp'
																	)}
																/>
															</div>
														) : null}
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>

					{isTablet && (
						<Button
							rippleAnimation
							className={s.levelListBtn}
							type={ButtonType?.secondary}
							handle={() => setModalType(ModalType.levelsTable)}
						>
							{localizeText('level_list')}
						</Button>
					)}
				</div>

				<div className={s.divider} />
			</div>
		</>
	);
};

import React from 'react';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { AnalyticsEventType, ModalType } from '@types';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Trans } from 'react-i18next';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import { sendAnalyticsHandler } from '@utils';
import { userSelectors } from '@store/reducers/user/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import cn from 'classnames';
import { getImageByURL } from '@utils/getImageByURL';

type PropsType = { goFunc?: () => void; isMenu?: boolean };

export const RegisterBonus = (props: PropsType) => {
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { successInvoiceCount } = useSelector(userSelectors.userInfo);
	const { isMobile, isTablet } = useSelector(commonUISelectors.commonUIInfo);

	const { localizeText } = useLocales({
		path: 'components/common/sections',
		node: 'ExtraOffers'
	});

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { goFunc, isMenu } = props;

	return (
		<div
			onClick={() => {
				isAuthed
					? navigate(langUrlPrefix + pathsConfig.payment.link)
					: setModalType(ModalType.auth);

				sendAnalyticsHandler(
					isMenu
						? AnalyticsEventType?.menu_advertising_click_banner
						: AnalyticsEventType?.advertising_click_banner,
					{
						banner_place: 'Хедер реклама',
						banner_name: isAuthed ? 'Double bonus' : 'Go to register',
						banner_position: 1
					}
				);

				goFunc && goFunc();
			}}
			className={cn(s.card, {
				[s.cardSuccessCounter]: !isMobile && !isTablet && successInvoiceCount <= 5
			})}
		>
			<div className={s.textBlock}>
				<span className={s.textBlock_title}>
					<Trans
						ns='components/common/sections/translation'
						i18nKey={
							isAuthed
								? 'ExtraOffers.register_x2_bonus_authed'
								: 'ExtraOffers.register_x2_bonus'
						}
						components={{
							g: <b className={s.goldPlus} />,
							fs: <b className={s.freeSpins} />
						}}
					/>
				</span>
				<span className={s.textBlock_descr}>
					{localizeText('register_x2_bonus_descr')}
				</span>
			</div>
			<img
				className={s.money_img}
				src={getImageByURL('../../../../../../../assets/img/common/register_bonus.webp')}
				alt='Register Bonus'
			/>
		</div>
	);
};

import React from 'react';
import { useLocales } from '@lib/hooks';
import s from './style.module.scss';
import cn from 'classnames';
import { getImageByURL } from '@utils/getImageByURL';

export const HowIncrease = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'CashbackBonuses'
	});

	return (
		<div className={s.container}>
			<div className={s.text_block}>
				<h1 className={s.text_block_title}>{localizeText('how_increase_cashback')}</h1>
			</div>

			<div className={s.cards_block}>
				<div className={s.cards_block_item}>
					<div className={s.cards_block_item_text}>
						<div className={s.title}>
							{localizeText('how_increase_cashback_card_title_1')}
						</div>
						<div className={s.descr}>
							{localizeText('how_increase_cashback_card_descr_1')}
						</div>
					</div>

					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/cashback/card_1.webp')}
						alt='Found an error?'
					/>
				</div>
				<div className={cn(s.cards_block_item, s.color_2)}>
					<div className={s.cards_block_item_text}>
						<div className={s.title}>
							{localizeText('how_increase_cashback_card_title_2')}
						</div>
						<div className={s.descr}>
							{localizeText('how_increase_cashback_card_descr_2')}
						</div>
					</div>
					<img
						className={cn(s.img, s.img_2)}
						src={getImageByURL('../../../../../../assets/img/cashback/card_2.webp')}
						alt='Found an error?'
					/>
				</div>

				<div className={cn(s.cards_block_item, s.color_3)}>
					<div className={s.cards_block_item_text}>
						<div className={s.title}>
							{localizeText('how_increase_cashback_card_title_3')}
						</div>
						<div
							className={s.descr}
							style={{ color: 'rgba(255, 255, 255, 0.75)' }}
						>
							{localizeText('how_increase_cashback_card_descr_3')}
						</div>
					</div>

					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/cashback/card_3.webp')}
						alt='Found an error?'
					/>
				</div>
			</div>
		</div>
	);
};

import React, { ReactNode } from 'react';
import s from './style.module.scss';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { pathsConfig } from '@config/paths';

type StaticContainerP = {
	children: ReactNode;
	className?: string;
};

export const StaticContainer = ({ children, className }: StaticContainerP) => {
	const { pathname } = useLocation();

	return (
		<div
			className={cn(s.container, className, {
				[s.flex]:
					pathsConfig?.sign_up_1?.link && pathname?.includes(pathsConfig?.sign_up_1?.link)
			})}
		>
			{children}
		</div>
	);
};

import React from 'react';
import { Button } from '@components/common/elements';
import { PersonSignInSvg } from '@components/svg';
import s from './style.module.scss';
import { useUrlLang } from '@lib/hooks';
import {
	AnalyticsEventType,
	AuthType,
	ButtonType,
	ModalType,
	SessionStorageParams
} from '@types';
import { sendAnalyticsHandler } from '@utils';
import cn from 'classnames';
import { setAuthType } from '@store/reducers/auth/dispathcers';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { useNavigate } from 'react-router-dom';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { useGetButtonsLang } from '@lib/hooks/useGetAuthButtonsLang';

export const Buttons = () => {
	const { modalType } = useSelector(commonUISelectors.commonUIInfo);

	const { langUrlPrefix } = useUrlLang();
	const navigate = useNavigate();
	const isSignIn = modalType === ModalType.signIn;

	const { renderLangSignIn, renderLangSignUp } = useGetButtonsLang();

	return (
		<div className={s.buttonsWrapper}>
			<Button
				rippleAnimation
				text={renderLangSignUp()}
				type={isSignIn ? ButtonType?.secondary : ButtonType.primary}
				className={cn(s.entryBtn, s.entryBtnSignUp)}
				handle={() => {
					sendAnalyticsHandler(AnalyticsEventType?.header_click_registration);
					setAuthType(AuthType.signUp);
					setModalType(ModalType.auth);
				}}
			/>

			<Button
				rippleAnimation
				text={
					<>
						<PersonSignInSvg
							className={s.personImg}
							fill='var(--main-secondary)'
						/>
						{renderLangSignIn()}
					</>
				}
				type={ButtonType.secondary}
				className={cn(s.entryBtn, s.entryBtnSignIn)}
				handle={() => {
					sendAnalyticsHandler(AnalyticsEventType?.header_click_singin);
					if (isSignIn) {
						setAuthType(null);
						setModalType(null);

						const returnGame = sessionStorage.getItem(SessionStorageParams?.returnGame);

						if (returnGame) {
							navigate && navigate(langUrlPrefix + returnGame);
							sessionStorage.removeItem(SessionStorageParams?.returnGame);
						}
					} else {
						setAuthType(AuthType.signIn);
						setModalType(ModalType.signIn);
					}
				}}
			/>
		</div>
	);
};

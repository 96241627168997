import React from 'react';
import { useLocales } from '@lib/hooks';
import { LogOut } from '@components/svg';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { AnalyticsEventType, ModalType } from '@types';
import { sendAnalyticsHandler } from '@utils';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import cn from 'classnames';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';

const LogOutBlock = ({ isSideBarOpened = true }: { isSideBarOpened?: boolean }) => {
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isMobile } = useSelector(commonUISelectors?.commonUIInfo);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Profile'
	});

	const logoutHandler = () => {
		sendAnalyticsHandler(
			isMobile
				? AnalyticsEventType?.menu_click_logout
				: AnalyticsEventType?.new_profile_log_out
		);
		setModalType(ModalType?.logout);
	};

	if (!isAuthed) {
		return null;
	}

	return (
		<>
			<button
				onClick={logoutHandler}
				className={cn(s.button, { [s.short]: !isSideBarOpened })}
			>
				<LogOut />
				{isSideBarOpened && (
					<span className={cn(s.logout, { [s.logoutSidebar]: isSideBarOpened })}>
						{localizeText('logout')}
					</span>
				)}
			</button>
			<div className={s.devider}>
				<div className={s.devider_line} />
			</div>
		</>
	);
};

export default LogOutBlock;

import React, { useEffect, useRef, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { CloseSvg } from '@components/svg';
import Div100vh from 'react-div-100vh';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import CurrentUserBets from './CurrentUserBets';
import CurrentUserTransactionsBody from './CurrentUserTransactions';
import s from './style.module.scss';
import cn from 'classnames';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { GoHome } from '@components/common/elements';
import { Helmet } from 'react-helmet';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';

const tabs = [
	{ key: 'bets', name: 'bets' },
	{ key: 'transaction', name: 'transaction' }
];

export const CurrentUserTransactions = () => {
	const [selectedTab, setSelectedTab] = useState(tabs[0]?.key);
	const [offset, setOffset] = useState(0);

	const activeRef = useRef<HTMLDivElement>(null);

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { localizeText } = useLocales({
		path: 'global',
		node: 'routes'
	});

	const closeModal = () => {
		setTimeout(() => {
			setModalType(null);
		}, 200);
	};

	const offsetStyle = {
		'--offset': offset + 'px'
	} as React.CSSProperties;

	useEffect(() => {
		const activeElement = activeRef.current;

		if (activeElement) {
			setOffset(activeElement?.offsetLeft);
		}
	}, [selectedTab, activeRef]);

	return (
		<>
			<Helmet>
				<link
					rel='canonical'
					href={window?.location?.href}
				/>
			</Helmet>
			<div className={s.wrapper}>
				<Div100vh className={s.inner}>
					{isMobile ? (
						<div className={s.header}>
							<div className={s.headerWrapper}>
								<div className={s.headerTitle}>
									{localizeText('currentUserTransactions.title')}
								</div>
								<div
									onClick={closeModal}
									className={s.headerClose}
								>
									<CloseSvg />
								</div>
							</div>
						</div>
					) : (
						<GoHome />
					)}

					<div className={s.typeBlock}>
						<div className={s.tabList}>
							{tabs?.map((t) => {
								const { key, name } = t;

								const isActive = selectedTab === key;

								return (
									<div
										key={key}
										ref={isActive ? activeRef : null}
										className={cn(s.tabItem, { [s.active]: isActive })}
										onClick={() => {
											setSelectedTab(key);

											sendAnalyticsHandler(AnalyticsEventType?.transaction_click_tab, {
												transaction_tab_name: key
											});
										}}
									>
										<h4 className={s.tabItemName}>
											{localizeText(`currentUserTransactions.${name}`)}
										</h4>
									</div>
								);
							})}

							<span
								style={offsetStyle}
								className={s.bar}
							/>
						</div>

						{selectedTab === 'bets' ? (
							<CurrentUserBets />
						) : (
							<CurrentUserTransactionsBody />
						)}
					</div>
				</Div100vh>
			</div>
		</>
	);
};

import React from 'react';
import { LocalizeText } from '@types';
import { Button } from '@components/common/elements';
import { CommentSvg } from '@components/svg';
import { openCarrotChat } from '@utils';
import s from './style.module.scss';
import cn from 'classnames';

type BottomP = {
	localizeText: LocalizeText;
	isSideBarOpened: boolean;
};

export const Bottom = (props: BottomP) => {
	const { localizeText, isSideBarOpened } = props;

	return (
		<div className={s.wrapper}>
			<div className={cn(s.support, { [s.hidden]: !isSideBarOpened })}>
				<span className={s.support_title}>
					{isSideBarOpened ? localizeText('support_24_7') : '24/7'}
				</span>
				<div className={s.support_btns}>
					<Button
						rippleAnimation
						className={s.btn}
						handle={openCarrotChat}
					>
						<CommentSvg />
						{isSideBarOpened && localizeText('support')}
					</Button>
				</div>
			</div>
		</div>
	);
};

import React, { ReactElement } from 'react';

import { Card } from './invoice-types';

import { InvoiceResolverP } from '../../../../type';
import { PaymentMethodType } from '@types';

export const InvoiceResolver = (props: InvoiceResolverP) => {
	const {
		paymentMethodType,
		invoiceValue,
		localizeText,
		promocodeValue,
		isNotSuitableAmount,
		isPromocodeForbiddenForUsing,
		currentPaymentMethod,
		setInnerPlatformFlow,
		setDataForPay,
		calculateFSBonusHandler,
		innerPlatformFlow,
		paymentType,
		openVideoHandler,
		doubleDeposBonus
	} = props;

	const getLayout = (): ReactElement => {
		switch (paymentMethodType) {
			case PaymentMethodType.fiat:
				return (
					<Card
						paymentMethodType={paymentMethodType}
						invoiceValue={invoiceValue}
						localizeText={localizeText}
						promocodeValue={promocodeValue}
						isNotSuitableAmount={isNotSuitableAmount}
						isPromocodeForbiddenForUsing={isPromocodeForbiddenForUsing}
						currentPaymentMethod={currentPaymentMethod}
						setInnerPlatformFlow={setInnerPlatformFlow}
						setDataForPay={setDataForPay}
						calculateFSBonusHandler={calculateFSBonusHandler}
						innerPlatformFlow={innerPlatformFlow}
						paymentType={paymentType}
						openVideoHandler={openVideoHandler}
						doubleDeposBonus={doubleDeposBonus}
					/>
				);
			default:
				return <></>;
		}
	};

	return getLayout();
};

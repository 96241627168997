import React from 'react';
import Div100vh from 'react-div-100vh';
import { Button } from '@components/common/elements';
import { ButtonType } from '@types';
import { CloseSvg } from '@components/svg';
import { useLocales, useUrlLang } from '@lib/hooks';
import { useNavigate } from 'react-router-dom';
import s from './style.module.scss';
import { pathsConfig } from '@config/paths';
import { getImageByURL } from '@utils/getImageByURL';

type PropsType = {
	closeModalHandler: () => void;
	withdrawalHandler: () => void;
};

export const BonusdDeletedModal = (props: PropsType) => {
	const { closeModalHandler, withdrawalHandler } = props;
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Payment'
	});

	const { langUrlPrefix } = useUrlLang();
	const navigate = useNavigate();

	return (
		<Div100vh
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) {
					closeModalHandler();
				}
			}}
			className={s.modal}
		>
			<div className={s.modalInner}>
				<div className={s.headBlock}>
					<h2>{localizeText('withdrawal_of_funds')}</h2>

					<button
						onClick={closeModalHandler}
						className={s.headBlock_close}
					>
						<CloseSvg />
					</button>
				</div>
				<div className={s.bottomBlock}>
					<div className={s.imageBlock}>
						<img
							alt='Bonus warning'
							src={getImageByURL(
								'../../../../../../../../../../assets/img/payments/bonus_warning.webp'
							)}
							className={s.warnImg}
						/>
					</div>

					<div className={s.text}>{localizeText('bonus_warn_text')}</div>

					<div className={s.btns}>
						<Button
							handle={withdrawalHandler}
							className={s.bottomBlock_withdrawalBtn}
							type={ButtonType?.primary}
							text={localizeText('withdraw_anyway')}
						/>
						<Button
							handle={() => navigate(langUrlPrefix + pathsConfig?.bonus?.link)}
							className={s.bottomBlock_btn}
							type={ButtonType?.secondary}
							text={localizeText('all_bonuses')}
						/>
					</div>
				</div>
			</div>
		</Div100vh>
	);
};

import React from 'react';
import { Button } from '@components/common/elements';
import { referralPendingRewards } from '@lib/api/bonus';
import { WithdrawalP } from '../../type';
import { ButtonType, ModalType } from '@types';
import { formatNumber, renderCurrencySymbol } from '@utils';
import { useSelector } from 'react-redux';
import { useLocales } from '@lib/hooks';
import { userSelectors } from '@store/reducers/user/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import s from './style.module.scss';
import convertToUsers from '@utils/convertToUsers';

export const Withdrawal = (props: WithdrawalP) => {
	const { pendingRewardsAmount, totalRewardsSum } = props;

	const { currency, isReferralFroze } = useSelector(userSelectors.userInfo);

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Referral'
	});

	const handleButtonClick = () => {
		if (isReferralFroze) {
			setModalType(ModalType.withdrawalBlocked);
		} else {
			referralPendingRewards();
		}
	};

	return (
		<div className={s.topBlock}>
			<div className={s.refBalance}>
				<span className={s.refBalance_amount}>
					{renderCurrencySymbol(currency?.toLowerCase())}{' '}
					{formatNumber(convertToUsers(pendingRewardsAmount))}
				</span>

				<div className={s.refsTotalAllTime}>
					<span>{localizeText('refs_total_all_time')}</span>
					<span>
						{renderCurrencySymbol(currency?.toLowerCase())}{' '}
						{formatNumber(totalRewardsSum ? convertToUsers(totalRewardsSum) : 0)}
					</span>
				</div>
			</div>

			<Button
				text={localizeText('withdrawal')}
				handle={handleButtonClick}
				isDisabled={pendingRewardsAmount === 0}
				className={s.btn}
				type={ButtonType?.primary}
				rippleAnimation
			/>
		</div>
	);
};

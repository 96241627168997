import React from 'react';
import { useLocales } from '@lib/hooks';
import s from './style.module.scss';
import { InfoWarn } from '@components/svg';

const WarningVerified = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Profile'
	});

	return (
		<div className={s.block}>
			<div className={s.text}>{localizeText('verify_email_phone')}</div>

			<InfoWarn />
		</div>
	);
};

export default WarningVerified;

import React from 'react';
import { AwardsListP } from '../../type';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import { formatNumber, renderCurrencySymbol } from '@utils';
import { Trans } from 'react-i18next';
import dayjs from 'dayjs';
import convertToUsers from '@utils/convertToUsers';
import { getImageByURL } from '@utils/getImageByURL';

export const ReferralList = (props: AwardsListP) => {
	const { rewards, localizeText } = props;

	const { userLevels, currency } = useSelector(userSelectors.userInfo);

	return (
		<div className={s.wrapper}>
			<div className={s.content}>
				{rewards.length === 0 ? (
					<div className={s.emptyBlock}>
						<h2 className={s.empty_title}>{localizeText('your_referrals')}</h2>
						<h3 className={s.empty_descr}>{localizeText('list_is_empty')}</h3>
					</div>
				) : (
					<>
						<h2 className={s.title}>{localizeText('your_referrals')}</h2>
						<div className={s.list}>
							{rewards.map((rewards, i) => {
								const findLevel = userLevels?.find(
									(level) => level?.name === rewards?.level
								);

								return (
									<div
										key={i}
										className={s.item}
									>
										<div className={s.leftBlock}>
											{findLevel && findLevel?.level < 21 && (
												<img
													className={s.user_level}
													src={getImageByURL(
														`../../../../../../../assets/img/levels/level_${findLevel?.level}.webp`
													)}
													alt='userlevel'
												/>
											)}
											<div className={s.leftBlock_text}>
												<div className={s.levelInfo}>
													{rewards.level}{' '}
													<div className={s.rewardUserId}>ID {rewards?.userId}</div>
												</div>
												<div className={s.date}>
													<Trans
														ns='components/routes/translation'
														i18nKey={'Referral.register_date'}
														values={{
															value: dayjs().format('DD.MM.YYYY')
														}}
														components={{ 1: <b className={s.green} /> }}
													/>
												</div>
											</div>
										</div>

										<div className={s.rightBlock}>
											<span>
												{renderCurrencySymbol(currency?.toLowerCase())}{' '}
												{formatNumber(convertToUsers(rewards.amount))}
											</span>
											<span>{localizeText('from_ref')}</span>
										</div>
									</div>
								);
							})}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

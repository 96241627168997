import React, { useEffect, useState } from 'react';
import { useLocales } from '@lib/hooks';
import { CloseSvg, LockOutline } from '@components/svg';
import { Button } from '@components/common/elements';
import { ButtonType } from '@types';
import s from './style.module.scss';
import cn from 'classnames';
import { getImageByURL } from '@utils/getImageByURL';

type PropsType = {
	toggleModal: () => void;
	isOpen: boolean;
};

export const HowGetBonus = (props: PropsType) => {
	const { toggleModal, isOpen } = props;

	const [innerOpened, setInnerOpened] = useState(false);

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	useEffect(() => {
		setTimeout(() => setInnerOpened(isOpen));
	}, [isOpen]);

	return (
		<div
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) {
					toggleModal();
				}
			}}
			className={cn(s.wrapper, { [s.opened]: isOpen })}
		>
			<div className={cn(s.inner, { [s.opened]: innerOpened })}>
				<div className={s.header}>
					<span className={s.header_title}>{localizeText('how_get_welcome_bonus')}</span>

					<div
						onClick={toggleModal}
						className={s.header_close}
					>
						<CloseSvg />
					</div>
				</div>
				<div className={s.body}>
					<div className={s.steps}>
						<div className={cn(s.step, s.selected)}>
							<div className={cn(s.step_desc, s.selected)}>
								<span>1</span>
								<span>{localizeText('how_get_welcome_bonus_step_1')}</span>
							</div>

							<span className={s.start}>{localizeText('start')}</span>
						</div>

						<div className={s.step}>
							<div className={s.step_desc}>
								<span>2</span>
								<span>{localizeText('how_get_welcome_bonus_step_2')}</span>
							</div>

							<LockOutline />
						</div>

						<div className={s.step}>
							<div className={s.step_desc}>
								<span>3</span>
								<span>{localizeText('how_get_welcome_bonus_step_3')}</span>
							</div>

							<LockOutline />
						</div>
					</div>

					<Button
						handle={toggleModal}
						type={ButtonType.primary}
						text={localizeText('go_to_registration')}
						rippleAnimation
					/>

					<img
						className={s.banner}
						alt='How to get bonus'
						src={getImageByURL(
							'../../../../../../../../assets/img/common/how_to_get_bonus.webp'
						)}
					/>
				</div>
			</div>
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { IProviderGame, ModalType } from '@types';
import { useLocales } from '@lib/hooks';
import { getWaggeringGames } from '@utils';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import s from './style.module.scss';
import { LottieComponent } from '@components/common/elements/lottie';

export const WageringGames = () => {
	const { bonusBalance, balance, bonusBalanceEnableValue } = useSelector(
		userSelectors.userInfo
	);

	const sideBarLocalize = useLocales({
		path: 'components/common/page',
		node: 'Sidebar'
	});

	const [wageringGames, setWageringGames] = useState<IProviderGame[]>();

	useEffect(() => {
		if (bonusBalance) {
			getWaggeringGames(setWageringGames, true);
		}
	}, [bonusBalance]);

	const bonusBalanceActivated =
		Boolean(bonusBalance) && balance <= bonusBalanceEnableValue;

	return (
		<>
			{bonusBalanceActivated && wageringGames && wageringGames?.length > 0 && (
				<div
					onClick={() => setModalType(ModalType.wageringGames)}
					className={s.wagering_games}
				>
					<div className={s.wagering_games_descr}>
						{sideBarLocalize.localizeText('games_for_wagering')}{' '}
						<LottieComponent
							path={'../../../../../assets/lotties/arrows_lottie.json'}
							style={{ width: 44, height: 14 }}
						/>
					</div>

					<div className={s.wagering_games_images}>
						{wageringGames?.map((g) => {
							if (g) {
								const { id, image, name } = g;
								const img =
									!image || image === 'null' || image === 'undefined'
										? '/assets/img/common/game-card-image-default.svg'
										: image;

								return (
									g.image && (
										<img
											key={id}
											src={img}
											alt={name}
										/>
									)
								);
							}

							return null;
						})}
					</div>
				</div>
			)}
		</>
	);
};

import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import s from './style.module.scss';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { AndroidAppIcon, CloseAndroidBanner, StarIcon } from '@components/svg';
import { userSelectors } from '@store/reducers/user/selectors';
import { useLocales } from '@lib/hooks';
import { downloadURI, renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { Button } from '@components/common/elements';
import { AnalyticsEventType, ButtonType, SessionStorageParams } from '@types';
import { ANDROID_APP_LINK } from '@config/global';
import { setIsAndroidBanner } from '@store/reducers/common-ui/dispatchers';

export const MobileAppBanner = () => {
	const container = document.getElementById('android-app') as HTMLElement;
	const root = document.getElementById('root') as HTMLElement;

	const { localizeText } = useLocales({
		path: 'global',
		node: 'androidBanner'
	});

	const { isAndroidBanner } = useSelector(commonUISelectors.commonUIInfo);
	const { currency } = useSelector(userSelectors.userInfo);

	const active = container && isAndroidBanner && ANDROID_APP_LINK;

	useEffect(() => {
		if (active && root) {
			root.style.position = 'relative';
			root.style.top = '64px';

			sendAnalyticsHandler(AnalyticsEventType.apk_show);
		} else {
			root.style.top = '0px';
		}
	}, [active]);

	const downloadApp = () => {
		sendAnalyticsHandler(AnalyticsEventType.apk_click_download);
		ANDROID_APP_LINK && downloadURI(ANDROID_APP_LINK, 'topx.apk');
	};

	const closeBanner = () => {
		sessionStorage?.setItem(SessionStorageParams?.apkBanner, 'closed');
		sendAnalyticsHandler(AnalyticsEventType.apk_click_close);
		setIsAndroidBanner(false);
	};

	const renderBanner = () => {
		if (active) {
			return (
				<div className={s.container}>
					<button
						onClick={closeBanner}
						className={s.closeBtn}
					>
						<CloseAndroidBanner />
					</button>

					<AndroidAppIcon />

					<div className={s.textBlock}>
						<span className={s.app}>{localizeText('app_title')}</span>
						<span className={s.android}>{localizeText('for_android')}</span>
						<div className={s.stars}>
							{Array(5)
								.fill(0)
								.map((_, index) => (
									<StarIcon key={index + Math.random()} />
								))}
						</div>
						<span className={s.bonus}>
							{localizeText('bonus_android_app', {
								curr: renderCurrencySymbol(currency)
							})}
						</span>
					</div>

					<Button
						className={s.btn}
						type={ButtonType?.primary}
						text={localizeText('install_app')}
						handle={downloadApp}
					/>
				</div>
			);
		}

		return null;
	};

	return createPortal(renderBanner(), container);
};

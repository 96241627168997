import React, { useState } from 'react';
import {
	CategoriesIcons,
	CloseSvg,
	ProviderListSvg,
	SearchSvg,
	ShevronSvg
} from '@components/svg';
import {
	IGameCategory,
	IProvidersFilter,
	CategoriesKeys,
	AnalyticsEventType
} from '@types';
import { Ripple } from '@components/common/elements';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import { useSelector } from 'react-redux';
import { useLocales, useUrlLang } from '@lib/hooks';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import s from './style.module.scss';
import { CategoryListModal } from './parts';
import { sendAnalyticsHandler } from '@utils';

interface ITab {
	title: string;
	category: IGameCategory;
}

type PropsTypes = {
	isFilterApplied: boolean;
	resetProvider: () => void;
	category: IGameCategory;
	allCategories: ITab[];
	onCategoryClick: (
		tabid: number,
		categoryKey: string,
		isDeleteCategory?: boolean
	) => void;
};

const Component = (props: PropsTypes) => {
	const { isFilterApplied, allCategories, category, resetProvider, onCategoryClick } =
		props;

	const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);

	const toggleCategoryModal = () => setIsOpenCategoryModal(!isOpenCategoryModal);

	const navigate: NavigateFunction = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const openProvidersSearch = () => {
		setTimeout(() => navigate(langUrlPrefix + pathsConfig.providers.init.link), 150);

		sendAnalyticsHandler(AnalyticsEventType?.header_click_providers);
	};

	const openGamesSearch = () => {
		setTimeout(() => navigate(langUrlPrefix + pathsConfig.gameSearch.link), 150);

		sendAnalyticsHandler(AnalyticsEventType?.header_click_search);
	};

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const location = useLocation();
	let provider: IProvidersFilter | null = null;

	if (location.state) {
		provider = location.state.provider;
	} else {
		provider = null;
	}

	const { localizeText } = useLocales({
		path: 'components/common/elements',
		node: 'GamesViewer'
	});

	const globalLocalize = useLocales({
		path: 'global',
		node: 'routes'
	});

	const resetCategoryHandler = () => {
		const findAllCategories = allCategories?.find(
			(cat) => cat?.category?.key === CategoriesKeys.all
		);

		sendAnalyticsHandler(AnalyticsEventType?.filter_unchoose_game_type, {
			filter_unchoose_game_type: category?.key
		});

		if (findAllCategories) {
			onCategoryClick(
				findAllCategories?.category?.id,
				findAllCategories?.category?.key,
				true
			);
		}
	};

	return (
		<>
			<div className={s.gamesViewerHeader}>
				<button
					onClick={() => {
						toggleCategoryModal();
						sendAnalyticsHandler(AnalyticsEventType?.open_filter);
					}}
					className={s.categoryBtn}
				>
					<CategoriesIcons name={category?.key} />

					<div className={s.categoryName}>
						{localizeText(category.name.replace(' ', '_'))}
					</div>

					<ShevronSvg fill='var(--main-secondary)' />

					<Ripple />
				</button>

				<div className={s.gamesViewerMenu}>
					<button
						className={s.gamesViewerMenuButton}
						onClick={openProvidersSearch}
					>
						<ProviderListSvg
							width={24}
							height={24}
						/>
						{!isMobile && (
							<span>{globalLocalize.localizeText('providers.linkName')}</span>
						)}
						<Ripple />
					</button>
					<button
						className={s.gamesViewerMenuButton}
						onClick={openGamesSearch}
					>
						<SearchSvg
							width={24}
							height={24}
						/>
						{!isMobile && (
							<span>{globalLocalize.localizeText('gameSearch.linkName')}</span>
						)}
						<Ripple />
					</button>
				</div>
			</div>

			<div className={s.titleMobileBlock}>
				<div className={s.title}>
					{isFilterApplied && category.key === CategoriesKeys?.all ? (
						<div
							onClick={() =>
								setTimeout(() => {
									resetProvider();
								}, 200)
							}
							className={s.gamesViewerFilter}
						>
							<span>{provider?.name}</span>

							<button>
								<CloseSvg
									width={14}
									height={14}
								/>
							</button>
							<Ripple />
						</div>
					) : category.key === CategoriesKeys?.all ? (
						localizeText(category.name.replace(' ', '_'))
					) : (
						<div
							onClick={() =>
								setTimeout(() => {
									resetCategoryHandler();
								}, 200)
							}
							className={s.gamesViewerFilter}
						>
							<span>{localizeText(category.name.replace(' ', '_'))}</span>

							<button>
								<CloseSvg
									width={14}
									height={14}
								/>
							</button>
							<Ripple />
						</div>
					)}
				</div>
			</div>

			<CategoryListModal
				isOpen={isOpenCategoryModal}
				closeModal={toggleCategoryModal}
				allCategories={allCategories}
				onCategoryClick={onCategoryClick}
			/>
		</>
	);
};

export default Component;

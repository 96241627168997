import React, { useState } from 'react';
import Div100vh from 'react-div-100vh';
import convertToUsers from '@utils/convertToUsers';
import { useSelector } from 'react-redux';
import { useLocales } from '@lib/hooks';
import { authSelectors } from '@store/reducers/auth/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { CloseSvg, ShevronLevels } from '@components/svg';
import { userSelectors } from '@store/reducers/user/selectors';
import s from './style.module.scss';
import cn from 'classnames';
import { getImageByURL } from '@utils/getImageByURL';

export const CashbackRulesModal = () => {
	const { userLevels, userLevelId } = useSelector(userSelectors.userInfo);

	const { isAuthed } = useSelector(authSelectors.authInfo);

	const [selectedLevel, setSelectedLevel] = useState<null | number>(null);

	const closeModalHanler = () => {
		setModalType(null);
	};

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'CashbackBonuses'
	});

	const levels = [...userLevels];

	const currentLevel = userLevels?.find((e) => e?.id === userLevelId);

	return (
		<Div100vh className={cn(s.content, { [s.nonAuthed]: !isAuthed })}>
			<div
				className={cn(s.wrapper, s.opened, {
					[s.nonAuthed]: !isAuthed
				})}
			>
				<div className={s.header}>
					<div className={s.headerWrapper}>
						<div className={s.headerTitle}>{localizeText('accrual_rules')}</div>
						<div
							onClick={closeModalHanler}
							className={s.headerClose}
						>
							<CloseSvg />
						</div>
					</div>
				</div>

				<div className={s.levels}>
					{levels
						?.sort((a, b) => a?.level - b?.level)
						?.map((l) => {
							if (!l) {
								return null;
							}

							const {
								level,
								id,
								cashbackPercentage,
								name,
								cashbackMaxSum,
								cashbackWager,
								cashbackPayout
							} = l;
							const isCurrent = currentLevel && currentLevel?.level === level;

							const isSelected = selectedLevel === id;

							return (
								<div
									onClick={() =>
										selectedLevel === id ? setSelectedLevel(null) : setSelectedLevel(id)
									}
									className={cn(s.item, {
										[s.current]: isSelected
									})}
								>
									{cashbackPercentage ? (
										<div className={cn(s.сashback, { [s.current]: isCurrent })}>
											<b>{cashbackPercentage}%</b> <span>{localizeText('cashback')}</span>
										</div>
									) : null}
									<div className={s.item_top}>
										{level < 21 && (
											<img
												className={s.levelImg}
												src={getImageByURL(
													`../../../../../../../assets/img/levels/level_${level}.webp`
												)}
											/>
										)}

										<div className={s.levelInfo}>
											<span className={s.levelInfo_name}>{name}</span>
											<span
												className={cn(s.levelInfo_won, {
													[s.active]: isCurrent
												})}
											>
												{localizeText('level_number', { level })}
											</span>
										</div>

										<ShevronLevels
											className={cn(s.shevron, { [s.opened]: isSelected })}
										/>
									</div>

									<div
										className={cn(s.item_bottom, {
											[s.opened]: isSelected
										})}
									>
										<div className={s.item_bottom_info}>
											<span>{localizeText('max_cashback_sum_modal')}</span>
											<span>{convertToUsers(cashbackMaxSum)}</span>
										</div>
										<div className={s.divider} />
										<div className={s.item_bottom_info}>
											<span>{localizeText('wager')}</span>
											<span>X{cashbackWager}</span>
										</div>
										<div className={s.divider} />
										<div className={s.item_bottom_info}>
											<span>{localizeText('cashback_payout')}</span>
											<span>X{cashbackPayout}</span>
										</div>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</Div100vh>
	);
};

import React from 'react';
import Div100vh from 'react-div-100vh';
import { useLocales, useUrlLang } from '@lib/hooks';
import { CloseSvg, DownloadArrow } from '@components/svg';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import { Button, GoHome } from '@components/common/elements';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { AnalyticsEventType, ButtonType } from '@types';
import { downloadURI, sendAnalyticsHandler } from '@utils';
import PoliticsTermsAndConditions from './PoliticsTermsAndConditions';
import { APP_PROD_LINK_SHORT, APP_TITLE } from '@config/global';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import s from './style.module.scss';
import cn from 'classnames';

type PoliticsRouteContentWrapperP = {
	localizeNode: string;
	paragraphQty: number;
};

export const PoliticsRouteContentWrapper = (props: PoliticsRouteContentWrapperP) => {
	const { localizeNode, paragraphQty } = props;
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { langUrlPrefix } = useUrlLang();
	const navigate = useNavigate();

	const closeModalHandler = () => {
		navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link);
		setModalType(null);
	};

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: localizeNode
	});

	const Container = isMobile
		? Div100vh
		: (
				props: JSX.IntrinsicAttributes &
					React.ClassAttributes<HTMLDivElement> &
					React.HTMLAttributes<HTMLDivElement>
			) => <div {...props} />;

	const downloadHandler = () => {
		downloadURI(`/files/${localizeNode}.pdf`, localizeNode);

		sendAnalyticsHandler(AnalyticsEventType?.information_download, {
			information_block: localizeNode
		});
	};

	return (
		<>
			<Helmet>
				<link
					rel='canonical'
					href={window?.location?.href}
				/>
			</Helmet>
			<div className={cn(s.wrapper, { [s.nonAuthed]: !isAuthed })}>
				{!isMobile && (
					<GoHome
						analiticsHandler={() =>
							sendAnalyticsHandler(AnalyticsEventType?.information_go_to_main)
						}
					/>
				)}
				<Container className={s.inner}>
					<div className={s.header}>
						<div className={s.headerWrapper}>
							<div className={s.headerTitle}>{localizeText('title')}</div>
							{isMobile && (
								<div
									onClick={closeModalHandler}
									className={s.headerClose}
								>
									<CloseSvg />
								</div>
							)}
						</div>
					</div>

					<div className={s.window}>
						{localizeNode === 'PoliticsTermsAndConditions' ? (
							<PoliticsTermsAndConditions />
						) : (
							[...Array(paragraphQty).keys()].map((p, i) => (
								<p
									key={i}
									className={s.text}
								>
									{localizeText(`content_${i + 1}`)
										?.replaceAll('topx.one', APP_PROD_LINK_SHORT)
										?.replaceAll('TopX', APP_TITLE)}
								</p>
							))
						)}

						<Button
							className={s.btn}
							type={ButtonType.secondary}
							handle={downloadHandler}
						>
							<DownloadArrow /> Download
						</Button>
					</div>
				</Container>
			</div>
		</>
	);
};

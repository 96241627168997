import React from 'react';
import { useLocales } from '@lib/hooks';
import { DefaultToast } from '@components/common/global-settings';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReferralLinkP } from '../../type';
import { Copy } from '@components/svg';
import toast from 'react-hot-toast';
import s from './style.module.scss';
import cn from 'classnames';

export const ReferralLink = (props: ReferralLinkP) => {
	const { link, isAuthed } = props;

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Referral'
	});

	return (
		<div className={s.content}>
			<div className={cn(s.input, { [s.inputDisabled]: !isAuthed })}>
				<span className={s.inputLabel}>{localizeText('your_ref_link')}</span>
				<span className={s.inputValue}>{link}</span>
				<div className={s.copy}>
					<CopyToClipboard
						text={link}
						onCopy={() =>
							toast((t) => (
								<DefaultToast
									t={t}
									text={'link_copied'}
								/>
							))
						}
					>
						<Copy />
					</CopyToClipboard>
				</div>
			</div>
		</div>
	);
};

{
	/* <Button
					handle={
						isAuthed
							? updateReferralCode
							: () => {
									setModalType(ModalType.auth);
									setAuthType(AuthType.signUp);
							  }
					}
					className={s.btn}
					isDisabled={false}
					text={isAuthed ? localizeText('upd_link') : localizeText('get_link')}
				/> */
}

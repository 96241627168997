import { AnalyticsTestsNameE } from '@types';
import { valueOfYandexMetrikaTest } from '@utils';
import { useEffect, useState } from 'react';
import { useLocales } from './useLocales';

enum YandexMetrikaLangEnum {
	control = 'control',
	new_version = 'new_version'
}

export function useGetButtonsLang() {
	const [bannerValue, setBannerValue] = useState<string>('');
	const authButtons = valueOfYandexMetrikaTest(AnalyticsTestsNameE?.AUTH_BUTTONS);

	const { localizeText } = useLocales({
		path: 'components/common/page',
		node: 'Header'
	});

	useEffect(() => {
		setBannerValue(authButtons || YandexMetrikaLangEnum.control);
	}, [authButtons]);

	function renderLangSignIn() {
		if (bannerValue === YandexMetrikaLangEnum?.new_version) {
			return localizeText('login');
		}

		return localizeText('sign_in');
	}

	function renderBannerDescr() {
		if (bannerValue === YandexMetrikaLangEnum?.new_version) {
			return localizeText('register_today');
		}

		return localizeText('sign_up_today');
	}

	function renderLangSignUp() {
		if (bannerValue === YandexMetrikaLangEnum?.new_version) {
			return localizeText('register');
		}

		return localizeText('sign_up');
	}

	return { renderLangSignIn, renderLangSignUp, renderBannerDescr };
}

import React from 'react';
import Countdown from 'react-countdown';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Button } from '@components/common/elements';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import { userSelectors } from '@store/reducers/user/selectors';
import { pathsConfig } from '@config/paths';
import { useNavigate } from 'react-router-dom';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import {
	formatNumber,
	millisecondsUntilNextFriday,
	renderCurrencySymbol,
	sendAnalyticsHandler
} from '@utils';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import s from './style.module.scss';
import convertToUsers from '@utils/convertToUsers';
import { getImageByURL } from '@utils/getImageByURL';

type DataType = {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
};

export const CashbackBlock = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'CashbackBonuses'
	});

	const { langUrlPrefix } = useUrlLang();

	const navigate = useNavigate();

	const { userLevels, userLevelId, currency } = useSelector(userSelectors.userInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { currentWeekCashbackAmount } = useSelector(bonusSelectors.bonusInfo);
	const { isTablet } = useSelector(commonUISelectors.commonUIInfo);

	const currentLevel = userLevels?.find((e) => e?.id === userLevelId);

	const renderer = ({ days, hours, minutes, seconds }: DataType) => {
		return (
			<div className={s.counter}>
				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{days > 9 ? days?.toString()[0] : 0}</div>
						<div>{days > 9 ? days?.toString()[1] : days}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('day')}</span>
				</div>

				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{hours > 9 ? hours?.toString()[0] : 0}</div>
						<div>{hours > 9 ? hours?.toString()[1] : hours}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('hours')}</span>
				</div>

				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{minutes > 9 ? minutes?.toString()[0] : 0}</div>
						<div>{minutes > 9 ? minutes?.toString()[1] : minutes}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('minutes')}</span>
				</div>

				<div className={s.counter_block}>
					<div className={s.counter_block_cards}>
						<div>{seconds > 9 ? seconds?.toString()[0] : 0}</div>
						<div>{seconds > 9 ? seconds?.toString()[1] : seconds}</div>
					</div>
					<span className={s.counter_block_text}>{localizeText('seconds')}</span>
				</div>
			</div>
		);
	};

	const msUntilNextFriday = millisecondsUntilNextFriday();

	return (
		<div className={s.container}>
			{!isTablet && (
				<div className={s.text_block}>
					<div className={s.imgBlock}>
						<img
							className={s.img}
							src={getImageByURL('../../../../../../assets/img/level-bonus/casback.webp')}
							alt='Cashback'
						/>
					</div>
				</div>
			)}

			<div className={s.counterWrapper}>
				<div className={s.titleBlock}>
					<h2>{localizeText(isAuthed ? 'cashback_title' : 'cashback_title_nonauth')}</h2>
					<span>{localizeText('cashback_title_description')}</span>
				</div>

				{isTablet && (
					<img
						className={s.img}
						src={getImageByURL('../../../../../../assets/img/level-bonus/casback.webp')}
						alt='Cashback'
					/>
				)}

				<div className={s.counterBlock}>
					{!isAuthed ? (
						<div className={s.cashbackPercentage}>
							{localizeText('cashback_up_to_30')}
						</div>
					) : currentLevel?.cashbackPercentage && currentLevel?.cashbackPercentage > 0 ? (
						<div className={s.cashbackPercentage}>
							{localizeText('cashback_percentage', {
								amount: currentLevel?.cashbackPercentage
							})}
						</div>
					) : null}
					<div className={s.counterBlock_bottom}>
						<div className={s.timer}>
							<div className={s.timer_title}>
								{isAuthed && currentWeekCashbackAmount > 0
									? localizeText('timer_title_sum', {
											sum: formatNumber(convertToUsers(currentWeekCashbackAmount)),
											curr: renderCurrencySymbol(currency) || '৳'
										})
									: localizeText('timer_title')}
							</div>

							<Countdown
								date={new Date(Date.now() + msUntilNextFriday)}
								renderer={renderer}
								daysInHours
							/>

							<Button
								rippleAnimation
								className={s.contactBtn}
								type={ButtonType?.primary}
								handle={() => {
									isAuthed
										? navigate(langUrlPrefix + pathsConfig.home.link)
										: setModalType(ModalType.auth);
									isAuthed
										? sendAnalyticsHandler(AnalyticsEventType?.new_cashback_backtoplay)
										: sendAnalyticsHandler(AnalyticsEventType?.new_cashback_signup_new);
								}}
							>
								{localizeText(isAuthed ? 'continue_play' : 'register_btn')}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import AllBonusBody from './AllBonus';
import { useSelector } from 'react-redux';
import { useLocales, useUrlLang } from '@lib/hooks';
import { CloseSvg } from '@components/svg';
import { metaConfig } from '@config/meta';
import { casinoType } from '@utils/casinoType';
import { Helmet } from 'react-helmet';
import { pathsConfig } from '@config/paths';
import { useNavigate } from 'react-router-dom';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { Footer } from '@components/common/page';
import s from './style.module.scss';
import cn from 'classnames';

import { getBonusBalances, getFreeSpinsQty } from '@lib/api/bonus';
import { authSelectors } from '@store/reducers/auth/selectors';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler } from '@utils';

export const AllBonus = () => {
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);

	const [infoModal, setInfoModal] = useState<number | null>(null);

	const { langUrlPrefix } = useUrlLang();
	const navigate = useNavigate();

	const { localizeText } = useLocales({
		path: 'global',
		node: 'routes'
	});

	const pageTitle = localizeText('allBonus.linkName');

	const closeModal = () => {
		navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link);

		sendAnalyticsHandler(AnalyticsEventType?.close_back, {
			close_back: 'all_bonuses'
		});
	};

	const updateData = async () => {
		await getBonusBalances();
		await getFreeSpinsQty();
	};

	useEffect(() => {
		isAuthed && updateData();
	}, [isAuthed]);

	return (
		<>
			<Helmet>
				<title>{metaConfig.metatags[casinoType()].bonuses?.title}</title>
				<meta
					name='description'
					content={metaConfig.metatags[casinoType()].bonuses?.description}
				/>
				<link
					rel='canonical'
					href={window?.location?.href}
				/>
			</Helmet>
			<div className={cn(s.wrapper, { [s.modalOpened]: Boolean(infoModal) })}>
				{isMobile ? (
					<div className={s.headerWrapper}>
						<h1 className={s.title}>{pageTitle}</h1>
						<div
							onClick={closeModal}
							className={s.headerClose}
						>
							<CloseSvg />
						</div>
					</div>
				) : null}
				<AllBonusBody
					setInfoModal={setInfoModal}
					infoModal={infoModal}
				/>
			</div>

			<Footer />
		</>
	);
};

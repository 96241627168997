import React, { useEffect, useRef, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { isTapX } from '@config/global';
import { useLocales } from '@lib/hooks';

import { User } from './parts/user';
import { Navbar, Bottom } from './parts';
import { Buttons } from './parts/buttons';
import { SidebarHeader } from './parts/sidebar-header';
import { LogOutBlock, PromoCards } from '@components/common/sections';

import { authSelectors } from '@store/reducers/auth/selectors';
import { userSelectors } from '@store/reducers/user/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';

import { setIsSidebarOpened } from '@store/reducers/common-ui/dispatchers';

import s from './style.module.scss';

export const MobileSidebar = () => {
	const { isSidebarOpened, isMobile, isFullScreenGaming, isAndroidBanner } = useSelector(
		commonUISelectors.commonUIInfo
	);
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { langCode } = useSelector(userSelectors.userInfo);

	const wrapperRef = useRef<HTMLDivElement>(null);

	const { localizeText } = useLocales({
		path: 'components/common/page',
		node: 'Sidebar'
	});

	useEffect(() => {
		if (wrapperRef) {
			wrapperRef?.current?.scrollTo(0, 0);
		}

		const body = document?.querySelector('body');

		if (body) {
			if (isSidebarOpened) {
				body.style.overflow = 'hidden';
			} else {
				body.style.overflow = 'auto';
			}
		}
	}, [isSidebarOpened]);

	useEffect(() => {
		if (wrapperRef && wrapperRef?.current) {
			if (isSidebarOpened) {
				setTimeout(
					() =>
						wrapperRef && wrapperRef.current
							? (wrapperRef.current.style.height = '100vh')
							: null,
					100
				);
			} else {
				wrapperRef && wrapperRef.current
					? (wrapperRef.current.style.height = 'unset')
					: null;
			}
		}
	}, [isSidebarOpened]);

	if (!isMobile && isFullScreenGaming) {
		return null;
	}

	const handleClickOutside = (e: MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setIsSidebarOpened(false);
		}
	};

	return (
		<>
			<div
				className={cn(s.wrapper, {
					[s.wrapperOpened]: isSidebarOpened,
					[s.wrapperClosed]: !isSidebarOpened,
					[s.androidBanner]: isAndroidBanner
				})}
				onClick={handleClickOutside}
			/>
			<div
				ref={wrapperRef}
				className={cn(s.scroll_container, {
					[s.scroll_container_authed]: isAuthed,
					[s.scroll_containerOpened]: isSidebarOpened,
					[s.scroll_containerClosed]: !isSidebarOpened,
					[s.androidBanner]: isAndroidBanner
				})}
			>
				{!isTapX && !isAuthed && (
					<div className={s.nonAuthedPromo}>
						<SidebarHeader />

						<PromoCards
							isMenu
							goFunc={() => setIsSidebarOpened(false)}
							isSidebar={true}
						/>

						<Buttons />
					</div>
				)}
				{isAuthed && (
					<User
						isSidebarOpened={isSidebarOpened}
						localizeText={localizeText}
					/>
				)}
				<div className={cn(s.inner)}>
					<Navbar isAuthed={isAuthed} />

					{isAuthed && <LogOutBlock />}

					<div className={s.inner_otherColor}>
						<Bottom
							localizeText={localizeText}
							currentLangCode={langCode}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

import React from 'react';
import { useLocales } from '@lib/hooks';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { CloseSvg } from '@components/svg';
import Div100vh from 'react-div-100vh';
import s from './style.module.scss';
import { Button } from '@components/common/elements';
import { ButtonType } from '@types';

export const WithdrawalInfoModal = () => {
	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'WithdrawalInfoModal'
	});

	const closeModal = () => {
		setModalType(null);
	};

	return (
		<div className={s.wrapper}>
			<Div100vh className={s.inner}>
				<div className={s.header}>
					<div className={s.headerWrapper}>
						<div className={s.headerTitle}>{localizeText('money_blocked_title')}</div>
						<div
							onClick={closeModal}
							className={s.headerClose}
						>
							<CloseSvg />
						</div>
					</div>
				</div>

				<div className={s.content}>
					<div className={s.description}>
						<span>{localizeText('money_blocked_descr_1')}</span>
						<span>{localizeText('money_blocked_descr_2')}</span>
					</div>

					<Button
						handle={closeModal}
						rippleAnimation
						className={s.button}
						type={ButtonType.secondary}
						text={localizeText('close')}
					/>
				</div>
			</Div100vh>
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { FlowBlock, Invoice, VideoBlock, Withdrawal } from './parts';
import { PaymentPreloader } from '@components/common/elements';
import { ResolverP } from '@components/routes/payment/type';

import { paymentSelectors } from '@store/reducers/payment/selectors';
import { userSelectors } from '@store/reducers/user/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { bonusSelectors } from '@store/reducers/bonus/selectors';

import '../../../../../../node_modules/video-react/dist/video-react.css';
import s from './style.module.scss';

import { CloseSvg } from '@components/svg';
import { PaymentType } from '@types';

export const Resolver = (props: ResolverP) => {
	const {
		paymentType,
		localizeText,
		isPaymentProcessing,
		isRequestToWithdrawalSuccess,
		setInnerPlatformFlow,
		innerPlatformFlow,
		crossBtnHandler,
		setDataForPay,
		dataForPay,
		cancelModalHandler
	} = props;

	const [videoOpen, setVideoOpen] = useState(false);

	const [freespinsBonus, setFreespinsBonus] = useState<null | Array<{
		depositAmount: number;
		freespinAmount: number;
	}>>(null);

	const [doubleDeposBonus, setDoubleDeposBonus] = useState<boolean>(false);

	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { allRMWBonus } = useSelector(bonusSelectors.bonusInfo);
	const { selectedTypePaymentMethods, selectedPaymentMethodId } = useSelector(
		paymentSelectors.paymentInfo
	);
	const { currency, successInvoiceCount } = useSelector(userSelectors.userInfo);

	const openVideoHandler = () => {
		setVideoOpen(!videoOpen);
	};

	const currentPaymentMethod = selectedTypePaymentMethods?.find(
		(e) => e?.id === selectedPaymentMethodId
	);

	useEffect(() => {
		const freespins = allRMWBonus?.find(
			(e) =>
				e?.isActive &&
				Boolean(e?.config?.depositMap) &&
				e?.currency === currency &&
				e?.config?.depositNumber === successInvoiceCount + 1
		);

		const doubleDeposite = allRMWBonus?.find(
			(e) =>
				e?.isActive &&
				e?.config?.bonusPaymentPercentage === 100 &&
				e?.currency === currency &&
				e?.config?.depositNumber === successInvoiceCount + 1
		);

		if (doubleDeposite) {
			setDoubleDeposBonus(true);
		}

		if (freespins) {
			setFreespinsBonus(freespins?.config?.depositMap || null);
		}
	}, [allRMWBonus]);

	const calculateFSBonusHandler = (amount: number) => {
		let countOfFs = 0;

		if (freespinsBonus) {
			for (let i = 0; i < freespinsBonus?.length; i++) {
				const prev = Number(freespinsBonus[i - 1]?.depositAmount);
				const curr = Number(freespinsBonus[i]?.depositAmount);
				const next = Number(freespinsBonus[i + 1]?.depositAmount);

				if (!countOfFs) {
					if (!prev) {
						if (Number(amount) >= curr && Number(amount) < next) {
							countOfFs = freespinsBonus[i].freespinAmount;
						}
					} else {
						if (i === freespinsBonus?.length - 1) {
							if (Number(amount) >= curr) {
								countOfFs = freespinsBonus[i].freespinAmount;
							}
						} else {
							if (Number(amount) === curr) {
								countOfFs = freespinsBonus[i].freespinAmount;
							} else if (Number(amount) >= curr && Number(amount) < next) {
								countOfFs = freespinsBonus[i].freespinAmount;
							}
						}
					}
				}
			}
		}

		return countOfFs;
	};

	return (
		<div style={{ position: 'relative' }}>
			<PaymentPreloader
				isActive={isPaymentProcessing}
				isRequestToWithdrawalSuccess={isRequestToWithdrawalSuccess}
			/>

			<div
				className={cn(s.wrapper, {
					[s.withdrawal]: paymentType === PaymentType.withdrawal
				})}
			>
				{!innerPlatformFlow || !isMobile ? (
					<>
						{paymentType === PaymentType.invoice && currentPaymentMethod && (
							<Invoice
								doubleDeposBonus={doubleDeposBonus}
								calculateFSBonusHandler={calculateFSBonusHandler}
								currentPaymentMethod={currentPaymentMethod}
								localizeText={localizeText}
								setInnerPlatformFlow={setInnerPlatformFlow}
								setDataForPay={setDataForPay}
								innerPlatformFlow={innerPlatformFlow}
								paymentType={paymentType}
								openVideoHandler={openVideoHandler}
							/>
						)}
						{paymentType === PaymentType.withdrawal && currentPaymentMethod && (
							<Withdrawal
								currentPaymentMethod={currentPaymentMethod}
								localizeText={localizeText}
								cancelModalHandler={cancelModalHandler}
								innerPlatformFlow={innerPlatformFlow}
							/>
						)}
					</>
				) : (
					<FlowBlock
						currentPaymentMethod={currentPaymentMethod}
						dataForPay={dataForPay}
						crossBtnHandler={crossBtnHandler}
						innerPlatformFlow={innerPlatformFlow}
					/>
				)}
			</div>

			{!isMobile && innerPlatformFlow && (
				<div
					onClick={({ target, currentTarget }) => {
						if (target === currentTarget) {
							setInnerPlatformFlow(null);
						}
					}}
					className={s.modalFlowBg}
				>
					<div className={s.modalFlowBg_inner}>
						<div className={s.modalFlowBg_header}>
							<h2>{localizeText('invoice')}</h2>

							<button
								onClick={() => setInnerPlatformFlow(null)}
								className={s.modalFlowBg_header_close}
							>
								<CloseSvg />
							</button>
						</div>

						<FlowBlock
							currentPaymentMethod={currentPaymentMethod}
							dataForPay={dataForPay}
							crossBtnHandler={crossBtnHandler}
							innerPlatformFlow={innerPlatformFlow}
						/>
					</div>
				</div>
			)}

			{currentPaymentMethod?.settings?.videoUrl && videoOpen && (
				<VideoBlock
					openVideoHandler={openVideoHandler}
					videoUrl={currentPaymentMethod?.settings?.videoUrl}
				/>
			)}
		</div>
	);
};

import React, { lazy, useRef } from 'react';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { AnalyticsEventType, ModalType } from '@types';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useHideNavElements } from '@lib/hooks';
import { useLocation } from 'react-router-dom';
import s from './style.module.scss';
import cn from 'classnames';
import { Hamburger } from './parts';
import { sendAnalyticsHandler } from '@utils';
import { setIsSidebarOpened } from '@store/reducers/common-ui/dispatchers';
import useShowSidebarButton from '@lib/hooks/useShowSidebarButton';

const HeaderBody = lazy(() => import('./Header'));

export const Header = () => {
	const { pathname } = useLocation();
	const {
		modalType,
		isMobile,
		isSidebarDesktopOpened,
		is404Page,
		isTablet,
		isSidebarOpened,
		isAndroidBanner
	} = useSelector(commonUISelectors.commonUIInfo);
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const buttonRef = useRef<HTMLButtonElement | null>(null);

	const isHideNavElements = useHideNavElements();

	const hideHeader = isMobile
		? (isHideNavElements && isAuthed && isMobile) ||
			pathname.includes('/games/provider') ||
			modalType === ModalType.auth ||
			modalType === ModalType.signIn ||
			modalType === ModalType.resetPass ||
			modalType === ModalType.renewPassword ||
			pathname.includes('/search') ||
			pathname.includes('/providers')
		: false;

	const hideBtn = (type?: ModalType | null): boolean => {
		if ((type !== null && isAuthed) || hideHeader) return true;

		return false;
	};

	const shouldHide = hideBtn(modalType);

	useShowSidebarButton({
		isSidebarOpened,
		buttonRef,
		element: 'header',
		shouldHide: shouldHide
	});

	const onBurgerClickHandler = () => {
		setIsSidebarOpened(!isSidebarOpened);
		sendAnalyticsHandler(AnalyticsEventType?.header_click_menu);
	};

	if (!isMobile && is404Page) {
		return null;
	}

	return (
		<React.Suspense>
			{isMobile && (
				<button
					ref={buttonRef}
					onClick={onBurgerClickHandler}
					className={cn(s.menuBtn, {
						[s.menuBtnHide]: shouldHide,
						[s.androidBanner]: isAndroidBanner
					})}
				>
					<Hamburger />
				</button>
			)}
			<header
				className={cn(s.wrapper, {
					[s.withoutHeader]: hideHeader,
					[s.hideSidebar]: !isSidebarDesktopOpened || isTablet,
					[s.isAuthed]: isAuthed,
					[s.isSideBarClosed]: !isMobile && !isTablet && isSidebarDesktopOpened,
					[s.isNotAuthed]: !isAuthed,
					[s.signIn]: modalType === ModalType.signIn && isMobile,
					[s.androidBanner]: isAndroidBanner
				})}
			>
				<HeaderBody />
			</header>
		</React.Suspense>
	);
};

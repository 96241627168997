import React, { ReactNode } from 'react';
import { StaticContainer } from '@components/common/layout';
import { useHideNavElements } from '@lib/hooks';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { pathsConfig } from '@config/paths';

type MainP = { children: ReactNode };

export const Main = ({ children }: MainP) => {
	const { pathname } = useLocation();
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isSidebarDesktopOpened, isTablet } = useSelector(
		commonUISelectors.commonUIInfo
	);
	const isHideNavElements = useHideNavElements();

	return (
		<main
			className={cn(s.wrapper, {
				[s.hiddenSidebar]: !isSidebarDesktopOpened || isTablet,
				[s.withoutSideBar]:
					pathsConfig?.sign_up_1?.link &&
					pathname?.includes(pathsConfig?.sign_up_1?.link),
				[s.isAuthed]: isAuthed,
				[s.withoutHeader]:
					(isHideNavElements && isAuthed) ||
					pathname.includes('/games/provider') ||
					(pathsConfig?.sign_up_1?.link &&
						pathname?.includes(pathsConfig?.sign_up_1?.link)) ||
					pathname?.includes(pathsConfig?.gameSearch?.link) ||
					pathname?.includes(pathsConfig?.providers.init.link)
			})}
		>
			<StaticContainer>{children}</StaticContainer>
		</main>
	);
};

import React from 'react';
import { Button } from '@components/common/elements';
import { AnalyticsEventType, ButtonType } from '@types';
import { CloseSvg } from '@components/svg';
import Div100vh from 'react-div-100vh';
import s from './style.module.scss';
import '../../../../../../../../node_modules/video-react/dist/video-react.css';
import { useLocales } from '@lib/hooks';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { sendAnalyticsHandler } from '@utils';

type PropsType = {
	openVideoHandler: () => void;
	videoUrl: string;
};

export const VideoBlock = (props: PropsType) => {
	const { openVideoHandler, videoUrl } = props;
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Payment'
	});

	return (
		<Div100vh
			onClick={({ target, currentTarget }) => {
				if (target === currentTarget) {
					openVideoHandler();
					sendAnalyticsHandler(AnalyticsEventType?.referral_close_video);
				}
			}}
			className={s.videoInstructionModal}
		>
			<div className={s.modalInner}>
				<div className={s.videoModalHeadBlock}>
					<h2>{localizeText('video_instruction')}</h2>

					<button
						onClick={() => {
							openVideoHandler();
							sendAnalyticsHandler(AnalyticsEventType?.referral_close_video);
						}}
						className={s.videoModalHeadBlock_close}
					>
						<CloseSvg />
					</button>
				</div>
				<div className={s.videoModalBottomBlock}>
					<iframe
						className={s.video}
						width={isMobile ? '335px' : '590px'}
						height={isMobile ? '490px' : '390px'}
						src={videoUrl}
						title='YouTube video player'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					/>

					{isMobile && (
						<Button
							handle={openVideoHandler}
							className={s.videoModalBottomBlock_btn}
							type={ButtonType?.secondary}
							text={localizeText('close')}
						/>
					)}
				</div>
			</div>
		</Div100vh>
	);
};

import React from 'react';
import toast from 'react-hot-toast';
import OneSignal from 'react-onesignal';
import { SubscribeNotificationToast } from '@components/common/global-settings';
import { setOnesignalExternalIdHandler } from '@lib/api/auth';
import {
	AnalyticsEventType,
	NotificationsModalStorageE,
	OneSignalNotificationsE
} from '@types';
import { sendAnalyticsHandler } from '@utils';
import { useEffect } from 'react';

export const OnesignalComponent = () => {
	useEffect(() => {
		const notificationModal = window.localStorage.getItem(
			NotificationsModalStorageE.notification_modal
		);

		if (
			process.env.REACT_ONE_SIGNAL_APP_ID &&
			OneSignal?.Notifications?.permissionNative === OneSignalNotificationsE?.default &&
			!notificationModal
		) {
			setTimeout(() => {
				toast(
					(t) => {
						return <SubscribeNotificationToast t={t} />;
					},
					{ duration: 1000000000 }
				);

				sendAnalyticsHandler(AnalyticsEventType?.popup_show, {
					name_popup: 'subscribe_to_notifications'
				});
			}, 5000);
		}

		if (
			process.env.REACT_ONE_SIGNAL_APP_ID &&
			OneSignal?.Notifications?.permissionNative === OneSignalNotificationsE?.granted
		) {
			if (OneSignal.User.PushSubscription.id) {
				setOnesignalExternalIdHandler({
					subscriptionId: OneSignal.User.PushSubscription.id
				});
			}
		}
	}, []);

	return null;
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { WithdrawalResolver } from './WithdrawalResolver';

import { paymentSelectors } from '@store/reducers/payment/selectors';
import { userSelectors } from '@store/reducers/user/selectors';

import { WithdrawalP } from '../../../../type';

export const Withdrawal = (props: WithdrawalP) => {
	const { localizeText, currentPaymentMethod, cancelModalHandler, innerPlatformFlow } = props;
	const { balance } = useSelector(userSelectors.userInfo);
	const {
		withdrawalCommission,
		withdrawalCardNumber: cardNumber,
		withdrawalValue: amount,
		paymentMethodType,
		withdrawalCryptoAddress,
		withdrawalActiveCryptoCurrency
	} = useSelector(paymentSelectors.paymentInfo);

	const [disabledBtn, setDisabledBtn] = useState(false);

	useEffect(() => {
		if (
			+amount < currentPaymentMethod?.settings?.minWithdraw / 100 ||
			+amount > currentPaymentMethod?.settings?.maxWithdraw / 100 ||
			balance < currentPaymentMethod?.settings?.minWithdraw / 100
		) {
			setDisabledBtn(true);
		} else {
			setDisabledBtn(false);
		}
	}, [amount]);

	return (
		<>
			<WithdrawalResolver
				withdrawalActiveCryptoCurrency={withdrawalActiveCryptoCurrency}
				withdrawalCryptoAddress={withdrawalCryptoAddress}
				isNotSuitableAmount={disabledBtn}
				localizeText={localizeText}
				paymentMethodType={paymentMethodType}
				amount={amount}
				balance={balance}
				cardNumber={cardNumber}
				withdrawalCommission={withdrawalCommission}
				currentPaymentMethod={currentPaymentMethod}
				cancelModalHandler={cancelModalHandler}
				innerPlatformFlow={innerPlatformFlow}
			/>
		</>
	);
};

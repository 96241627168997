import React from 'react';
import { useLocales, useUrlLang } from '@lib/hooks';
import { RightArrowSwiper } from '@components/svg';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { Trans } from 'react-i18next';
import { userSelectors } from '@store/reducers/user/selectors';
import { useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import { AnalyticsEventType } from '@types';
import s from './style.module.scss';
import { getImageByURL } from '@utils/getImageByURL';

export const ReferralBlock = () => {
	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'TelegramBonuses'
	});

	const navigate = useNavigate();

	const { langUrlPrefix } = useUrlLang();

	const { isTablet } = useSelector(commonUISelectors.commonUIInfo);
	const { currency } = useSelector(userSelectors.userInfo);

	const renderImage = () => (
		<img
			className={s.img}
			src={getImageByURL('../../../../../../assets/img/referral/title.webp')}
			alt='Referral'
		/>
	);

	return (
		<div className={s.container}>
			<div
				onClick={() => {
					navigate(langUrlPrefix + pathsConfig?.referral?.link);
					sendAnalyticsHandler(AnalyticsEventType?.tg_go_to_referral);
				}}
				className={s.inner}
			>
				<div className={s.promoBlock}>{localizeText('another_bonus')}</div>
				<div className={s.text_block}>
					<h1 className={s.text_block_title}>
						<Trans
							ns='components/routes/translation'
							i18nKey={'TelegramBonuses.referral_whant_get'}
							values={{ curr: renderCurrencySymbol(currency) || '৳' }}
							components={{ b: <b /> }}
						/>
					</h1>

					<div className={s.text_block_descr}>
						{localizeText('referral_whant_get_descr')}
					</div>

					{isTablet && renderImage()}

					<button
						className={s.contactBtn}
						onClick={() => navigate(langUrlPrefix + pathsConfig?.referral?.link)}
					>
						{localizeText('how_to_earn')}
						<RightArrowSwiper />
					</button>
				</div>
				{!isTablet && renderImage()}
			</div>
		</div>
	);
};

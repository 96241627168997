import React from 'react';
import { useNavigate } from 'react-router-dom';
import { pathsConfig } from '@config/paths';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { AnalyticsEventType, ModalType } from '@types';
import { isTapX } from '@config/global';
import { useLocales, useUrlLang } from '@lib/hooks';
import { sendAnalyticsHandler } from '@utils';
import s from './style.module.scss';
import cn from 'classnames';

export const DepositBanner = () => {
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/common/sections',
		node: 'DepositBait'
	});

	const navigate = useNavigate();

	const goToDepositUser = () => {
		isAuthed
			? navigate(langUrlPrefix + pathsConfig?.payment?.link)
			: setModalType(ModalType.auth);

		sendAnalyticsHandler(AnalyticsEventType?.advertising_click_banner, {
			banner_place: 'главная карусель',
			banner_name: 'Click and double deposite',
			banner_position: 1
		});
	};

	return (
		<>
			<div
				onClick={goToDepositUser}
				className={cn(s.wrapper, {
					[s.tapX]: isTapX
				})}
			>
				<span className={s.getBonus}>{localizeText('get_bonus')}</span>
			</div>
		</>
	);
};

import { userSelectors } from '@store/reducers/user/selectors';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler, getUserTheme } from '@utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const AnalyticsTests = () => {
	const { id, successInvoiceCount } = useSelector(userSelectors.userInfo);

	useEffect(() => {
		if (id) {
			window?.ym(97429474, 'params', { UserID: id, deps: successInvoiceCount });

			sendAnalyticsHandler(AnalyticsEventType?.page_view, {
				UserID: id,
				theme: getUserTheme()
			});
		}
	}, [id]);

	return null;
};

import React from 'react';
import { useLocales, useUrlLang } from '@lib/hooks';
import { CloseSvg } from '@components/svg';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/common/elements/button';
import { ButtonType } from '@types';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { useSelector } from 'react-redux';
import { setBonusGameOpen } from '@store/reducers/bonus/dispatchers';
import s from './style.module.scss';
import { renderGameUrl } from '@utils';
import { getImageByURL } from '@utils/getImageByURL';

export const BonusOpenGame = () => {
	const { bonusGameOpen } = useSelector(bonusSelectors.bonusInfo);

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/common/elements',
		node: 'ProviderGameCard'
	});

	if (!bonusGameOpen) {
		return null;
	}

	const closeBonusGameModal = () => {
		setBonusGameOpen(null);
	};

	const renderImage = () => {
		if (bonusGameOpen?.isFreespinGame) {
			return getImageByURL('../../../../../assets/img/games/freespin_play.webp');
		}

		if (bonusGameOpen?.isFreezeBalancePlay) {
			return getImageByURL('../../../../../assets/img/games/bonus_balance_play.webp');
		}

		return getImageByURL('../../../../../assets/img/games/main_balance_play.webp');
	};

	const renderTitle = () => {
		if (bonusGameOpen?.isFreespinGame) {
			return localizeText('freespin_play_title');
		}

		if (bonusGameOpen?.isFreezeBalancePlay) {
			return localizeText('bonus_balance_play_title');
		}

		return localizeText('main_balance_play_title');
	};

	const renderDescription = () => {
		if (bonusGameOpen?.isFreespinGame) {
			return localizeText('freespin_play_descr');
		}

		if (bonusGameOpen?.isFreezeBalancePlay) {
			return localizeText('bonus_balance_play_descr');
		}

		return localizeText('main_balance_play_descr');
	};

	const renderBtnText = () => {
		if (bonusGameOpen?.isFreespinGame) {
			return localizeText('freespin_play_btn');
		}

		if (bonusGameOpen?.isFreezeBalancePlay) {
			return localizeText('bonus_balance_play_btn');
		}

		return localizeText('main_balance_play_btn');
	};

	return (
		<div className={s.outer}>
			<div className={s.wrapper}>
				<div className={s.inner}>
					<div className={s.header}>
						<div className={s.headerWrapper}>
							<div
								onClick={closeBonusGameModal}
								className={s.headerClose}
							>
								<CloseSvg />
							</div>
						</div>
					</div>

					<div className={s.content}>
						<img
							className={s.img}
							src={renderImage()}
							alt='Balance type'
						/>

						<div className={s.title}>{renderTitle()}</div>
						<div className={s.description}>{renderDescription()}</div>

						<Button
							type={ButtonType?.primary}
							text={renderBtnText()}
							handle={() => {
								closeBonusGameModal();
								navigate(langUrlPrefix + renderGameUrl(bonusGameOpen?.route));
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

import React, { useEffect } from 'react';
import { Ripple } from '@components/common/elements';
import { formatNumber, renderCurrencySymbol, sendAnalyticsHandler } from '@utils';
import { pathsConfig } from '@config/paths';
import { useNavigate } from 'react-router-dom';
import { AnalyticsEventType } from '@types';
import { useLocales, useUrlLang } from '@lib/hooks';
import { Banner } from './parts';
import s from './style.module.scss';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { userSelectors } from '@store/reducers/user/selectors';
import convertToUsers from '@utils/convertToUsers';
import { getCurrentWeekCashbackAmount } from '@lib/api/bonus';
import { authSelectors } from '@store/reducers/auth/selectors';

type PropsType = { goFunc?: () => void; isMenu?: boolean };

export const Cashback = (props: PropsType) => {
	const { localizeText } = useLocales({
		path: 'components/common/sections',
		node: 'ExtraOffers'
	});

	const { isAuthed } = useSelector(authSelectors.authInfo);

	const { langUrlPrefix } = useUrlLang();

	const { currentWeekCashbackAmount } = useSelector(bonusSelectors.bonusInfo);
	const { currency } = useSelector(userSelectors.userInfo);

	const navigate = useNavigate();

	const { goFunc, isMenu } = props;

	function getTime() {
		const now = new Date();
		const currentDay = now.getUTCDay();
		const daysUntilFriday = currentDay < 5 ? 5 - currentDay : 12 - currentDay;

		const nextFriday = new Date(now);

		nextFriday.setUTCDate(now.getUTCDate() + daysUntilFriday);
		nextFriday.setHours(23, 0, 0, 0);

		const msUntilNextFriday = nextFriday.getTime() - now.getTime();

		const hoursUntilTarget = Math.floor(msUntilNextFriday / (1000 * 60 * 60));

		return hoursUntilTarget;
	}

	const hoursUntilNextFriday = getTime();

	useEffect(() => {
		if (isAuthed) {
			getCurrentWeekCashbackAmount();
		}
	}, [isAuthed]);

	return (
		<div
			onClick={() => {
				navigate(langUrlPrefix + pathsConfig.cashback_bonus.link);

				goFunc && goFunc();

				sendAnalyticsHandler(
					isMenu
						? AnalyticsEventType?.menu_advertising_click_banner
						: AnalyticsEventType?.advertising_click_banner,
					{
						banner_place: 'Хедер реклама',
						banner_name: 'Cashback bonus',
						banner_position: 1
					}
				);
			}}
			className={s.card}
		>
			<div className={s.promoText}>
				<Trans
					ns='components/common/sections/translation'
					i18nKey={'ExtraOffers.time_until_cashback'}
					values={{
						time: hoursUntilNextFriday
					}}
				/>
			</div>

			<div className={s.textBlock}>
				<div className={s.textBlockAmount}>
					{`${renderCurrencySymbol(currency)?.toLowerCase()}${formatNumber(convertToUsers(currentWeekCashbackAmount))}`}
				</div>
				<span>{localizeText('your_cashback')}</span>
			</div>

			<Banner />
			<Ripple />
		</div>
	);
};

import React from 'react';
import {
	BonusBalancesBlock,
	DepositeBonusDescriptionModal,
	FreespinsBonusBlock,
	InvoiceBonusBlock,
	MenuBonusBlock,
	OtherBonuses
} from './parts';
import { CashbackBlock } from '../level-bonus/parts';
import s from './style.module.scss';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { useSelector } from 'react-redux';
import { authSelectors } from '@store/reducers/auth/selectors';

type PropsType = {
	setInfoModal: React.Dispatch<React.SetStateAction<number | null>>;
	infoModal: number | null;
};

const AllBonus = (props: PropsType) => {
	const { setInfoModal, infoModal } = props;

	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { freeSpinsInfo, bonusBalances } = useSelector(bonusSelectors.bonusInfo);

	const closeInfoModal = () => setInfoModal(null);

	const clickInfoHandler = (num: number) => setInfoModal(num);

	return (
		<>
			<MenuBonusBlock
				isBonusBalances={isAuthed && bonusBalances?.length > 0}
				isFreeSpin={isAuthed && !!freeSpinsInfo && freeSpinsInfo?.length > 0}
			/>

			{isAuthed && bonusBalances && bonusBalances?.length > 0 ? (
				<>
					<BonusBalancesBlock />
					<div className={s.divider} />
				</>
			) : null}

			{isAuthed && freeSpinsInfo && freeSpinsInfo?.length > 0 ? (
				<>
					<FreespinsBonusBlock />
					<div className={s.divider} />
				</>
			) : null}

			<InvoiceBonusBlock clickInfoHandler={clickInfoHandler} />

			<div className={s.divider} />

			<CashbackBlock />

			<div className={s.divider} />

			<OtherBonuses />

			<DepositeBonusDescriptionModal
				num={infoModal}
				toggleModal={closeInfoModal}
			/>
		</>
	);
};

export default AllBonus;

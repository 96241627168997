import React from 'react';
import s from './style.module.scss';
import { getImageByURL } from '@utils/getImageByURL';

export const Banner = () => {
	return (
		<div className={s.banner}>
			<img
				className={s.money}
				alt='Money'
				src={getImageByURL('../../../assets/img/referral/free-money-image.webp')}
			/>
		</div>
	);
};

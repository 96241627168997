import React, { Fragment } from 'react';
import { pathsConfig } from '@config/paths';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import { MenuSideBarNames, ModalType } from '@types';
import { NavbarItem } from './NavbarItem';
import { DropdownLang } from '@components/common/elements';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { useSelector } from 'react-redux';
import { isTapX } from '@config/global';
import { useUrlLang } from '@lib/hooks';
import { userSelectors } from '@store/reducers/user/selectors';
import s from './style.module.scss';
import cn from 'classnames';

type NavbarP = {
	isAuthed: boolean;
	isSideBarOpened: boolean;
};

export const Navbar = (props: NavbarP) => {
	const { isAuthed, isSideBarOpened } = props;

	const { isVerified } = useSelector(userSelectors.userInfo);
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const { langUrlPrefix } = useUrlLang();

	const navbarItems = [
		{
			link: langUrlPrefix + pathsConfig.profile.link,
			nameKey: MenuSideBarNames.settings,
			isRoute: true,
			show: isAuthed && !isVerified,
			line: true
		},
		{
			link: langUrlPrefix + pathsConfig?.home?.tabs?.casino?.link,
			nameKey: MenuSideBarNames.casino,
			isRoute: true,
			show: true,
			line: false
		},
		{
			link: langUrlPrefix + pathsConfig.bonus.link,
			nameKey: MenuSideBarNames.bonuces,
			isRoute: true,
			show: !isTapX,
			line: false
		},

		{
			link: langUrlPrefix + pathsConfig.profile.link,
			nameKey: MenuSideBarNames.settings,
			isRoute: !isMobile,
			show: isAuthed && isVerified,
			line: false,
			handle: () => setModalType(ModalType.profileSettings)
		},

		{
			link: langUrlPrefix + pathsConfig.transaction.link,
			nameKey: MenuSideBarNames.bets_and_tarns,
			isRoute: !isMobile,
			show: isAuthed,
			line: false,
			handle: () => setModalType(ModalType?.transAndBets)
		},

		{
			link: '',
			nameKey: MenuSideBarNames.promocode,
			isRoute: false,
			show: isAuthed,
			line: false,
			handle: () => setModalType(ModalType.promocode)
		},

		{
			link: langUrlPrefix + pathsConfig.information.link,
			nameKey: MenuSideBarNames.info,
			isRoute: !isMobile,
			show: true,
			line: true,
			handle: () => setModalType(ModalType?.information)
		}
	];

	return (
		<Fragment>
			<nav className={cn(s.navbar, { [s.hidden]: !isSideBarOpened })}>
				{navbarItems.map((route, i) => {
					if (route?.show) {
						return (
							<Fragment key={i}>
								<NavbarItem
									{...route}
									isSideBarOpened={isSideBarOpened}
								/>

								{route?.line && (
									<div className={s.devider}>
										<div className={s.devider_line} />
									</div>
								)}
							</Fragment>
						);
					}

					return null;
				})}
			</nav>

			<div className={s.changeLangBlock}>
				<DropdownLang
					hide={!isSideBarOpened}
					menu
				/>
			</div>
			<div className={s.devider}>
				<div className={s.devider_line} />
			</div>
		</Fragment>
	);
};

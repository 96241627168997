import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocales } from '@lib/hooks';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import {
	EmailBlock,
	NotificationBlock,
	PasswordBlock,
	PhoneBlock,
	WarningVerified
} from './parts';
import { userSelectors } from '@store/reducers/user/selectors';
import s from './style.module.scss';

type PropsType = {
	codeOpenModalHandler: (type: 'phone' | 'email') => void;
	passwordOpenModalHandler: () => void;
};

const Profile = (props: PropsType) => {
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);
	const { isVerified, id } = useSelector(userSelectors.userInfo);
	const [isEdit, setIsEdit] = useState(false);

	const { codeOpenModalHandler, passwordOpenModalHandler } = props;

	const { localizeText } = useLocales({
		path: 'components/routes',
		node: 'Profile'
	});

	return (
		<div className={s.window}>
			{(!isVerified || !isEdit) && <WarningVerified />}
			<div className={s.subtitle}>
				<span>{localizeText('profile_data')}</span>
				<span className={s.subtitle_id}>ID {id}</span>
			</div>
			<EmailBlock codeOpenModalHandler={codeOpenModalHandler} />
			<PhoneBlock
				isEdit={isEdit}
				setIsEdit={setIsEdit}
				// codeOpenModalHandler={codeOpenModalHandler}
			/>

			{process.env.REACT_ONE_SIGNAL_APP_ID && (
				<>
					<div className={s.line} />
					<NotificationBlock />
				</>
			)}

			<div className={s.line} />
			<PasswordBlock passwordOpenModalHandler={passwordOpenModalHandler} />
			{isMobile && <div className={s.line} />}
		</div>
	);
};

export default Profile;

import React from 'react';
import { isTapX } from '@config/global';
import { useNavigate } from 'react-router-dom';
import { Bottom, Navbar } from './parts';
import { useLocales, useUrlLang } from '@lib/hooks';
import { authSelectors } from '@store/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import {
	setIsSidebarDesktopOpened,
	setIsSidebarTabletOpened,
	setModalType
} from '@store/reducers/common-ui/dispatchers';
import { LogOutBlock } from '@components/common/sections';
import { pathsConfig } from '@config/paths';
import {
	DoubleArrow,
	TapXLogo,
	TopXHeaderLogo,
	TopXHeaderLogoSmall
} from '@components/svg';
import s from './style.module.scss';
import cn from 'classnames';

export const Sidebar = () => {
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { isSidebarDesktopOpened, isMobile, is404Page, isTablet, isSidebarTabletOpened } =
		useSelector(commonUISelectors.commonUIInfo);

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/common/page',
		node: 'Sidebar'
	});

	if (isMobile) {
		return null;
	}

	const onLogoClick = () => {
		setModalType(null);
		navigate(langUrlPrefix + pathsConfig.home.tabs.casino.link);

		isSidebarDesktopOpened &&
			isTablet &&
			setIsSidebarDesktopOpened(!isSidebarDesktopOpened);
	};

	if (is404Page) {
		return null;
	}

	const isSideBarOpened = isTablet ? isSidebarTabletOpened : isSidebarDesktopOpened;

	return (
		<div
			className={cn(s.wrapper, {
				[s.hidden]: !isSideBarOpened
			})}
		>
			<div
				onClick={() =>
					isTablet
						? setIsSidebarTabletOpened(!isSidebarTabletOpened)
						: setIsSidebarDesktopOpened(!isSidebarDesktopOpened)
				}
				className={cn(s.sideBarArrow, {
					[s.opened]: isSideBarOpened
				})}
			>
				<DoubleArrow />
			</div>

			<div
				className={cn(s.inner, {
					[s.hidden]: !isSideBarOpened
				})}
			>
				<div
					className={cn(s.logoBlock, {
						[s.hidden]: !isSideBarOpened
					})}
					onClick={onLogoClick}
				>
					{isSideBarOpened ? (
						isTapX ? (
							<TapXLogo className={s.logo} />
						) : (
							<TopXHeaderLogo className={s.logo} />
						)
					) : (
						<TopXHeaderLogoSmall />
					)}
				</div>

				<div className={cn(s.sidebarBody, { [s.hidden]: !isSideBarOpened })}>
					<Navbar
						isSideBarOpened={isSideBarOpened}
						isAuthed={isAuthed}
					/>

					<LogOutBlock isSideBarOpened={isSideBarOpened} />

					<Bottom
						isSideBarOpened={isSideBarOpened}
						localizeText={localizeText}
					/>
				</div>
			</div>
		</div>
	);
};
